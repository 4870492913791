import { Box, ClickAwayListener, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Popover } from '@mui/material'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import DomainTypeTooltip from 'components/domainType/DomainTypeTooltip'
import { ComponentProps, HTMLProps, cloneElement, forwardRef, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { getDomainTypeSetting } from 'utils/helpers'
import { useNavigate } from 'utils/hooks'
import { CustomEvent } from './CalendarView'

interface Props {
  event: CustomEvent
  onMouseDown(event: React.MouseEvent): void
  children: React.ReactElement
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: MenuList,
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

const ForwardTooltipRefAndProps = forwardRef(function ForwardTooltipRefAndProps({
  children,
  className,
  ...props
}: HTMLProps<HTMLDivElement> & { children: React.ReactElement }, ref): JSX.Element {
  const EventWrapper = children
  const containedDiv = EventWrapper.props.children
  return cloneElement(EventWrapper, {
    children: cloneElement(containedDiv, {
      ...containedDiv.props,
      ...props,
      ref
    })
  })
})

export default function CustomCalendarEventWrapper({
  event,
  onMouseDown,
  children
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const navigate = useNavigate()
  const isApiDomainType = getDomainTypeSetting(domainTypes, event.subtype, 'Api') ?? false
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const handleContextMenu = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4
        }
        : null
    )
  }, [contextMenu])
  const handleClose = () => {
    setContextMenu(null)
  }
  const additionalButtons = useMemo(() => {
    return isApiDomainType
      ? [
        {
          text: 'Open In New Tab',
          icon: 'open_in_new',
          onClick: () => navigate.toDetailsPage(event.subtype, event.instance, true)
        }
      ]
      : undefined
  }, [isApiDomainType, navigate, event.subtype, event.instance])
  const anchorPosition = useMemo(() => {
    return contextMenu !== null
      ? {
        top: contextMenu.mouseY,
        left: contextMenu.mouseX
      }
      : undefined
  }, [contextMenu])
  return (
    <>
      <Box
        display='flex'
        onContextMenu={handleContextMenu}>
        <DomainTypeTooltip
          domainType={event.subtype}
          instance={event.instance}>
          <ForwardTooltipRefAndProps onMouseDown={onMouseDown}>
            {children}
          </ForwardTooltipRefAndProps>
        </DomainTypeTooltip>
      </Box>
      <ClickAwayListener
        onClickAway={handleClose}>
        <Popover
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={anchorPosition}>
          <DomainTypeButtons
            domainType={event.subtype}
            instances={[event.instance]}
            on='TableRow'
            components={components}
            additionalButtons={additionalButtons}
            onComplete={handleClose} />
        </Popover>
      </ClickAwayListener>
    </>
  )
}
