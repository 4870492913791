// import './wdyr'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import { store } from './store'
import App from './App'
import { Provider } from 'react-redux'

const container = document.getElementById('root')
if (container === null) {
  alert('No #root element found')
} else {
  const root = createRoot(container)
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )
}