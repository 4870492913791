import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { ThemeProvider } from '@mui/system'
import { ContextType, memo, useContext, useMemo, useState } from 'react'
import { DomainTypeContext, SidePanel, SidePanelContext } from 'utils/context'
import AppendDomainTypeContext from '../domainType/AppendDomainTypeContext'
import DomainTypeTheme from '../domainType/DomainTypeTheme'
import { LocalDetailsPage } from '../pages/DetailsPage'
import FindPage from '../pages/FindPage'
import ResizableDrawer from './ResizableDrawer'

type Props = {
  children: JSX.Element
}

const defaultDrawerWidth = 600
const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'drawerWidth'
})<{
  open: boolean,
  drawerWidth: number
}>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  height: '100%',
  overflowY: 'scroll',
  paddingTop: 0,
  marginRight: open ? 0 : -drawerWidth,
  transition: open
    ? `margin-right ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.easeOut}`
    : `margin-right ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.sharp}`
}))

interface SidePanelComponentProps {
  readonly sidePanel: SidePanel | null
}

const SidePanelComponent = memo(function Panel({ sidePanel }: SidePanelComponentProps): JSX.Element | null {
  const { setSidePanel } = useContext(SidePanelContext)
  if (sidePanel === null) {
    return null
  }
  switch (sidePanel.type) {
    case 'details':
      return (
        <DomainTypeContext.Provider value={sidePanel.context}>
          <AppendDomainTypeContext
            newAttributes={[sidePanel.attribute]}>
            <ThemeProvider theme={sidePanel.theme}>
              <DomainTypeTheme domainType={sidePanel.domainType}>
                <LocalDetailsPage
                  rootDomainType={sidePanel.domainType}
                  instance={sidePanel.instance}
                  onClose={() => setSidePanel(null)} />
              </DomainTypeTheme>
            </ThemeProvider>
          </AppendDomainTypeContext>
        </DomainTypeContext.Provider>
      )
    case 'find':
      return (
        <FindPage
          domainType={sidePanel.domainType} />
      )
  }
})

export default function SidePanelContainer({
  children
}: Props): JSX.Element {
  const [drawerWidth, setDrawerWidth] = useState(defaultDrawerWidth)
  const [sidePanel, setSidePanel] = useState<SidePanel | null>(null)
  const contextValue = useMemo<ContextType<typeof SidePanelContext>>(() => ({
    setSidePanel,
    sidePanel
  }), [setSidePanel, sidePanel])
  return (
    <Box sx={{
      display: 'flex',
      height: 'calc(100% - 64px)'
    }}>
      <SidePanelContext.Provider value={contextValue}>
        <Main
          open={sidePanel !== null}
          drawerWidth={drawerWidth}>
          {children}
        </Main>
        <ResizableDrawer
          drawerWidth={drawerWidth}
          setDrawerWidth={setDrawerWidth}>
          <SidePanelComponent
            key={sidePanel?.id}
            sidePanel={sidePanel} />
        </ResizableDrawer>
      </SidePanelContext.Provider>
    </Box>
  )
}
