import { PropsWithChildren, useContext, useMemo } from 'react'
import { DomainTypeOverriderContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'

interface Props {
  overriderDetails: OverriderDetails | null
}

export default function PushDomainTypeOverriderContext({
  overriderDetails,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const context = useContext(DomainTypeOverriderContext)
  const domainTypeOverriderContext = useMemo(() => {
    if (overriderDetails === null) {
      return context
    }
    return [
      ...context,
      overriderDetails
    ]
  }, [context, overriderDetails])
  return (
    <DomainTypeOverriderContext.Provider value={domainTypeOverriderContext}>
      {children}
    </DomainTypeOverriderContext.Provider>
  )
}
