import { TextField } from '@mui/material'
import { NonListRefAttributeValue, PathError } from 'types'
import { toErrorText } from 'utils/helpers'
import RefCell from '../../AttributeCell/RefCell'
import LabelledInput from '../LabelledInput'

interface ReadOnlyProps {
  readonly attributeValue: NonListRefAttributeValue
  readonly pathError?: PathError
}

export default function ReadOnlyRefInput({
  attributeValue,
  pathError
}: ReadOnlyProps): JSX.Element {
  if (attributeValue.value === null) {
    return (
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant='standard'
        fullWidth
        size='small'
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        inputProps={{
          readOnly: true
        }} />
    )
  }
  return (
    <LabelledInput
      label={attributeValue.attribute.Title}
      required={attributeValue.attribute.Required ?? false}>
      <RefCell attributeValue={attributeValue} />
    </LabelledInput>
  )
}