import { Button, FormControl, IconButton, Stack, Unstable_TrapFocus as TrapFocus } from '@mui/material'
import { GridAddIcon, GridCloseIcon, GridColumns, GridFilterFormProps, GridLinkOperator } from '@mui/x-data-grid'
import EnumInput from 'components/attribute/AttributeInput/EnumInput'
import { ComponentProps, MutableRefObject, useEffect, useImperativeHandle, useMemo, useReducer, useRef, useState } from 'react'
import { DomainType, Filter } from 'types'
import { stringifyFilterValue } from 'utils/filters'
import { FilterPanelContext } from './TableView'
import filtersReducer, { addFilter, deleteFilter, editFilter } from './filtersReducer'

interface Props {
  domainType: DomainType | null
  selectedColumn: string | undefined
  filtersRef: MutableRefObject<{
    filterLinkOperator: 'and' | 'or',
    filters: Filter[]
  }>
  columns: GridColumns
  onClose(): void
}

function getMultiLinkOperator(filterLinkOperator: 'and' | 'or'): GridLinkOperator {
  return filterLinkOperator === 'and'
    ? GridLinkOperator.And
    : GridLinkOperator.Or
}

function FilterForm({
  item,
  columns,
  domainType,
  valueInputProps,
  hasMultipleFilters,
  showMultiFilterOperators = false,
  multiFilterOperator,
  focusElementRef,
  applyFilterChanges,
  applyMultiFilterOperatorChanges,
  deleteFilter
}: GridFilterFormProps & { columns: GridColumns, domainType: DomainType | null }): JSX.Element {
  const filterableColumns = columns.filter(column => column.filterable)
  const valueRef = useRef<HTMLElement>(null)
  const currentColumn = item.columnField
    ? filterableColumns.find(column => column.field === item.columnField)
    : null
  const currentOperator = useMemo(() => {
    if (item.operatorValue === undefined
      || !item.operatorValue
      || !currentColumn) {
      return null
    }
    return currentColumn.filterOperators
      ?.find((operator) => operator.value === item.operatorValue)
  }, [item, currentColumn])
  useImperativeHandle(
    focusElementRef,
    () => ({
      focus: () => {
        if (currentOperator?.InputComponent) {
          valueRef.current?.focus()
        }
      }
    }),
    [currentOperator]
  )
  return (
    <Stack
      direction='row'
      p={1}
      alignItems='flex-end'>
      <FormControl
        variant='standard'
        sx={{
          flexShrink: 0,
          justifyContent: 'flex-end',
          marginRight: 0.5,
          marginBottom: 0.2
        }}>
        <IconButton
          title='Delete'
          onClick={() => deleteFilter(item)}>
          <GridCloseIcon fontSize='small' />
        </IconButton>
      </FormControl>
      <FormControl
        variant='standard'
        sx={{
          minWidth: 70,
          marginRight: '5px',
          justifyContent: 'end',
          display: hasMultipleFilters ? 'flex' : 'none',
          visibility: showMultiFilterOperators ? 'visible' : 'hidden'
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'filterLinkOperator',
              Title: '',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: [
                  {
                    Id: 'and',
                    Value: 'and',
                    Description: 'And'
                  },
                  {
                    Id: 'or',
                    Value: 'or',
                    Description: 'Or'
                  }
                ]
              },
              List: false
            },
            value: multiFilterOperator ?? null
          }}
          readOnly={false}
          disableClearable
          onChange={attributeValue => applyMultiFilterOperatorChanges(
            attributeValue.value === 'and'
              ? GridLinkOperator.And
              : GridLinkOperator.Or
          )} />
      </FormControl>
      <FormControl
        variant='standard'
        sx={{
          width: 150
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'columnField',
              Title: 'Column',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: filterableColumns.map(column => ({
                  Id: column.field,
                  Value: column.field,
                  Description: column.headerName ?? column.field
                }))
              },
              List: false
            },
            value: item.columnField
          }}
          readOnly={false}
          disableClearable
          onChange={attributeValue => {
            const column = columns.find(column => column.field === attributeValue.value)
            const newOperator = column?.filterOperators
              ?.find(operator => operator.value === item.operatorValue) || column?.filterOperators?.[0]
            applyFilterChanges({
              ...item,
              operatorValue: newOperator?.value,
              columnField: attributeValue.value === null
                ? ''
                : String(attributeValue.value)
            })
          }} />
      </FormControl>
      <FormControl
        variant='standard'
        sx={{
          width: 120
        }}>
        <EnumInput
          attributeValue={{
            attribute: {
              Name: 'operatorValue',
              Title: 'Operator',
              AttributeType: 'enum',
              EnumeratedType: {
                ExternalId: '',
                GlobalExternalId: '',
                Values: currentColumn?.filterOperators?.map(operator => ({
                  Id: operator.value,
                  Value: operator.value,
                  Description: operator.value
                })) ?? []
              },
              List: false
            },
            value: item.operatorValue ?? null
          }}
          readOnly={false}
          disableClearable
          onChange={attributeValue => applyFilterChanges({
            ...item,
            operatorValue: attributeValue.value === null
              ? undefined
              : String(attributeValue.value)
          })} />
      </FormControl>
      <FormControl
        variant='standard'
        sx={{
          width: 190
        }}>
        {currentOperator?.InputComponent !== undefined
          ? (
            <currentOperator.InputComponent
              apiRef={{} as ComponentProps<typeof currentOperator.InputComponent>['apiRef']}
              item={item}
              applyValue={applyFilterChanges}
              focusElementRef={valueRef}
              {...currentOperator.InputComponentProps}
              domainType={domainType}
              {...valueInputProps} />
          )
          : null}
      </FormControl>
    </Stack>
  )
}

const isEnabled = () => true

export default function FilterPanel({
  filtersRef,
  columns,
  domainType,
  selectedColumn,
  onClose
}: Props): JSX.Element {
  const [filterLinkOperator, setFilterLinkOperator] = useState<'and' | 'or'>(filtersRef.current.filterLinkOperator)
  const [filters, dispatchFiltersAction] = useReducer(filtersReducer, filtersRef.current.filters)

  useEffect(() => {
    const handleEvent = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClose()
      }
    }
    // register handler
    // do not use lambda here because to unsubscribe later
    window.addEventListener('keyup', handleEvent)

    // unregister handler
    return () => {
      window.removeEventListener('keyup', handleEvent)
    }
  })

  useEffect(() => {
    filtersRef.current = {
      filterLinkOperator,
      filters
    }
  }, [filterLinkOperator, filters, filtersRef])
  return (
    <FilterPanelContext.Provider
      value={{
        filters,
        dispatchFiltersAction
      }}>
      <TrapFocus
        open
        disableEnforceFocus
        isEnabled={isEnabled}>
        <Stack
          flex='1'
          sx={{
            '&:focus': {
              outline: 0
            }
          }}
          onKeyDown={event => event.stopPropagation()}>
          <Stack
            flex='1 1'
            overflow={{
              x: 'hidden',
              y: 'auto'
            }}
            maxHeight={400}>
            {filters
              .map<[Filter, number]>((filter, index) => [filter, index])
              .filter(([filter]) => {
                return selectedColumn === undefined || filter.Property === selectedColumn
              })
              .map(([filter, index]) => (
                <FilterForm
                  key={index}
                  item={{
                    id: index,
                    columnField: filter.Property,
                    value: filter.Value,
                    operatorValue: filter.Operator
                  }}
                  columns={columns}
                  domainType={domainType}
                  hasMultipleFilters={filters.length > 1}
                  showMultiFilterOperators={index > 0}
                  multiFilterOperator={getMultiLinkOperator(filterLinkOperator)}
                  disableMultiFilterOperator={index > 1}
                  applyFilterChanges={newFilter => {
                    if (newFilter.columnField !== filter.Property && selectedColumn !== undefined) {
                      return
                    }
                    if (newFilter.columnField !== filter.Property) {
                      dispatchFiltersAction(editFilter(index, 'Property', newFilter.columnField))
                    }
                    if (newFilter.operatorValue !== filter.Operator) {
                      dispatchFiltersAction(editFilter(index, 'Operator', newFilter.operatorValue ?? 'like'))
                    }
                    if (newFilter.value === undefined) {
                      dispatchFiltersAction(deleteFilter(index))
                    }
                  }}
                  applyMultiFilterOperatorChanges={setFilterLinkOperator}
                  deleteFilter={() => {
                    dispatchFiltersAction(deleteFilter(index))
                  }} />
              ))}
          </Stack>
          <Stack
            direction='row'
            justifyContent='space-between'
            p={0.5}>
            <Button
              onClick={() => {
                const currentColumn = columns.find(column => column.field === selectedColumn)
                  ?? columns.find(column => column.filterable)
                if (!currentColumn) {
                  return
                }
                dispatchFiltersAction(addFilter({
                  Property: currentColumn.field,
                  Operator: currentColumn.filterOperators?.find(t => true)?.value ?? 'like',
                  Value: stringifyFilterValue(undefined)
                }))
              }}
              startIcon={<GridAddIcon />}
              color='primary'>
              Add Filter
            </Button>
          </Stack>
        </Stack>
      </TrapFocus>
    </FilterPanelContext.Provider>
  )
}