import { OverridableDomainTypeComponent } from 'components/overrides'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeAttributeValue } from 'types'
import { getDomainTypeSetting, isListAttributeValue } from 'utils/helpers'
import { ReadOnlyDomainTypeInput, SingleListNonApiDomainTypeInput } from '..'
import { NarrowedProps } from '../AttributeInput'
import ListApiDomainTypeInput from './ListApiDomainTypeInput'
import ListNonApiDomainTypeInput from './ListNonApiDomainTypeInput'
import NonListApiDomainTypeInput from './NonListApiDomainTypeInput'
import NonListNonApiDomainTypeInput from './NonListNonApiDomainTypeInput'

function DefaultDomainTypeInput({
  attributeValue,
  readOnly,
  pathError,
  disabled,
  onChange
}: NarrowedProps<DomainTypeAttributeValue>): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  if (!domainType) {
    return null
  }
  if (readOnly) {
    return (
      <ReadOnlyDomainTypeInput
        attributeValue={attributeValue}
        pathError={pathError} />
    )
  }
  else if (isListAttributeValue(attributeValue)) {
    if (getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false) {
      return (
        <ListApiDomainTypeInput
          domainTypes={domainTypes}
          domainType={domainType}
          attributeValue={attributeValue}
          disabled={disabled}
          readOnly={readOnly}
          pathError={pathError}
          onChange={onChange} />
      )
    }
    if (attributeValue.attribute.Single ?? false) {
      return (
        <SingleListNonApiDomainTypeInput
          domainType={domainType}
          attributeValue={attributeValue}
          disabled={disabled}
          pathError={pathError}
          onChange={onChange} />
      )
    }
    return (
      <ListNonApiDomainTypeInput
        domainTypes={domainTypes}
        domainType={domainType}
        attributeValue={attributeValue}
        disabled={disabled}
        readOnly={readOnly}
        pathError={pathError}
        onChange={onChange} />
    )
  }
  else if (getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false) {
    return (
      <NonListApiDomainTypeInput
        domainTypes={domainTypes}
        domainType={domainType}
        attributeValue={attributeValue}
        disabled={disabled}
        pathError={pathError}
        onChange={onChange} />
    )
  }
  return (
    <NonListNonApiDomainTypeInput
      domainType={domainType}
      attributeValue={attributeValue}
      disabled={disabled}
      pathError={pathError}
      onChange={onChange} />
  )
}

export default function DomainTypeInput(props: NarrowedProps<DomainTypeAttributeValue>): JSX.Element | null {
  const {
    attributeValue
  } = props
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  return (
    <OverridableDomainTypeComponent<'input'>
      component='input'
      domainType={domainType}
      props={props}
      DefaultComponent={DefaultDomainTypeInput} />
  )
}
