import { Chip, chipClasses, Icon, useTheme } from '@mui/material'
import { NonListEnumAttributeValue } from 'types'
import { getContrastingColour, isNullOrUndefined } from 'utils/helpers'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'

export default function EnumCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListEnumAttributeValue>): JSX.Element | null {
  const enumeratedType = attributeValue.attribute.EnumeratedType
  const enumeratedValue = enumeratedType.Values.find(ev => ev.Value === String(attributeValue.value))
  const theme = useTheme()
  if (isNullOrUndefined(enumeratedValue)) {
    return null
  }
  const background = isNullOrUndefined(enumeratedValue.Colour)
    ? null
    : getContrastingColour(
      enumeratedValue.Colour,
      theme.palette.mode,
      theme.palette.contrastThreshold
    )
  const color = isNullOrUndefined(background)
    ? theme.palette.text.primary
    : theme.palette.getContrastText(background)
  return (
    <Chip
      sx={{
        pointerEvents: 'none',
        background,
        color,
        [`& .${chipClasses.icon}`]: {
          color
        }
      }}
      icon={isNullOrUndefined(enumeratedValue.Icon)
        ? undefined
        : (
          <Icon>
            {enumeratedValue.Icon}
          </Icon>
        )}
      label={(
        <CellTooltip
          value={enumeratedValue.Description}
          columnWidth={columnWidth} />
      )}
      size='small' />
  )
}
