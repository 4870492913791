import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import { useMemo } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import DashboardSection from './DashboardSection'
import DataformResultsSection from './DataformResultsSection'
import MultiDataformResultsSection from './MultiDataformResultsSection'
import RelatedSection from './RelatedSection'
import SummarySection from './SummarySection'
import TableSection from './TableSection'
import { DetailsPageSection, HighlightedRow } from './useDetails'

interface Props {
  section: DetailsPageSection
  isLoading: boolean
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
}

export default function Section({
  section,
  isLoading,
  highlightedRow,
  onHighlightRow
}: Props): JSX.Element | null {
  const newAttributes = useMemo(() => {
    return section.singleListAttributeDetails?.attribute !== undefined
      ? [section.singleListAttributeDetails.attribute]
      : []
  }, [section.singleListAttributeDetails?.attribute])
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(() => {
    return section.singleListAttributeDetails?.domainType !== undefined
      ? [[section.singleListAttributeDetails.domainType, section.singleListAttributeDetails.instance]]
      : []
  }, [section.singleListAttributeDetails?.domainType, section.singleListAttributeDetails?.instance])
  const sectionAttribute = section.type === 'table'
    ? section.attribute
    : null
  const tableAttributes = useMemo(
    () => sectionAttribute !== null
      ? [sectionAttribute]
      : [],
    [sectionAttribute]
  )
  const component = useMemo(() => {
    switch (section.type) {
      case 'summary':
        return (
          <SummarySection section={section} />
        )
      case 'dataformResults':
        return (
          <DataformResultsSection
            section={section}
            isLoading={isLoading} />
        )
      case 'multiDataformResults':
        return (
          <MultiDataformResultsSection
            section={section}
            isLoading={isLoading} />
        )
      case 'table':
        return (
          <AppendDomainTypeContext
            newAttributes={tableAttributes}>
            <TableSection
              section={section}
              isLoading={isLoading}
              highlightedRow={highlightedRow}
              onHighlightRow={onHighlightRow} />
          </AppendDomainTypeContext>
        )
      case 'dashboard':
        return (
          <DashboardSection
            section={section} />
        )
      case 'related':
        return (
          <RelatedSection
            section={section}
            isLoading={isLoading} />
        )
      default:
        return null
    }
  }, [highlightedRow, isLoading, onHighlightRow, section, tableAttributes])
  return (
    <AppendDomainTypeContext
      newAttributes={newAttributes}
      newInstances={newInstances}>
      <DomainTypeTheme domainType={section.domainType}>
        {component}
      </DomainTypeTheme>
    </AppendDomainTypeContext>
  )
}
