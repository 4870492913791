import { useTheme } from '@mui/material'
import MultiResultsInput from 'components/dataform/MultiResultsInput'
import CornerIcon from 'components/utils/CornerIcon'
import { NonListMultiDataformResultsAttributeValue } from 'types'
import { TOOLTIP_DELAY } from 'utils/constants'
import { getMultiDataformResultsIcon } from 'utils/helpers'
import { useDomainTypeSetting, useMultiDataformResults } from 'utils/hooks'
import { NarrowedProps } from './AttributeCell'
import LightTooltip from '../../utils/LightTooltip'

export default function MultiDataformResultsCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListMultiDataformResultsAttributeValue>): JSX.Element | null {
  const [multiResults, errorMessage] = useMultiDataformResults(attributeValue, true)
  const theme = useTheme()
  const [dataformIcon] = useDomainTypeSetting(
    ['Dataform', 'Dataform'],
    'Icon',
    'dynamic_form'
  )
  if (attributeValue.value === null
    || Object.values(attributeValue.value).every(value => value === null)) {
    return null
  }
  const [cornerIcon, cornerIconColor] = getMultiDataformResultsIcon(attributeValue.value, theme)
  return (
    <LightTooltip
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      minWidth={750}
      maxWidth={750}
      maxHeight='none'
      title={(
        <MultiResultsInput
          key={JSON.stringify(multiResults)}
          initialMultiResults={multiResults}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly
          maxHeight='calc(45vh - 152px)'
          minHeight='calc(45vh - 152px)' />
      )}>
      <span>
        <CornerIcon
          icon={dataformIcon}
          cornerIcon={cornerIcon}
          cornerIconColor={cornerIconColor} />
      </span>
    </LightTooltip>
  )
}
