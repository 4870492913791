import { Autocomplete, TextField } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { useMemo, useState } from 'react'
import { ListStringAttributeValue } from 'types'
import { fixMultipleAutocompleteRequiredBehaviour, toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import LabelledInput from '../LabelledInput'

export default function ListStringInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<ListStringAttributeValue>): JSX.Element {
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const options = useMemo<(string | { inputValue: string, title: string })[]>(() => [
    {
      inputValue,
      title: `Add "${inputValue}"`
    }
  ], [inputValue])
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      multiple
      freeSolo
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      readOnly={readOnly}
      inputValue={inputValue}
      onInputChange={(event, value) => setInputValue(value)}
      options={options}
      filterOptions={() => inputValue ? options : []}
      getOptionLabel={option => typeof option === 'string'
        ? option
        : option.title}
      value={attributeValue.value ?? []}
      ChipProps={{ size: 'small' }}
      onChange={(event, values) => {
        if (onChange === undefined) {
          return
        }
        onChange({
          attribute: attributeValue.attribute,
          value: values
            .map(value => typeof value === 'string'
              ? value
              : value.inputValue)
        })
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth
          InputProps={{
            ...params.InputProps,
            readOnly
          }}
          inputProps={fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, attributeValue.value ?? [])} />
      )} />
  )
}
