import { Grid, Paper } from '@mui/material'
import { Dashboard as DashboardType } from 'types/dashboard'
import Panel from './Panel'

interface Props {
  readonly dashboard: DashboardType
  readonly variableValues?: Partial<Record<string, string[]>>
}

export default function Dashboard({
  dashboard,
  variableValues
}: Props): JSX.Element {
  return (
    <Grid
      container
      spacing={1}>
      {dashboard.Panels
        ?.map(panel => (
          <Grid
            key={panel.Id}
            item
            xs={6}
            sm={6}
            md={6}
            lg={6}
            xl={4}>
            <Paper>
              <Panel
                dashboard={dashboard}
                panel={panel}
                variableValues={variableValues} />
            </Paper>
          </Grid>
        ))}
    </Grid>
  )
}