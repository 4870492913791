import { useSelector } from 'react-redux'
import { getPreferences } from 'state/reducers'
import { Dashboard, Panel as PanelType } from 'types/dashboard'

interface Props {
  readonly dashboard: Dashboard
  readonly panel: PanelType
  readonly variableValues?: Partial<Record<string, string[]>>
}

const rootUrl = window.Config.ServerUrl

function getPanelUrl(
  dashboard: Dashboard,
  panel: PanelType,
  theme: 'dark' | 'light',
  variableValues: Partial<Record<string, string[]>> = {}
): string {
  const searchParams = new URLSearchParams()
  searchParams.append('orgId', '1')
  searchParams.append('panelId', panel.Id)
  searchParams.append('theme', theme)
  for (const variable of dashboard.Variables ?? []) {
    for (const value of variableValues[variable.Name] ?? ['All']) {
      searchParams.append(`var-${variable.Name}`, value)
    }
  }
  return `${rootUrl}grafana/d-solo/${dashboard.Subcategory}/${dashboard.Value}?${searchParams.toString()}`
}

export default function Panel({
  dashboard,
  panel,
  variableValues
}: Props): JSX.Element {
  const { theme } = useSelector(getPreferences)
  return (
    <iframe
      title={`${dashboard.Value} - ${panel.Title}`}
      src={getPanelUrl(
        dashboard,
        panel,
        theme,
        variableValues
      )}
      width='100%'
      height='400'
      frameBorder='0'>
    </iframe>
  )
}