import { Login, Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, IconButton, InputAdornment, Link } from '@mui/material'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import * as E from 'fp-ts/Either'
import { ReactComponent as DarkThemePhalanxLogo } from 'phalanx_white.svg'
import { ReactComponent as LightThemePhalanxLogo } from 'phalanx_black.svg'
import { useCallback, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { push } from 'redux-first-history'
import { ApiError, User } from 'types'
import { useApi } from 'utils/hooks'
import IsAuthenticated from '../../containers/auth/IsAuthenticated'
import { performSignInFulfilled } from '../../state/actions/auth'
import SignInWithMicrosoft from './SignInWithMicrosoft'

const PREFIX = 'SignInForm'

const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
}

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },

  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1)
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const messages = defineMessages({
  'header.text': {
    id: 'signInForm.header.text',
    defaultMessage: 'PHALANX'
  },
  'username.label': {
    id: 'signInForm.username.label',
    defaultMessage: 'Username'
  },
  'password.label': {
    id: 'signInForm.password.label',
    defaultMessage: 'Password'
  },
  'button.text': {
    id: 'signInForm.button.text',
    defaultMessage: 'Sign In'
  },
  'button.loadingText': {
    id: 'signInForm.button.loadingText',
    defaultMessage: 'Signing In...'
  },
  'error.alreadySignedIn': {
    id: 'signInForm.alreadySignedIn',
    defaultMessage: 'You are already signed in'
  },
  'api.errorCode.signIn.incorrectUserOrPassword': {
    id: 'signInForm.incorrectUserOrPassword',
    defaultMessage: 'Incorrect username or password'
  },
  'api.errorCode.signIn.phalanxLicenseExpired': {
    id: 'signInForm.phalanxLicenseExpired',
    defaultMessage: 'The PHALANX licence has expired'
  },
  'api.errorCode.signIn.invalidPhalanxLicense': {
    id: 'signInForm.invalidPhalanxLicense',
    defaultMessage: 'The PHALANX licence is invalid'
  },
  'api.errorCode.signIn.notAuthorised': {
    id: 'signInForm.notAuthorised',
    defaultMessage: 'You are not authorised'
  },
  'api.errorCode.signIn.exception': {
    id: 'signInForm.exception',
    defaultMessage: 'An problem occurred when trying to sign in'
  },
  'api.errorCode.signIn.noCompany': {
    id: 'signInForm.noCompany',
    defaultMessage: 'You are not configured to view any companies'
  },
  'api.errorCode.invalidResponse': {
    id: 'signInForm.invalidResponse',
    defaultMessage: 'A problem occurred when signing in'
  }
})

function Copyright(): JSX.Element {
  return (
    <Typography
      variant='body2'
      color='textSecondary'
      align='center'>
      {'Copyright © '}
      <Link
        href='https://spartansolutions.com/'
        target='_blank'>
        Spartan Solutions Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      .
    </Typography>
  )
}

export default function SignInForm(): JSX.Element {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    username: '',
    password: ''
  })
  const onChange = (key: keyof typeof values, value: string): void => setValues({
    ...values,
    [key]: value
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const api = useApi()
  const signInHandler = useCallback(async (response: E.Either<ApiError, User>) => {
    if (E.isRight(response)) {
      dispatch(performSignInFulfilled(response.right))
      const redirect = new URLSearchParams(location.search).get('redirect')
      if (redirect !== null) {
        dispatch(push(redirect))
      }
    } else {
      const errorCode = response.left.errorCode as keyof typeof messages
      setErrorMessage(errorCode in messages ? formatMessage(messages[errorCode]) : errorCode)
    }
  }, [dispatch, location.search, formatMessage])
  const performSignIn = useCallback(async () => {
    if (api.isSignedIn) {
      return
    }
    setIsLoading(true)
    const response = await api.signIn(values)
    setIsLoading(false)
    signInHandler(response)
  }, [api, values, signInHandler])
  const theme = useTheme()
  return (
    <StyledContainer
      maxWidth='xs'>
      <IsAuthenticated>
        <Typography
          variant='h5'
          gutterBottom>
          {formatMessage(messages['error.alreadySignedIn'])}
        </Typography>
      </IsAuthenticated>
      <CssBaseline />
      <div className={classes.paper}>
        {theme.palette.mode === 'dark'
          ? (
            <DarkThemePhalanxLogo
              height='40'
              width='200' />
          )
          : (
            <LightThemePhalanxLogo
              height='40'
              width='200' />
          )}
        {errorMessage && (
          <Alert
            severity='error'>
            {errorMessage}
          </Alert>
        )}
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            void performSignIn()
          }}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='username'
            label={formatMessage(messages['username.label'])}
            name='username'
            autoComplete='username'
            autoFocus
            value={values.username}
            onChange={({ target }) => onChange('username', target.value)} />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label={formatMessage(messages['password.label'])}
            type={!showPassword ? 'password' : undefined}
            id='password'
            autoComplete='current-password'
            value={values.password}
            onChange={({ target }) => onChange('password', target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      setShowPassword(!showPassword)
                    }
                    }
                    edge='end'
                    size='large'>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }} />
          <LoadingButton
            type='submit'
            fullWidth
            loading={isLoading}
            variant='contained'
            loadingPosition='end'
            endIcon={<Login />}
            color='primary'
            className={classes.submit}
            size='large'>
            {isLoading
              ? formatMessage(messages['button.loadingText'])
              : formatMessage(messages['button.text'])}
          </LoadingButton>
        </form>

        <SignInWithMicrosoft
          signInHandler={signInHandler} />
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </StyledContainer>
  )
}
