import { DomainType, EditDomainTypeAction, EditDomainTypeOverriderAction, EDIT_DOMAIN_TYPE, EDIT_DOMAIN_TYPE_OVERRIDER, FetchDomainTypesFulfilledAction, FETCH_DOMAIN_TYPES_FULFILLED, OverridableDomainTypeSettings } from 'types'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'

export function fetchDomainTypesFulfilled(domainTypes: DomainType[]): FetchDomainTypesFulfilledAction {
  return {
    type: FETCH_DOMAIN_TYPES_FULFILLED,
    payload: {
      domainTypes
    }
  }
}

export function editDomainType(domainType: DomainType): EditDomainTypeAction {
  return {
    type: EDIT_DOMAIN_TYPE,
    payload: {
      domainType
    }
  }
}

export function editDomainTypeOverrider<Setting extends keyof OverridableDomainTypeSettings>(
  overriderDetails: OverriderDetails,
  domainTypeId: string,
  setting: Setting,
  value: OverridableDomainTypeSettings[Setting]
): EditDomainTypeOverriderAction<Setting> {
  return {
    type: EDIT_DOMAIN_TYPE_OVERRIDER,
    payload: {
      overriderDetails,
      domainTypeId,
      setting,
      value
    }
  }
}