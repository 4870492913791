import { TextField } from '@mui/material'
import { AttributeValue } from 'types'

interface Props {
  readonly attributeValue: AttributeValue
}

export default function NotSupportedInput({
  attributeValue
}: Props): JSX.Element | null {
  return (
    <TextField
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      variant='standard'
      fullWidth
      size='small'
      required={attributeValue.attribute.Required ?? false}
      error
      helperText={`Attributes of type ${attributeValue.attribute.AttributeType}${attributeValue.attribute.List === true ? '[]' : ''} are not yet supported by this form`}
      inputProps={{
        readOnly: true
      }} />
  )
}
