import { BoxProps, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'
import ScrollBar from 'components/navigation/ScrollBar'

interface Dimensions {
  readonly minWidth?: BoxProps['minWidth']
  readonly maxWidth?: BoxProps['maxWidth']
  readonly minHeight?: BoxProps['minHeight']
  readonly maxHeight?: BoxProps['minWidth']
}

export default styled(({
  className,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  ...props
}: TooltipProps & Dimensions): JSX.Element => (
  <Tooltip
    {...props}
    disableFocusListener
    classes={{ popper: className }}
    title={(
      <ScrollBar>
        {props.title}
      </ScrollBar>
    )} />
))(({ theme, minWidth, maxWidth, minHeight, maxHeight }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: 'unset',
    border: `1px solid ${theme.palette.divider}`,
    padding: 1,
    marginLeft: -1,
    width: '100%',
    minWidth,
    maxWidth,
    minHeight,
    overflow: 'hidden',
    fontWeight: 'unset'
  },
  '& .simplebar-wrapper': {
    maxHeight: maxHeight ?? 400
  }
}))
