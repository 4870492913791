import { StringAttributeValue } from 'types'
import { isListAttributeValue } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import ListStringInput from './ListStringInput'
import NonListStringInput from './NonListStringInput'

export default function StringInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<StringAttributeValue>): JSX.Element {
  if (isListAttributeValue(attributeValue)) {
    return (
      <ListStringInput
        attributeValue={attributeValue}
        readOnly={readOnly}
        pathError={pathError}
        onChange={onChange} />
    )
  }
  return (
    <NonListStringInput
      attributeValue={attributeValue}
      readOnly={readOnly}
      pathError={pathError}
      onChange={onChange} />
  )
}
