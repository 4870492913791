import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { NumberElement } from 'types/dataform'
import { useDebouncedOnChange } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

export default function Number({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<NumberElement>): JSX.Element | null {
  const [debouncedValue, debouncedOnChange] = useDebouncedOnChange(onChange, value, 100)
  return (
    <TextField
      disabled={locked}
      fullWidth
      label={element.Text}
      type='number'
      size='small'
      value={debouncedValue ?? ''}
      onChange={(event: ChangeEvent<HTMLInputElement>) => debouncedOnChange(
        isNaN(event.target.valueAsNumber)
          ? null
          : event.target.valueAsNumber
      )}
      required={required}
      error={error}
      helperText={errorText}
      inputProps={{
        readOnly
      }} />
  )
}