import { CellTooltip } from 'components/attribute/AttributeCell'
import { DomainTypeComponentProps } from '..'

export default function DataformResultsCell({
  attributeValue,
  columnWidth
}: DomainTypeComponentProps['cell']): JSX.Element | null {
  if (attributeValue.value === null) {
    return null
  }
  return (
    <CellTooltip
      value={String(attributeValue.value.Title)}
      columnWidth={columnWidth} />
  )
}