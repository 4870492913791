import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, emphasize, styled, Theme } from '@mui/material/styles'

const MENU_WIDTH = 275

const basePaperStyle = (theme: Theme): CSSObject => ({
  backgroundColor: emphasize(theme.palette.background.default, 0.02)
})

const openedMixin = (theme: Theme): CSSObject => ({
  width: MENU_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest
  }),
  overflowX: 'hidden',
  ...basePaperStyle(theme)
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  overflowX: 'hidden',
  ...basePaperStyle(theme)
})

export default styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: MENU_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open === true && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(open !== true && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))