import { Box, Grid } from '@mui/material'
import AttributeListSettingPopper from 'components/domainType/AttributeListSettingPopper'
import { Lens } from 'monocle-ts'
import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { Attribute, AttributeValue, DomainType, DomainTypeInstance, OverridableDomainTypeSettings } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeOverriderContext } from 'utils/context'
import { getAttributeValue, getDomainTypeAttributes, getHeading, getOverridableDomainTypeSetting, getRootDomainType, getSubtype, getValue, isDataformResultsAttribute, isDomainTypeListAttribute, isMultiDataformResultsAttribute, makeSortFunction } from 'utils/helpers'

export const COLUMN_WIDTH = 300

export type OverridableStringListSetting = NonNullable<{
  [Key in keyof OverridableDomainTypeSettings]: OverridableDomainTypeSettings[Key] extends string[] | null | undefined ? Key : never
}[keyof OverridableDomainTypeSettings]>

const emptyItemsToFixLayout = [
  <Grid
    key='layout-0'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-1'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-2'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-3'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-4'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />,
  <Grid
    key='layout-5'
    item
    pt={0}
    flex={`1 1 ${COLUMN_WIDTH}px`} />
]

const attributeName = Lens.fromPath<AttributeValue>()(['attribute', 'Name'])
const attributeTitle = Lens.fromPath<AttributeValue>()(['attribute', 'Title'])

function getSimpleAttributeValues(
  domainTypes: Partial<Record<string, DomainType>>,
  attributes: Attribute[],
  instance: DomainTypeInstance,
  namePrefix = '',
  titlePrefix = ''
): AttributeValue[] {
  return attributes
    .filter(attribute => !isDataformResultsAttribute(attribute)
      && !isMultiDataformResultsAttribute(attribute))
    .flatMap(attribute => {
      if (!isDomainTypeListAttribute(attribute)) {
        return [
          getAttributeValue(instance, attribute)
        ]
      }
      if (attribute.Single !== true) {
        return []
      }
      const domainType = domainTypes[attribute.AttributeDomainType]
      if (domainType === undefined) {
        return []
      }
      const singleInstance = getValue(instance, attribute)?.[0]
      if (singleInstance === undefined) {
        return []
      }
      return getSimpleAttributeValues(
        domainTypes,
        getDomainTypeAttributes(domainTypes, domainType),
        singleInstance,
        `${attribute.Name}_`,
        `${attribute.Title}${PATH_SEPARATOR}`
      )
    })
    .map(attributeName.modify(name => `${namePrefix}${name}`))
    .map(attributeTitle.modify(title => `${titlePrefix}${title}`))
}

interface Output {
  readonly subtype: DomainType
  readonly heading: string
  readonly columns: string[]
  readonly simpleAttributeValues: AttributeValue[]
  readonly filteredSortedAttributeValues: AttributeValue[]
  readonly emptyItemsToFixLayout: JSX.Element[]
  readonly settingPopperPlaceholderGridItem: JSX.Element
  readonly settingPopperButton: JSX.Element
}

export default function useDomainTypeSummary(
  domainType: DomainType,
  instance: DomainTypeInstance,
  columnsSetting: OverridableStringListSetting
): Output {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType) ?? domainType
  const subtype = getSubtype(domainTypes, rootDomainType, instance) ?? domainType
  const attributes = getDomainTypeAttributes(domainTypes, subtype)
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const simpleAttributeValues = getSimpleAttributeValues(
    domainTypes,
    attributes,
    instance
  )
  const columns = getOverridableDomainTypeSetting(
    domainTypes,
    subtype,
    overriders,
    columnsSetting,
    simpleAttributeValues.map(attributeName.get)
  )
  const filteredSortedAttributeValues = useMemo(() => {
    return simpleAttributeValues
      .filter(attributeValue => columns.includes(attributeValue.attribute.Name))
      .sort(makeSortFunction(columns, attributeValue => attributeValue.attribute.Name))
  }, [simpleAttributeValues, columns])
  const heading = getHeading(domainTypes, subtype, instance)
  const settingPopperPlaceholderGridItem = useMemo(() => {
    return (
      <Grid
        item
        p={1}
        key='popper'
        sx={{
          height: '52px'
        }}
        flex={`1 1 ${COLUMN_WIDTH}px`}>
      </Grid>
    )
  }, [])
  const settingPopperButton = useMemo(() => (
    <Box
      position='absolute'
      right={0}
      bottom={0}
      p={1}>
      <AttributeListSettingPopper
        domainType={subtype}
        allAttributeValues={simpleAttributeValues}
        setting={columnsSetting} />
    </Box>
  ), [columnsSetting, simpleAttributeValues, subtype])
  return {
    subtype,
    heading,
    columns,
    simpleAttributeValues,
    filteredSortedAttributeValues,
    emptyItemsToFixLayout,
    settingPopperPlaceholderGridItem,
    settingPopperButton
  }
}