import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { ElementType } from 'types/dataform'
import { ElementTypeCodecs } from 'utils/codecs/dataform'
import { isNullOrUndefined } from 'utils/helpers'
import { activeGroupChanged, completed, elementAsExpected, elementConstraintErrorAdded, elementConstraintErrorRemoved, elementDisabledByDependency, elementEnabledByDependency, elementLockedByMilestone, elementNotAsExpected, elementRequiredErrorAdded, elementRequiredErrorRemoved, elementUnlockedByMilestone, elementValueChanged, milestoneLockedByElement, milestoneUnlockedByElement } from './actions'
import { getElementDependencyValue, hasError, isElementActive, satisfiesRequiredValidation } from './selectors'
import { Action, BackButtonClicked, DataformEditedAction, ElementConstraintErrorAddedAction, ElementConstraintErrorRemovedAction, ElementDisabledByDependencyAction, ElementEnabledByDependencyAction, ElementRequiredErrorAddedAction, ElementRequiredErrorRemovedAction, ElementValueChangedAction, GroupStepperClicked, InitialisedAction, NextButtonClicked, State } from './types'

export function updatePrecedingElementsIfMilestone(
  state: State,
  action: ElementValueChangedAction
): Action[] {
  if (action.payload.element.Milestone !== true) {
    return []
  }
  const allElements = state.dataform.Groups.flatMap(group => group.Elements)
  const elementIndex = allElements.indexOf(action.payload.element)
  const lockedByMilestone = action.payload.value !== null
  const precedingElements = allElements.slice(0, elementIndex)
  return precedingElements.map(element => lockedByMilestone
    ? elementLockedByMilestone(element, action.payload.element)
    : elementUnlockedByMilestone(element, action.payload.element)
  )
}

export function applyDependencies(
  state: State,
  action: ElementValueChangedAction
): Action[] {
  const dependentElements = state.dataform.Groups.flatMap(group => group.Elements)
    .map(element => [
      element,
      element.DepsId
        ?.filter(dependency => dependency.ControllingId === action.payload.element.Id) ?? []
    ] as const)
    .filter(([element, dependencies]) => dependencies.length > 0)
  const controllingElementState = state.elements[action.payload.element.Id]
  const controllingElementValue = controllingElementState?.value ?? null
  const isControllingElementActive = isElementActive(controllingElementState)
  return dependentElements.flatMap(([element, dependencies]) => {
    if (!isNullOrUndefined(state.elements[element.Id]?.circularDependency)) {
      return []
    }
    return dependencies.map(dependency => {
      const enabled = isControllingElementActive
        && (getElementDependencyValue(dependency.Value, action.payload.element.ExpectedResult)
          ?.test(String(controllingElementValue)) ?? true)
      return enabled
        ? elementEnabledByDependency(element, dependency)
        : elementDisabledByDependency(element, dependency)
    })
  })
}

export function setAsExpected(
  state: State,
  action: ElementValueChangedAction
): Action[] {
  const asExpectedAction = elementAsExpected(action.payload.element)
  const notAsExpectedAction = elementNotAsExpected(action.payload.element)
  if (action.payload.element.ElementType === ElementType.Dataform) {
    return pipe(
      action.payload.value,
      ElementTypeCodecs[ElementType.Dataform].decode,
      E.match(
        (): Action[] => [asExpectedAction],
        (results): Action[] => results.AsExpected === false
          ? [notAsExpectedAction]
          : [asExpectedAction]
      )
    )
  }
  const expectedValue = state.elements[action.payload.element.Id]?.expectedValue ?? null
  const value = action.payload.value ?? null
  const asExpected = expectedValue?.test(String(value)) ?? true
  return [
    asExpected
      ? asExpectedAction
      : notAsExpectedAction
  ]
}

export function setConstraintError(
  state: State,
  action: ElementValueChangedAction
): Action[] {
  const constraint = action.payload.element.Constraint
  if (isNullOrUndefined(constraint)) {
    return []
  }
  if (isNullOrUndefined(action.payload.value)) {
    return [
      elementConstraintErrorRemoved(action.payload.element)
    ]
  }
  const rule = constraint.Rule
  if (constraint.Type === 'regex') {
    const parts = constraint.Rule.match(/^\/(.*?)\/([igumy]*)$/)
    const regex = isNullOrUndefined(parts)
      ? new RegExp(rule)
      : new RegExp(parts[1] ?? '', parts[2])
    return [
      regex.test(String(action.payload.value))
        ? elementConstraintErrorRemoved(action.payload.element)
        : elementConstraintErrorAdded(action.payload.element)
    ]
  } else if (constraint.Type === 'feelExpression') {
    //todo
    return []
  }
  const bounds = constraint.Rule
    .split(',')
    .map(bound => parseFloat(bound))
    .filter(bound => !isNaN(bound))
  const [lower = -Infinity, upper = Infinity] = bounds
  const value = parseFloat(String(action.payload.value))
  return [
    value >= lower && value <= upper
      ? elementConstraintErrorRemoved(action.payload.element)
      : elementConstraintErrorAdded(action.payload.element)
  ]
}

export function setRequiredError(
  state: State,
  action: ElementValueChangedAction
): Action[] {
  if (action.payload.element.Required !== true) {
    return []
  }
  const codec = ElementTypeCodecs[action.payload.element.ElementType]
  if (codec.is(null)) {
    return []
  }
  return [
    satisfiesRequiredValidation(action.payload.element.ElementType, action.payload.value)
      || !isElementActive(state.elements[action.payload.element.Id])
      ? elementRequiredErrorRemoved(action.payload.element)
      : elementRequiredErrorAdded(action.payload.element)
  ]
}

export function setValueToDefault(
  state: State,
  action: ElementEnabledByDependencyAction
): Action[] {
  const defaultValue = state.elements[action.payload.element.Id]?.defaultValue ?? null
  return [
    elementValueChanged(
      action.payload.element,
      typeof defaultValue === 'string' && !defaultValue
        ? null
        : defaultValue
    )
  ]
}

export function setValueToNull(
  state: State,
  action: ElementDisabledByDependencyAction
): Action[] {
  return [
    elementValueChanged(
      action.payload.element,
      null
    )
  ]
}

export function updateFollowingMilestones(
  state: State,
  action: ElementRequiredErrorAddedAction | ElementRequiredErrorRemovedAction | ElementConstraintErrorAddedAction | ElementConstraintErrorRemovedAction
): Action[] {
  const allElements = state.dataform.Groups.flatMap(group => group.Elements)
  const elementIndex = allElements.indexOf(action.payload.element)
  const subsequentMilestones = allElements.slice(elementIndex + 1)
    .filter(element => element.Milestone === true)
  return subsequentMilestones.map(milestone => {
    const isLocked = (state.elements[action.payload.element.Id]?.constraintError ?? false)
      || (state.elements[action.payload.element.Id]?.requiredError ?? false)
    return isLocked
      ? milestoneLockedByElement(milestone, action.payload.element)
      : milestoneUnlockedByElement(milestone, action.payload.element)
  })
}

export function changeGroup(
  state: State,
  action: GroupStepperClicked
): Action[] {
  return [activeGroupChanged(action.payload.index)]
}

export function incrementGroupOrCompleteIfNoErrors(
  state: State,
  action: NextButtonClicked
): Action[] {
  const groupElements = state.dataform.Groups[state.activeGroup]?.Elements ?? []
  const anyErrors = groupElements.some(element => hasError(state.elements[element.Id]))
  return anyErrors
    ? []
    : [
      state.activeGroup === state.dataform.Groups.length - 1
        ? completed()
        : activeGroupChanged(state.activeGroup + 1)
    ]
}

export function decrementGroup(
  state: State,
  action: BackButtonClicked
): Action[] {
  return [activeGroupChanged(state.activeGroup - 1)]
}

export function setInitialElementValues(state: State, action: InitialisedAction): Action[] {
  return state.dataform.Groups.flatMap(group => group.Elements)
    .map(element => elementValueChanged(
      element,
      state.elements[element.Id]?.resultsValue ?? state.elements[element.Id]?.defaultValue ?? null
    ))
}

export function reinitialiseElementValues(state: State, action: DataformEditedAction): Action[] {
  return state.dataform.Groups.flatMap(group => group.Elements)
    .map(element => elementValueChanged(
      element,
      state.elements[element.Id]?.value ?? state.elements[element.Id]?.resultsValue ?? state.elements[element.Id]?.defaultValue ?? null
    ))
}