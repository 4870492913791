import { Add } from '@mui/icons-material'
import { Autocomplete, Chip, TextField } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import CreateDialog from 'components/domainType/CreateDialog'
import EditDialog from 'components/domainType/EditDialog'
import { useMemo, useState } from 'react'
import { DomainType, ListDomainTypeAttributeValue, PathError } from 'types'
import { fixMultipleAutocompleteRequiredBehaviour, getDomainTypeSetting, getHeading, toErrorText } from 'utils/helpers'
import LabelledInput from '../LabelledInput'

function hasErrorAtIndex(pathError: PathError | undefined, index: number): boolean {
  return Array.isArray(pathError)
    && (typeof pathError[index] === 'string'
      || (typeof pathError[index] === 'object'
        && Object.keys(pathError[index] ?? {}).length > 0))
}

interface ListNonApiDomainTypeProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: ListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly readOnly: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: ListDomainTypeAttributeValue): void
}

export default function ListNonApiDomainTypeInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  readOnly,
  pathError,
  onChange
}: ListNonApiDomainTypeProps): JSX.Element | null {
  const value = useMemo(
    () => attributeValue.value ?? [],
    [attributeValue.value]
  )
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const [open, setOpen] = useState<false | 'create' | 'edit'>(false)
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const editingInstance = editingIndex === null
    ? undefined
    : value[editingIndex]
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  return (
    <>
      <CreateDialog
        open={open === 'create'}
        domainType={domainType}
        bypassApi
        onClose={() => setOpen(false)}
        onCreateSuccess={instance => {
          setOpen(false)
          if (onChange === undefined) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value: [...value, {
              ...instance,
              _create: true
            }]
          })
        }} />
      <EditDialog
        open={open === 'edit'}
        instance={editingInstance ?? {}}
        domainType={domainType}
        formMode={editingIndex === null
          ? undefined
          : value[editingIndex]?._create === true
            ? 'create'
            : undefined}
        pathError={Array.isArray(pathError) && editingIndex !== null
          ? pathError[editingIndex]
          : undefined}
        bypassApi
        onClose={() => setOpen(false)}
        onEditSuccess={instance => {
          setOpen(false)
          setEditingIndex(null)
          if (onChange === undefined || editingIndex === null) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value: [
              ...value.slice(0, editingIndex),
              instance,
              ...value.slice(editingIndex + 1)
            ]
          })
        }} />
      <Autocomplete
        multiple
        open={false}
        disabled={disabled}
        options={value}
        value={value}
        openText='Add'
        onChange={(event, value) => {
          if (onChange === undefined) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value
          })
        }}
        popupIcon={<Add onClick={() => setOpen('create')} />}
        getOptionLabel={option => getHeading(domainTypes, domainType, option)}
        isOptionEqualToValue={(option, value) => option[identifier] === value[identifier]}
        renderTags={(value, getTagProps) => {
          return value.map((instance, index) => (
            // eslint-disable-next-line react/jsx-key
            <Chip
              size='small'
              label={getHeading(domainTypes, domainType, instance)}
              color={hasErrorAtIndex(pathError, index)
                ? 'error'
                : 'default'}
              onClick={() => {
                setOpen('edit')
                setEditingIndex(index)
              }}
              {...getTagProps({ index })} />
          ))
        }}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant='standard'
            size='small'
            required={attributeValue.attribute.Required ?? false}
            error={pathError !== undefined}
            helperText={toErrorText(pathError)}
            label={attributeValue.attribute.Title}
            fullWidth
            InputProps={{
              ...params.InputProps,
              readOnly: true
            }}
            inputProps={fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, value)} />
        )} />
    </>
  )
}