import { TextField } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { DateElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'

export default function Date({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<DateElement>): JSX.Element | null {
  return (
    <DatePicker
      disabled={locked}
      label={element.Text}
      value={value}
      onChange={value => onChange(value)}
      readOnly={readOnly}
      renderInput={params => (
        <TextField
          fullWidth
          size='small'
          required={required}
          {...params}
          error={error || params.error}
          helperText={errorText ?? params.helperText} />
      )} />
  )
}