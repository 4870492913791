import { none, some } from 'fp-ts/lib/Option'
import * as t from 'io-ts'
import { Optional } from 'monocle-ts'
import { Action, DomainType, EDIT_DOMAIN_TYPE, EDIT_DOMAIN_TYPE_OVERRIDER, FETCH_DOMAIN_TYPES_FULFILLED, SWITCH_COMPANY } from 'types'
import { DomainTypeCodec } from 'utils/codecs'
import { makeOverrideLens } from 'utils/helpers'

const getDomainTypeLens = (id: string) => new Optional<unknown, unknown>(
  state => {
    if (!t.record(t.string, t.unknown).is(state)) {
      return none
    }
    const domainType = state[id]
    return DomainTypeCodec.is(domainType)
      ? some(domainType)
      : none
  },
  domainType => state => {
    if (!t.record(t.string, t.unknown).is(state)) {
      return state
    }
    return {
      ...state,
      [id]: domainType
    }
  }
)

export default (state: Partial<Record<string, DomainType>> = {}, action: Action): Partial<Record<string, DomainType>> => {
  switch (action.type) {
    case SWITCH_COMPANY: {
      return {}
    }
    case FETCH_DOMAIN_TYPES_FULFILLED:
      return {
        ...state,
        ...action.payload.domainTypes.reduce((prev, curr) => {
          prev[curr.Id] = curr
          return prev
        }, {} as Partial<Record<string, DomainType>>)
      }
    case EDIT_DOMAIN_TYPE:
      return {
        ...state,
        [action.payload.domainType.Id]: action.payload.domainType
      }
    case EDIT_DOMAIN_TYPE_OVERRIDER: {
      if (action.payload.overriderDetails.root !== 'domainType') {
        return state
      }
      const lens = getDomainTypeLens(action.payload.overriderDetails.id).composeOptional(makeOverrideLens(
        action.payload.overriderDetails.path,
        action.payload.domainTypeId
      ))
      return lens.modify(previous => ({
        ...previous,
        [action.payload.setting]: action.payload.value
      }))(state) ?? state
    }
    default:
      return state
  }
}

export function getAll(state: Partial<Record<string, DomainType>>): Partial<Record<string, DomainType>> {
  return state
}

export function getById(state: Partial<Record<string, DomainType>>, id: string): DomainType | undefined {
  return state[id]
}