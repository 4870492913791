import { TextField } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { LabelledInput } from 'components/attribute/AttributeInput'
import { DomainTypeAttributeValue, PathError } from 'types'
import { toErrorText } from 'utils/helpers'

interface ReadOnlyDomainTypeProps {
  readonly attributeValue: DomainTypeAttributeValue
  readonly pathError?: PathError
}

export default function ReadOnlyDomainTypeInput({
  attributeValue,
  pathError
}: ReadOnlyDomainTypeProps): JSX.Element | null {
  if (attributeValue.value === null) {
    return (
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant='standard'
        fullWidth
        size='small'
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        InputProps={{
          readOnly: true
        }} />
    )
  }
  return (
    <LabelledInput
      label={attributeValue.attribute.Title}
      required={attributeValue.attribute.Required ?? false}>
      <AttributeCell attributeChainValue={attributeValue} />
    </LabelledInput>
  )
}