import {
  MoreVert as MoreIcon
} from '@mui/icons-material'
import { ClickAwayListener, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Popover } from '@mui/material'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import { ComponentProps, MouseEvent, useCallback, useState } from 'react'
import { ButtonLocation, DomainType, DomainTypeInstance } from 'types'
import TooltipIconButton from '../utils/TooltipIconButton'

interface Props {
  readonly domainType: DomainType
  readonly instances: DomainTypeInstance[]
  readonly on: ButtonLocation | null
  readonly priority?: 'high' | 'medium' | 'low',
  readonly leafNodeType?: 'active' | 'nested'
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: MenuList,
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

export default function ButtonsPopover({
  instances,
  domainType,
  on,
  priority,
  leafNodeType = 'active'
}: Props): JSX.Element | null {
  const [contenxtMenuAnchorEl, setContenxtMenuAnchorAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const isContextMenuOpen = Boolean(contenxtMenuAnchorEl)
  const handleOpen = useCallback((event: MouseEvent) => {
    setContenxtMenuAnchorAnchorEl(event.currentTarget)
  }, [setContenxtMenuAnchorAnchorEl])

  const handleClose = useCallback(() => {
    setContenxtMenuAnchorAnchorEl(null)
  }, [setContenxtMenuAnchorAnchorEl])

  return (
    <>
      <ClickAwayListener
        onClickAway={handleClose}>
        <Popover
          open={isContextMenuOpen}
          onClose={handleClose}
          anchorEl={contenxtMenuAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <DomainTypeButtons
            domainType={domainType}
            instances={instances}
            on={on}
            priority={priority}
            components={components}
            leafNodeType={leafNodeType}
            onComplete={handleClose} />
        </Popover>
      </ClickAwayListener>
      <TooltipIconButton
        tooltipText='Actions'
        icon={<MoreIcon />}
        onClick={handleOpen} />
    </>
  )
}