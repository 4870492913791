import { ImageElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'
import NotSupported from './NotSupported'

export default function Image({
  dataform,
  group,
  element,
  readOnly = false
}: ElementProps<ImageElement>): JSX.Element | null {
  // TODO - image
  return (
    <NotSupported element={element} />
  )
}