import { useTheme } from '@mui/material'
import { ResultsInput } from 'components/dataform'
import CornerIcon from 'components/utils/CornerIcon'
import { NonListDataformResultsAttributeValue } from 'types'
import { TOOLTIP_DELAY } from 'utils/constants'
import { getDataformResultsIcon } from 'utils/helpers'
import { useDataformResults, useDomainTypeSetting } from 'utils/hooks'
import { NarrowedProps } from './AttributeCell'
import LightTooltip from '../../utils/LightTooltip'

export default function DataformResultsCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListDataformResultsAttributeValue>): JSX.Element | null {
  const [dataform, results, errorMessage] = useDataformResults(attributeValue)
  const theme = useTheme()
  const [dataformIcon] = useDomainTypeSetting(
    ['Dataform', 'Dataform'],
    'Icon',
    'dynamic_form'
  )
  if (attributeValue.value === null) {
    return null
  }
  const [cornerIcon, cornerIconColor] = getDataformResultsIcon(attributeValue.value, theme)
  return (
    <LightTooltip
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      minWidth={750}
      maxWidth={750}
      maxHeight='none'
      title={(
        <ResultsInput
          key={JSON.stringify(results)}
          dataform={dataform}
          initialResults={results}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly
          maxHeight='calc(45vh - 104px)'
          minHeight='calc(45vh - 104px)' />
      )}>
      <span>
        <CornerIcon
          icon={dataformIcon}
          cornerIcon={cornerIcon}
          cornerIconColor={cornerIconColor} />
      </span>
    </LightTooltip>
  )
}
