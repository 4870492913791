import { MultiResults, Results } from 'types/dataform'

interface State {
  readonly tab: string | null
  readonly multiResults: MultiResults | null
  readonly allCompleted: boolean
}

export const defaultState = {
  tab: null,
  multiResults: null,
  allCompleted: false
}

const INITIALISED = 'INITIALISED'
interface InitialisedAction {
  readonly type: typeof INITIALISED
  readonly payload: {
    readonly multiResults: MultiResults
  }
}
export function initialised(multiResults: MultiResults): InitialisedAction {
  return {
    type: INITIALISED,
    payload: {
      multiResults
    }
  }
}

const RESULTS_CHANGED = 'RESULTS_CHANGED'
interface ResultsChangedAction {
  readonly type: typeof RESULTS_CHANGED
  readonly payload: {
    readonly results: Results
  }
}
export function resultsChanged(results: Results): ResultsChangedAction {
  return {
    type: RESULTS_CHANGED,
    payload: {
      results
    }
  }
}

const RESULTS_COMPLETED = 'RESULTS_COMPLETED'
interface ResultsCompletedAction {
  readonly type: typeof RESULTS_COMPLETED
}
export function resultsCompleted(): ResultsCompletedAction {
  return {
    type: RESULTS_COMPLETED
  }
}

const TAB_CHANGED = 'TAB_CHANGED'
interface TabChangedAction {
  readonly type: typeof TAB_CHANGED
  readonly payload: {
    readonly value: string
  }
}
export function tabChanged(value: string): TabChangedAction {
  return {
    type: TAB_CHANGED,
    payload: {
      value
    }
  }
}

type Action =
  | InitialisedAction
  | ResultsChangedAction
  | ResultsCompletedAction
  | TabChangedAction

export default function multiDataformReducer(state: State, action: Action): State {
  if (state.multiResults === null) {
    if (action.type === INITIALISED) {
      return {
        ...state,
        tab: action.payload.multiResults[0]?.key ?? null,
        multiResults: action.payload.multiResults
      }
    }
    return state
  }
  switch (action.type) {
    case RESULTS_CHANGED: {
      const index = state.multiResults
        .findIndex(({ key, dataform }) => dataform?.Id === action.payload.results.DataformId)
      const entry = state.multiResults[index]
      if (entry === undefined) {
        return state
      }
      return {
        ...state,
        multiResults: [
          ...state.multiResults.slice(0, index),
          {
            key: entry.key,
            dataform: entry.dataform,
            results: action.payload.results
          },
          ...state.multiResults.slice(index + 1)
        ]
      }
    }
    case RESULTS_COMPLETED: {
      const currentIndex = state.multiResults.findIndex(({ key }) => key === state.tab)
      if (currentIndex === -1) {
        return state
      }
      const nextIncomplete = [
        ...state.multiResults.slice(currentIndex + 1),
        ...state.multiResults.slice(0, currentIndex)
      ].find(({ key, dataform, results }) => results?.Complete !== true)
      return {
        ...state,
        tab: nextIncomplete?.key ?? state.tab,
        allCompleted: nextIncomplete === undefined
      }
    }
    case TAB_CHANGED:
      return {
        ...state,
        tab: action.payload.value
      }
    default:
      return state
  }
}