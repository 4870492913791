import { GridFilterItem } from '@mui/x-data-grid'
import AttributeInput from 'components/attribute/AttributeInput'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { filterOperators, makeInputAttributeValue, stringifyFilterValue } from 'utils/filters'
import { getDomainTypeAttribute, isNullOrUndefined } from 'utils/helpers'
import { useFilterContext } from 'utils/hooks'
import FilterPanelContext from './FilterPanelContext'
import { editFilter } from '../filtersReducer'

interface Props {
  item: GridFilterItem
  domainType?: DomainType | null
}

function useStrictModeEffect(
  effect: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1]
): ReturnType<typeof useEffect> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(function effectThatDoesNotRunTwiceInStrictMode(this: { ignore?: boolean }) {
    if (this.ignore === undefined) {
      effect()
    }
    return () => {
      this.ignore = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }.bind({}), deps)
}

export default function FilterInputComponent({
  item,
  domainType
}: Props): JSX.Element | null {
  const firstUpdateRef = useRef(true)
  const filterContext = useFilterContext()
  const { dispatchFiltersAction } = useContext(FilterPanelContext)
  const domainTypes = useSelector(getAllDomainTypes)
  const attribute = !isNullOrUndefined(domainType)
    ? getDomainTypeAttribute(domainTypes, domainType, item.columnField)
    : undefined
  const filterOperator = filterOperators
    .find(f => f.operator === item.operatorValue
      && f.canApply(attribute))
  const attributeValue = attribute !== undefined && filterOperator !== undefined
    ? makeInputAttributeValue(
      attribute,
      filterOperator,
      item.value,
      filterContext,
      domainTypes
    )
    : undefined
  const clearFilterValue = useCallback(() => {
    if (typeof item.id === 'number') {
      dispatchFiltersAction(editFilter(item.id, 'Value', null))
    }
  }, [dispatchFiltersAction, item.id])
  useEffect(() => {
    if (item.value !== '' && (attributeValue?.value ?? null) === null) {
      clearFilterValue()
    }
  }, [attributeValue?.value, clearFilterValue, item.value])
  useStrictModeEffect(() => {
    if (!firstUpdateRef.current) {
      clearFilterValue()
    }
  }, [clearFilterValue, item.columnField])
  useEffect(() => {
    firstUpdateRef.current = false
  }, [])
  if (attributeValue === undefined) {
    return null
  }
  return (
    <AttributeInput
      key={item.columnField}
      readOnly={false}
      attributeValue={attributeValue}
      onChange={({ value }) => {
        if (typeof item.id === 'number') {
          dispatchFiltersAction(editFilter(item.id, 'Value', stringifyFilterValue(value)))
        }
      }} />
  )
}