import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { BooleanFromString, DateFromISOString, NumberFromString } from 'io-ts-types'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ApiError, Attribute, DomainType, DomainTypeInstance, DomainTypeOverrider, Filter, Query, Sort } from 'types'
import { JsonFilterCodec, JsonFromUnknown, JsonSortCodec } from 'utils/codecs'
import { DomainTypeOverriderContext } from 'utils/context'
import { getAnyAllFilters, stringifyFilterValue } from 'utils/filters'
import { getDomainTypeSetting, getRootDomainType, isValidStartEndDateAttribute } from 'utils/helpers'
import { SignedInApi, SnackPack, useApi, useCancellableApiSession, useDeepEqualMemo, useDefaultSorts, useDomainTypeSetting, useNavigate, useSearchParams, useSnackPack } from 'utils/hooks'
import { ActionDetails } from 'utils/hooks/useActions'
import findPageReducer, { changeItemDatesError, closeActionDialog, defaultState, performAction, performChangeItemDates, performSearch, performSearchFulfilled, viewChanged } from './findPageReducer'
import { getChangeItemDatesPatchRequestBody, getTimelineItemChains } from './helpers'
import { CalendarProps, CalendarTimelineSettings, FindPageView, GroupByValue } from './types'

export const codecs = {
  page: NumberFromString,
  pageSize: NumberFromString,
  searchText: t.string,
  sorts: JsonFromUnknown.pipe(t.array(JsonSortCodec)),
  filterLinkOperator: t.union([t.literal('and'), t.literal('or')]),
  filters: JsonFromUnknown.pipe(t.array(JsonFilterCodec)),
  bypassDomainTypeFilters: JsonFromUnknown.pipe(t.array(t.string)),
  bypassSearchIndex: BooleanFromString,
  queryId: t.string,
  date: DateFromISOString
}

function getCalendarViewFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  view: FindPageView,
  calendarView: CalendarProps['view'],
  date: Date
): Filter[] {
  if (!isCalendarView(view)) {
    return []
  }
  const [domainTypeChain] = getTimelineItemChains(domainTypes, domainType, overriders)
  let index = domainTypeChain.length - 1
  let startDateAttribute: Attribute | null | undefined = undefined
  let endDateAttribute: Attribute | null | undefined = undefined
  do {
    if (index < 0) {
      return []
    }
    ({
      startDateAttribute,
      endDateAttribute
    } = domainTypeChain[index--] ?? {})
    endDateAttribute = endDateAttribute ?? startDateAttribute
  } while (
    startDateAttribute === null
    || !isValidStartEndDateAttribute(startDateAttribute)
    || !isValidStartEndDateAttribute(endDateAttribute)
  )
  const prefix = domainTypeChain.slice(0, index + 1).map(settings => settings.itemsAttribute?.Name ?? '')
  const startOfWindow = DateTime.fromJSDate(date).startOf(calendarView).toUTC().toISO()
  const endOfWindow = DateTime.fromJSDate(date).endOf(calendarView).toUTC().toISO()
  return [
    {
      Property: [...prefix, startDateAttribute.Name].join('_'),
      Operator: startDateAttribute.AttributeType === 'date'
        ? 'lte'
        : 'lt',
      Value: stringifyFilterValue(endOfWindow)
    },
    {
      Property: [...prefix, endDateAttribute.Name].join('_'),
      Operator: endDateAttribute.AttributeType === 'date'
        ? 'gte'
        : 'gt',
      Value: stringifyFilterValue(startOfWindow)
    }
  ]
}

function isCalendarView(view: FindPageView): boolean {
  return view === 'calendar' || view === 'timeline'
}

interface UseFindOutput {
  isLoading: boolean
  items: DomainTypeInstance[]
  total: number
  calendarItems: DomainTypeInstance[]
  calendarProps: CalendarProps
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filterLinkOperator: 'and' | 'or'
  filters: Filter[]
  isExporting: boolean
  checkedRowIds: string[]
  view: FindPageView
  snackPack: SnackPack
  onSearch(): void
  onSearchTextChange(value?: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  onRowClick(id: string): void
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
  onClickExport: undefined | ((columns: string[]) => void)
  onCheckedRowIdsChange(ids: string[]): void
  onViewChange(value: FindPageView): void
}

const EMPTY_CHCKED_ROW_IDS: string[] = []

export default function useFind(
  domainType: DomainType,
  urlPrefix = '',
  additionalFilters?: Filter[]
): UseFindOutput {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = useSelector(() => getRootDomainType(domainTypes, domainType))
  const defaultSorts = useDefaultSorts(domainType)
  const defaults = useMemo(() => ({
    page: 1,
    pageSize: 15,
    searchText: '',
    sorts: defaultSorts,
    filters: [],
    filterLinkOperator: 'and' as 'and' | 'or',
    bypassDomainTypeFilters: [],
    bypassSearchIndex: false,
    queryId: '',
    date: DateTime.now().toJSDate()
  }), [defaultSorts])
  const [
    {
      isLoading,
      searchResponse,
      editingItem,
      actionDetails,
      actionDialogOpen,
      changeItemDatesErrorCode,
      wasDisplayingCalendarView,
      searchInputs: {
        page,
        pageSize,
        searchText,
        filters,
        sorts,
        filterLinkOperator,
        bypassSearchIndex,
        bypassDomainTypeFilters
      }
    },
    dispatch
  ] = useReducer(findPageReducer, defaultState)
  const [
    {
      date,
      ...searchInputs
    },
    {
      push: pushState
    }
  ] = useSearchParams(codecs, defaults, urlPrefix)
  const onSortsChange = useCallback((value: Sort[]) => pushState({
    sorts: value
  }), [pushState])
  const [view, setView] = useDomainTypeSetting(
    domainType,
    'FindView',
    'table'
  )
  const onViewChange = useCallback((newView: FindPageView) => {
    dispatch(viewChanged(view, newView))
    setView(newView)
  }, [setView, view])
  const api = useApi()
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const [calendarView, onCalendarViewChange] = useDomainTypeSetting(
    domainType,
    'CalendarView',
    'month'
  )
  const calendarViewFilters = useDeepEqualMemo(useMemo(
    () => getCalendarViewFilters(domainTypes, domainType, overriders, view, calendarView, date),
    [calendarView, date, domainType, domainTypes, overriders, view]
  ))
  const isDisplayingCalendarView = isCalendarView(view)
  const search = useCancellableApiSession(api)
  const onSearch = useCallback(function doSearch() {
    const apiSession = search.cancelPreviousAndStartNew()
    if (rootDomainType === null || !apiSession.isSignedIn) {
      return search.cancel
    }
    const [searchPage, searchPageSize] = isDisplayingCalendarView
      ? [1, 500]
      : [searchInputs.page, searchInputs.pageSize]
    dispatch(performSearch({
      page: searchInputs.page,
      pageSize: searchInputs.pageSize,
      searchText: searchInputs.searchText,
      filters: searchInputs.filters,
      sorts: searchInputs.sorts,
      filterLinkOperator: searchInputs.filterLinkOperator,
      bypassSearchIndex: searchInputs.bypassSearchIndex,
      bypassDomainTypeFilters: searchInputs.bypassDomainTypeFilters
    }))
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      searchInputs.filterLinkOperator,
      searchInputs.filters,
      searchInputs.searchText,
      additionalFilters
    )
    apiSession.search(
      rootDomainType.Name,
      domainType.Name,
      anyFilters,
      allFilters.concat(calendarViewFilters),
      searchInputs.sorts,
      searchPage,
      searchPageSize,
      searchInputs.bypassSearchIndex,
      searchInputs.bypassDomainTypeFilters
    )
      .then(response => {
        if (E.isRight(response)) {
          dispatch(performSearchFulfilled(response.right, isDisplayingCalendarView))
        }
      })
    return search.cancel
  }, [search, rootDomainType, isDisplayingCalendarView, searchInputs.page, searchInputs.pageSize, searchInputs.searchText, searchInputs.filters, searchInputs.sorts, searchInputs.filterLinkOperator, searchInputs.bypassSearchIndex, searchInputs.bypassDomainTypeFilters, domainTypes, domainType, additionalFilters, calendarViewFilters])
  useEffect(() => {
    return onSearch()
  }, [onSearch])
  const onSearchTextChange = useCallback((value: string) => pushState({
    searchText: value,
    page: 1
  }), [pushState])
  const onFilterLinkOperatorChange = useCallback((value: 'and' | 'or') => pushState({
    page: 1,
    filterLinkOperator: value
  }), [pushState])
  const onFiltersChange = useCallback((value: Filter[]) => pushState({
    page: 1,
    filters: value
  }), [pushState])
  const onPageChange = useCallback((value: number) => pushState({
    page: value
  }), [pushState])
  const onPageSizeChange = useCallback((value: number) => pushState({
    pageSize: value,
    page: Math.floor(((page - 1) * pageSize + 1) / value) + 1
  }), [pushState, page, pageSize])

  const navigate = useNavigate()

  const onRowClick = useCallback((id: string) => {
    if (rootDomainType === null) {
      return
    }
    const identifier = getDomainTypeSetting(domainTypes, rootDomainType, 'Identifier') ?? 'Id'
    const instance = searchResponse?.results.find(row => String(row[identifier]) === id)
    if (instance !== undefined) {
      navigate.toDetailsPage(domainType, instance)
    }
  }, [domainTypes, navigate, rootDomainType, searchResponse?.results, domainType])
  const fetchTotal = useCallback(async (api: SignedInApi, query: Query) => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      query.FilterLinkOperator ?? 'and',
      query.Filters,
      query.SearchText ?? '',
      additionalFilters
    )
    const response = await api.search(
      rootDomainType?.Name ?? domainType.DatabaseTable ?? '',
      domainType.Name,
      anyFilters,
      allFilters,
      query.Sorts,
      1,
      0,
      query.BypassSearchIndex ?? false,
      query.BypassDomainTypeFilters ?? []
    )
    return pipe(
      response,
      E.map(
        searchResult => searchResult.totalHits
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalFilters, domainType, domainTypes, rootDomainType?.Name, searchResponse])
  const onApplyQuery = useCallback((query: Query) => {
    pushState({
      filters: query.Filters,
      sorts: query.Sorts,
      searchText: query.SearchText ?? '',
      page: 1,
      queryId: query.Id,
      filterLinkOperator: query.FilterLinkOperator ?? 'and',
      bypassDomainTypeFilters: query.BypassDomainTypeFilters ?? [],
      bypassSearchIndex: query.BypassSearchIndex ?? false
    })
  }, [pushState])
  const [isExporting, setIsExporting] = useState(false)
  const onClickExport = useCallback(async (columns: string[]) => {
    if (rootDomainType === null || !api.isSignedIn) {
      return
    }
    setIsExporting(true)
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      filterLinkOperator,
      filters,
      searchText,
      additionalFilters
    )
    await api.export(
      rootDomainType.Name,
      domainType.Name,
      anyFilters,
      allFilters,
      sorts,
      columns,
      bypassSearchIndex,
      bypassDomainTypeFilters
    )
    setIsExporting(false)
  }, [additionalFilters, api, bypassDomainTypeFilters, bypassSearchIndex, domainType, domainTypes, filterLinkOperator, filters, rootDomainType, searchText, sorts])

  const [checkedRowIds, onCheckedRowIdsChange] = useState<string[]>(EMPTY_CHCKED_ROW_IDS)
  useEffect(() => {
    onCheckedRowIdsChange(EMPTY_CHCKED_ROW_IDS)
  }, [page, pageSize])
  const onChangeItemDates = useCallback(async (
    itemId: string,
    domainTypeChain: CalendarTimelineSettings[],
    groupItems: DomainTypeInstance[],
    items: DomainTypeInstance[],
    startDate: number,
    endDate: number,
    groupByValue?: GroupByValue,
    actionDetails?: ActionDetails
  ) => {
    if (rootDomainType === null || !api.isSignedIn) {
      return
    }
    const body = getChangeItemDatesPatchRequestBody(
      domainTypeChain,
      groupItems,
      items,
      startDate,
      endDate,
      groupByValue
    )
    if (body === null) {
      return
    }
    dispatch(performChangeItemDates({
      itemId,
      startDate,
      endDate,
      groupByValue
    }, actionDetails))
    if (actionDetails !== undefined) {
      return
    }
    const response = await api.patch(
      rootDomainType.Name,
      body
    )
    if (E.isRight(response)) {
      onSearch()
    } else {
      dispatch(changeItemDatesError(response.left.errorCode))
    }
  }, [api, onSearch, rootDomainType])
  const onDateChange = useCallback((value: Date) => pushState({
    date: value
  }), [pushState])
  const onCloseActionDialog = useCallback(() => {
    dispatch(closeActionDialog())
  }, [])
  const onPerformAction = useCallback(() => {
    dispatch(performAction())
    onSearch()
  }, [onSearch])
  const snackPack = useSnackPack()
  const { addMessage } = snackPack
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (changeItemDatesErrorCode === undefined) {
      return
    }
    addMessage(formatMessage({
      id: changeItemDatesErrorCode,
      defaultMessage: changeItemDatesErrorCode
    }))
  }, [changeItemDatesErrorCode, formatMessage, addMessage])
  const calendarProps = useMemo(() => ({
    view: calendarView,
    date,
    editingItem,
    actionDetails,
    actionDialogOpen,
    onViewChange: onCalendarViewChange,
    onDateChange,
    onChangeItemDates,
    onCloseActionDialog,
    onPerformAction
  }), [actionDetails, actionDialogOpen, calendarView, date, editingItem, onCalendarViewChange, onChangeItemDates, onCloseActionDialog, onDateChange, onPerformAction])
  const {
    items,
    calendarItems
  } = useMemo(() => ({
    items: !wasDisplayingCalendarView
      ? searchResponse?.results ?? []
      : [],
    calendarItems: wasDisplayingCalendarView
      ? searchResponse?.results ?? []
      : []
  }), [searchResponse?.results, wasDisplayingCalendarView])
  return {
    view,
    isLoading,
    items,
    total: isDisplayingCalendarView === wasDisplayingCalendarView
      ? searchResponse?.totalHits ?? 0
      : 0,
    calendarItems,
    calendarProps,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    isExporting,
    checkedRowIds,
    snackPack,
    onSearch,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange,
    onPageSizeChange,
    onSortsChange,
    onRowClick,
    fetchTotal,
    onApplyQuery,
    onClickExport: getDomainTypeSetting(domainTypes, domainType, 'Api') === true
      ? onClickExport
      : undefined,
    onCheckedRowIdsChange,
    onViewChange
  }
}
