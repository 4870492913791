import { HorizontalSplitOutlined, SaveOutlined, VerticalSplitOutlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useState } from 'react'
import { DomainType } from 'types'
import { useDomainTypeSetting } from 'utils/hooks'

interface Props {
  readonly domainType: DomainType
  onClose(): void
}

export default function DetailsTabOrientationPanel({
  domainType,
  onClose
}: Props): JSX.Element {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [tabOrientation, onTabOrientationChange] = useDomainTypeSetting(
    domainType,
    'TabOrientation',
    'vertical'
  )
  return (
    <>
      <MenuList>
        {tabOrientation !== 'horizontal' && (
          <MenuItem
            onClick={() => {
              onTabOrientationChange('horizontal')
              onClose()
            }}>
            <ListItemIcon>
              <HorizontalSplitOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Horizontal Tabs</ListItemText>
          </MenuItem>
        )}
        {tabOrientation !== 'vertical' && (
          <MenuItem
            onClick={() => {
              onTabOrientationChange('vertical')
              onClose()
            }}>
            <ListItemIcon>
              <VerticalSplitOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Vertical Tabs</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => setSaveDialogOpen(true)}>
          <ListItemIcon>
            <SaveOutlined />
          </ListItemIcon>
          <ListItemText>Save View</ListItemText>
        </MenuItem>
      </MenuList>
      <SaveDomainTypeSettingButton
        domainType={domainType}
        setting='TabOrientation'
        value={tabOrientation}
        dialogOpen={saveDialogOpen}
        onChangeDialogOpen={value => {
          setSaveDialogOpen(value)
          if (!value) {
            onClose()
          }
        }} />
    </>
  )
}