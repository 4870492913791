import { GridColumns } from '@mui/x-data-grid'
import { createContext } from 'react'
import { DomainType, DomainTypeInstance, Filter } from 'types'

interface Context {
  domainType: DomainType | null
  columns: GridColumns
  page?: number
  setPage?(value: number): void
  pageSize?: number
  setPageSize?(value: number): void
  total?: number
  isLoading: boolean
  rowCount?: number
  setColumnVisibility(field: string, isVisible: boolean): void
  disablePagination: boolean
  disableSorts: boolean
  checkedItems: DomainTypeInstance[]
  onAddFilter(filter: Filter): void
  filters: Filter[]
  onClickFilterIcon(field: string): void
}

export default createContext<Context>({
  domainType: null,
  columns: [],
  page: 1,
  setPage: value => null,
  pageSize: 15,
  setPageSize: value => null,
  total: 0,
  isLoading: false,
  rowCount: 0,
  setColumnVisibility: (field, isVisible) => null,
  disablePagination: false,
  disableSorts: false,
  checkedItems: [],
  onAddFilter: filter => null,
  filters: [],
  onClickFilterIcon: field => null
})
