import * as E from 'fp-ts/Either'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance, PathErrors } from 'types'
import { NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED } from 'utils/constants'
import { getDomainTypeSetting, getRootDomainType, validateRequiredAttributes } from 'utils/helpers'
import { useApi } from './useApi'
import { useDomainTypeForm, UseDomainTypeFormOutput } from './useDomainTypeForm'
import { useEditListAttribute } from './useEditListAttribute'
import { usePathErrors } from './usePathErrors'

interface UseCreateOutput extends UseDomainTypeFormOutput {
  isApiDomainType: boolean,
  isCreating: boolean
  pathErrors: PathErrors
  errorCode: string
  onCreate(): void
}

export function useCreate(
  instanceDomainType: DomainType,
  onCreateSuccess: (instance: DomainTypeInstance) => void,
  bypassApi = false
): UseCreateOutput {
  const {
    pathErrors = {},
    setPathErrors,
    removeErrorAtPath
  } = usePathErrors({})
  const {
    attributeValues,
    createInstance,
    onChange,
    onReset: onResetForm,
    ...rest
  } = useDomainTypeForm(instanceDomainType, null, 'create', undefined, removeErrorAtPath)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, instanceDomainType)
  const api = useApi()
  const [isCreating, setIsCreating] = useState(false)

  const [errorCode, setErrorCode] = useState('')
  const isApiDomainType = getDomainTypeSetting(domainTypes, instanceDomainType, 'Api') ?? false
  const onSingleCreateSuccess = useCallback((instances: DomainTypeInstance[]) => {
    const singleInstance = instances[0]
    if (singleInstance !== undefined) {
      onCreateSuccess(singleInstance)
    }
  }, [onCreateSuccess])
  const {
    isEditing: isEditingListAttribute,
    onEditListAttribute
  } = useEditListAttribute(instanceDomainType, onSingleCreateSuccess)

  const onCreate = useCallback(() => {
    const requiredPathErrors = validateRequiredAttributes(attributeValues)
    if (requiredPathErrors !== undefined) {
      setPathErrors(requiredPathErrors)
      setErrorCode(NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED)
      return
    }
    if (!isApiDomainType || bypassApi) {
      if (!bypassApi) {
        onEditListAttribute([
          {
            original: null,
            edited: createInstance(attributeValues)
          }
        ])
      } else {
        onCreateSuccess(createInstance(attributeValues))
      }
      return
    }
    setPathErrors({})
    setErrorCode('')
    async function create() {
      if (rootDomainType === null || !api.isSignedIn) {
        return
      }
      setIsCreating(true)
      const instance = createInstance(attributeValues)
      const response = await api.post(rootDomainType.Name, instance)
      if (E.isRight(response)) {
        onCreateSuccess(response.right)
      } else {
        setPathErrors(response.left.pathErrors ?? {})
        setErrorCode(response.left.errorCode)
      }
      setIsCreating(false)
    }
    create()
  }, [attributeValues, isApiDomainType, bypassApi, setPathErrors, onEditListAttribute, createInstance, onCreateSuccess, rootDomainType, api])
  const onReset = useCallback(() => {
    onResetForm()
    setPathErrors({})
    setErrorCode('')
  }, [onResetForm, setPathErrors])
  return {
    ...rest,
    isApiDomainType,
    isCreating: isCreating || isEditingListAttribute,
    attributeValues,
    pathErrors,
    errorCode,
    createInstance,
    onChange,
    onCreate,
    onReset
  }
}