import { Box } from '@mui/material'
import IsInRole from 'components/auth/IsInRole'
import Unauthorised from 'components/navigation/Unauthorised'
import { DomainType } from 'types'
import { PAGE_PADDING } from 'utils/constants'
import DetailsPage from './DetailsPage'
import useDetails from './useDetails'

interface Props {
  rootDomainType: DomainType
}

export default function RemoteDetailsPage({
  rootDomainType
}: Props): JSX.Element {
  const {
    id,
    isLoading,
    sections,
    domainType,
    role,
    instance,
    apiError,
    highlightedRow,
    onHighlightRow,
    view,
    onChangeView,
    onListItemClick,
    selectedTab,
    onTabChange,
    onInvalidate
  } = useDetails(rootDomainType)
  return (
    <IsInRole
      role={role}
      Unauthorised={Unauthorised}>
      <Box
        p={PAGE_PADDING}
        pt={0}>
        <DetailsPage
          key={id}
          isLoading={isLoading}
          sections={sections}
          domainType={domainType}
          instance={instance ?? {}}
          apiError={apiError}
          highlightedRow={highlightedRow}
          onHighlightRow={onHighlightRow}
          view={view}
          onChangeView={onChangeView}
          onListItemClick={onListItemClick}
          selectedTab={selectedTab}
          onTabChange={onTabChange}
          onInvalidate={onInvalidate} />
      </Box>
    </IsInRole>
  )
}