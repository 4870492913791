import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio as MaterialRadio, RadioGroup } from '@mui/material'
import { Choice, Element, RadioElement } from 'types/dataform'
import { isNullOrUndefined } from 'utils/helpers'
import { ElementProps } from '../ElementInput'

const defaultChoices: Choice[] = [
  {
    Value: 'Yes',
    Text: 'Yes'
  },
  {
    Value: 'No',
    Text: 'No'
  }
]

export function getRadioChoices(element: Element): Choice[] {
  if (isNullOrUndefined(element.Choices)) {
    return defaultChoices
  }
  if (element.Choices.length < 2) {
    return defaultChoices
  }
  return element.Choices
}

export function getRadioColor(
  value: string,
  expectedValue: RegExp | null
): 'success' | 'error' | 'primary' {
  const isExpected = expectedValue !== null && expectedValue.test(value)
  const isUnexpected = expectedValue !== null && !expectedValue.test(value)
  return isExpected
    ? 'success'
    : isUnexpected
      ? 'error'
      : 'primary'
}

export default function Radio({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  expectedValue,
  error,
  errorText
}: ElementProps<RadioElement>): JSX.Element | null {
  const choices = getRadioChoices(element)
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error}>
      <FormLabel>{element.Text}</FormLabel>
      <RadioGroup
        value={value}
        onChange={(event, value) => onChange(value)}>
        {choices.map(choice => (
          <FormControlLabel
            key={choice.Value}
            value={choice.Value}
            control={(
              <MaterialRadio
                size='small'
                color={getRadioColor(choice.Value, expectedValue)}
                disabled={locked || readOnly ? value !== choice.Value : false} />
            )}
            label={choice.Text} />
        ))}
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}