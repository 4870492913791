import { ApiError, DomainTypeInstance } from 'types'

export type DetailsPageView = 'expandableList' | 'tabs'

interface State {
  readonly shouldReload: boolean
  readonly isLoading: boolean,
  readonly remoteInstance: DomainTypeInstance | null
  readonly apiError: ApiError | null
}

const LOAD_INSTANCE = 'LOAD_INSTANCE'
interface LoadInstanceAction {
  readonly type: typeof LOAD_INSTANCE
}
export const loadInstance = (): LoadInstanceAction => ({
  type: LOAD_INSTANCE
})

const LOAD_INSTANCE_FULFILLED = 'LOAD_INSTANCE_FULFILLED'
interface LoadInstanceFulfilledAction {
  readonly type: typeof LOAD_INSTANCE_FULFILLED
  readonly payload: {
    readonly instance: DomainTypeInstance
  }
}
export const loadInstanceFulfilled = (
  instance: DomainTypeInstance
): LoadInstanceFulfilledAction => ({
  type: LOAD_INSTANCE_FULFILLED,
  payload: {
    instance
  }
})

const LOAD_INSTANCE_ERROR = 'LOAD_INSTANCE_ERROR'
interface LoadInstanceErrorAction {
  readonly type: typeof LOAD_INSTANCE_ERROR
  readonly payload: {
    readonly apiError: ApiError
  }
}
export const loadInstanceError = (
  apiError: ApiError
): LoadInstanceErrorAction => ({
  type: LOAD_INSTANCE_ERROR,
  payload: {
    apiError
  }
})

const RELOAD = 'RELOAD'
interface ReloadAction {
  readonly type: typeof RELOAD
}
export const reload = (): ReloadAction => ({
  type: RELOAD
})

const LOCAL_INSTANCE_CHANGED = 'LOCAL_INSTANCE_CHANGED'
interface LocalInstanceChangedAction {
  readonly type: typeof LOCAL_INSTANCE_CHANGED
}
export function localInstanceChanged(): LocalInstanceChangedAction {
  return {
    type: LOCAL_INSTANCE_CHANGED
  }
}

export type DetailsPageAction =
  | LoadInstanceAction
  | LoadInstanceFulfilledAction
  | LoadInstanceErrorAction
  | ReloadAction
  | LocalInstanceChangedAction

export const defaultState: State = {
  shouldReload: false,
  isLoading: true,
  remoteInstance: null,
  apiError: null
}

export default function detailsPageReducer(state: State, action: DetailsPageAction): State {
  switch (action.type) {
    case LOAD_INSTANCE:
      return {
        ...state,
        shouldReload: false,
        isLoading: !state.shouldReload
      }
    case LOAD_INSTANCE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        remoteInstance: action.payload.instance
      }
    case LOAD_INSTANCE_ERROR:
      return {
        ...state,
        isLoading: false,
        remoteInstance: null,
        apiError: action.payload.apiError
      }
    case RELOAD:
      return {
        ...state,
        shouldReload: true
      }
    case LOCAL_INSTANCE_CHANGED:
      if (state.remoteInstance === null) {
        return state
      }
      return {
        ...state,
        remoteInstance: null
      }
    default:
      return state
  }
}
