import { useRef } from 'react'

function isEqual<T>(v1: T, v2: T): boolean {
  try {
    return JSON.stringify(v1) === JSON.stringify(v2)
  } catch {
    return false
  }
}

export function useDeepEqualMemo<T>(
  value: T
): T {
  const ref = useRef<T>()

  if (ref.current === undefined || !isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}