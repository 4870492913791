
import * as React from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { VariableSizeList, ListChildComponentProps } from 'react-window'
import Typography from '@mui/material/Typography'
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, Paper, Popper, PopperProps, TextField } from '@mui/material'
import { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState, MouseEvent } from 'react'
import { DomainType, DomainTypeInstance, ListDomainTypeAttributeValue, ListRefAttributeValue, PathError } from 'types'
import { fixMultipleAutocompleteRequiredBehaviour, getDomainTypeSetting, getHeading, makeSortFunction, toErrorText } from 'utils/helpers'
import { useApiDomainTypeAutocomplete } from 'utils/hooks'
import AttributeCell, { DomainTypeCell } from '../AttributeCell'
import LabelledInput from './LabelledInput'
import { AddOutlined } from '@mui/icons-material'
const LISTBOX_PADDING = 8 // px

interface ListApiDomainTypeProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: ListDomainTypeAttributeValue | ListRefAttributeValue
  readonly disabled?: boolean
  readonly readOnly: boolean
  readonly pathError?: PathError
  onChange(attributeValue: DomainTypeInstance[] | string[] | null): void
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const element = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }

  return (
    <Typography
      component='li'
      noWrap
      style={inlineStyle}>
      {element}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return (
    <div
      ref={ref}
      {...props}
      {...outerProps} />
  )
})

function useResetCache(data: unknown) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props
    const itemData = (children ?? []) as React.ReactChild[]

    const theme = useTheme()
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true })
    const itemCount = itemData.length
    const itemSize = smUp ? 36 : 48

    const getChildSize = () => itemSize

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
    }

    const gridRef = useResetCache(itemCount)

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={index => getChildSize()}
            overscanCount={5}
            itemCount={itemCount}>
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  })

function PaperComponent({
  onClick,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLElement>>): JSX.Element {
  return (
    <Paper
      {...props}
      elevation={20}>
      <Box
        p={1}>
        <Button
          variant='text'
          startIcon={<AddOutlined />}
          onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
          }}
          onClick={onClick}>
          Add All
        </Button>
        <Typography>
          Start Typing to Filter
        </Typography>
      </Box>
      {props.children}
    </Paper>
  )
}

export default function MultiSelectAutocompleteInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  readOnly,
  pathError,
  onChange }: ListApiDomainTypeProps): JSX.Element | null {
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const {
    open,
    setOpen,
    options,
    loading
  } = useApiDomainTypeAutocomplete(domainTypes, domainType, attributeValue.attribute.Filters, undefined, true, attributeValue.attribute.BypassDomainTypeFilters ?? [])

  const value = useMemo(() => {
    const values = attributeValue.value ?? []
    if (values.length > 0 && typeof values[0] === 'string') {
      const ids = values as string[]
      return options.filter(opt => ids.includes(String(opt[identifier])))
    }

    return values as DomainTypeInstance[]
  }, [attributeValue.value, identifier, options])

  const sortedOptions = useMemo(() => {
    const optionsWithHeading = options.map(option => {
      return [
        option,
        getHeading(
          domainTypes,
          domainType,
          option
        )
      ] as const
    })
    const order = optionsWithHeading
      .map(([option, heading]) => heading)
      .sort()
    return optionsWithHeading
      .sort(makeSortFunction(
        order,
        ([option, heading]) => heading
      ))
      .map(([option]) => option)
  }, [domainType, domainTypes, options])
  const [inputValue, setInputValue] = useState('')

  const popperComponent = useCallback((props: PopperProps) => (
    <Popper
      {...props}
      modifiers={[{
        name: 'flip',
        enabled: true,
        options: {
          altBoundary: true,
          rootBoundary: 'document',
          padding: 8
        }
      },
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }]}
      style={{
        ...props.style
      }} />
  ), [])

  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell
          attributeChainValue={{
            attribute: {
              ...attributeValue.attribute,
              AttributeType: 'domainType'
            },
            value
          }} />
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      multiple
      fullWidth
      open={open}
      onOpen={() => { setOpen(true) }}
      onClose={() => { setOpen(false) }}
      disabled={disabled}
      onChange={(event: unknown, value) => onChange(value)}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) => {
        return option[identifier] === value[identifier]
      }}
      disableListWrap
      PopperComponent={popperComponent}
      componentsProps={{
        paper: { onClick: (evt: MouseEvent<HTMLDivElement>) => onChange(options) }
      }}
      PaperComponent={PaperComponent}
      ListboxComponent={ListboxComponent}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      getOptionLabel={option => getHeading(domainTypes, domainType, option)}
      value={value}
      options={sortedOptions}
      loading={loading}
      readOnly={readOnly}
      renderTags={(value, getTagProps, ownerState) => {
        const numTags = value.length
        const limitTags = 1
        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                onDelete={undefined}
                key={index}
                size='small'
                label={ownerState.getOptionLabel?.(option)} />
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
            &nbsp;
          </>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? (
                    <CircularProgress
                      color='inherit'
                      size={20} />
                  )
                  : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
          inputProps={fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, value)} />
      )}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={String(option[identifier])}>
            <Checkbox
              size='small'
              sx={{
                width: 24,
                height: 24,
                marginRight: '8px'
              }}
              checked={selected} />
            <DomainTypeCell
              attributeValue={{
                attribute: {
                  ...attributeValue.attribute,
                  AttributeType: 'domainType'
                },
                value: option
              }}
              disableLink />
          </li>
        )
      }} />
  )
}