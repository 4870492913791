import { Button, Icon, IconButton, Tooltip } from '@mui/material'
import { ElementType, MouseEvent, useMemo } from 'react'

interface Props {
  readonly text: string
  readonly icon: string,
  readonly onClick: (e: MouseEvent) => void
  readonly iconOnly?: boolean
  readonly disabled?: boolean
  readonly component?: ElementType<unknown> | string
}

export default function TextIconButton({
  text,
  onClick,
  icon,
  iconOnly = false,
  disabled = false,
  component = undefined
}: Props): JSX.Element {
  function internalOnClick(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    onClick(e)
  }

  const componentAttr = useMemo(() => component !== undefined ? { component } : {}, [component])

  return (
    iconOnly
      ? (
        <Tooltip title={text}>
          <div>
            <IconButton
              tabIndex={-1}
              color='primary'
              {...componentAttr}
              onClick={internalOnClick}
              disabled={disabled === true}>
              <Icon>{icon}</Icon>
            </IconButton>
          </div>
        </Tooltip>
      )
      : (
        <Button
          tabIndex={-1}
          onClick={internalOnClick}
          startIcon={<Icon>{icon}</Icon>}
          {...componentAttr}
          disabled={disabled === true}>
          {text}
        </Button>
      )
  )
}
