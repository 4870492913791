import CreateDialog from 'components/domainType/CreateDialog'
import { OverridableDomainTypeComponent } from 'components/overrides'
import { JSXElementConstructor, useCallback, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'
import { getDomainTypeSetting } from 'utils/helpers'
import { CreateButton as CreateButtonType, useNavigate } from 'utils/hooks'
import { ButtonProps } from './DomainTypeButtons'

interface Props {
  readonly button: CreateButtonType
  readonly Component: JSXElementConstructor<ButtonProps>
  onComplete?: () => void
}

function DefaultCreateButton({
  button,
  Component,
  onComplete
}: Props): JSX.Element | null {
  const [dialogOpen, setDialogOpen] = useState(false)
  const navigate = useNavigate()
  const context = useContext(DomainTypeContext)
  const domainTypes = useSelector(getAllDomainTypes)

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    onComplete?.()
  }, [setDialogOpen, onComplete])

  const onSuccess = useCallback(async (createdInstance: DomainTypeInstance) => {
    if (getDomainTypeSetting(domainTypes, button.domainType, 'Api') ?? false) {
      navigate.toDetailsPage(button.domainType, createdInstance)
    } else {
      context.onInvalidate?.()
    }

    onCloseDialog()
  }, [domainTypes, button.domainType, onCloseDialog, navigate, context])

  return (
    <>
      <Component
        text={button.name}
        disabled={button.disabled}
        icon='add'
        onClick={() => setDialogOpen(true)} />
      <CreateDialog
        open={dialogOpen}
        domainType={button.domainType}
        onClose={onCloseDialog}
        onCreateSuccess={onSuccess} />
    </>
  )
}

export default function CreateButton(props: Props): JSX.Element {
  return (
    <OverridableDomainTypeComponent<'createButton'>
      component='createButton'
      domainType={props.button.domainType}
      props={props}
      DefaultComponent={DefaultCreateButton} />
  )
}