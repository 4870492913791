import { useCallback, useMemo, useReducer } from 'react'
import { PathErrors } from 'types'

interface SetPathErrorsAction {
  readonly type: 'SET_PATH_ERRORS'
  readonly value: PathErrors | undefined
}

interface RemoveErrorAtPathAction {
  readonly type: 'REMOVE_ERROR_AT_PATH'
  readonly path: string
}

type Action =
  | SetPathErrorsAction
  | RemoveErrorAtPathAction

function reducer(state: PathErrors | undefined, action: Action): PathErrors | undefined {
  switch (action.type) {
    case 'SET_PATH_ERRORS':
      return action.value
    case 'REMOVE_ERROR_AT_PATH':
      return {
        ...state,
        [action.path]: undefined
      }
    default:
      return state
  }
}

interface UsePathErrorsOutput {
  readonly pathErrors: PathErrors | undefined
  setPathErrors(value: PathErrors | undefined): void
  removeErrorAtPath(path: string): void
}

export function usePathErrors(initialValue?: PathErrors): UsePathErrorsOutput {
  const [pathErrors, dispatch] = useReducer(reducer, { pathErrors: initialValue })
  const setPathErrors = useCallback((value: PathErrors | undefined) => {
    dispatch({
      type: 'SET_PATH_ERRORS',
      value
    })
  }, [])
  const removeErrorAtPath = useCallback((path: string) => {
    dispatch({
      type: 'REMOVE_ERROR_AT_PATH',
      path
    })
  }, [])
  return useMemo(() => ({
    pathErrors,
    setPathErrors,
    removeErrorAtPath
  }), [pathErrors, removeErrorAtPath, setPathErrors])
}