import { PaletteMode } from '@mui/material'
import * as t from 'io-ts'
import { RouterState } from 'redux-first-history/build/es6/reducer'
import { JsonStringCodec } from 'utils/codecs'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { Results } from './dataform'

declare global {
  interface Window {
    Config: {
      ServerUrl: string
    }
    SemanticVersion: string
  }
}

export interface AuthState {
  readonly user: User | null
  readonly person: Person | null
  readonly company: Company | null
  readonly adalProperties: AdalProperties | null
}

export interface PreferencesState {
  readonly theme: PaletteMode
  readonly sideMenuExpanded: boolean
  readonly openMenuSections: (string | null)[]
}

export type DomainTypes = Partial<Record<string, DomainType>>

export interface State {
  readonly router: RouterState
  readonly auth: AuthState
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly preferences: PreferencesState
}

export const PERFORM_SIGN_IN_FULFILLED = 'PERFORM_SIGN_IN_FULFILLED'
export interface PerformSignInFulfilledAction {
  readonly type: typeof PERFORM_SIGN_IN_FULFILLED
  readonly payload: {
    readonly response: User
  }
}

export const SIGNOUT = 'SIGNOUT'
export interface SignOutAction {
  readonly type: typeof SIGNOUT
}

export const SWITCH_COMPANY = 'SWITCH_COMPANY'
export interface SwitchCompanyAction {
  readonly type: typeof SWITCH_COMPANY
  readonly payload: {
    readonly companyId: string
  }
}

export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED'
export interface ResetPasswordFulfilledAction {
  readonly type: typeof RESET_PASSWORD_FULFILLED
}

export const FETCH_ADAL_PROPERTIES_FULFILLED = 'FETCH_ADAL_PROPERTIES_FULFILLED'
export interface FetchAdalPropertiesFulfilledAction {
  readonly type: typeof FETCH_ADAL_PROPERTIES_FULFILLED
  readonly payload: {
    readonly adalProperties: AdalProperties
  }
}

export const FETCH_PERSON_FULFILLED = 'FETCH_PERSON_FULFILLED'
export interface FetchPersonFulfilledAction {
  readonly type: typeof FETCH_PERSON_FULFILLED
  readonly payload: {
    readonly person: Person
  }
}

export const FETCH_COMPANY_FULFILLED = 'FETCH_COMPANY_FULFILLED'
export interface FetchCompanyFulfilledAction {
  readonly type: typeof FETCH_COMPANY_FULFILLED
  readonly payload: {
    readonly company: Company
  }
}

export const EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION = 'EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION'
export interface EditPersonGlobalDomainTypeSettingAction<Setting extends keyof GlobalDomainTypeSettings> {
  readonly type: typeof EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION
  readonly payload: {
    readonly setting: Setting
    readonly value: GlobalDomainTypeSettings[Setting]
  }
}

export const CHANGE_THEME = 'CHANGE_THEME'
export interface ChangeThemeAction {
  readonly type: typeof CHANGE_THEME
  readonly payload: {
    readonly theme: PaletteMode
  }
}

export const TOGGLE_SIDE_MENU_EXPANDED = 'TOGGLE_SIDE_MENU_EXPANDED'
export interface ToggleSideMenuExpandedAction {
  readonly type: typeof TOGGLE_SIDE_MENU_EXPANDED
}

export const SET_MENU_SECTION_OPEN = 'SET_MENU_SECTION_OPEN'
export interface SetMenuSectionOpenAction {
  readonly type: typeof SET_MENU_SECTION_OPEN
  readonly payload: {
    readonly id: string | null
    readonly open: boolean
  }
}

export const FETCH_DOMAIN_TYPES_FULFILLED = 'FETCH_DOMAIN_TYPES_FULFILLED'
export interface FetchDomainTypesFulfilledAction {
  readonly type: typeof FETCH_DOMAIN_TYPES_FULFILLED
  readonly payload: {
    readonly domainTypes: DomainType[]
  }
}

export const EDIT_DOMAIN_TYPE = 'EDIT_DOMAIN_TYPE'
export interface EditDomainTypeAction {
  readonly type: typeof EDIT_DOMAIN_TYPE
  readonly payload: {
    readonly domainType: DomainType
  }
}

export const EDIT_DOMAIN_TYPE_OVERRIDER = 'EDIT_DOMAIN_TYPE_OVERRIDER'
export interface EditDomainTypeOverriderAction<Setting extends keyof OverridableDomainTypeSettings> {
  readonly type: typeof EDIT_DOMAIN_TYPE_OVERRIDER
  readonly payload: {
    readonly overriderDetails: OverriderDetails
    readonly domainTypeId: string
    readonly setting: Setting
    readonly value: OverridableDomainTypeSettings[Setting]
  }
}

export type Action =
  | PerformSignInFulfilledAction
  | FetchAdalPropertiesFulfilledAction
  | FetchPersonFulfilledAction
  | FetchCompanyFulfilledAction
  | SignOutAction
  | SwitchCompanyAction
  | FetchDomainTypesFulfilledAction
  | ChangeThemeAction
  | ToggleSideMenuExpandedAction
  | SetMenuSectionOpenAction
  | EditDomainTypeAction
  | EditPersonGlobalDomainTypeSettingAction<keyof GlobalDomainTypeSettings>
  | EditDomainTypeOverriderAction<keyof OverridableDomainTypeSettings>

export type ChildrenNoProps = React.PropsWithChildren<Record<never, never>>

export type DomainTypeInstance = Record<string, unknown>

export interface SearchResponse {
  readonly totalHits: number
  readonly results: DomainTypeInstance[]
}

export interface ResetPasswordRequest {
  readonly username: string
  readonly newPassword: string
  readonly confirmPassword: string
  readonly token: string
}

export interface EffectResultBase {
  readonly Name: string
}

export interface SetValueSuccessEffectResult extends EffectResultBase {
  readonly Type: 'SetValueActionEffect'
  readonly Result: 'Success'
}

export interface SetValueErrorEffectResult extends EffectResultBase {
  readonly Type: 'SetValueActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface CreateSuccessResult {
  readonly Result: 'Success'
  readonly Item: DomainTypeInstance
}

export interface CreateErrorResult {
  readonly Result: 'Error'
  readonly Item: DomainTypeInstance
  readonly Message: string
}

export type CreateResult =
  | CreateSuccessResult
  | CreateErrorResult

export interface CreateItemsSuccessEffectResult extends EffectResultBase {
  readonly Type: 'CreateItemsActionEffect'
  readonly Result: 'Success'
  readonly Items: CreateResult[]
}

export interface CreateItemsErrorEffectResult extends EffectResultBase {
  readonly Type: 'CreateItemsActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface AddToListSuccessEffectResult extends EffectResultBase {
  readonly Type: 'AddToListActionEffect'
  readonly Result: 'Success'
}

export interface AddToListErrorEffectResult extends EffectResultBase {
  readonly Type: 'AddToListActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface AssignTaskSuccessEffectResult extends EffectResultBase {
  readonly Type: 'AssignTaskActionEffect'
  readonly Result: 'Success'
}

export interface AssignTaskErrorEffectResult extends EffectResultBase {
  readonly Type: 'AssignTaskActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface SendPushNotificationSuccessResult extends EffectResultBase {
  readonly Type: 'SendPushNotificationActionEffect'
  readonly Result: 'Success'
  readonly Job: JobDetails
}

export interface SendPushNotificationErrorResult extends EffectResultBase {
  readonly Type: 'SendPushNotificationActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface SendEmailSuccessResult extends EffectResultBase {
  readonly Type: 'SendEmailActionEffect'
  readonly Result: 'Success'
  readonly Job: JobDetails
}

export interface SendEmailErrorResult extends EffectResultBase {
  readonly Type: 'SendEmailActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface QueueJobSuccessResult extends EffectResultBase {
  readonly Type: 'QueueJobActionEffect'
  readonly Result: 'Success'
  readonly Job: JobDetails
}

export interface QueueJobErrorResult extends EffectResultBase {
  readonly Type: 'QueueJobActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface CopyToFileStoreSuccessResult extends EffectResultBase {
  readonly Type: 'CopyToFileStoreActionEffect'
  readonly Result: 'Success'
}

export interface CopyToFileStoreErrorResult extends EffectResultBase {
  readonly Type: 'CopyToFileStoreActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface DownloadFromFileStoreSuccessResult extends EffectResultBase {
  readonly Type: 'DownloadFromFileStoreActionEffect'
  readonly Result: 'Success'
  readonly DomainTypeName: string
  readonly Id: string
  readonly FileId: string
  readonly FileName: string
}

export interface DownloadFromFileStoreErrorResult extends EffectResultBase {
  readonly Type: 'DownloadFromFileStoreActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface DownloadInstanceSuccessResult extends EffectResultBase {
  readonly Type: 'DownloadInstanceActionEffect'
  readonly Result: 'Success'
  readonly DomainTypeName: string
  readonly InstanceId: string
}

export interface DownloadInstanceErrorResult extends EffectResultBase {
  readonly Type: 'DownloadInstanceActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export interface SaveUpdatesSuccessResult extends EffectResultBase {
  readonly Type: 'SaveUpdatesActionEffect'
  readonly Result: 'Success'
}

export interface SaveUpdatesErrorResult extends EffectResultBase {
  readonly Type: 'SaveUpdatesActionEffect'
  readonly Result: 'Error'
  readonly Message: string
}

export type EffectResult =
  | SetValueSuccessEffectResult
  | SetValueErrorEffectResult
  | CreateItemsSuccessEffectResult
  | CreateItemsErrorEffectResult
  | AddToListSuccessEffectResult
  | AddToListErrorEffectResult
  | AssignTaskSuccessEffectResult
  | AssignTaskErrorEffectResult
  | SendPushNotificationSuccessResult
  | SendPushNotificationErrorResult
  | SendEmailSuccessResult
  | SendEmailErrorResult
  | QueueJobSuccessResult
  | QueueJobErrorResult
  | CopyToFileStoreSuccessResult
  | CopyToFileStoreErrorResult
  | DownloadFromFileStoreSuccessResult
  | DownloadFromFileStoreErrorResult
  | DownloadInstanceSuccessResult
  | DownloadInstanceErrorResult
  | SaveUpdatesSuccessResult
  | SaveUpdatesErrorResult

export interface EffectResults {
  readonly EffectResults?: EffectResult[]
}

export interface ActionResponse {
  readonly Paths: ActionPaths
  readonly EffectResults: EffectResult[]
}

export type ActionPaths = (Partial<Record<string, string | ActionPaths | EffectResult[]>> & EffectResults)[]

export interface DomainType extends DomainTypeSettings {
  readonly Id: string
  readonly Name: string
  readonly Title: string
  readonly PluralTitle: string
  readonly Attributes: Attribute[]
}

export interface BaseAttribute {
  readonly Name: string
  readonly Title: string
  readonly Id?: string
  readonly Description?: string
  readonly List?: boolean
  readonly Single?: boolean | null
  readonly Required?: boolean | null
  readonly Hidden?: boolean | null
  readonly BypassDomainTypeFilters?: string[] | null
}

export interface StringAttribute extends BaseAttribute {
  readonly AttributeType: 'string'
  readonly Format?: 'colour' | 'JSON' | 'email' | 'password' | null
  readonly Translated?: boolean | null
}

export interface BoolAttribute extends BaseAttribute {
  readonly AttributeType: 'bool'
}

export interface NumberAttribute extends BaseAttribute {
  readonly AttributeType: 'number'
}

export interface GuidAttribute extends BaseAttribute {
  readonly AttributeType: 'guid'
}

export interface DomainTypeAttribute extends BaseAttribute {
  readonly AttributeType: 'domainType'
  readonly AttributeDomainType: string
  readonly Filters?: Filter[] | null
}

export interface RefAttribute extends BaseAttribute {
  readonly AttributeType: 'ref'
  readonly AttributeDomainType: string
  readonly Filters?: Filter[] | null
}

export interface ContextRefAttribute extends BaseAttribute {
  readonly AttributeType: 'contextRef'
  readonly ContextDomainType: string
  readonly ContextAttributeName: string
  readonly AttributeDomainType: string
  readonly Filters?: Filter[] | null
}

export interface DateAttribute extends BaseAttribute {
  readonly AttributeType: 'date'
}

export interface DateTimeAttribute extends BaseAttribute {
  readonly AttributeType: 'dateTime'
}

export interface EnumAttribute extends BaseAttribute {
  readonly AttributeType: 'enum'
  readonly EnumeratedType: EnumeratedType
}

export interface DataformResultsAttribute extends BaseAttribute {
  readonly AttributeType: 'dataformResults'
  readonly SelectBy: 'name' | 'selectionCriteria'
  readonly DataformName?: string | null
  readonly SelectionCategory?: string | null
  readonly SelectionSubcategory?: string | null
  readonly DefaultSelectionProperties?: string[] | null
  readonly Purpose?: string | null
}

export interface MultiDataformResultsAttribute extends BaseAttribute {
  readonly AttributeType: 'multiDataformResults'
  readonly SelectBy: 'name' | 'selectionCriteria'
  readonly DataformNames?: string[] | null
  readonly SelectionCategory?: string | null
  readonly SelectionSubcategory?: string | null
  readonly DefaultSelectionProperties?: string[] | null
  readonly Purpose?: string | null
}

export interface EnumeratedType {
  readonly ExternalId: string
  readonly GlobalExternalId: string
  readonly Values: EnumeratedValue[]
}

export interface EnumeratedValue {
  readonly Id: string
  readonly Value: string
  readonly Description: string
  readonly Order?: number | null
  readonly Icon?: string | null
  readonly Colour?: string | null
}

export type Attribute =
  | StringAttribute
  | NumberAttribute
  | BoolAttribute
  | DateAttribute
  | DateTimeAttribute
  | GuidAttribute
  | DomainTypeAttribute
  | RefAttribute
  | ContextRefAttribute
  | EnumAttribute
  | DataformResultsAttribute
  | MultiDataformResultsAttribute

export type ListAttribute<A extends Attribute = Attribute> = A & { List: true }
export type NonListAttribute<A extends Attribute = Attribute> = A & { List?: false | null }

export type JsonString = t.TypeOf<typeof JsonStringCodec>

export interface Filter {
  readonly Property: string
  readonly Operator: string
  readonly Value: JsonString | null
}

export interface Sort {
  readonly Property: string
  readonly Direction: 'asc' | 'desc' | null
}

export interface SignInRequest {
  readonly username: string
  readonly password: string
}

export interface ResetPasswordRequest {
  readonly newPassword: string
  readonly confirmPassword: string
}

export interface AdalProperties {
  readonly authorityUrl: string
  readonly clientId: string
  readonly tenantId: string
}

interface UserCompany {
  readonly id: string
  readonly name: string
  readonly token: string
}
export interface User {
  readonly id: string
  readonly token: string
  readonly companies: UserCompany[]
  readonly roles: string[]
  selectedCompanyId?: string
}

export interface Person {
  readonly Id: string
  readonly FullName: string
  readonly GlobalDomainTypeSettings?: GlobalDomainTypeSettings | null
  readonly DomainTypeOverrides?: DomainTypeOverride[] | null
}

export interface Theme {
  readonly Primary: string
}

export interface Company {
  readonly Id: string
  readonly Name: string
  readonly Theme?: Theme | null
}

export interface DomainTypeOverride extends OverridableDomainTypeSettings {
  readonly Id: string
}

export interface DomainTypeOverrider {
  readonly Id: string
  readonly GlobalDomainTypeSettings?: GlobalDomainTypeSettings | null
  readonly DomainTypeOverrides?: DomainTypeOverride[] | null
}

export interface GlobalDomainTypeSettings {
  readonly View?: 'expandableList' | 'tabs' | null
  readonly TabOrientation?: 'vertical' | 'horizontal' | null
}

type DomainTypeListSettingNames = {
  [Key in keyof DomainTypeSettings]-?: DomainTypeSettings[Key] extends unknown[] | null | undefined ? Key : never
}[keyof DomainTypeSettings]

export type DomainTypeListSettings = {
  [Key in DomainTypeListSettingNames]: DomainTypeSettings[Key]
}

export interface OverridableDomainTypeSettings extends GlobalDomainTypeSettings {
  readonly Columns?: string[] | null
  readonly Sections?: Section[] | null
  readonly DefaultSort?: string | null
  readonly DefaultSortDirection?: 'asc' | 'desc' | null
  readonly Queries?: Query[] | null
  readonly View?: 'expandableList' | 'tabs' | null
  readonly FindView?: 'table' | 'cards' | 'calendar' | 'timeline' | null
  readonly Tab?: string | null
  readonly Summary?: string[] | null
  readonly StartDate?: string | null
  readonly EndDate?: string | null
  readonly CalendarView?: 'day' | 'week' | 'month' | null
  readonly TimelineItems?: string | null
  readonly TimelineGroupBy?: string | null
}

export interface EditableDomainTypeSettings extends OverridableDomainTypeSettings {
  readonly Icon?: string | null
  readonly CornerIcon?: string | null
  readonly Heading?: string | null
  readonly ExpandColumns?: boolean | null
  readonly ExpandableRow?: boolean | null
  readonly Find?: boolean | null
  readonly ViewRole?: string | null
  readonly CreateRole?: string | null
  readonly EditRole?: string | null
  readonly Actions?: DomainTypeAction[] | null
  readonly Buttons?: DomainTypeButton[] | null
  readonly CreateForm?: string[] | null
  readonly EditForm?: string[] | null
  readonly Category?: string | null
  readonly Colour?: string | null
}

export interface DomainTypeSettings extends EditableDomainTypeSettings {
  readonly DatabaseTable?: string | null
  readonly Parent?: string | null
  readonly Api?: boolean | null
  readonly Subtype?: string | null
  readonly SerializationType?: string | null
  readonly Identifier?: string | null
}

export interface Query {
  readonly Id: string
  readonly Title: string
  readonly Filters: Filter[]
  readonly Sorts: Sort[]
  readonly SearchText?: string | null
  readonly FilterLinkOperator?: 'and' | 'or' | null
  readonly BypassDomainTypeFilters?: string[] | null
  readonly BypassSearchIndex?: boolean | null
  readonly DomainTypeOverrides?: DomainTypeOverride[]
}

export interface Section {
  readonly Id: string
  readonly Title?: string | null
  readonly Hidden?: boolean | null
  readonly Expanded?: boolean | null
}

export interface ActionContext {
  readonly Name: string
  readonly DomainType: string
  readonly Batch: boolean
  readonly Parent?: boolean | null
}

export interface InstanceActionEffectValue {
  readonly ReadFrom: 'InstanceActionEffectValue'
  readonly Property?: string | null
}

export interface PersonActionEffectValue {
  readonly ReadFrom: 'PersonActionEffectValue'
  readonly Property?: string | null
}

export interface ContextActionEffectValue {
  readonly ReadFrom: 'ContextActionEffectValue'
  readonly Context: string
  readonly Property?: string | null
}

export interface ParametersActionEffectValue {
  readonly ReadFrom: 'ParametersActionEffectValue'
  readonly Parameter: string
  readonly Property?: string | null
}

export interface CompanyActionEffectValue {
  readonly ReadFrom: 'CompanyActionEffectValue'
  readonly Property?: string | null
}

export interface DomainTypeActionEffectValue {
  readonly ReadFrom: 'DomainTypeActionEffectValue'
  readonly Property?: string | null
}

export type ActionEffectValue =
  | InstanceActionEffectValue
  | PersonActionEffectValue
  | ContextActionEffectValue
  | ParametersActionEffectValue
  | CompanyActionEffectValue
  | DomainTypeActionEffectValue

export interface ActionParameterBase {
  readonly Name: string
  readonly Required: boolean
}

export interface AttributeActionParameter extends ActionParameterBase {
  readonly Type: 'AttributeActionParameter'
  readonly Attribute: string
  readonly PerformByEditing: boolean
}

export interface InputAttributeActionParameter extends ActionParameterBase {
  readonly Type: 'InputAttributeActionParameter'
  readonly Attribute: Attribute
}

export interface FileActionParameter extends ActionParameterBase {
  readonly Type: 'FileActionParameter'
  readonly UploadType: 'cache' | 'custom'
  readonly Accept?: string[] | null
}

export interface StaticActionParameter extends ActionParameterBase {
  readonly Type: 'StaticActionParameter'
  readonly Value: string
}

export type ActionParameter =
  | AttributeActionParameter
  | InputAttributeActionParameter
  | FileActionParameter
  | StaticActionParameter

export interface ActionEffectBase {
  readonly Name: string
  readonly Target: 'instance' | 'context' | 'rootInstance' | 'request'
  readonly Context?: string | null
}

export interface CreateItemsActionEffect extends ActionEffectBase {
  readonly Type: 'CreateItemsActionEffect'
  readonly DomainType: string
  readonly Transformer: string
}

export interface AddToListActionEffect extends ActionEffectBase {
  readonly Type: 'AddToListActionEffect'
}

export interface QueueJobActionEffect extends ActionEffectBase {
  readonly Type: 'QueueJobActionEffect'
  readonly DownloadFile: boolean
}

export interface SendPushNotificationActionEffect extends ActionEffectBase {
  readonly Type: 'SendPushNotificationActionEffect'
}

export interface AssignTaskActionEffect extends ActionEffectBase {
  readonly Type: 'AssignTaskActionEffect'
}

export interface SetValueActionEffect extends ActionEffectBase {
  readonly Type: 'SetValueActionEffect'
}

export interface SendEmailActionEffect extends ActionEffectBase {
  readonly Type: 'SendEmailActionEffect'
}

export interface CopyToFileStoreActionEffect extends ActionEffectBase {
  readonly Type: 'CopyToFileStoreActionEffect'
}

export interface DownloadFromFileStoreActionEffect extends ActionEffectBase {
  readonly Type: 'DownloadFromFileStoreActionEffect'
}

export interface DownloadInstanceActionEffect extends ActionEffectBase {
  readonly Type: 'DownloadInstanceActionEffect'
}

export type ActionEffect =
  | CreateItemsActionEffect
  | AddToListActionEffect
  | SendPushNotificationActionEffect
  | AssignTaskActionEffect
  | SetValueActionEffect
  | SendEmailActionEffect
  | QueueJobActionEffect
  | CopyToFileStoreActionEffect
  | DownloadFromFileStoreActionEffect
  | DownloadInstanceActionEffect

export interface DomainTypeAction {
  readonly Name: string
  readonly Batch: boolean
  readonly Conditions?: Filter[] | null
  readonly Context?: ActionContext[] | null
  readonly Parameters?: ActionParameter[] | null
  readonly Effects?: ActionEffect[] | null
  readonly Role: string
}

export type ButtonLocation =
  | 'DetailsHeader'
  | 'TableToolbar'
  | 'TableRow'

export interface BaseDomainTypeButton {
  readonly Name: string
  readonly Type: string
  readonly Conditions?: Filter[] | null
  readonly Role: string
  readonly ShowOn: ButtonLocation[]
  readonly Priority?: 'high' | 'medium' | 'low' | null
}

export interface ActionDomainTypeButton extends BaseDomainTypeButton {
  readonly Type: 'ActionButton'
  readonly Action: string
  readonly Icon: string
}

export interface CreateDomainTypeButton extends BaseDomainTypeButton {
  readonly Type: 'CreateButton'
}

export interface EditDomainTypeButton extends BaseDomainTypeButton {
  readonly Type: 'EditButton'
}

export interface DeleteDomainTypeButton extends BaseDomainTypeButton {
  readonly Type: 'DeleteButton'
}

export type DomainTypeButton =
  | ActionDomainTypeButton
  | CreateDomainTypeButton
  | EditDomainTypeButton
  | DeleteDomainTypeButton

export interface JobDetails {
  readonly Id: string
  readonly Message?: string
}

export interface BaseJobStatus {
  readonly Id: string
  readonly JobName: string
  readonly LastModified: string
}

export interface ReadyJobStatus extends BaseJobStatus {
  readonly Status: 'Ready'
}

export interface ReservedJobStatus extends BaseJobStatus {
  readonly Status: 'Reserved'
}

export interface CompletedJobStatus extends BaseJobStatus {
  readonly Status: 'Completed'
  readonly Data: unknown
}

export interface DownloadFileData {
  readonly fileName: string
  readonly downloadAs: string
  readonly absoluteFilePath: string
}

export interface ErroredJobStatus extends BaseJobStatus {
  readonly Status: 'Errored'
  readonly ErrorMessage: string
}

export type JobStatus =
  | ReadyJobStatus
  | ReservedJobStatus
  | ErroredJobStatus
  | CompletedJobStatus

export type TypesAreEqual<T, U> = T extends U ? (U extends T ? U : never) : never

export interface Preferences {
  readonly theme?: 'light' | 'dark',
  readonly sideMenuExpanded?: boolean
}

export type PathError =
  | string
  | { [key: string]: PathError | undefined }
  | PathError[]

export type PathErrors = Partial<Record<string, PathError>>

export interface ServerError {
  readonly Message: string
}

export interface ApiError {
  readonly errorCode: string
  readonly status?: number
  readonly pathErrors?: PathErrors
}

export interface ContextAttributeNode<T = unknown> {
  readonly attribute: ListAttribute<DomainTypeAttribute>
  readonly nodes: ContextDomainTypeNode<T>[]
  readonly type: 'context' | 'nested'
}

export type ContextDomainTypeNode<T = unknown> = {
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly type: 'context' | 'active' | 'nested' | 'active-placeholder' | 'nested-placeholder'
  readonly nodes: ContextAttributeNode<T>[]
} & T

export type ContextTree<T = unknown> = ContextDomainTypeNode<T>[]

export type ValueTypes = {
  string: string,
  bool: boolean,
  number: number,
  guid: string,
  domainType: Record<string, unknown>,
  date: string,
  dateTime: string,
  enum: string | number,
  ref: string,
  contextRef: string,
  dataformResults: Results,
  multiDataformResults: Partial<Record<string, Results | null>>
}

export type AttributeType = Attribute['AttributeType']

export type Value<A extends Attribute = Attribute> = ValueTypes[A['AttributeType']]
export type ChainValue<A extends Attribute = Attribute> = (Value<A> | null)[] | Value<A> | null
export interface AttributeChainValue<A extends Attribute = Attribute> {
  readonly attribute: A
  readonly value: ChainValue<A>
}

export type NonListAttributeValue<A extends Attribute = Attribute> = A['List'] extends true ? never : {
  readonly attribute: A
  readonly value: Value<A> | null
  readonly hidden?: boolean
}
export type ListAttributeValue<A extends Attribute = Attribute> = A['List'] extends false | null | undefined ? never : {
  readonly attribute: A
  readonly value: Value<A>[] | null
  readonly hidden?: boolean
}
export type AttributeValue<A extends Attribute = Attribute> = NonListAttributeValue<A> | ListAttributeValue<A>
export type NonListAttributeValues<A extends Attribute = Attribute> = A['List'] extends true ? never : {
  readonly attribute: A
  readonly values: NonListAttributeValue<A>['value'][]
}
export type ListAttributeValues<A extends Attribute = Attribute> = A['List'] extends false | null | undefined ? never : {
  readonly attribute: A
  readonly values: ListAttributeValue<A>['value'][]
}
export type AttributeValues<A extends Attribute = Attribute> = NonListAttributeValues<A> | ListAttributeValues<A>
export type DateAttributeValue = AttributeValue<DateAttribute>
export type DateTimeAttributeValue = AttributeValue<DateTimeAttribute>
export type NumberAttributeValue = AttributeValue<NumberAttribute>
export type EnumAttributeValue = AttributeValue<EnumAttribute>
export type GuidAttributeValue = AttributeValue<GuidAttribute>
export type DomainTypeAttributeValue = AttributeValue<DomainTypeAttribute>
export type RefAttributeValue = AttributeValue<RefAttribute>
export type ContextRefAttributeValue = AttributeValue<ContextRefAttribute>
export type BoolAttributeValue = AttributeValue<BoolAttribute>
export type StringAttributeValue = AttributeValue<StringAttribute>
export type DataformResultsAttributeValue = AttributeValue<DataformResultsAttribute>
export type MultiDataformResultsAttributeValue = AttributeValue<MultiDataformResultsAttribute>

export type NonListDateAttributeValue = NonListAttributeValue<DateAttribute>
export type NonListDateTimeAttributeValue = NonListAttributeValue<DateTimeAttribute>
export type NonListNumberAttributeValue = NonListAttributeValue<NumberAttribute>
export type NonListEnumAttributeValue = NonListAttributeValue<EnumAttribute>
export type NonListGuidAttributeValue = NonListAttributeValue<GuidAttribute>
export type NonListDomainTypeAttributeValue = NonListAttributeValue<DomainTypeAttribute>
export type NonListRefAttributeValue = NonListAttributeValue<RefAttribute>
export type NonListContextRefAttributeValue = NonListAttributeValue<ContextRefAttribute>
export type NonListBoolAttributeValue = NonListAttributeValue<BoolAttribute>
export type NonListStringAttributeValue = NonListAttributeValue<StringAttribute>
export type NonListDataformResultsAttributeValue = NonListAttributeValue<DataformResultsAttribute>
export type NonListMultiDataformResultsAttributeValue = NonListAttributeValue<MultiDataformResultsAttribute>

export type ListDateAttributeValue = ListAttributeValue<DateAttribute>
export type ListDateTimeAttributeValue = ListAttributeValue<DateTimeAttribute>
export type ListNumberAttributeValue = ListAttributeValue<NumberAttribute>
export type ListEnumAttributeValue = ListAttributeValue<EnumAttribute>
export type ListGuidAttributeValue = ListAttributeValue<GuidAttribute>
export type ListDomainTypeAttributeValue = ListAttributeValue<DomainTypeAttribute>
export type ListRefAttributeValue = ListAttributeValue<RefAttribute>
export type ListContextRefAttributeValue = ListAttributeValue<ContextRefAttribute>
export type ListBoolAttributeValue = ListAttributeValue<BoolAttribute>
export type ListStringAttributeValue = ListAttributeValue<StringAttribute>
export type ListDataformResultsAttributeValue = ListAttributeValue<DataformResultsAttribute>
export type ListMultiDataformResultsAttributeValue = ListAttributeValue<MultiDataformResultsAttribute>
