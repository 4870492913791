import { Box, Grid, Stack, Typography } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import ButtonsPopover from 'components/domainType/ButtonsPopover'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeChainValue, DomainType, DomainTypeInstance } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { getAttributeChain, getChainValue } from 'utils/helpers'
import DomainTypeIcon from '../DomainTypeIcon'
import useDomainTypeSummary, { COLUMN_WIDTH, OverridableStringListSetting } from './useDomainTypeSummary'

interface Props {
  domainType: DomainType
  instance: DomainTypeInstance
  columnsSetting: OverridableStringListSetting
  hideButtons?: boolean
  hideHeading?: boolean
  showSettingPopper?: boolean
}

export default function DomainTypeGridSummary({
  domainType,
  instance,
  columnsSetting,
  hideButtons = false,
  hideHeading = false,
  showSettingPopper = false
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const {
    subtype,
    heading,
    columns,
    emptyItemsToFixLayout,
    settingPopperPlaceholderGridItem,
    settingPopperButton
  } = useDomainTypeSummary(
    domainType,
    instance,
    columnsSetting
  )
  return (
    <Stack
      gap={1}
      overflow='hidden'>
      {!hideHeading && (
        <Stack
          display='flex'
          flexDirection='row'
          alignItems='center'
          gap={1}
          p={1}
          pb={0}>
          <DomainTypeIcon
            avatar
            avatarScale={1.2}
            domainType={subtype} />
          <Typography variant='subtitle1'>
            {heading}
          </Typography>
          {!hideButtons && (
            <>
              <Box flexGrow={1} />
              <span
                onClick={event => event.stopPropagation()}
                onMouseDown={event => event.stopPropagation()}>
                <ButtonsPopover
                  key='popover'
                  domainType={subtype}
                  instances={[instance]}
                  on='TableRow' />
              </span>
            </>
          )}
        </Stack>
      )}
      <Grid
        container
        alignItems='stretch'
        marginBottom='-1px'>
        {columns.flatMap(column => {
          const attributeChain = getAttributeChain(domainTypes, subtype, column)
          if (attributeChain === undefined) {
            return []
          }
          const attribute = attributeChain[attributeChain.length - 1]
          if (attribute === undefined) {
            return []
          }
          const value = getChainValue(instance, attributeChain)
          const attributeChainValue: AttributeChainValue = {
            attribute,
            value
          }
          return [
            <Grid
              key={column}
              container
              item
              columns={5}
              alignItems='center'
              flex={`1 1 ${COLUMN_WIDTH}px`}
              borderBottom={theme => `1px solid ${theme.palette.divider}`}>
              <Grid
                item
                p={0.5}
                pl={1}
                pr={1}
                xs={2}>
                <Typography
                  variant='caption'
                  color={theme => theme.palette.text.secondary}>
                  {attributeChain.map(attribute => attribute.Title).join(PATH_SEPARATOR)}
                </Typography>
              </Grid>
              <Grid
                item
                p={0.5}
                pl={1}
                pr={1}
                xs={3}>
                <Typography
                  variant='body1'
                  fontSize='0.875rem'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  display='flex'>
                  <AttributeCell
                    key={column}
                    attributeChainValue={attributeChainValue} />
                </Typography>
              </Grid>
            </Grid>
          ]
        }).concat(showSettingPopper
          ? [settingPopperPlaceholderGridItem]
          : []
        )}
        {emptyItemsToFixLayout}
      </Grid>
      {showSettingPopper && settingPopperButton}
    </Stack>
  )
}