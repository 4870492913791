import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material'
import { InlineRadioElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'
import { getRadioChoices, getRadioColor } from './Radio'

export default function InlineRadio({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  expectedValue,
  error,
  errorText
}: ElementProps<InlineRadioElement>): JSX.Element | null {
  const choices = getRadioChoices(element)
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error}>
      <FormLabel>{element.Text}</FormLabel>
      <RadioGroup
        row
        value={value}
        onChange={(event, value) => onChange(value)}>
        {choices.map(choice => (
          <FormControlLabel
            key={choice.Value}
            value={choice.Value}
            control={(
              <Radio
                size='small'
                color={getRadioColor(choice.Value, expectedValue)}
                disabled={locked || readOnly ? value !== choice.Value : false} />
            )}
            label={choice.Text} />
        ))}
      </RadioGroup>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}