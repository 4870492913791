import { LoadingButton } from '@mui/lab'
import { Alert, AlertTitle, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DataForm from 'components/attribute/AttributeForm'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ActionParameter, ActionPaths, ActionResponse, ApiError, Attribute, AttributeValue, ContextDomainTypeNode, ContextTree, DomainType, DomainTypeAction, DomainTypeInstance, EffectResult, EffectResults, FileActionParameter, PathError, PathErrors } from 'types'
import { EffectResultCodec, PathErrorsCodec } from 'utils/codecs'
import { FormModeContext } from 'utils/context'
import { getAttributeValue, getBatchInstances, getDomainTypeAttribute, getDomainTypeSetting, getNodes, getRootDomainType, isNullOrUndefined, requiresNoInstances, toErrorText, validateRequiredAttributes } from 'utils/helpers'
import { ActionButton, useApi, usePathErrors } from 'utils/hooks'
import ActionEffectResultsView, { ActionEffectResults } from './ActionEffectResultsView'
import ContextTreeView, { getNodeIds, NODE_ID_SEPARATOR } from './ContextTreeView'
import DomainTypeHeading from './DomainTypeHeading'
import UploadFileInput, { UploadFileInputHandle } from '../utils/UploadFileInput'

function createRequestTree(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  contextTree: ContextDomainTypeNode[] | null,
  selectedNodeIds: string[],
  nodeIdPath: string[] = []
): unknown[] {
  if (contextTree === null) {
    return []
  }
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  return contextTree
    .filter((node, index) => selectedNodeIds
      .includes([...nodeIdPath, String(index)].join(NODE_ID_SEPARATOR)))
    .map((node, index) => createRequestNode(
      domainTypes,
      identifier,
      node,
      selectedNodeIds,
      [...nodeIdPath, String(index)]
    ))
}

function createRequestNode(
  domainTypes: Partial<Record<string, DomainType>>,
  identifier: string,
  node: ContextDomainTypeNode,
  selectedNodeIds: string[],
  nodeIdPath: string[]
): unknown {
  return {
    [identifier]: node.instance[identifier],
    ...node.nodes.reduce<Record<string, unknown>>((prev, curr) => {
      const attributeDomainType = domainTypes[curr.attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return prev
      }
      prev[curr.attribute.Name] = createRequestTree(
        domainTypes,
        attributeDomainType,
        curr.nodes,
        selectedNodeIds,
        [...nodeIdPath, curr.attribute.Name]
      )
      return prev
    }, {})
  }
}

function getPathEffectResults(
  response: ActionPaths | undefined,
  contextTree: ContextDomainTypeNode[]
): ContextTree<EffectResults> {
  return contextTree.map<ContextDomainTypeNode<EffectResults>>((node, index) => {
    const domainTypeResponse = response?.[index]
    return {
      ...node,
      nodes: node.nodes.map(attributeNode => {
        const attributeResponse = domainTypeResponse?.[attributeNode.attribute.Name]
        return {
          ...attributeNode,
          nodes: getPathEffectResults(
            Array.isArray(attributeResponse) && !t.array(EffectResultCodec).is(attributeResponse)
              ? attributeResponse
              : undefined,
            attributeNode.nodes
          )
        }
      }),
      EffectResults: domainTypeResponse?.EffectResults
    }
  })
}

function hasError(effectResult: EffectResult): boolean {
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect':
      return effectResult.Result === 'Error'
        || effectResult.Items.some(createResult => createResult.Result === 'Error')
    default:
      return effectResult.Result === 'Error'
  }
}

function hasDisplayableSuccess(effectResult: EffectResult): boolean {
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect':
      return effectResult.Result === 'Success'
        && effectResult.Items.some(createResult => createResult.Result === 'Success')
    case 'DownloadFromFileStoreActionEffect':
    case 'QueueJobActionEffect':
    case 'DownloadInstanceActionEffect':
      return true
    default:
      return false
  }
}

function isFile(parameter: ActionParameter): parameter is FileActionParameter {
  return parameter.Type === 'FileActionParameter'
}

function hasNoEffects(action: DomainTypeAction): boolean {
  return !(action.Parameters ?? []).some(parameter => parameter.Type === 'AttributeActionParameter')
    && (action.Effects ?? []).length === 0
}

function validateRequiredParameters(
  attributeValues: AttributeValue[],
  fileParameters: FileActionParameter[],
  files: Partial<Record<string, [FileActionParameter['UploadType'], File]>>
): PathErrors | undefined {
  let pathErrors = validateRequiredAttributes(attributeValues)
  for (const parameter of fileParameters) {
    if (isNullOrUndefined(files[parameter.Name])) {
      pathErrors = {
        ...pathErrors,
        [parameter.Name]: `${parameter.Name} is required`
      }
    }
  }
  return pathErrors
}

interface Props {
  readonly open: boolean
  readonly actionButton: ActionButton
  onClose(): void
  onPerform(): void
}

export default function ActionDialog({
  open,
  actionButton,
  onClose,
  onPerform
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const anyParameters = (actionButton.action.Parameters?.length ?? 0) > 0
  const [actionEffectResults, setActionEffectResults] = useState<ActionEffectResults | null>(null)
  const [isPerforming, setIsPerforming] = useState(false)
  const {
    pathErrors = {},
    setPathErrors,
    removeErrorAtPath
  } = usePathErrors({})
  const domainType = actionButton.apiDomainType
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const api = useApi()
  const isAdmin = useMemo(() => {
    return requiresNoInstances(actionButton.action)
  }, [actionButton.action])

  const isNestedBatchAction = useMemo(() => {
    return getNodes(actionButton.contextTree).some(node => node.type === 'nested')
  }, [actionButton.contextTree])

  const staticParams = useMemo(() => actionButton.action.Parameters?.reduce<DomainTypeInstance>((prev, current) => {
    if (current.Type === 'StaticActionParameter') {
      prev[current.Name] = current.Value
    }
    return prev
  }, {}) ?? {}, [actionButton.action.Parameters])

  const defaultAttributeValues = useMemo(() => {
    return (actionButton.action.Parameters ?? [])
      .filter(parameter => !actionButton.parameterValues
        ?.find(({ attribute }) => attribute.Name === parameter.Name))
      .flatMap(parameter => {
        if (parameter.Type === 'FileActionParameter') {
          return []
        }

        let attribute

        if (parameter.Type === 'AttributeActionParameter') {
          attribute = getDomainTypeAttribute(domainTypes, actionButton.domainType, parameter.Attribute)
        }
        if (parameter.Type === 'InputAttributeActionParameter') {
          attribute = parameter.Attribute
        }

        if (attribute === undefined) {
          return []
        }

        const modifiedAttribute: Attribute = {
          ...attribute,
          Name: parameter.Name,
          Required: parameter.Required
        }
        return [getAttributeValue({}, modifiedAttribute)]
      })
  }, [actionButton.action.Parameters, actionButton.domainType, actionButton.parameterValues, domainTypes])

  const [attributeValues, setAttributeValues] = useState(defaultAttributeValues)
  useEffect(() => {
    setAttributeValues(defaultAttributeValues)
  }, [defaultAttributeValues])

  const fileParameters = useMemo(() => {
    return actionButton.action.Parameters
      ?.filter(isFile) ?? []
  }, [actionButton.action.Parameters])
  const fileInputsRef = useRef<Partial<Record<string, UploadFileInputHandle | null>>>({})
  const [files, setFiles] = useState<Partial<Record<string, [FileActionParameter['UploadType'], File]>>>({})

  const onSuccess = useCallback(() => {
    onPerform()
    setActionEffectResults(null)
  }, [onPerform])

  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([])
  useEffect(() => {
    setSelectedNodeIds(
      actionButton
        .contextTree.flatMap((node, index) => getNodeIds(node, [String(index)]))
    )
  }, [actionButton.contextTree])

  const performAction = useCallback(async () => {
    const requiredPathErrors = validateRequiredParameters(
      attributeValues,
      fileParameters,
      files
    )
    if (requiredPathErrors !== undefined) {
      setPathErrors(requiredPathErrors)
      return
    }
    setIsPerforming(true)
    if (rootDomainType === null
      || !api.isSignedIn) {
      setIsPerforming(false)
      return
    }
    const parameters = attributeValues.concat(actionButton.parameterValues ?? []).reduce<DomainTypeInstance>((prev, curr) => {
      prev[curr.attribute.Name] = curr.value
      return prev
    }, staticParams)
    const getFilesByUploadType = (uploadType: FileActionParameter['UploadType']) => {
      return Object.keys(files).filter(key => files[key]?.[0] === uploadType).reduce<File[]>((prev, curr) => {
        const file: File | undefined = files[curr]?.[1]
        if (isNullOrUndefined(file)) {
          return []
        }
        parameters[curr] = {
          Name: file.name,
          Type: file.type,
          Size: file.size
        }
        return [...prev, file]
      }, [])
    }
    const cacheFilesToUpload = getFilesByUploadType('cache')
    if (cacheFilesToUpload.length > 0) {
      await api.uploadToCache(cacheFilesToUpload)
    }
    const customFilesToUpload = getFilesByUploadType('custom')
    if (customFilesToUpload.length > 0) {
      await api.upload(rootDomainType.Name, customFilesToUpload)
    }
    if (hasNoEffects(actionButton.action)) {
      onSuccess()
      setIsPerforming(false)
      return
    }
    const response = await api.action(
      rootDomainType.Name,
      parameters,
      actionButton.domainType.Id,
      actionButton.action.Name,
      createRequestTree(domainTypes, domainType, actionButton.contextTree, selectedNodeIds)
    )
    E.match<ApiError, ActionResponse, void>(
      apiError => {
        const responsePathErrors = apiError.pathErrors
        if (responsePathErrors === undefined) {
          setPathErrors({})
        } else if (PathErrorsCodec.is(responsePathErrors.Parameters)) {
          setPathErrors(responsePathErrors.Parameters)
        }
      },
      actionResponse => {
        const pathEffectResults = getPathEffectResults(
          actionResponse.Paths,
          actionButton.contextTree
        )
        const requestEffectResults = actionResponse.EffectResults
        const actionInstanceNodes = getNodes(pathEffectResults)
        const hasEffectErrors = actionInstanceNodes
          .flatMap(node => node.EffectResults ?? [])
          .concat(requestEffectResults)
          .some(hasError)
        const hasDisplayableEffectSucces = actionInstanceNodes
          .flatMap(node => node.EffectResults ?? [])
          .concat(requestEffectResults)
          .some(hasDisplayableSuccess)
        if (hasEffectErrors || hasDisplayableEffectSucces) {
          setActionEffectResults({
            PathEffectResults: pathEffectResults,
            RequestEffectResults: requestEffectResults
          })
        } else {
          onSuccess()
        }
      }
    )(response)
    setIsPerforming(false)
  }, [attributeValues, fileParameters, files, rootDomainType, api, actionButton.parameterValues, actionButton.action, actionButton.domainType.Id, actionButton.contextTree, staticParams, domainTypes, domainType, selectedNodeIds, setPathErrors, onSuccess])

  const shouldPerformAutomatically = useMemo(() => {
    return !anyParameters
      && open
      && !isPerforming
      && isNullOrUndefined(actionEffectResults)
      && !isNestedBatchAction
  }, [actionEffectResults, anyParameters, isNestedBatchAction, isPerforming, open])
  useEffect(() => {
    if (shouldPerformAutomatically) {
      performAction()
    }
  }, [performAction, shouldPerformAutomatically])
  const [severity, message] = useMemo(() => {
    const nodes = getNodes(actionEffectResults?.PathEffectResults ?? [])
    const hasEffectErrors = nodes
      .flatMap(node => node.EffectResults ?? [])
      .concat(actionEffectResults?.RequestEffectResults ?? [])
      .some(hasError)
    return hasEffectErrors === true
      ? ['warning', 'There was an error triggering one or more action effects'] as const
      : ['success', 'All action effects triggered successfully'] as const
  }, [actionEffectResults])
  const newBatchInstances = useMemo(() => {
    return getBatchInstances(actionButton.contextTree)
  }, [actionButton.contextTree])
  const onDialogClose = useMemo(() => {
    return isNullOrUndefined(actionEffectResults)
      ? isPerforming
        ? undefined
        : onClose
      : onSuccess
  }, [actionEffectResults, isPerforming, onClose, onSuccess])
  const disabled = useMemo(() => {
    return isPerforming || (selectedNodeIds.length === 0 && !isAdmin)
  }, [isAdmin, isPerforming, selectedNodeIds.length])
  const activeInstances = useMemo(() => {
    return getNodes(actionButton.contextTree)
      .filter(node => node.domainType.Id === actionButton.pageDomainType.Id)
      .map(node => node.instance)
  }, [actionButton.contextTree, actionButton.pageDomainType.Id])
  const singleInstance = useMemo(() => {
    return activeInstances.length === 1
      ? activeInstances[0]
      : undefined
  }, [activeInstances])
  const parameterPathErrors = useMemo(() => {
    return (actionButton.parameterValues ?? [])
      .reduce((parameterPathErrors: Partial<Record<string, PathError>>, parameterValue: AttributeValue) => {
        if (!(parameterValue.attribute.Name in pathErrors)) {
          return parameterPathErrors
        }
        parameterPathErrors[parameterValue.attribute.Name] = pathErrors[parameterValue.attribute.Name]
        return parameterPathErrors
      }, {})
  }, [actionButton.parameterValues, pathErrors])
  return (
    <AppendDomainTypeContext
      newBatchInstances={newBatchInstances}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onKeyDown={event => event.stopPropagation()}
        onClose={onDialogClose}>
        {isNullOrUndefined(actionEffectResults)
          ? (
            <>
              <DialogTitle>
                <DomainTypeHeading
                  domainType={actionButton.pageDomainType}
                  instance={singleInstance}
                  isLoading={false}
                  title={`${actionButton.name}:`}
                  plural={activeInstances.length > 1}
                  count={activeInstances.length} />
              </DialogTitle>
              <DialogContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                {isNestedBatchAction && (
                  <Alert
                    severity='info'>
                    <AlertTitle>
                      Select the items on which to perform the action
                    </AlertTitle>
                    <ContextTreeView
                      contextTree={actionButton.contextTree}
                      includeRootNode
                      defaultUnexpanded
                      selectable
                      selectedNodeIds={selectedNodeIds}
                      onSelectionChange={setSelectedNodeIds} />
                  </Alert>
                )}
                {Object.values(parameterPathErrors).map((pathError, index) => (
                  <Alert
                    key={index}
                    severity='error'>
                    {toErrorText(pathError)}
                  </Alert>
                ))}
                {fileParameters.length > 0 && (
                  <Box
                    component='form'
                    display='flex'
                    flexDirection='column'
                    gap={1}
                    autoComplete='off'>
                    {actionButton.action.Parameters
                      ?.filter(isFile)
                      .map(parameter => (
                        <>
                          <UploadFileInput
                            ref={handle => fileInputsRef.current[parameter.Name] = handle}
                            accept={parameter.Accept ?? undefined} />
                          <TextField
                            label={parameter.Name}
                            variant='standard'
                            fullWidth
                            size='small'
                            spellCheck='false'
                            value={files[parameter.Name]?.[1]?.name ?? ''}
                            required={parameter.Required}
                            error={pathErrors[parameter.Name] !== undefined}
                            helperText={toErrorText(pathErrors[parameter.Name])}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position='end'>
                                  <Button
                                    variant='text'
                                    size='small'
                                    onClick={async () => {
                                      const input = fileInputsRef.current[parameter.Name]
                                      if (isNullOrUndefined(input)) {
                                        return
                                      }
                                      const inputFiles = await input.open()
                                      const firstFile = inputFiles?.[0]
                                      removeErrorAtPath(parameter.Name)
                                      setFiles({
                                        ...files,
                                        [parameter.Name]: isNullOrUndefined(firstFile)
                                          ? undefined
                                          : [parameter.UploadType, firstFile]
                                      })
                                    }}>
                                    Choose File
                                  </Button>
                                </InputAdornment>
                              )
                            }} />
                        </>
                      ))}
                  </Box>
                )}
                {attributeValues.length > 0 && (
                  <FormModeContext.Provider value='create'>
                    <DataForm
                      attributeValues={attributeValues}
                      pathErrors={pathErrors}
                      onChange={attributeValue => {
                        removeErrorAtPath(attributeValue.attribute.Name)
                        const index = attributeValues
                          .findIndex(a => a.attribute.Name === attributeValue.attribute.Name)
                        const newAttributeValues = [
                          ...attributeValues.slice(0, index),
                          attributeValue,
                          ...attributeValues.slice(index + 1)
                        ]
                        setAttributeValues(newAttributeValues)
                      }} />
                  </FormModeContext.Provider>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  variant='text'
                  disabled={isPerforming}
                  onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={isPerforming || shouldPerformAutomatically}
                  disabled={disabled}
                  onClick={performAction}>
                  {actionButton.action.Name}
                </LoadingButton>
              </DialogActions>
            </>
          )
          : (
            <>
              <DialogTitle>
                <DomainTypeHeading
                  domainType={actionButton.pageDomainType}
                  instance={singleInstance}
                  isLoading={false}
                  title={`${actionButton.name}:`}
                  plural={activeInstances.length > 1}
                  count={activeInstances.length} />
              </DialogTitle>
              <DialogContent>
                <Alert severity={severity}>
                  <AlertTitle>
                    {message}
                  </AlertTitle>
                  {!isNullOrUndefined(actionEffectResults) && (
                    <ActionEffectResultsView
                      effects={actionButton.action.Effects ?? []}
                      actionEffectResults={actionEffectResults} />
                  )}
                </Alert>
              </DialogContent>
              <DialogActions>
                <Button
                  variant='text'
                  onClick={onSuccess}>
                  Close
                </Button>
              </DialogActions>
            </>
          )}
      </Dialog>
    </AppendDomainTypeContext>
  )
}