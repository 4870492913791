import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DataForm from 'components/attribute/AttributeForm'
import AttributeListSettingPopper from 'components/domainType/AttributeListSettingPopper'
import DialogAlert from 'components/utils/DialogAlert'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import { useContext, useEffect, useMemo } from 'react'
import { Attribute, DomainType, DomainTypeInstance, EnumAttribute, StringAttribute } from 'types'
import { FormModeContext } from 'utils/context'
import { useCreate } from 'utils/hooks'

interface Props {
  readonly open: boolean
  readonly domainType: DomainType
  readonly formMode?: 'create' | 'edit'
  readonly bypassApi?: boolean
  onClose(): void
  onCreateSuccess(instance: DomainTypeInstance): void
}

export default function PersonCreateDialog({
  open,
  domainType,
  formMode,
  bypassApi = false,
  onClose,
  onCreateSuccess
}: Props): JSX.Element {
  const formModeContext = useContext(FormModeContext)
  formMode = formMode ?? formModeContext ?? 'create'

  const customDomainType = useMemo(() => {
    const passwordAttribute: StringAttribute = {
      AttributeType: 'string',
      Name: 'Password',
      Title: 'Password',
      Format: 'password',
      Required: true
    }

    const loginTypeAttribute: EnumAttribute = {
      AttributeType: 'enum',
      Name: 'LoginType',
      Title: 'Login Type',
      Required: true,
      EnumeratedType: {
        GlobalExternalId: 'LoginTypes',
        ExternalId: 'LoginTypes',
        Values: [{
          Id: 'AD',
          Value: 'AD',
          Description: 'Active Directory'
        },
        {
          Id: 'Local',
          Value: 'Local',
          Description: 'Local'
        }]
      }
    }

    const newAttributes: Attribute[] = [loginTypeAttribute, passwordAttribute]

    return {
      ...domainType,
      Attributes: newAttributes.concat(domainType.Attributes)
    }
  }, [domainType])

  const {
    isApiDomainType,
    isCreating,
    allAttributeValues,
    attributeValues,
    subtype,
    pathErrors,
    errorCode,
    createInstance,
    onChange,
    onCreate,
    onReset
  } = useCreate(customDomainType, onCreateSuccess, bypassApi)
  useEffect(() => {
    if (open) {
      onReset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const verb = isApiDomainType
    ? 'Create'
    : 'Add'
  const instance = createInstance(attributeValues)
  return (
    <FormModeContext.Provider value={formMode}>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={isCreating ? undefined : onClose}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={domainType}
            instance={instance}
            isLoading={false}
            title={`${verb}:`} />
        </DialogTitle>
        <DialogAlert
          isLoading={isCreating}
          errorCode={errorCode} />
        <DialogContent>
          <AppendDomainTypeContext newInstances={[[customDomainType, instance]]}>
            <DataForm
              attributeValues={attributeValues}
              pathErrors={pathErrors}
              onChange={onChange} />
          </AppendDomainTypeContext>
          <AttributeListSettingPopper
            domainType={subtype}
            allAttributeValues={allAttributeValues}
            setting='CreateForm'
            alwaysShowRequired />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isCreating}
            variant='text'
            onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={isCreating}
            loading={isCreating}
            onClick={onCreate}>
            {verb}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormModeContext.Provider>
  )
}