import { Dataform, DefaultValueContext, Dependency, Element, ElementValueTypes, Results } from 'types/dataform'
import { ActiveGroupChangedAction, ACTIVE_GROUP_CHANGED, BackButtonClicked, BACK_BUTTON_CLICKED, COMPLETED, CompletedAction, DataformEditedAction, DATAFORM_EDITED, ElementAsExpectedAction, ElementConstraintErrorAddedAction, ElementConstraintErrorRemovedAction, ElementDisabledByDependencyAction, ElementEnabledByDependencyAction, ElementLockedByMilestoneAction, ElementNotAsExpectedAction, ElementRequiredErrorAddedAction, ElementRequiredErrorRemovedAction, ElementUnlockedByMilestoneAction, ElementValueChangedAction, ELEMENT_AS_EXPECTED, ELEMENT_CONSTRAINT_ERROR_ADDED, ELEMENT_CONSTRAINT_ERROR_REMOVED, ELEMENT_DISABLED_BY_DEPENDENCY, ELEMENT_ENABLED_BY_DEPENDENCY, ELEMENT_LOCKED_BY_MILESTONE, ELEMENT_NOT_AS_EXPECTED, ELEMENT_REQUIRED_ERROR_ADDED, ELEMENT_REQUIRED_ERROR_REMOVED, ELEMENT_UNLOCKED_BY_MILESTONE, ELEMENT_VALUE_CHANGED, GroupStepperClicked, GROUP_STEPPER_CLICKED, INITIALISED, InitialisedAction, MilestoneLockedByElementAction, MilestoneUnlockedByElement, MILESTONE_LOCKED_BY_ELEMENT, MILESTONE_UNLOCKED_BY_ELEMENT, NextButtonClicked, NEXT_BUTTON_CLICKED, UNLOCKED, UnlockedAction } from './types'

export function initialised(
  dataform: Dataform,
  results: Results | null,
  defaultValueContext: DefaultValueContext
): InitialisedAction {
  return {
    type: INITIALISED,
    payload: {
      dataform,
      results,
      defaultValueContext
    }
  }
}

export function dataformEdited(
  dataform: Dataform,
  defaultValueContext: DefaultValueContext
): DataformEditedAction {
  return {
    type: DATAFORM_EDITED,
    payload: {
      dataform,
      defaultValueContext
    }
  }
}

export function elementValueChanged<E extends Element>(
  element: E,
  value: ElementValueTypes[E['ElementType']]
): ElementValueChangedAction<E> {
  return {
    type: ELEMENT_VALUE_CHANGED,
    payload: {
      element,
      value
    }
  }
}

export function elementEnabledByDependency(
  element: Element,
  dependency: Dependency
): ElementEnabledByDependencyAction {
  return {
    type: ELEMENT_ENABLED_BY_DEPENDENCY,
    payload: {
      element,
      dependency
    }
  }
}

export function elementDisabledByDependency(
  element: Element,
  dependency: Dependency
): ElementDisabledByDependencyAction {
  return {
    type: ELEMENT_DISABLED_BY_DEPENDENCY,
    payload: {
      element,
      dependency
    }
  }
}

export function elementLockedByMilestone(
  element: Element,
  milestone: Element
): ElementLockedByMilestoneAction {
  return {
    type: ELEMENT_LOCKED_BY_MILESTONE,
    payload: {
      element,
      milestone
    }
  }
}

export function elementUnlockedByMilestone(
  element: Element,
  milestone: Element
): ElementUnlockedByMilestoneAction {
  return {
    type: ELEMENT_UNLOCKED_BY_MILESTONE,
    payload: {
      element,
      milestone
    }
  }
}

export function milestoneLockedByElement(
  milestone: Element,
  element: Element
): MilestoneLockedByElementAction {
  return {
    type: MILESTONE_LOCKED_BY_ELEMENT,
    payload: {
      milestone,
      element
    }
  }
}

export function milestoneUnlockedByElement(
  milestone: Element,
  element: Element
): MilestoneUnlockedByElement {
  return {
    type: MILESTONE_UNLOCKED_BY_ELEMENT,
    payload: {
      milestone,
      element
    }
  }
}

export function elementAsExpected(element: Element): ElementAsExpectedAction {
  return {
    type: ELEMENT_AS_EXPECTED,
    payload: {
      element
    }
  }
}

export function elementNotAsExpected(element: Element): ElementNotAsExpectedAction {
  return {
    type: ELEMENT_NOT_AS_EXPECTED,
    payload: {
      element
    }
  }
}

export function elementConstraintErrorRemoved(element: Element): ElementConstraintErrorRemovedAction {
  return {
    type: ELEMENT_CONSTRAINT_ERROR_REMOVED,
    payload: {
      element
    }
  }
}

export function elementConstraintErrorAdded(element: Element): ElementConstraintErrorAddedAction {
  return {
    type: ELEMENT_CONSTRAINT_ERROR_ADDED,
    payload: {
      element
    }
  }
}

export function elementRequiredErrorRemoved(element: Element): ElementRequiredErrorRemovedAction {
  return {
    type: ELEMENT_REQUIRED_ERROR_REMOVED,
    payload: {
      element
    }
  }
}

export function elementRequiredErrorAdded(element: Element): ElementRequiredErrorAddedAction {
  return {
    type: ELEMENT_REQUIRED_ERROR_ADDED,
    payload: {
      element
    }
  }
}

export function groupStepperClicked(index: number): GroupStepperClicked {
  return {
    type: GROUP_STEPPER_CLICKED,
    payload: {
      index
    }
  }
}

export function backButtonClicked(): BackButtonClicked {
  return {
    type: BACK_BUTTON_CLICKED
  }
}

export function nextButtonClicked(): NextButtonClicked {
  return {
    type: NEXT_BUTTON_CLICKED
  }
}

export function activeGroupChanged(index: number): ActiveGroupChangedAction {
  return {
    type: ACTIVE_GROUP_CHANGED,
    payload: {
      index
    }
  }
}

export function completed(): CompletedAction {
  return {
    type: COMPLETED
  }
}

export function unlocked(): UnlockedAction {
  return {
    type: UNLOCKED
  }
}