import { TextField } from '@mui/material'
import { ChangeEvent, useCallback } from 'react'
import { NumberAttributeValue } from 'types'
import { isListAttributeValue, toErrorText } from 'utils/helpers'
import { NarrowedProps } from './AttributeInput'
import NotSupportedInput from './NotSupportedInput'

export default function NumberInput({
  attributeValue,
  readOnly,
  pathError,
  disabled,
  onChange
}: NarrowedProps<NumberAttributeValue>): JSX.Element | null {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: isNaN(event.target.valueAsNumber)
        ? null
        : event.target.valueAsNumber
    })
  }, [attributeValue.attribute, onChange])
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  return (
    <TextField
      value={attributeValue.value === null
        ? ''
        : String(attributeValue.value)}
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      type='number'
      variant='standard'
      fullWidth
      size='small'
      onChange={handleChange}
      required={attributeValue.attribute.Required ?? false}
      disabled={disabled}
      error={pathError !== undefined}
      helperText={toErrorText(pathError)}
      inputProps={{
        inputMode: 'numeric',
        pattern: '^[-0-9][0-9]+$',
        readOnly
      }} />
  )
}
