import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { Errors } from 'io-ts'
import { DateTime } from 'luxon'
import { ContextTree, DomainType, DomainTypeInstance, NonListDomainTypeAttributeValue } from 'types'
import { getCachedParentDomainTypes, getDomainTypeExternalId, switchCase } from 'utils/helpers'
import { DateFilterValueCodec, DateRangeFilterValueCodec, DateTimeFilterValueCodec, DateTimeRangeFilterValueCodec } from './codecs'
import { DateFilterValue, DateRangeFilterValue, DateTimeFilterValue, DateTimeRangeFilterValue, RelativeDateRange, RelativeDateTimeRange } from './types'

export function caseInsensitiveIncludes(value: string | null, filterValue: string | null): boolean {
  return value?.toLowerCase().includes(filterValue?.toLowerCase() ?? '') ?? false
}

export function parseDateFilterValue(value: unknown): DateTime | null {
  return pipe(
    value,
    DateFilterValueCodec.decode,
    E.map<DateFilterValue, DateTime>(
      switchCase<DateFilterValue, 'Type', DateFilterValue['Type'], DateTime>(
        'Type',
        {
          AbsoluteDateFilterValue: filterValue => {
            return DateTime.fromISO(filterValue.Value)
          },
          RelativeDateFilterValue: filterValue => {
            return DateTime.now()
          }
        }
      )
    ),
    E.chain(date => {
      return date.isValid
        ? E.right(date.startOf('day'))
        : E.left([])
    }),
    E.match(error => null, success => success)
  )
}

export function parseDateTimeFilterValue(value: unknown): DateTime | null {
  return pipe(
    value,
    DateTimeFilterValueCodec.decode,
    E.map<DateTimeFilterValue, DateTime>(
      switchCase<DateTimeFilterValue, 'Type', DateTimeFilterValue['Type'], DateTime>(
        'Type',
        {
          AbsoluteDateTimeFilterValue: filterValue => {
            return DateTime.fromISO(filterValue.Value)
          },
          RelativeDateTimeFilterValue: filterValue => {
            return DateTime.now()
          }
        }
      )
    ),
    E.chain(date => {
      return date.isValid
        ? E.right(date)
        : E.left([])
    }),
    E.match(error => null, success => success)
  )
}

const singularDateUnits = {
  minutes: 'minute',
  hours: 'hour',
  days: 'day',
  weeks: 'week',
  months: 'month',
  years: 'year'
} as const

export function parseDateRangeFilterValue(value: unknown): [DateTime, DateTime] | null {
  return pipe(
    value,
    DateRangeFilterValueCodec.decode,
    E.map<DateRangeFilterValue, [DateTime, DateTime]>(
      switchCase<DateRangeFilterValue, 'Type', DateRangeFilterValue['Type'], [DateTime, DateTime]>(
        'Type',
        {
          AbsoluteDateRangeFilterValue: filterValue => {
            const from = DateTime.fromISO(filterValue.From)
            const to = DateTime.fromISO(filterValue.To)
            return [from, to]
          },
          RelativeDateRangeFilterValue: filterValue => {
            return switchCase<RelativeDateRange, 'Type', RelativeDateRange['Type'], [DateTime, DateTime]>(
              'Type',
              {
                NextRelativeDateRange: range => {
                  const from = range.IncludeToday
                    ? DateTime.now()
                    : DateTime.now().plus({ days: 1 })
                  const to = from.plus({
                    [range.Measure]: range.Units
                  }).minus({ days: 1 })
                  return [from, to]
                },
                NextCalendarRelativeDateRange: range => {
                  const from = range.IncludeCurrent
                    ? DateTime.now().startOf(singularDateUnits[range.Measure])
                    : DateTime.now()
                      .plus({ [range.Measure]: 1 })
                      .startOf(singularDateUnits[range.Measure])
                  const to = from.plus({
                    [range.Measure]: range.Units
                  }).minus({ days: 1 })
                  return [from, to]
                },
                LastRelativeDateRange: range => {
                  const to = range.IncludeToday
                    ? DateTime.now()
                    : DateTime.now().minus({ days: 1 })
                  const from = to.minus({
                    [range.Measure]: range.Units
                  }).plus({ days: 1 })
                  return [from, to]
                },
                LastCalendarRelativeDateRange: range => {
                  const to = range.IncludeCurrent
                    ? DateTime.now().endOf(singularDateUnits[range.Measure])
                    : DateTime.now()
                      .minus({ [range.Measure]: 1 })
                      .endOf(singularDateUnits[range.Measure])
                  const from = to.minus({
                    [range.Measure]: range.Units
                  }).plus({ days: 1 })
                  return [from, to]
                },
                ThisRelativeDateRange: range => {
                  const from = DateTime.now().startOf(range.Measure)
                  const to = DateTime.now().endOf(range.Measure)
                  return [from, to]
                }
              }
            )(filterValue.Range)
          }
        }
      )),
    E.chain(dates => {
      const [from, to] = dates
      return from.isValid && to.isValid
        ? E.right<Errors, [DateTime, DateTime]>([from.startOf('day'), to.endOf('day')])
        : E.left([])
    }),
    E.match(error => null, success => success)
  )
}

export function parseDateTimeRangeFilterValue(value: unknown): [DateTime, DateTime] | null {
  return pipe(
    value,
    DateTimeRangeFilterValueCodec.decode,
    E.map<DateTimeRangeFilterValue, [DateTime, DateTime]>(
      switchCase<DateTimeRangeFilterValue, 'Type', DateTimeRangeFilterValue['Type'], [DateTime, DateTime]>(
        'Type',
        {
          AbsoluteDateTimeRangeFilterValue: filterValue => {
            const from = DateTime.fromISO(filterValue.From)
            const to = DateTime.fromISO(filterValue.To)
            return [from, to]
          },
          RelativeDateTimeRangeFilterValue: filterValue => {
            return switchCase<RelativeDateTimeRange, 'Type', RelativeDateTimeRange['Type'], [DateTime, DateTime]>(
              'Type',
              {
                NextRelativeDateTimeRange: range => {
                  const from = DateTime.now()
                  const to = from.plus({
                    [range.Measure]: range.Units
                  })
                  return [from, to]
                },
                NextCalendarRelativeDateTimeRange: range => {
                  const from = range.IncludeCurrent
                    ? DateTime.now().startOf(singularDateUnits[range.Measure])
                    : DateTime.now()
                      .plus({ [range.Measure]: 1 })
                      .startOf(singularDateUnits[range.Measure])
                  const to = from.plus({
                    [range.Measure]: range.Units
                  })
                  return [from, to]
                },
                LastRelativeDateTimeRange: range => {
                  const to = DateTime.now()
                  const from = to.minus({
                    [range.Measure]: range.Units
                  })
                  return [from, to]
                },
                LastCalendarRelativeDateTimeRange: range => {
                  const to = range.IncludeCurrent
                    ? DateTime.now().endOf(singularDateUnits[range.Measure])
                    : DateTime.now()
                      .minus({ [range.Measure]: 1 })
                      .endOf(singularDateUnits[range.Measure])
                  const from = to.minus({
                    [range.Measure]: range.Units
                  })
                  return [from, to]
                },
                ThisRelativeDateTimeRange: range => {
                  const from = DateTime.now().startOf(range.Measure)
                  const to = DateTime.now().endOf(range.Measure)
                  return [from, to]
                }
              }
            )(filterValue.Range)
          }
        }
      )),
    E.chain(dates => {
      const [from, to] = dates
      return from.isValid && to.isValid
        ? E.right(dates)
        : E.left([])
    }),
    E.match(error => null, success => success)
  )
}

function getContextDomainTypeInstanceAttributeValues(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>> = {}
): NonListDomainTypeAttributeValue[] {
  const parents = getCachedParentDomainTypes(
    domainTypes,
    domainType,
    parentDomainTypesCache
  )
  return parents.map(parent => ({
    attribute: {
      Name: getDomainTypeExternalId(parent),
      Title: parent.Title,
      Description: parent.Title,
      AttributeType: 'domainType',
      AttributeDomainType: parent.Id
    },
    value: instance
  }))
}

export function getContextAttributeValues(
  domainTypes: Partial<Record<string, DomainType>>,
  contextTree: ContextTree,
  batchInstances: [DomainType, DomainTypeInstance][] = [],
  parentDomainTypesCache: Partial<Record<string, DomainType[]>> = {}
): NonListDomainTypeAttributeValue[] {
  return contextTree.flatMap(node => (
    getContextDomainTypeInstanceAttributeValues(
      domainTypes,
      node.domainType,
      node.instance,
      parentDomainTypesCache
    ).concat(node.nodes.flatMap(attributeNode => getContextAttributeValues(
      domainTypes,
      attributeNode.nodes,
      [],
      parentDomainTypesCache
    )))
  )).concat(batchInstances.flatMap(([domainType, instance]) => {
    return getContextDomainTypeInstanceAttributeValues(
      domainTypes,
      domainType,
      instance,
      parentDomainTypesCache
    )
  }))
}