import { Box } from '@mui/material'
import { Dashboard } from 'components/dashboard'
import { DashboardSection as DashboardSectionType } from './useDetails'

interface Props {
  section: DashboardSectionType
}

export default function DashboardSection({
  section
}: Props): JSX.Element {
  return (
    <Box>
      <Dashboard
        dashboard={section.dashboard}
        variableValues={section.variableValues} />
    </Box>
  )
}