import { Alert, Box, InputBase, Link, MenuItem, Select } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import { ContextType, memo } from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, generatePath } from 'react-router-dom'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { PAGE_URL } from 'utils/constants'
import { getDomainTypeSetting, isInRole, isNullOrUndefined } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'
import TableViewContext from './TableView/TableViewContext'

type Keys =
  | 'page'
  | 'total'
  | 'pageSize'
  | 'rowCount'
  | 'isLoading'
  | 'setPage'
  | 'setPageSize'
type Props = Pick<ContextType<typeof TableViewContext>, Keys>

function SearchIndexLink(): JSX.Element {
  const searchIndexDomainType = useDomainType('SearchIndex', 'Metadata')
  const domainTypes = useSelector(getAllDomainTypes)
  const role = getDomainTypeSetting(domainTypes, searchIndexDomainType, 'ViewRole')
  const user = useSelector(getUser)
  if (!isNullOrUndefined(role) && !isInRole(user, role)) {
    return <>search index</>
  }
  return (
    <Link
      component={RouterLink}
      sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '8px',
        color: theme => theme.palette.muiPrimary.main
      }}
      to={{
        pathname: generatePath(PAGE_URL.FIND, {
          databaseTable: 'Metadata',
          name: 'SearchIndex'
        })
      }}
      onClick={event => event.stopPropagation()}>
      search index
    </Link>
  )
}

export default memo(function FindPagination({
  page = 1,
  total = 0,
  pageSize = 15,
  rowCount = 0,
  isLoading,
  setPage,
  setPageSize
}: Props): JSX.Element {
  const from = Math.min(total, Math.max(0, (page - 1) * pageSize + 1))
  const to = Math.min(from + pageSize - 1, total)
  const expectedRowCount = to - from + 1
  const hasFewerRowsThanExpected = to > 0 && rowCount < expectedRowCount
  return (
    <>
      {!isLoading && hasFewerRowsThanExpected && (
        <Alert
          sx={{
            p: 1,
            pt: 0,
            pb: 0,
            ml: 1
          }}
          severity='error'>
          This page has fewer rows than expected.
          There might be a problem with the <SearchIndexLink />.
        </Alert>
      )}
      <Box flexGrow={1} />
      <p>Items per page:</p>
      <Select
        input={<InputBase />}
        variant='standard'
        value={pageSize}
        style={{
          marginRight: '32px',
          marginLeft: '8px'
        }}
        onChange={event => {
          if (setPageSize !== undefined && typeof event.target.value === 'number') {
            setPageSize(event.target.value)
          }
        }}>
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
      <p style={{ marginRight: '20px' }}>{`${from}-${to} of ${total}`}</p>
      <Pagination
        color='primary'
        count={Math.ceil(total / pageSize)}
        page={page}
        onChange={(event, value) => {
          if (setPage !== undefined) {
            setPage(value)
          }
        }} />
    </>
  )
})