import { CheckBoxOutlineBlankOutlined, CheckBoxOutlined } from '@mui/icons-material'
import { Chip } from '@mui/material'
import { NonListBoolAttributeValue } from 'types'
import { NarrowedProps } from './AttributeCell'

export default function BoolCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListBoolAttributeValue>): JSX.Element | null {
  if (attributeValue.value === null) {
    return null
  }
  return (
    <Chip
      sx={{
        pointerEvents: 'none'
      }}
      icon={attributeValue.value ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
      label={attributeValue.value ? 'Yes' : 'No'}
      size='small' />
  )
}
