import { Lock } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Paper, Stack } from '@mui/material'

export default function Unauthorised(): JSX.Element {
  return (
    <Stack
      direction='column'
      spacing={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        height: '100%',
        background: theme => `repeating-linear-gradient(
          45deg,
          ${theme.palette.action.hover},
          ${theme.palette.action.hover} 50px,
          ${theme.palette.action.disabledBackground} 50px,
          ${theme.palette.action.disabledBackground} 100px
        )`
      }}>
      <Box flexGrow={1} />
      <Paper>
        <Alert
          severity='error'
          variant='filled'
          icon={<Lock />}>
          <AlertTitle>Unauthorised</AlertTitle>
          You have insufficient permissions to view this page. Contact your administrator to request access.
        </Alert>
      </Paper>
      <Box flexGrow={2} />
    </Stack>
  )
}