import { Autocomplete, Box, Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import { FormEvent, useCallback, useState } from 'react'
import { ButtonLocation, DomainType, DomainTypeAttribute, DomainTypeInstance, ListAttribute } from 'types'
import { OPERATIONS_DIALOG_KEY, PATH_SEPARATOR } from 'utils/constants'
import { Button, useButtons, useDomainTypeListAttributeButtons, useWindowEventListener } from 'utils/hooks'
import useMountEffect from 'utils/hooks/useMountEffect'
import DomainTypeHeading from './DomainTypeHeading'
import DomainTypeButton from './DomainTypeButtons/DomainTypeButton'
import { ButtonProps } from './DomainTypeButtons/DomainTypeButtons'

interface Props {
  readonly domainType: DomainType
  readonly instances: DomainTypeInstance[]
  readonly on: ButtonLocation
}

function SelfClickingButton(props: ButtonProps): null {
  useMountEffect(() => {
    props.onClick()
  })
  return null
}

type OperationButton =
  | {
    type: 'domainType',
    button: Button
  }
  | {
    type: 'domainTypeListAttribute',
    attributes: ListAttribute<DomainTypeAttribute>[],
    button: Button
  }

function getOptionLabel(button: OperationButton): string {
  if (button.type === 'domainType') {
    return button.button.name
  }
  return button.attributes
    .map(attribute => attribute.Title)
    .concat([button.button.name])
    .join(PATH_SEPARATOR)
}

function getButtonIcon(button: OperationButton): string {
  if (button.button.type === 'action') {
    return button.button.icon
  }
  return {
    create: 'add',
    edit: 'edit',
    delete: 'delete'
  }[button.button.type]
}

export default function OperationsDialog({
  domainType,
  instances,
  on
}: Props): JSX.Element {
  const [open, setOpen] = useState(false)
  const [button, setButton] = useState<OperationButton | null>(null)
  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if (open || event.key !== OPERATIONS_DIALOG_KEY) {
      return
    }
    event.preventDefault()
    setButton(null)
    setOpen(true)
  }, [open])
  const singleInstance = instances.length === 1
    ? instances[0]
    : undefined
  useWindowEventListener('keydown', onKeyDown)
  const domainTypeButtons = useButtons(domainType, instances, on)
    .filter(button => !button.disabled)
  const domainTypeListAttributeButtons = useDomainTypeListAttributeButtons(domainType, singleInstance)
    .filter(({ button }) => !button.disabled)
  const operationButtons = domainTypeButtons
    .map((button): OperationButton => ({
      type: 'domainType',
      button
    }))
    .concat(domainTypeListAttributeButtons
      .map(({ attributes, button }): OperationButton => ({
        type: 'domainTypeListAttribute',
        attributes,
        button
      })))
  return (
    <>
      {button && (
        <AppendDomainTypeContext
          newAttributes={button.type === 'domainTypeListAttribute'
            ? button.attributes
            : []}>
          <DomainTypeButton
            button={button.button}
            instances={button.type === 'domainType'
              ? instances
              : []}
            Component={SelfClickingButton} />
        </AppendDomainTypeContext>
      )}
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          <DomainTypeHeading
            domainType={domainType}
            instance={singleInstance}
            isLoading={false}
            title='Operations:'
            plural={instances.length > 1}
            count={instances.length} />
        </DialogTitle>
        <DialogContent>
          <Box
            component='form'
            display='flex'
            flexDirection='column'
            gap={1}
            autoComplete='off'
            onSubmit={(event: FormEvent) => event.preventDefault()}>
            <Autocomplete
              sx={{ marginTop: 1 }}
              size='small'
              openOnFocus
              onClose={() => setOpen(false)}
              autoHighlight
              renderOption={(props, button) => (
                <li {...props}>
                  <Stack
                    direction='row'
                    gap={1}
                    alignItems='center'>
                    <DomainTypeIcon
                      overrideIcon={getButtonIcon(button)}
                      overrideCornerIcon=''
                      domainType={button.button.domainType} />
                    {getOptionLabel(button)}
                  </Stack>
                </li>
              )}
              getOptionLabel={getOptionLabel}
              options={operationButtons}
              onChange={(event, button) => {
                setOpen(false)
                setButton(button)
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoFocus
                  label='Operation' />
              )} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}