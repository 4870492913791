import { FormHelperText, styled } from '@mui/material'
import { PropsWithChildren } from 'react'

const PREFIX = 'LabelledInput'

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  label: `${PREFIX}-label`,
  content: `${PREFIX}-content`
}

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.wrapper}`]: {
    position: 'relative',
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
    width: '100%',
    padding: 0,
    border: 0
  },
  [`& .${classes.label}`]: {
    fontWeight: 400,
    fontSize: '1rem',
    color: theme.palette.mode === 'light'
      ? 'rgba(0, 0, 0, 0.6)'
      : 'rgba(255, 255, 255, 0.7)',
    transformOrigin: 'top left',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    maxWidth: '133%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'block',
    whiteSpace: 'nowrap',
    transform: 'translate(0, -1.5px) scale(0.75)'
  },
  [`& .${classes.content}`]: {
    marginTop: '16px',
    fontSize: '1rem',
    borderBottom: theme.palette.mode === 'light'
      ? '1px solid rgba(0, 0, 0, 0.42)'
      : '1px solid rgba(255, 255, 255, 0.7)',
    padding: '0 0 4px',
    height: '29px'
  }
}))

interface Props {
  readonly label: string
  readonly required: boolean
  readonly errorText?: string
}

export default function LabelledInput({
  label,
  required,
  errorText,
  children
}: PropsWithChildren<Props>): JSX.Element | null {
  return (
    <Root>
      <div className={classes.wrapper}>
        <label className={classes.label}>
          {label}{required ? ' *' : ''}
        </label>
        <div className={classes.content}>
          {children}
        </div>
        {errorText !== undefined && (
          <FormHelperText>
            {errorText}
          </FormHelperText>
        )}
      </div>
    </Root>
  )
}
