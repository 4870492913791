import { Box, FormControl, FormHelperText, FormLabel, Slider } from '@mui/material'
import { useState } from 'react'
import { PercentageElement } from 'types/dataform'
import { PercentageCodec } from 'utils/codecs/dataform'
import { ElementProps } from '../ElementInput'

export default function Percentage({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<PercentageElement>): JSX.Element | null {
  const [isDragging, setIsDragging] = useState(false)
  const [localValue, setLocalValue] = useState<number>(value ?? 0)
  const disabled = readOnly || locked
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error}>
      <FormLabel>{element.Text}</FormLabel>
      <Box
        pl={1}
        pr={1}>
        <Slider
          size='small'
          valueLabelDisplay={disabled
            ? 'on'
            : 'auto'}
          valueLabelFormat={value => `${value}%`}
          value={isDragging
            ? localValue
            : value ?? 0}
          disabled={disabled}
          onChange={(event, value) => {
            if (!Array.isArray(value)) {
              setLocalValue(value)
              setIsDragging(true)
            }
          }}
          onChangeCommitted={(event, value) => {
            if (PercentageCodec.is(value)) {
              setIsDragging(false)
              onChange(value)
            }
          }} />
      </Box>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}