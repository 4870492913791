import {
  AdalProperties,
  Company,
  EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION,
  EditPersonGlobalDomainTypeSettingAction,
  FETCH_ADAL_PROPERTIES_FULFILLED,
  FETCH_COMPANY_FULFILLED,
  FETCH_PERSON_FULFILLED,
  FetchAdalPropertiesFulfilledAction,
  FetchCompanyFulfilledAction,
  FetchPersonFulfilledAction,
  GlobalDomainTypeSettings,
  PERFORM_SIGN_IN_FULFILLED,
  PerformSignInFulfilledAction,
  Person,
  RESET_PASSWORD_FULFILLED,
  ResetPasswordFulfilledAction,
  SIGNOUT,
  SWITCH_COMPANY,
  SignOutAction,
  SwitchCompanyAction,
  User
} from 'types'

export function performSignInFulfilled(response: User): PerformSignInFulfilledAction {
  return {
    type: PERFORM_SIGN_IN_FULFILLED,
    payload: {
      response
    }
  }
}

export function signOut(): SignOutAction {
  return {
    type: SIGNOUT
  }
}

export function resetPasswordFulfilled(): ResetPasswordFulfilledAction {
  return {
    type: RESET_PASSWORD_FULFILLED
  }
}

export function switchCompany(companyId: string): SwitchCompanyAction {
  return {
    type: SWITCH_COMPANY,
    payload: {
      companyId
    }
  }
}

export function fetchAdalPropertiesFulfilled(adalProperties: AdalProperties): FetchAdalPropertiesFulfilledAction {
  return {
    type: FETCH_ADAL_PROPERTIES_FULFILLED,
    payload: {
      adalProperties
    }
  }
}

export function fetchPersonFulfilled(person: Person): FetchPersonFulfilledAction {
  return {
    type: FETCH_PERSON_FULFILLED,
    payload: {
      person
    }
  }
}

export function fetchCompanyFulfilled(company: Company): FetchCompanyFulfilledAction {
  return {
    type: FETCH_COMPANY_FULFILLED,
    payload: {
      company
    }
  }
}

export function editPersonGlobalDomainTypeSetting<Setting extends keyof GlobalDomainTypeSettings>(
  setting: Setting,
  value: GlobalDomainTypeSettings[Setting]
): EditPersonGlobalDomainTypeSettingAction<Setting> {
  return {
    type: EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION,
    payload: {
      setting,
      value
    }
  }
}