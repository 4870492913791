import React from 'react'
import { useSelector } from 'react-redux'
import * as fromState from '../../state/reducers'

const IsNotAuthenticated: React.FunctionComponent<React.PropsWithChildren> = function IsNotAuthenticated({
  children
}: React.PropsWithChildren) {
  const user = useSelector(fromState.getUser)
  const isNotAuthenticated = !user || !user.token
  return isNotAuthenticated
    ? (
      <>
        {children}
      </>
    )
    : null
}

export default IsNotAuthenticated
