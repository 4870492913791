import { isRight } from 'fp-ts/lib/Either'
import { Location } from 'history'
import * as t from 'io-ts'
import { Lens } from 'monocle-ts'
import { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { ApiError, Attribute, DataformResultsAttribute, DataformResultsAttributeValue, DomainType, DomainTypeAttribute, DomainTypeInstance, DomainTypeOverrider, Filter, ListAttribute, MultiDataformResultsAttribute, MultiDataformResultsAttributeValue, RefAttribute, Section } from 'types'
import { Dashboard } from 'types/dashboard'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeOverriderContext } from 'utils/context'
import { stringifyFilterValue } from 'utils/filters'
import { getAttributeValue, getDomainTypeAttributes, getDomainTypeSetting, getIdentifier, getOverridableDomainTypeSettingItem, getParentDomainTypes, getSubtype, getValue, hasApi, isDataformResultsAttribute, isDomainTypeListAttribute, isInRole, isMultiDataformResultsAttribute } from 'utils/helpers'
import { filterDashboardsForPage } from 'utils/helpers/dashboard'
import { useApi, useDashboards, useDeepEqualMemo, useDomainTypeSetting } from 'utils/hooks'
import detailsPageReducer, { defaultState, DetailsPageView, loadInstance, loadInstanceError, loadInstanceFulfilled, localInstanceChanged, reload } from './detailsPageReducer'

interface SectionBase {
  readonly id: string
  readonly title: string
  readonly hidden: boolean
  readonly expanded: boolean
  readonly role: string | null
  readonly domainType: DomainType
  readonly singleListAttributeDetails: SingleListAttributeDetails | undefined
}

interface SingleListAttributeDetails {
  readonly domainType: DomainType,
  readonly instance: DomainTypeInstance
  readonly attribute: Attribute
}

export interface SummarySection extends SectionBase {
  readonly type: 'summary'
  readonly instance: DomainTypeInstance
}

export interface DataformResultsSection extends SectionBase {
  readonly type: 'dataformResults'
  readonly attributeValue: DataformResultsAttributeValue
}

export interface MultiDataformResultsSection extends SectionBase {
  readonly type: 'multiDataformResults'
  readonly attributeValue: MultiDataformResultsAttributeValue
}

export interface TableSection extends SectionBase {
  readonly type: 'table'
  readonly attribute: ListAttribute<DomainTypeAttribute>
  readonly rows: DomainTypeInstance[] | null
}

interface RelatedSectionAttributeChain {
  readonly title: string
  readonly filters: Filter[]
  readonly attributes: (DomainTypeAttribute | RefAttribute)[]
  readonly id: string
}

export interface RelatedSection extends SectionBase {
  readonly type: 'related'
  readonly instance: DomainTypeInstance
  readonly attributeChains: RelatedSectionAttributeChain[]
}

export interface DashboardSection extends SectionBase {
  readonly type: 'dashboard'
  readonly dashboard: Dashboard
  readonly variableValues: Partial<Record<string, string[]>>
}

export type DetailsPageSection =
  | SummarySection
  | DataformResultsSection
  | MultiDataformResultsSection
  | TableSection
  | RelatedSection
  | DashboardSection

function isSingleListAttribute(attribute: ListAttribute): boolean {
  return attribute.Single ?? false
}

function isNotSingleListAttribute(attribute: ListAttribute): boolean {
  return !isSingleListAttribute(attribute)
}

function getRelationships(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  attributeChain: (DomainTypeAttribute | RefAttribute)[] = []
): [DomainType, (DomainTypeAttribute | RefAttribute)[]][] {
  const targetDomainTypeIds = attributeChain.length === 0
    ? getParentDomainTypes(domainTypes, domainType).map(type => type.Id)
    : [domainType.Id]
  if (attributeChain.some(attribute => targetDomainTypeIds.includes(attribute.AttributeDomainType))) {
    return []
  }
  return Object.values(domainTypes)
    .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
    .map((type: DomainType): [DomainType, (DomainTypeAttribute | RefAttribute)[]] => [
      type,
      type.Attributes
        .filter((attribute): attribute is DomainTypeAttribute | RefAttribute => {
          if (attribute.AttributeType !== 'domainType' && attribute.AttributeType !== 'ref') {
            return false
          }
          const attributeDomainType = domainTypes[attribute.AttributeDomainType]
          if (attributeDomainType === undefined) {
            return false
          }
          if (attributeDomainType.Name === 'Person' && attributeChain.length > 0) {
            return false
          }
          if (!targetDomainTypeIds.includes(attributeDomainType.Id)) {
            return false
          }
          return attribute.AttributeType !== 'ref' || attributeChain.length === 0
        })
    ])
    .filter(([type, attributes]) => attributes.length > 0)
    .flatMap(([type, attributes]) => {
      return (
        getDomainTypeSetting(domainTypes, type, 'Api') ?? false
          ? attributes
            .map((attribute): [DomainType, (DomainTypeAttribute | RefAttribute)[]] => [
              type,
              [attribute].concat(attributeChain)
            ])
          : []
      ).concat(
        attributes
          .flatMap(attribute => getRelationships(domainTypes, type, [attribute].concat(attributeChain)))
      )
    })
}

function getTitlePrefix(
  singleListAttribute: ListAttribute<DomainTypeAttribute> | undefined
): string {
  return singleListAttribute !== undefined
    ? `${singleListAttribute.Title}${PATH_SEPARATOR}`
    : ''
}

function getSingleListAttributeDetails(
  domainTypes: Partial<Record<string, DomainType>>,
  instance: DomainTypeInstance,
  expandedAttribute?: ListAttribute<DomainTypeAttribute>
): SingleListAttributeDetails | undefined {
  const expandedDomainType = expandedAttribute !== undefined
    ? domainTypes[expandedAttribute.AttributeDomainType]
    : undefined
  return expandedAttribute !== undefined && expandedDomainType !== undefined
    ? {
      domainType: expandedDomainType,
      instance: instance,
      attribute: expandedAttribute
    }
    : undefined
}

function getSingleListSections(
  domainTypes: Partial<Record<string, DomainType>>,
  instance: DomainTypeInstance,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  dashboards: Dashboard[],
  attribute: ListAttribute<DomainTypeAttribute>,
  idPrefix = ''
): DetailsPageSection[] {
  const domainType = domainTypes[attribute.AttributeDomainType]
  if (domainType === undefined) {
    return []
  }
  const list = getValue(instance, attribute)
  if (list === null || list.length < 1) {
    return []
  }
  const singleInstance = list[0]
  if (singleInstance === undefined) {
    return []
  }
  return getSections(
    domainTypes,
    domainType,
    pageDomainType,
    singleInstance,
    overriders,
    dashboards,
    `${attribute.Name}_${idPrefix}`,
    attribute
  )
}

const domainTypeId = Lens.fromProp<DomainType>()('Id')

function getSectionSettings(
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  id: string
): Section {
  const title = getOverridableDomainTypeSettingItem(
    domainTypes,
    pageDomainType,
    overriders,
    'Sections',
    sections => (sections?.find(section => section.Id === id)?.Title ?? '') || null
  )
  const hidden = getOverridableDomainTypeSettingItem(
    domainTypes,
    pageDomainType,
    overriders,
    'Sections',
    sections => sections?.find(section => section.Id === id)?.Hidden ?? null
  )
  const expanded = getOverridableDomainTypeSettingItem(
    domainTypes,
    pageDomainType,
    overriders,
    'Sections',
    sections => sections?.find(section => section.Id === id)?.Expanded ?? null
  )
  return {
    Id: id,
    Title: title,
    Hidden: hidden,
    Expanded: expanded
  }
}

function makeSummarySection(
  idPrefix: string,
  domainType: DomainType,
  instance: DomainTypeInstance,
  domainTypes: Partial<Record<string, DomainType>>,
  overriders: DomainTypeOverrider[],
  singleListSections: DetailsPageSection[],
  singleListAttributeDetails: SingleListAttributeDetails | undefined
): SummarySection {
  const id = `${idPrefix}Summary`
  const sectionSettings = getSectionSettings(domainTypes, domainType, overriders, id)
  return {
    type: 'summary',
    id,
    title: 'Summary',
    domainType,
    instance,
    hidden: false,
    expanded: sectionSettings.Expanded ?? true,
    role: null,
    singleListAttributeDetails
  }
}

function makeDataformResultsSection(
  idPrefix: string,
  attribute: DataformResultsAttribute,
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  titlePrefix: string,
  instance: DomainTypeInstance,
  domainType: DomainType,
  singleListAttributeDetails: SingleListAttributeDetails | undefined
): DataformResultsSection {
  const id = `${idPrefix}${attribute.Name}`
  const sectionSettings = getSectionSettings(domainTypes, pageDomainType, overriders, id)
  return {
    type: 'dataformResults',
    id,
    title: sectionSettings.Title ?? `${titlePrefix}${attribute.Title}`,
    attributeValue: getAttributeValue(instance, attribute),
    domainType,
    hidden: sectionSettings.Hidden ?? false,
    expanded: sectionSettings.Expanded ?? false,
    singleListAttributeDetails,
    role: null
  }
}

function makeMultiDataformResultsSection(
  idPrefix: string,
  attribute: MultiDataformResultsAttribute,
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  titlePrefix: string,
  instance: DomainTypeInstance,
  domainType: DomainType,
  singleListAttributeDetails: SingleListAttributeDetails | undefined
): MultiDataformResultsSection {
  const id = `${idPrefix}${attribute.Name}`
  const sectionSettings = getSectionSettings(domainTypes, pageDomainType, overriders, id)
  return {
    type: 'multiDataformResults',
    id,
    title: sectionSettings.Title ?? `${titlePrefix}${attribute.Title}`,
    attributeValue: getAttributeValue(instance, attribute),
    domainType,
    hidden: sectionSettings.Hidden ?? false,
    expanded: sectionSettings.Expanded ?? false,
    singleListAttributeDetails,
    role: null
  }
}

function makeTableSection(
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  instance: DomainTypeInstance,
  overriders: DomainTypeOverrider[],
  attribute: ListAttribute<DomainTypeAttribute>,
  idPrefix = '',
  titlePrefix: string,
  singleListAttributeDetails: SingleListAttributeDetails | undefined
): TableSection | null {
  const rows = getValue(instance, attribute)
  const attributeDomainType = domainTypes[attribute.AttributeDomainType]
  if (attributeDomainType === undefined) {
    return null
  }
  const id = `${idPrefix}${attribute.Name}`
  const sectionSettings = getSectionSettings(domainTypes, pageDomainType, overriders, id)
  return {
    type: 'table',
    id,
    attribute,
    title: sectionSettings.Title ?? `${titlePrefix}${attribute.Title}`,
    domainType: attributeDomainType,
    rows,
    hidden: sectionSettings.Hidden ?? false,
    expanded: sectionSettings.Expanded ?? false,
    role: null,
    singleListAttributeDetails
  }
}

function makeDashboardSection(
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  dashboard: Dashboard,
  parentDomainTypeIds: string[],
  titlePrefix: string,
  domainType: DomainType,
  instance: DomainTypeInstance,
  identifier: string
): DashboardSection {
  const sectionSettings = getSectionSettings(domainTypes, pageDomainType, overriders, dashboard.Value)
  const variable = dashboard.Variables
    ?.find(variable => {
      return parentDomainTypeIds.includes(variable.DomainType)
    })
  return {
    type: 'dashboard',
    id: dashboard.Value,
    title: `${titlePrefix}${dashboard.Value} Dashboard`,
    hidden: sectionSettings.Hidden ?? false,
    expanded: sectionSettings.Expanded ?? false,
    domainType,
    dashboard,
    variableValues: {
      [variable?.Name ?? '']: [String(instance[variable?.Property ?? identifier])]
    },
    role: dashboard.Role,
    singleListAttributeDetails: undefined
  }
}

function makeRelatedSection(
  attributeChains: (DomainTypeAttribute | RefAttribute)[][],
  idPrefix: string,
  domainType: DomainType,
  domainTypes: Partial<Record<string, DomainType>>,
  pageDomainType: DomainType,
  overriders: DomainTypeOverrider[],
  instance: DomainTypeInstance,
  identifier: string
): RelatedSection {
  const id = `${idPrefix}${domainType.Title}`
  const sectionSettings = getSectionSettings(domainTypes, pageDomainType, overriders, id)
  const relatedSectionTitle = sectionSettings.Title ?? ''
  const identifierValue = String(instance[identifier])
  return {
    type: 'related',
    id,
    title: relatedSectionTitle || domainType.PluralTitle,
    attributeChains: attributeChains.map(attributes => {
      const property = attributes.map(attribute => attribute.Name).join('_')
      return {
        title: attributes.map(attribute => attribute.Title).join(PATH_SEPARATOR),
        filters: [{
          Property: property,
          Operator: 'eq',
          Value: stringifyFilterValue(identifierValue)
        }],
        attributes,
        id: identifierValue
      }
    }),
    hidden: sectionSettings.Hidden ?? false,
    expanded: sectionSettings.Expanded ?? false,
    domainType,
    instance,
    role: getDomainTypeSetting(domainTypes, domainType, 'ViewRole') ?? null,
    singleListAttributeDetails: undefined
  }
}

function getSections(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  pageDomainType: DomainType,
  instance: DomainTypeInstance,
  overriders: DomainTypeOverrider[],
  dashboards: Dashboard[],
  idPrefix = '',
  singleListAttribute?: ListAttribute<DomainTypeAttribute>
): DetailsPageSection[] {
  const identifier = getIdentifier(domainTypes, domainType)
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const domainTypeListAttributes = attributes.filter(isDomainTypeListAttribute)
  const complexAttributes = domainTypeListAttributes.filter(isNotSingleListAttribute)
  const singleListAttributes = domainTypeListAttributes.filter(isSingleListAttribute)
  const dataformResultsAttributes = attributes.filter(isDataformResultsAttribute)
  const multiDataformResultsAttributes = attributes.filter(isMultiDataformResultsAttribute)
  const relationships = hasApi(domainTypes, domainType)
    ? getRelationships(domainTypes, domainType)
    : []
  const singleListSections = singleListAttributes
    .flatMap(attribute => getSingleListSections(
      domainTypes,
      instance,
      pageDomainType,
      overriders,
      dashboards,
      attribute,
      idPrefix
    ))
  const parentDomainTypeIds = getParentDomainTypes(domainTypes, domainType)
    .map(domainTypeId.get)
  const filteredDashboards = filterDashboardsForPage(
    dashboards,
    'details',
    parentDomainTypeIds
  )
  const titlePrefix = getTitlePrefix(singleListAttribute)
  const singleListAttributeDetails = getSingleListAttributeDetails(
    domainTypes,
    instance,
    singleListAttribute
  )
  return [
    makeSummarySection(
      idPrefix,
      domainType,
      instance,
      domainTypes,
      overriders,
      singleListSections,
      singleListAttributeDetails
    ),
    ...dataformResultsAttributes
      .map(attribute => makeDataformResultsSection(
        idPrefix,
        attribute,
        domainTypes,
        pageDomainType,
        overriders,
        titlePrefix,
        instance,
        domainType,
        singleListAttributeDetails
      )),
    ...multiDataformResultsAttributes
      .map(attribute => makeMultiDataformResultsSection(
        idPrefix,
        attribute,
        domainTypes,
        pageDomainType,
        overriders,
        titlePrefix,
        instance,
        domainType,
        singleListAttributeDetails
      )),
    ...singleListSections.filter(section => section.type !== 'summary'),
    ...complexAttributes
      .map(attribute => makeTableSection(
        domainTypes,
        pageDomainType,
        instance,
        overriders,
        attribute,
        idPrefix,
        titlePrefix,
        singleListAttributeDetails
      ))
      .filter((section): section is TableSection => section !== null),
    ...filteredDashboards.map(dashboard => makeDashboardSection(
      domainTypes,
      pageDomainType,
      overriders,
      dashboard,
      parentDomainTypeIds,
      titlePrefix,
      domainType,
      instance,
      identifier
    )),
    ...relationships
      .reduce((prev, [domainType, attributeChain]) => {
        const existingIndex = prev
          .findIndex(([existingDomainType]) => existingDomainType.Id === domainType.Id)
        const existingGroup = prev[existingIndex]
        if (existingGroup === undefined) {
          return prev.concat([[domainType, [attributeChain]]])
        }
        const [existingDomainType, existingAttributeChains] = existingGroup
        const modifiedGroup: [DomainType, (DomainTypeAttribute | RefAttribute)[][]] = [
          existingDomainType,
          existingAttributeChains
            .concat([attributeChain])
        ]
        return [
          ...prev.slice(0, existingIndex),
          modifiedGroup,
          ...prev.slice(existingIndex + 1)
        ]
      }, [] as [DomainType, (DomainTypeAttribute | RefAttribute)[][]][])
      .map(([type, attributeChains]) => makeRelatedSection(
        attributeChains,
        idPrefix,
        type,
        domainTypes,
        pageDomainType,
        overriders,
        instance,
        identifier
      ))
  ]
}

export interface HighlightedRow {
  readonly section: string
  readonly id: string
}

interface UseDetailsOutput {
  id: string
  isLoading: boolean
  instance: DomainTypeInstance | null
  apiError: ApiError | null
  domainType: DomainType
  role: string | null
  sections: DetailsPageSection[]
  highlightedRow: HighlightedRow | null
  onHighlightRow(highlightedRow: HighlightedRow | null): void
  view: DetailsPageView
  onChangeView(view: DetailsPageView): void
  onListItemClick(id: string): void
  selectedTab: string
  onTabChange(id: string): void
  onInvalidate?(): void
}

type Params = {
  id: string
}

function createDefaultState(
  localInstance?: DomainTypeInstance
): typeof defaultState {
  return {
    ...defaultState,
    shouldReload: localInstance === undefined,
    isLoading: localInstance === undefined,
    remoteInstance: null
  }
}

function clearStateToSupportPageRefresh() {
  window.history.replaceState({}, document.title)
}

function getRouterInstance(location: Location): DomainTypeInstance | undefined {
  if (t.UnknownRecord.is(location.state)
    && t.UnknownRecord.is(location.state.instance)) {
    return location.state.instance
  }
  return undefined
}

export default function useDetails(
  rootDomainType: DomainType,
  localInstance?: DomainTypeInstance
): UseDetailsOutput {
  const { id = '' } = useParams<Params>()
  const location = useLocation()
  const latestRouterInstance = getRouterInstance(location)
  const [routerInstance, setRouterInstance] = useState(latestRouterInstance)
  useEffect(() => {
    setRouterInstance(undefined)
  }, [id])
  useEffect(() => {
    if (latestRouterInstance !== undefined) {
      setRouterInstance(latestRouterInstance)
    }
  }, [latestRouterInstance])
  if (routerInstance !== undefined) {
    clearStateToSupportPageRefresh()
  }
  localInstance = localInstance ?? routerInstance

  const domainTypes = useSelector(getAllDomainTypes)
  const [
    {
      shouldReload,
      isLoading,
      remoteInstance,
      apiError
    },
    dispatch
  ] = useReducer(
    detailsPageReducer,
    createDefaultState(localInstance)
  )
  useEffect(() => {
    dispatch(localInstanceChanged())
  }, [localInstance, id])
  const user = useSelector(getUser)
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const instance = remoteInstance ?? localInstance ?? null
  const domainType = useMemo(() => {
    return getSubtype(domainTypes, rootDomainType, instance ?? {}) ?? rootDomainType
  }, [domainTypes, instance, rootDomainType])
  const [view, onChangeView] = useDomainTypeSetting(
    domainType,
    'View',
    'expandableList'
  )

  const [selectedTab, onTabChange] = useDomainTypeSetting(
    domainType,
    'Tab',
    'Summary'
  )

  const [highlightedRow, onHighlightRow] = useState<HighlightedRow | null>(null)
  const api = useApi()
  useEffect(() => {
    if ((remoteInstance ?? localInstance) !== undefined && !shouldReload) {
      return
    }
    async function load() {
      if (!api.isSignedIn) {
        return
      }
      dispatch(loadInstance())
      const response = await api.get(rootDomainType.Name, id)
      if (isRight(response)) {
        dispatch(loadInstanceFulfilled(response.right))
      } else {
        dispatch(loadInstanceError(response.left))
      }
    }
    load()
  }, [api, domainTypes, id, localInstance, remoteInstance, rootDomainType, shouldReload])
  const dashboards = useDashboards()
  const sections = useDeepEqualMemo(useMemo(
    () => getSections(
      domainTypes,
      domainType,
      domainType,
      remoteInstance ?? localInstance ?? {},
      overriders,
      dashboards
    )
      .filter(section => section.role === null || isInRole(user, section.role)),
    [domainTypes, domainType, remoteInstance, localInstance, overriders, dashboards, user]
  ))

  const [domainTypeSections, onSectionsChange] = useDomainTypeSetting(
    domainType,
    'Sections',
    []
  )

  const onListItemClick = useCallback((id: string) => {
    const existingSetting = getOverridableDomainTypeSettingItem(
      domainTypes,
      domainType,
      overriders,
      'Sections',
      sections => sections?.find(section => section.Id === id) ?? null
    )

    const updatedSection = existingSetting !== null
      ? {
        ...existingSetting,
        Expanded: id === 'Summary'
          ? !(existingSetting.Expanded ?? true)
          : !(existingSetting.Expanded ?? false)
      }
      :
      {
        Id: id,
        Expanded: true
      }

    const updatedSections = domainTypeSections.find(section => section.Id === id) ?? null
      ? domainTypeSections.map(section => section.Id === id ? updatedSection : section)
      : [...domainTypeSections, updatedSection]

    onSectionsChange(updatedSections)
  }, [domainType, domainTypeSections, domainTypes, onSectionsChange, overriders])
  const onInvalidate = useCallback(() => {
    dispatch(reload())
  }, [])
  const role = useMemo(() => {
    return isLoading
      ? null
      : getDomainTypeSetting(domainTypes, domainType, 'ViewRole') ?? null
  }, [domainType, domainTypes, isLoading])
  return {
    id,
    isLoading,
    instance,
    apiError,
    domainType,
    role,
    sections,
    highlightedRow,
    onHighlightRow,
    view,
    onChangeView,
    onListItemClick,
    selectedTab,
    onTabChange,
    onInvalidate: rootDomainType.Api ?? false
      ? onInvalidate
      : undefined
  }
}
