import { createContext } from 'react'
import { Attribute, DomainType, DomainTypeAttribute, DomainTypeInstance, RefAttribute } from 'types'

interface Relationship {
  readonly attributeChain: (DomainTypeAttribute | RefAttribute)[]
  readonly instance: DomainTypeInstance
  readonly id: string
}

interface Context {
  readonly instances: [DomainType, Readonly<DomainTypeInstance>][]
  readonly attributes: Attribute[]
  readonly batchInstances: [DomainType, Readonly<DomainTypeInstance>][]
  readonly relationship?: Relationship
  onInvalidate?(): void
}

export default createContext<Context>({
  instances: [],
  attributes: [],
  batchInstances: []
})