import { debounce } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'

export function useDebouncedOnChange<T>(
  onChange: (value: T) => void,
  value: T,
  wait: number
): [T, (value: T) => void] {
  const [isDebouncing, setIsDebouncing] = useState(false)
  const debouncedOnChange = useMemo(() => {
    return debounce((value: T) => {
      setIsDebouncing(false)
      onChange(value)
    }, wait)
  }, [onChange, wait])
  const [localValue, setLocalValue] = useState(value)
  useEffect(() => {
    if (isDebouncing) {
      debouncedOnChange(localValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue, isDebouncing])
  const handleChange = useCallback((value: T) => {
    setIsDebouncing(true)
    setLocalValue(value)
  }, [])
  return [isDebouncing ? localValue : value, handleChange]
}