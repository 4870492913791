import { useCallback, useMemo } from 'react'
import { DomainType, ListDomainTypeAttributeValue, NonListDomainTypeAttributeValue, PathError } from 'types'
import NonListNonApiDomainTypeInput from './NonListNonApiDomainTypeInput'

interface SingleListNonApiDomainTypeProps {
  readonly domainType: DomainType
  readonly attributeValue: ListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: ListDomainTypeAttributeValue): void
}

export default function SingleListNonApiDomainTypeInput({
  domainType,
  attributeValue,
  disabled,
  pathError,
  onChange
}: SingleListNonApiDomainTypeProps): JSX.Element | null {
  const nonListAttributeValue = useMemo(() => ({
    attribute: attributeValue.attribute,
    value: attributeValue.value === null || attributeValue.value.length < 1
      ? null
      : attributeValue.value[0] ?? null
  }), [attributeValue])
  const singlePathError = Array.isArray(pathError)
    ? pathError[0]
    : undefined
  const handleChange = useCallback((attributeValue: NonListDomainTypeAttributeValue) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: attributeValue.value === null
        ? null
        : [attributeValue.value]
    })
  }, [onChange])
  return (
    <NonListNonApiDomainTypeInput
      domainType={domainType}
      attributeValue={nonListAttributeValue}
      disabled={disabled}
      pathError={singlePathError}
      onChange={handleChange} />
  )
}