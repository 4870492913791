import { ImageList, ImageListItem, Paper, Typography } from '@mui/material'
import { HighlightedRow, TableSection } from 'components/pages/DetailsPage'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useEffect, useState } from 'react'
import { blobToBase64 } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'

interface Props {
  section: TableSection
  isLoading: boolean
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
}

export default function ImageHandleTableSection({
  section
}: Props): JSX.Element {
  const work = useDomainTypeContextInstance('Work', 'Work', t.type({ Id: t.string }))
  const [images, setImages] = useState<(string | null)[] | null>(null)
  const api = useApi()
  useEffect(() => {
    const loadImage = async () => {
      if (!api.isSignedIn
        || work === null
        || section.rows === null) {
        return
      }
      const files: (string | null)[] = []
      for (const row of section.rows) {
        const response = await api.getFileBlob(
          'Work',
          work.Id,
          String(row.Name)
        )
        if (E.isLeft(response)) {
          files.push(null)
          continue
        }
        const value = await blobToBase64(response.right, 'image/jpeg')
        if (value !== null) {
          files.push(value)
        } else {
          files.push(null)
        }
      }
      setImages(files)
    }
    loadImage()
  }, [api, section.rows, work])
  return (
    <Paper
      sx={{
        height: (images?.length ?? 0) > 0
          ? '300px'
          : undefined,
        overflow: 'auto',
        p: 1
      }}>
      {images === null
        ? null
        : images.length > 0
          ? (
            <ImageList
              variant='masonry'
              cols={6}
              gap={8}>
              {images.map((image, i) => (
                <ImageListItem key={i}>
                  {image === null
                    ? (
                      <img
                        alt='Not found' />
                    )
                    : (
                      <img
                        src={image}
                        alt='' />
                    )}
                </ImageListItem>
              ))}
            </ImageList>
          )
          : (
            <Typography variant='body1'>
              No images
            </Typography>
          )}
    </Paper>
  )
}