import { ApiKeyCreateButton, DataformDetails, DataformResultsCell, DomainTypeComponentOverrides, EquipmentCell, EquipmentInput, ImageHandleTableSection, InlineInput, PersonCreateDialog } from 'components/overrides'
import { createContext } from 'react'

interface Context {
  readonly global: DomainTypeComponentOverrides
  readonly overrides: Partial<Record<string, DomainTypeComponentOverrides>>
}

export default createContext<Context>({
  global: {},
  overrides: {
    Equipment: {
      cell: EquipmentCell,
      input: EquipmentInput
    },
    'Dataform:Dataform': {
      details: DataformDetails
    },
    DataformResults: {
      cell: DataformResultsCell
    },
    'ApiKey:ApiKey': {
      createButton: ApiKeyCreateButton
    },
    DateFilterValue: {
      input: InlineInput
    },
    DateTimeFilterValue: {
      input: InlineInput
    },
    DateRangeFilterValue: {
      input: InlineInput
    },
    DateTimeRangeFilterValue: {
      input: InlineInput
    },
    RelativeDateRange: {
      input: InlineInput
    },
    RelativeDateTimeRange: {
      input: InlineInput
    },
    'Person:Person': {
      createDialog: PersonCreateDialog
    },
    ImageHandle: {
      tableSection: ImageHandleTableSection
    }
  }
})
