import { JSXElementConstructor, useContext } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { DomainTypeComponentOverrideContext } from 'utils/context'
import { getOverriddenComponent, isNullOrUndefined } from 'utils/helpers'
import { DomainTypeComponentOverrides, DomainTypeComponentProps } from '.'

interface Props<C extends keyof DomainTypeComponentOverrides> {
  readonly component: C,
  readonly domainType: DomainType | null | undefined
  readonly props: JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<DomainTypeComponentOverrides[C], DomainTypeComponentProps[C]>
  readonly DefaultComponent: JSXElementConstructor<DomainTypeComponentProps[C]>
}

export default function OverridableDomainTypeComponent<C extends keyof DomainTypeComponentOverrides>({
  component,
  domainType,
  props,
  DefaultComponent
}: Props<C>): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const context = useContext(DomainTypeComponentOverrideContext)
  if (isNullOrUndefined(domainType)) {
    return null
  }
  const Component = getOverriddenComponent(
    domainTypes,
    domainType,
    context,
    component
  ) ?? DefaultComponent
  return (
    <Component
      {...props}
      DefaultComponent={DefaultComponent} />
  )
}