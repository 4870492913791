import { Dataform, DefaultValueContext, Dependency, Element, ElementValueTypes, Results } from 'types/dataform'

export type ElementGraph = Partial<Record<string, string[]>>

export interface ElementState<E extends Element = Element> {
  readonly hidden: boolean
  readonly required: boolean
  readonly value: ElementValueTypes[E['ElementType']] | null
  readonly resultsValue: ElementValueTypes[E['ElementType']] | null
  readonly defaultValue: ElementValueTypes[E['ElementType']] | null
  readonly expectedValue: RegExp | null
  readonly requiredError: boolean
  readonly constraintError: boolean
  readonly lockedByMilestones: Element[]
  readonly lockedByElements: Element[]
  readonly disabledByDependencies: Dependency[]
  readonly asExpected: boolean
  readonly readOnly: boolean
  readonly circularDependency: string[] | null
  readonly circularCalculation: string[] | null
}

export interface State {
  readonly type: 'initialised'
  readonly dataform: Dataform
  readonly activeGroup: number
  readonly showRequiredErrors: boolean
  readonly complete: boolean
  readonly elements: Partial<Record<string, ElementState>>
}

export interface NotInitialisedState {
  readonly type: 'notInitialised'
}

export const INITIALISED = 'INITIALISED'
export interface InitialisedAction {
  readonly type: typeof INITIALISED
  readonly payload: {
    readonly dataform: Dataform
    readonly results: Results | null
    readonly defaultValueContext: DefaultValueContext
  }
}

export const DATAFORM_EDITED = 'DATAFORM_EDITED'
export interface DataformEditedAction {
  readonly type: typeof DATAFORM_EDITED
  readonly payload: {
    readonly dataform: Dataform
    readonly defaultValueContext: DefaultValueContext
  }
}

export const ELEMENT_VALUE_CHANGED = 'ELEMENT_VALUE_CHANGED'
export interface ElementValueChangedAction<E extends Element = Element> {
  readonly type: typeof ELEMENT_VALUE_CHANGED
  readonly payload: {
    readonly element: E
    readonly value: ElementValueTypes[E['ElementType']]
  }
}

export const ELEMENT_ENABLED_BY_DEPENDENCY = 'ELEMENT_ENABLED_BY_DEPENDENCY'
export interface ElementEnabledByDependencyAction {
  readonly type: typeof ELEMENT_ENABLED_BY_DEPENDENCY
  readonly payload: {
    readonly element: Element
    readonly dependency: Dependency
  }
}

export const ELEMENT_DISABLED_BY_DEPENDENCY = 'ELEMENT_DISABLED_BY_DEPENDENCY'
export interface ElementDisabledByDependencyAction {
  readonly type: typeof ELEMENT_DISABLED_BY_DEPENDENCY
  readonly payload: {
    readonly element: Element
    readonly dependency: Dependency
  }
}

export const ELEMENT_LOCKED_BY_MILESTONE = 'ELEMENT_LOCKED_BY_MILESTONE'
export interface ElementLockedByMilestoneAction {
  readonly type: typeof ELEMENT_LOCKED_BY_MILESTONE
  readonly payload: {
    readonly element: Element
    readonly milestone: Element
  }
}

export const ELEMENT_UNLOCKED_BY_MILESTONE = 'ELEMENT_UNLOCKED_BY_MILESTONE'
export interface ElementUnlockedByMilestoneAction {
  readonly type: typeof ELEMENT_UNLOCKED_BY_MILESTONE
  readonly payload: {
    readonly element: Element
    readonly milestone: Element
  }
}

export const MILESTONE_LOCKED_BY_ELEMENT = 'MILESTONE_LOCKED_BY_ELEMENT'
export interface MilestoneLockedByElementAction {
  readonly type: typeof MILESTONE_LOCKED_BY_ELEMENT
  readonly payload: {
    readonly milestone: Element
    readonly element: Element
  }
}

export const MILESTONE_UNLOCKED_BY_ELEMENT = 'MILESTONE_UNLOCKED_BY_ELEMENT'
export interface MilestoneUnlockedByElement {
  readonly type: typeof MILESTONE_UNLOCKED_BY_ELEMENT
  readonly payload: {
    readonly milestone: Element
    readonly element: Element
  }
}

export const ELEMENT_AS_EXPECTED = 'ELEMENT_AS_EXPECTED'
export interface ElementAsExpectedAction {
  readonly type: typeof ELEMENT_AS_EXPECTED
  readonly payload: {
    readonly element: Element
  }
}

export const ELEMENT_NOT_AS_EXPECTED = 'ELEMENT_NOT_AS_EXPECTED'
export interface ElementNotAsExpectedAction {
  readonly type: typeof ELEMENT_NOT_AS_EXPECTED
  readonly payload: {
    readonly element: Element
  }
}

export const ELEMENT_CONSTRAINT_ERROR_REMOVED = 'ELEMENT_CONSTRAINT_ERROR_REMOVED'
export interface ElementConstraintErrorRemovedAction {
  readonly type: typeof ELEMENT_CONSTRAINT_ERROR_REMOVED
  readonly payload: {
    readonly element: Element
  }
}

export const ELEMENT_CONSTRAINT_ERROR_ADDED = 'ELEMENT_CONSTRAINT_ERROR_ADDED'
export interface ElementConstraintErrorAddedAction {
  readonly type: typeof ELEMENT_CONSTRAINT_ERROR_ADDED
  readonly payload: {
    readonly element: Element
  }
}

export const ELEMENT_REQUIRED_ERROR_REMOVED = 'ELEMENT_REQUIRED_ERROR_REMOVED'
export interface ElementRequiredErrorRemovedAction {
  readonly type: typeof ELEMENT_REQUIRED_ERROR_REMOVED
  readonly payload: {
    readonly element: Element
  }
}

export const ELEMENT_REQUIRED_ERROR_ADDED = 'ELEMENT_REQUIRED_ERROR_ADDED'
export interface ElementRequiredErrorAddedAction {
  readonly type: typeof ELEMENT_REQUIRED_ERROR_ADDED
  readonly payload: {
    readonly element: Element
  }
}

export const GROUP_STEPPER_CLICKED = 'GROUP_STEPPER_CLICKED'
export interface GroupStepperClicked {
  readonly type: typeof GROUP_STEPPER_CLICKED
  readonly payload: {
    readonly index: number
  }
}

export const BACK_BUTTON_CLICKED = 'BACK_BUTTON_CLICKED'
export interface BackButtonClicked {
  readonly type: typeof BACK_BUTTON_CLICKED
}

export const NEXT_BUTTON_CLICKED = 'NEXT_BUTTON_CLICKED'
export interface NextButtonClicked {
  readonly type: typeof NEXT_BUTTON_CLICKED
}

export const ACTIVE_GROUP_CHANGED = 'ACTIVE_GROUP_CHANGED'
export interface ActiveGroupChangedAction {
  readonly type: typeof ACTIVE_GROUP_CHANGED
  readonly payload: {
    readonly index: number
  }
}

export const COMPLETED = 'COMPLETED'
export interface CompletedAction {
  readonly type: typeof COMPLETED
}

export const UNLOCKED = 'UNLOCKED'
export interface UnlockedAction {
  readonly type: typeof UNLOCKED
}

export type Action =
  | InitialisedAction
  | DataformEditedAction
  | ElementValueChangedAction
  | ElementEnabledByDependencyAction
  | ElementDisabledByDependencyAction
  | ElementLockedByMilestoneAction
  | ElementUnlockedByMilestoneAction
  | MilestoneLockedByElementAction
  | MilestoneUnlockedByElement
  | ElementAsExpectedAction
  | ElementNotAsExpectedAction
  | ElementConstraintErrorRemovedAction
  | ElementConstraintErrorAddedAction
  | ElementRequiredErrorRemovedAction
  | ElementRequiredErrorAddedAction
  | GroupStepperClicked
  | BackButtonClicked
  | NextButtonClicked
  | ActiveGroupChangedAction
  | CompletedAction
  | UnlockedAction