import { NonListNumberAttributeValue } from 'types'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'

export default function NumberCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListNumberAttributeValue>): JSX.Element {
  return (
    <CellTooltip
      value={attributeValue.value?.toString() ?? ''}
      columnWidth={columnWidth} />
  )
}
