import * as E from 'fp-ts/Either'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance, Filter, Query, Sort } from 'types'
import { DomainTypeContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { applyAllFilters, applyAnyFilters, getAnyAllFilters } from 'utils/filters'
import { getAttributeValue, getContextTree, getDomainTypeAttributes, getPatchRequestBody, getRootDomainType, getSortableValue, isValidSortAttribute } from 'utils/helpers'
import { SnackPack, useApi, useDomainTypeSetting, useFilterContext, useQueries, useSnackPack } from 'utils/hooks'
import { getChangeItemDatesPatchRequestBody } from './helpers'
import { CalendarProps, CalendarTimelineSettings, FindPageView, GroupByValue } from './types'

export interface UseInMemoryFindOutput {
  items: DomainTypeInstance[]
  calendarItems: DomainTypeInstance[]
  total: number
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filterLinkOperator: 'and' | 'or'
  filters: Filter[]
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  currentQuery: Query
  checkedRowIds: string[]
  view: FindPageView
  calendarProps: CalendarProps
  snackPack: SnackPack
  onSearchTextChange(value?: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  getTotal(query: Query): number
  onApplyQuery(query: Query): void
  onCheckedRowIdsChange(ids: string[]): void
  onViewChange(value: FindPageView): void
}

const EMPTY_CHCKED_ROW_IDS: string[] = []

export default function useInMemoryFind(
  domainType: DomainType,
  rows: DomainTypeInstance[],
  queryId: string,
  setQueryId: (queryId: string) => void
): UseInMemoryFindOutput {
  const [filterLinkOperator, setFilterLinkOperator] = useState<'and' | 'or'>('and')
  const [filters, setFilters] = useState<Filter[]>([])
  const [sorts, setSorts] = useState<Sort[]>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [searchText, setSearchText] = useState('')
  const [editingItem, setEditingItem] = useState<CalendarProps['editingItem']>(null)
  const [actionDetails, setActionDetails] = useState<CalendarProps['actionDetails']>(undefined)
  const [changeItemDatesErrorCode, setChangeItemDatesErrorCode] = useState<string | undefined>(undefined)
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const domainTypeAttributes = useMemo(() => {
    return getDomainTypeAttributes(domainTypes, domainType)
  }, [domainTypes, domainType])

  const applyFilters = useCallback((items: DomainTypeInstance[]): DomainTypeInstance[] => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      filterLinkOperator,
      filters,
      searchText
    )
    return items
      .filter(row => {
        if (anyFilters.length === 0) {
          return row
        }
        return applyAnyFilters(
          domainTypes,
          domainType,
          row,
          anyFilters,
          filterContext
        )
      })
      .filter(row => {
        return applyAllFilters(
          domainTypes,
          domainType,
          row,
          allFilters,
          filterContext
        )
      })
  }, [filterLinkOperator, searchText, domainTypes, domainType, filters, filterContext])
  const sortItems = useCallback((items: DomainTypeInstance[]): DomainTypeInstance[] => {
    if (sorts.length === 0) {
      return items
    }
    const sort = sorts[0]
    if (sort === undefined) {
      return items
    }
    const attribute = domainTypeAttributes
      .filter(isValidSortAttribute)
      .find(a => a.Name === sort.Property)
    if (attribute === undefined) {
      return items
    }
    const sortedItems = [...items]
    sortedItems.sort((item1: DomainTypeInstance, item2: DomainTypeInstance) => {
      const item1Value = getSortableValue(domainTypes, getAttributeValue(item1, attribute))
      const item2Value = getSortableValue(domainTypes, getAttributeValue(item2, attribute))

      if (item1Value === item2Value) {
        return 0
      }

      else if (item1Value === null) {
        return -1
      }
      else if (item2Value === null) {
        return 1
      }

      if (item1Value < item2Value) {
        return -1
      }
      if (item1Value > item2Value) {
        return 1
      }

      return 0
    })
    return sort.Direction === 'desc' ? sortedItems.reverse() : sortedItems
  }, [domainTypeAttributes, domainTypes, sorts])
  const applyPage = useCallback((items: DomainTypeInstance[]): DomainTypeInstance[] => {
    const startIndex = (page - 1) * pageSize
    return items.slice(startIndex, startIndex + pageSize)
  }, [page, pageSize])
  const [view, setView] = useDomainTypeSetting(domainType, 'FindView', 'table')
  const filteredItems = useMemo(() => {
    return applyFilters(rows)
  }, [applyFilters, rows])
  const items = useMemo(() => {
    if (view === 'calendar' || view === 'timeline') {
      return []
    }
    return applyPage(sortItems(filteredItems))
  }, [applyPage, filteredItems, sortItems, view])
  const calendarItems = useMemo(() => {
    if (view !== 'calendar' && view !== 'timeline') {
      return []
    }
    return sortItems(filteredItems)
  }, [filteredItems, sortItems, view])
  const getTotal = useCallback((query: Query) => {
    const [anyFilters, allFilters] = getAnyAllFilters(
      domainTypes,
      domainType,
      query.FilterLinkOperator ?? 'and',
      query.Filters,
      query.SearchText ?? ''
    )
    return rows
      .filter(row => {
        if (anyFilters.length === 0) {
          return row
        }
        return applyAnyFilters(
          domainTypes,
          domainType,
          row,
          anyFilters,
          filterContext
        )
      })
      .filter(row => {
        return applyAllFilters(
          domainTypes,
          domainType,
          row,
          allFilters,
          filterContext
        )
      }).length
  }, [domainType, domainTypes, filterContext, rows])
  const {
    currentQuery,
    overriderQueries,
    domainTypeQueriesWithAll
  } = useQueries(domainType, queryId)
  const onApplyQuery = useCallback((query: Query) => {
    setFilterLinkOperator(query.FilterLinkOperator ?? 'and')
    setFilters(query.Filters)
    setSorts(query.Sorts)
    setSearchText(query.SearchText ?? '')
    setQueryId(query.Id)
  }, [setQueryId])
  const onSearchTextChange = useCallback((value: string) => {
    setSearchText(value)
  }, [])
  const onFilterLinkOperatorChange = useCallback((value: 'and' | 'or') => {
    setFilterLinkOperator(value)
  }, [])
  const onFiltersChange = useCallback((value: Filter[]) => {
    setFilters(value)
  }, [])
  const onSortsChange = useCallback((value: Sort[]) => {
    setSorts(value)
  }, [])
  const onPageSizeChange = useCallback((value: number) => {
    setPageSize(value)
    setPage(Math.floor(((page - 1) * pageSize + 1) / value) + 1)
  }, [page, pageSize])
  const [checkedRowIds, onCheckedRowIdsChange] = useState<string[]>(EMPTY_CHCKED_ROW_IDS)
  useEffect(() => {
    onCheckedRowIdsChange(EMPTY_CHCKED_ROW_IDS)
  }, [page, pageSize])
  const [calendarView, setCalendarView] = useDomainTypeSetting(domainType, 'CalendarView', 'month')
  const [date, setDate] = useState<Date>(DateTime.now().toJSDate())
  const api = useApi()
  const domainTypeContext = useContext(DomainTypeContext)
  const onChangeItemDates = useCallback(async (
    itemId: string,
    domainTypeChain: CalendarTimelineSettings[],
    groupItems: DomainTypeInstance[],
    items: DomainTypeInstance[],
    startDate: number,
    endDate: number,
    groupByValue?: GroupByValue,
    actionDetails?: CalendarProps['actionDetails']
  ) => {
    if (!api.isSignedIn) {
      return
    }
    const body = getChangeItemDatesPatchRequestBody(
      domainTypeChain,
      groupItems,
      items,
      startDate,
      endDate,
      groupByValue
    )
    if (body === null) {
      return
    }

    const contextTree = getContextTree(
      domainTypeContext,
      domainType,
      [body]
    )
    const node = contextTree[0]
    if (node === undefined) {
      return null
    }
    const patchRequestBody = getPatchRequestBody(
      domainTypes,
      node
    )
    if (patchRequestBody === undefined) {
      return
    }
    const rootDomainType = getRootDomainType(
      domainTypes,
      node.domainType
    )
    if (rootDomainType === null) {
      return null
    }
    setEditingItem({
      itemId,
      startDate,
      endDate,
      groupByValue
    })
    setActionDetails(actionDetails)
    setChangeItemDatesErrorCode(undefined)
    if (actionDetails !== undefined) {
      setActionDialogOpen(true)
      return
    }
    const response = await api.patch(
      rootDomainType.Name,
      patchRequestBody
    )
    if (E.isRight(response)) {
      domainTypeContext.onInvalidate?.()
    } else {
      setChangeItemDatesErrorCode(response.left.errorCode)
    }
  }, [api, domainType, domainTypeContext, domainTypes])
  const instance = domainTypeContext.instances[0]?.[1]
  useEffect(() => {
    setEditingItem(null)
    setActionDetails(undefined)
  }, [instance])
  const context = useContext(DomainTypeContext)
  const [actionDialogOpen, setActionDialogOpen] = useState(false)
  const onCloseActionDialog = useCallback(() => {
    setActionDialogOpen(false)
    setEditingItem(null)
  }, [])
  const onPerformAction = useCallback(() => {
    setActionDialogOpen(false)
    context.onInvalidate?.()
  }, [context])
  const snackPack = useSnackPack()
  const { addMessage } = snackPack
  const { formatMessage } = useIntl()
  useEffect(() => {
    if (changeItemDatesErrorCode === undefined) {
      return
    }
    addMessage(formatMessage({
      id: changeItemDatesErrorCode,
      defaultMessage: changeItemDatesErrorCode
    }))
  }, [changeItemDatesErrorCode, formatMessage, addMessage])
  const calendarProps = useMemo(() => ({
    view: calendarView,
    date,
    editingItem,
    actionDetails,
    actionDialogOpen,
    onViewChange: setCalendarView,
    onDateChange: setDate,
    onChangeItemDates,
    onCloseActionDialog,
    onPerformAction
  }), [actionDetails, actionDialogOpen, calendarView, date, editingItem, onChangeItemDates, onCloseActionDialog, onPerformAction, setCalendarView])
  return {
    items,
    calendarItems,
    total: filteredItems.length,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    overriderQueries,
    domainTypeQueries: domainTypeQueriesWithAll,
    currentQuery,
    checkedRowIds,
    view,
    calendarProps,
    snackPack,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange: setPage,
    onPageSizeChange,
    onSortsChange,
    getTotal,
    onApplyQuery,
    onCheckedRowIdsChange,
    onViewChange: setView
  }
}
