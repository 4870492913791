import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { Dashboard } from 'types/dashboard'
import { DashboardCodec } from 'utils/codecs/dashboard'
import { isInRole } from 'utils/helpers'
import { useApi, useCancellableApiSession } from '.'

export function useDashboards(
): Dashboard[] {
  const api = useApi()
  const loadDashboards = useCancellableApiSession(api)
  const [dashboards, setDashboards] = useState<Dashboard[]>([])
  useEffect(() => {
    const apiSession = loadDashboards.cancelPreviousAndStartNew()
    if (!apiSession.isSignedIn) {
      return
    }
    apiSession.search(
      'Metadata',
      'Dashboard',
      [],
      [],
      [],
      1,
      25
    )
      .then(response => {
        if (E.isRight(response)
          && t.array(DashboardCodec).is(response.right.results)) {
          setDashboards(response.right.results)
        }
      })
    return loadDashboards.cancel
  }, [loadDashboards])
  const user = useSelector(getUser)
  return useMemo(() => {
    return dashboards
      .filter(dashboard => isInRole(user, dashboard.Role))
  }, [dashboards, user])
}