import { ContextType, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { DomainType, User } from 'types'
import { DomainTypeContext } from 'utils/context'
import { CONTEXT_PREFIX, FilterContext, ME_PREFIX } from 'utils/filters'
import { getContextAttributeValues } from 'utils/filters/helpers'
import { getContextTree } from 'utils/helpers'
import { useDomainTypeContextWithoutOnInvalidate } from '.'

export function getFilterContext(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  user: User | null
): FilterContext {
  const contextTree = getContextTree(domainTypeContext)
  const context = getContextAttributeValues(
    domainTypes,
    contextTree,
    domainTypeContext.batchInstances
  )
  return {
    [ME_PREFIX]: user?.id ?? null,
    [CONTEXT_PREFIX]: context,
    domainTypes
  }
}

export function useFilterContext(): FilterContext {
  const user = useSelector(getUser)
  const domainTypeContext = useDomainTypeContextWithoutOnInvalidate()
  const domainTypes = useSelector(getAllDomainTypes)
  return useMemo(() => {
    return getFilterContext(domainTypes, domainTypeContext, user)
  }, [domainTypeContext, domainTypes, user])
}