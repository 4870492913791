import { Theme } from '@mui/material'
import { ContextType, createContext } from 'react'
import { DomainType, DomainTypeAttribute, DomainTypeInstance, ListAttribute } from 'types'
import { DomainTypeContext } from 'utils/context'

interface FindSidePanel {
  readonly id: string
  readonly type: 'find'
  readonly domainType: DomainType
  readonly theme: Theme
}

interface DetailsSidePanel {
  readonly id: string
  readonly type: 'details'
  readonly context: ContextType<typeof DomainTypeContext>
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly attribute: ListAttribute<DomainTypeAttribute>
  readonly title?: string
  readonly theme: Theme
}

export type SidePanel = FindSidePanel | DetailsSidePanel

interface SidePanelContext {
  setSidePanel(sidePanel: SidePanel | null): void
  sidePanel: SidePanel | null
}

export default createContext<SidePanelContext>({
  setSidePanel: value => false,
  sidePanel: null
})