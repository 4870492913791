import { Action, AdalProperties, FETCH_ADAL_PROPERTIES_FULFILLED } from 'types'

export default function adalProperties(state: AdalProperties | null = null, action: Action): AdalProperties | null {
  switch (action.type) {
    case FETCH_ADAL_PROPERTIES_FULFILLED:
      return action.payload.adalProperties
    default:
      return state
  }
}

export function getAdalProperties(state: AdalProperties | null): AdalProperties | null {
  return state
}