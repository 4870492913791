import { Card, CardActions, CardContent, Grid, Stack, styled } from '@mui/material'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { push } from 'redux-first-history'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Query } from 'types'
import { PAGE_URL } from 'utils/constants'
import { getAnyAllFilters } from 'utils/filters'
import { getRootDomainType } from 'utils/helpers'
import { useQueries } from 'utils/hooks'
import { QueryChips } from '../FindPage'

const StyledGrid = styled(Grid)(({ theme }) => ({
  '& .MuiCardHeader-action': {
    alignSelf: 'center',
    marginRight: 0
  }
}))

interface Props {
  domainType: DomainType
}

const currentQuery: Query = {
  Id: '',
  Title: '',
  Filters: [],
  Sorts: []
}

export default function QueryCard({
  domainType
}: Props): JSX.Element | null {
  const dispatch = useDispatch()
  const {
    overriderQueries,
    domainTypeQueries
  } = useQueries(domainType)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  if (overriderQueries.length === 0 && domainTypeQueries.length === 0) {
    return null
  }
  return (
    <StyledGrid
      key={domainType.Id}
      item
      onClick={() => {
        dispatch(push({
          pathname: generatePath(PAGE_URL.FIND, {
            databaseTable: domainType.DatabaseTable ?? domainType.Name,
            name: domainType.Name
          })
        }))
      }}
      sx={{ cursor: 'pointer' }}
      xl={2}
      lg={3}
      md={4}
      sm={6}
      xs={12}>
      <Card
        sx={{
          maxWidth: 350,
          background: theme => theme.palette.background.paper
        }}>
        <CardContent
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1
          }}>
          <Stack
            display='flex'
            flexDirection='row'
            alignItems='center'
            gap={1}>
            <DomainTypeIcon
              avatar
              avatarScale={1.2}
              domainType={domainType} />
            {domainType.PluralTitle}
          </Stack>
          {overriderQueries.length > 0 || domainTypeQueries.length > 0
            ? (
              <QueryChips
                domainType={domainType}
                currentQuery={currentQuery}
                overriderQueries={overriderQueries}
                domainTypeQueries={domainTypeQueries}
                renderContainer
                hideAddButton
                fetchTotal={async (api, query) => {
                  const [anyFilters, allFilters] = getAnyAllFilters(
                    domainTypes,
                    domainType,
                    query.FilterLinkOperator ?? 'and',
                    query.Filters,
                    query.SearchText ?? ''
                  )
                  const response = await api.search(
                    rootDomainType?.Name ?? domainType.DatabaseTable ?? '',
                    domainType.Name,
                    anyFilters,
                    allFilters,
                    query.Sorts,
                    1,
                    0
                  )
                  return pipe(
                    response,
                    E.map(
                      searchResult => searchResult.totalHits
                    )
                  )
                }}
                onApplyQuery={query => {
                  dispatch(push({
                    pathname: generatePath(PAGE_URL.FIND, {
                      databaseTable: domainType.DatabaseTable ?? domainType.Name,
                      name: domainType.Name
                    }),
                    search: new URLSearchParams({
                      filterLinkOperator: query.FilterLinkOperator ?? 'and',
                      filters: JSON.stringify(query.Filters),
                      sorts: JSON.stringify(query.Sorts),
                      searchText: query.SearchText ?? '',
                      queryId: query.Id
                    }).toString()
                  }))
                }} />
            )
            : null}
        </CardContent>
        <CardActions
          sx={{ p: 0 }} />
      </Card>
    </StyledGrid>
  )
}
