import { Box } from '@mui/material'
import { FormEvent } from 'react'
import { PathErrors, AttributeValue } from 'types'
import AttributeInput from '../AttributeInput/AttributeInput'

interface Props {
  readonly attributeValues: AttributeValue[]
  readonly readOnly?: boolean
  readonly disabled?: boolean
  readonly pathErrors: PathErrors
  onChange(attributeValue: AttributeValue): void
  onSubmit?(): void
}

export default function AttributeForm({
  attributeValues,
  readOnly = false,
  disabled = false,
  pathErrors,
  onChange,
  onSubmit
}: Props): JSX.Element {
  return (
    <Box
      component='form'
      display='flex'
      flexDirection='column'
      gap={1}
      autoComplete='off'
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onSubmit?.()
      }}>
      {attributeValues
        .filter(attributeValue => !(attributeValue.hidden ?? false))
        .map(attributeValue => (
          <AttributeInput
            key={attributeValue.attribute.Name}
            attributeValue={attributeValue}
            pathError={pathErrors[attributeValue.attribute.Name]}
            readOnly={readOnly}
            disabled={disabled}
            onChange={onChange} />
        ))
      }
      <input
        type='submit'
        hidden />
    </Box>
  )
}