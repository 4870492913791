import { Edit } from '@mui/icons-material'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import { NonListStringAttributeValue } from 'types'
import { toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'

export default function JSONInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<NonListStringAttributeValue>): JSX.Element {
  const [open, setOpen] = useState(false)
  const onClose = useCallback(() => setOpen(false), [])
  const handleChange = useCallback((value: string) => {
    onChange?.({
      attribute: attributeValue.attribute,
      value
    })
  }, [attributeValue.attribute, onChange])
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={onClose}>
        <DialogTitle>JSON Editor</DialogTitle>
        {error && (
          <Alert severity='error'>
            Entered text is not a valid JSON string
          </Alert>
        )}
        <DialogContent>
          <Box
            component='form'
            autoComplete='off'>
            <TextField
              sx={{ marginTop: 1 }}
              value={value}
              multiline
              rows={25}
              id={attributeValue.attribute.Name}
              label={attributeValue.attribute.Title}
              variant='outlined'
              fullWidth
              size='small'
              required={attributeValue.attribute.Required ?? false}
              error={pathError !== undefined}
              helperText={toErrorText(pathError)}
              onChange={event => setValue(event.target.value)}
              inputProps={{
                readOnly
              }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}>
            Discard Edits
          </Button>
          <Button
            onClick={() => {
              try {
                JSON.parse(value)
                setError(false)
                handleChange(value)
                setOpen(false)
              } catch {
                setError(true)
              }
            }}>
            Apply Edits
          </Button>
        </DialogActions>
      </Dialog>
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant='standard'
        fullWidth
        size='small'
        value={attributeValue.value}
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='open JSON dialog'
                edge='end'
                onClick={() => {
                  setValue(attributeValue.value ?? '')
                  setOpen(true)
                }}>
                <Edit />
              </IconButton>
            </InputAdornment>
          )
        }} />
    </>
  )
}
