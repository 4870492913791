import * as E from 'fp-ts/Either'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeValue, DomainType, DomainTypeInstance, PathErrors } from 'types'
import { NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED } from 'utils/constants'
import { getDomainTypeSetting, getRootDomainType, validateRequiredAttributes } from 'utils/helpers'
import { useApi } from './useApi'
import { useDomainTypeForm, UseDomainTypeFormOutput } from './useDomainTypeForm'
import { useEditListAttribute } from './useEditListAttribute'
import { usePathErrors } from './usePathErrors'

interface UseEditOutput extends UseDomainTypeFormOutput {
  isEditing: boolean
  pathErrors: PathErrors
  errorCode: string
  createInstance(attributeValues: AttributeValue[]): DomainTypeInstance
  onEdit(): void
}

export function useEdit(
  instanceDomainType: DomainType,
  instance: DomainTypeInstance | null,
  formMode: 'create' | 'edit',
  outerPathErrors: PathErrors | undefined,
  onEditSuccess: (instance: DomainTypeInstance) => void,
  bypassApi = false
): UseEditOutput {
  const {
    pathErrors = {},
    setPathErrors,
    removeErrorAtPath
  } = usePathErrors(outerPathErrors ?? {})
  const {
    attributeValues,
    createInstance,
    onChange,
    onReset: onResetForm,
    ...rest
  } = useDomainTypeForm(instanceDomainType, instance, formMode, undefined, removeErrorAtPath)

  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, instanceDomainType)
  const api = useApi()
  const [isEditing, setIsEditing] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const isApiDomainType = getDomainTypeSetting(domainTypes, instanceDomainType, 'Api') ?? false
  const onSingleEditSuccess = useCallback((instances: DomainTypeInstance[]) => {
    const singleInstance = instances[0]
    if (singleInstance !== undefined) {
      onEditSuccess(singleInstance)
    }
  }, [onEditSuccess])
  const {
    isEditing: isEditingListAttribute,
    onEditListAttribute
  } = useEditListAttribute(instanceDomainType, onSingleEditSuccess)
  const onEdit = useCallback(() => {
    const requiredPathErrors = validateRequiredAttributes(attributeValues)
    if (requiredPathErrors !== undefined) {
      setPathErrors(requiredPathErrors)
      setErrorCode(NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED)
      return
    }
    if (!isApiDomainType || bypassApi) {
      if (!bypassApi) {
        onEditListAttribute([
          {
            original: instance,
            edited: createInstance(attributeValues)
          }
        ])
      } else {
        onEditSuccess(createInstance(attributeValues))
      }
      return
    }
    setPathErrors({})
    setErrorCode('')
    async function edit() {
      if (rootDomainType === null || !api.isSignedIn) {
        return
      }
      setIsEditing(true)
      const response = await api.put(
        rootDomainType.Name,
        createInstance(attributeValues)
      )
      if (E.isRight(response)) {
        onEditSuccess(response.right)
      } else {
        setPathErrors(response.left.pathErrors ?? {})
        setErrorCode(response.left.errorCode)
      }
      setIsEditing(false)
    }
    edit()
  }, [attributeValues, isApiDomainType, bypassApi, setPathErrors, onEditListAttribute, instance, createInstance, onEditSuccess, rootDomainType, api])
  const onReset = useCallback(() => {
    onResetForm()
    setPathErrors(outerPathErrors ?? {})
    setErrorCode('')
  }, [onResetForm, outerPathErrors, setPathErrors])
  return {
    ...rest,
    isEditing: isEditing || isEditingListAttribute,
    attributeValues,
    pathErrors,
    errorCode,
    createInstance,
    onChange,
    onEdit,
    onReset
  }
}