import { Box, Snackbar, Stack } from '@mui/material'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ApiError, DomainType, DomainTypeInstance, Filter, Query, Sort } from 'types'
import { DomainTypeOverriderContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getDomainTypeSetting } from 'utils/helpers'
import { SignedInApi, SnackPack, useDomainTypeSetting } from 'utils/hooks'
import QueryChips from '../../domainType/QueryChips'
import CalendarView from './CalendarView'
import CardsView from './CardsView'
import FindToolbar from './FindToolbar'
import TableView, { getColumns } from './TableView'
import TimelineView from './TimelineView'
import filtersReducer, { addFilter } from './filtersReducer'
import { CalendarProps, FindPageView } from './types'

interface Props {
  domainType: DomainType
  isLoading: boolean
  hideColumns?: string[]
  items: DomainTypeInstance[]
  total: number
  calendarItems: DomainTypeInstance[]
  page: number
  pageSize: number
  searchText: string
  sorts: Sort[]
  filters: Filter[]
  permanentFilters?: Filter[]
  permanentFilterOptions?: string[]
  selectedPermanentFilterOption?: number
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  currentQuery: Query
  isExporting: boolean
  view: FindPageView
  calendarProps: CalendarProps
  highlightedRowIds?: string[]
  hideHeading?: boolean
  filterLinkOperator: 'and' | 'or'
  checkedRowIds: string[]
  autoFocusSearchInput?: boolean
  snackPack: SnackPack
  onSearch(): void
  onFiltersChange(value: Filter[]): void
  onPageChange(value: number): void
  onPageSizeChange(value: number): void
  onSortsChange(value: Sort[]): void
  onRowClick(id: string): void
  onApplyQuery(query: Query): void
  onClickExport?(columns: string[]): void
  onViewChange(value: FindPageView): void
  onSearchTextChange?(value: string): void
  onFilterLinkOperatorChange(value: 'and' | 'or'): void
  onCheckedRowIdsChange(ids: string[]): void
  onPermanentFilterOptionChange?(index: number): void
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
}

export default function FindView({
  isLoading,
  domainType,
  hideColumns,
  items,
  total,
  calendarItems,
  page,
  pageSize,
  searchText,
  sorts,
  filters,
  overriderQueries,
  domainTypeQueries,
  currentQuery,
  isExporting,
  view,
  calendarProps,
  highlightedRowIds,
  hideHeading,
  filterLinkOperator,
  checkedRowIds,
  autoFocusSearchInput = false,
  permanentFilters,
  permanentFilterOptions,
  selectedPermanentFilterOption,
  snackPack,
  onPermanentFilterOptionChange,
  onSearch,
  onSearchTextChange,
  onFiltersChange,
  onPageChange,
  onPageSizeChange,
  onSortsChange,
  onRowClick,
  onApplyQuery,
  onClickExport,
  onViewChange,
  onFilterLinkOperatorChange,
  onCheckedRowIdsChange,
  fetchTotal
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const columns = useMemo(() => getColumns(
    domainTypes,
    [domainType],
    overriders,
    [],
    false
  ), [domainType, domainTypes, overriders])
  const checkedItems = useMemo(() => {
    if (checkedRowIds.length === 0) {
      return []
    }
    return items.filter(item => {
      const itemId = String(item[getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'])
      return checkedRowIds.includes(itemId)
    })
  }, [checkedRowIds, domainType, domainTypes, items])
  const filtersRef = useRef({
    filters,
    filterLinkOperator
  })
  useEffect(() => {
    filtersRef.current.filterLinkOperator = filterLinkOperator
    filtersRef.current.filters = filters
  }, [filterLinkOperator, filters])
  const [panelOpen, setPanelOpen] = useState<string | false>(false)
  const lastPanelOpenValueRef = useRef<string | false>(panelOpen)

  useEffect(() => {
    if (lastPanelOpenValueRef.current === 'filters') {
      if (filtersRef.current.filterLinkOperator !== filterLinkOperator) {
        onFilterLinkOperatorChange(filtersRef.current.filterLinkOperator)
      }
      if (filtersRef.current.filters !== filters) {
        onFiltersChange(filtersRef.current.filters)
      }
    }
    lastPanelOpenValueRef.current = panelOpen
  }, [panelOpen, lastPanelOpenValueRef, filters, onFiltersChange, onFilterLinkOperatorChange, filterLinkOperator])
  const [, editColumns] = useDomainTypeSetting(
    domainType,
    'Columns',
    []
  )
  const setColumnVisibility = useCallback((field: string, isVisible: boolean) => {
    editColumns(
      columns
        .filter(column => {
          return (!(column.hide ?? false) && field !== column.field)
            || (field === column.field && isVisible) || column.field === 'actions'
        })
        .map(column => column.field)
    )
  }, [columns, editColumns])
  const [selectedColumn, setSelectedColumn] = useState<string>()
  const onAddFilter = useCallback((filter: Filter): void => {
    filtersRef.current.filters = filtersReducer(filtersRef.current.filters, addFilter(filter))
    setSelectedColumn(filter.Property)
    setPanelOpen(panelOpen === 'filters' ? false : 'filters')
  }, [panelOpen])
  const onClickFilterIcon = useCallback((column: string): void => {
    setPanelOpen(panelOpen === 'filters' ? false : 'filters')
    setSelectedColumn(column)
  }, [panelOpen])
  const currentQueryToAdd = useMemo(() => ({
    ...currentQuery,
    Filters: filters,
    Sorts: sorts,
    SearchText: searchText,
    FilterLinkOperator: filterLinkOperator
  }), [currentQuery, filterLinkOperator, filters, searchText, sorts])
  const {
    open: snackbarOpen,
    message,
    handleClose: handleSnackbarClose,
    handleExited
  } = snackPack
  return (
    <Stack>
      <Stack
        gap={1}
        direction='row'
        alignItems='center'
        sx={{
          mt: 1,
          mb: 1,
          minHeight: '40px'
        }}>
        <DomainTypeHeading
          domainType={domainType}
          isLoading={false}
          plural
          title='Find:'
          hideHeading={hideHeading}
          flexWrap='wrap'>
          <QueryChips
            domainType={domainType}
            currentQuery={currentQueryToAdd}
            overriderQueries={overriderQueries}
            domainTypeQueries={domainTypeQueries}
            fetchTotal={fetchTotal}
            onApplyQuery={onApplyQuery} />
        </DomainTypeHeading>
      </Stack>
      <Box>
        <FindToolbar
          columns={columns}
          panelOpen={panelOpen}
          setPanelOpen={setPanelOpen}
          disableFilters={false}
          filterLinkOperator={filterLinkOperator}
          filters={filters}
          permanentFilters={permanentFilters}
          permanentFilterOptions={permanentFilterOptions}
          selectedPermanentFilterOption={selectedPermanentFilterOption}
          onPermanentFilterOptionChange={onPermanentFilterOptionChange}
          isExporting={isExporting}
          onClickExport={onClickExport}
          domainType={domainType}
          checkedItems={checkedItems}
          searchText={searchText}
          autoFocusSearchInput={autoFocusSearchInput}
          onSearch={onSearch}
          onSearchTextChange={onSearchTextChange}
          onFiltersChange={onFiltersChange}
          setColumnVisibility={setColumnVisibility}
          filtersRef={filtersRef}
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          view={view}
          onViewChange={onViewChange}
          calendarProps={calendarProps} />
        {view === 'table' && (
          <TableView
            items={items}
            domainType={domainType}
            isLoading={isLoading}
            sorts={sorts}
            page={page}
            pageSize={pageSize}
            total={total}
            checkedRowIds={checkedRowIds}
            checkedItems={checkedItems}
            highlightedRowIds={highlightedRowIds}
            hideColumns={hideColumns}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onSortsChange={onSortsChange}
            onRowClick={onRowClick}
            onCheckedRowIdsChange={onCheckedRowIdsChange}
            onColumnVisibilityChange={setColumnVisibility}
            onAddFilter={onAddFilter}
            filters={filters}
            onClickFilterIcon={onClickFilterIcon} />
        )}
        {view === 'cards' && (
          <CardsView
            domainType={domainType}
            items={items}
            page={page}
            pageSize={pageSize}
            total={total}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onCardClick={onRowClick}
            isLoading={isLoading} />
        )}
        {view === 'calendar' && (
          <CalendarView
            isLoading={isLoading}
            domainType={domainType}
            items={calendarItems}
            snackPack={snackPack}
            onItemClick={onRowClick}
            setPanelOpen={setPanelOpen}
            {...calendarProps} />
        )}
        {view === 'timeline' && (
          <TimelineView
            isLoading={isLoading}
            domainType={domainType}
            items={calendarItems}
            snackPack={snackPack}
            onItemClick={onRowClick}
            setPanelOpen={setPanelOpen}
            {...calendarProps} />
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionProps={{
          onExited: handleExited
        }}
        message={message} />
    </Stack>
  )
}