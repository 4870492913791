import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { getDomainType } from 'utils/helpers'

export function useDomainType(
  name: string,
  databaseTable: string | null
): DomainType | null {
  const domainTypes = useSelector(getAllDomainTypes)
  return useMemo(() => {
    return getDomainType(domainTypes, name, databaseTable)
  }, [databaseTable, domainTypes, name])
}