import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { DomainType, DomainTypeInstance, NonListRefAttributeValue, PathError } from 'types'
import { getDomainTypeSetting, getHeading, toErrorText } from 'utils/helpers'
import { useApiDomainTypeAutocomplete } from 'utils/hooks'
import DomainTypeCell from '../../AttributeCell/DomainTypeCell'

interface NonListRefProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: NonListRefAttributeValue
  readonly disabled?: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: NonListRefAttributeValue): void
}

export default function NonListRefInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  pathError,
  onChange
}: NonListRefProps): JSX.Element | null {
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const includeIds = useMemo(() => typeof attributeValue.value === 'string' && attributeValue.value !== ''
    ? [attributeValue.value]
    : [], [attributeValue.value])
  const {
    open,
    setOpen,
    options,
    loading,
    searchText,
    setSearchText
  } = useApiDomainTypeAutocomplete(
    domainTypes,
    domainType,
    attributeValue.attribute.Filters,
    includeIds
  )
  const handleChange = useCallback((event: unknown, value: DomainTypeInstance | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: value === null
        ? null
        : String(value[identifier])
    })
  }, [attributeValue.attribute, identifier, onChange])
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      disabled={disabled}
      onChange={handleChange}
      isOptionEqualToValue={(option: DomainTypeInstance, value: DomainTypeInstance) => option[identifier] === value[identifier]}
      renderOption={(props, option) => (
        <li {...props}>
          <DomainTypeCell
            attributeValue={{
              attribute: {
                ...attributeValue.attribute,
                AttributeType: 'domainType'
              },
              value: option
            }}
            disableLink />
        </li>
      )}
      filterOptions={options => options}
      getOptionLabel={option => getHeading(domainTypes, domainType, option)}
      value={options.find(option => option[identifier] === attributeValue.value) ?? null}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading
                  ? (
                    <CircularProgress
                      color='inherit'
                      size={20} />
                  )
                  : null}
                {params.InputProps.endAdornment}
              </>
            )
          }} />
      )} />
  )
}