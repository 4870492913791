import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import { alpha, styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { CSSProperties } from 'react'

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden'
}))

const SimpleBarStyle = styled(SimpleBar)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48)
    },
    '&.simplebar-visible:before': {
      opacity: 1
    }
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6
  },
  '& .simplebar-mask': {
    zIndex: 'inherit'
  }
}))

interface Props {
  children: React.ReactNode
  style?: CSSProperties | undefined
}
export default function ScrollBar({ children, style }: Props): JSX.Element {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent

  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

  if (isMobile) {
    return (
      <Box
        sx={{ overflowX: 'auto' }}>
        {children}
      </Box>
    )
  }

  return (
    <RootStyle>
      <SimpleBarStyle
        autoHide={true}
        clickOnTrack={false}
        style={style}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  )
}