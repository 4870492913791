import { DragHandle } from '@mui/icons-material'
import { Box } from '@mui/material'
import ButtonsPopover from 'components/domainType/ButtonsPopover'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { JSXElementConstructor, useCallback, useMemo, useRef } from 'react'
import { DomainTypeInstance } from 'types'
import { Dataform, Element, ElementType, ElementValueTypes, Group } from 'types/dataform'
import { isNullOrUndefined } from 'utils/helpers'
import { useDomainType, useDragToReorder } from 'utils/hooks'
import { ElementState, defaultElementState, isElementActive } from './dataformReducer'
import * as Elements from './elements'

interface Props<E extends Element> {
  readonly dataform: Dataform
  readonly state: ElementState<E> | undefined
  readonly group: Group
  readonly element: E
  readonly elementOrder: string[]
  onElementValueChange(element: E, value: ElementValueTypes[E['ElementType']] | null): void
  onChangeElementOrder(elementOrder: string[]): void
  onCommitElementOrder(elementOrder: string[]): void
  readonly formReadOnly?: boolean
  readonly showRequiredErrors: boolean
  readonly editable: boolean
}

export interface ElementProps<E extends Element> {
  readonly dataform: Dataform
  readonly group: Group
  readonly element: E
  onChange(value: ElementValueTypes[E['ElementType']] | null): void
  readonly value: ElementValueTypes[E['ElementType']] | null
  readonly required: boolean
  readonly readOnly: boolean
  readonly locked: boolean
  readonly expectedValue: RegExp | null
  readonly error: boolean
  readonly errorText: string | null
}

type ElementTypeComponents = {
  [Key in ElementType]: JSXElementConstructor<ElementProps<Element & { ElementType: Key }>>
}

const elementTypeComponents: ElementTypeComponents = {
  [ElementType.Radio]: Elements.Radio,
  [ElementType.Checkbox]: Elements.Checkbox,
  [ElementType.Number]: Elements.Number,
  [ElementType.TextBox]: Elements.TextBox,
  [ElementType.TextBoxWithQuestion]: Elements.TextBoxWithQuestion,
  [ElementType.Statement]: Elements.Statement,
  [ElementType.Dropdown]: Elements.Dropdown,
  [ElementType.InlineRadio]: Elements.InlineRadio,
  [ElementType.Date]: Elements.Date,
  [ElementType.DateTime]: Elements.DateTime,
  [ElementType.Signature]: Elements.Signature,
  [ElementType.Initials]: Elements.Initials,
  [ElementType.Percentage]: Elements.Percentage,
  [ElementType.Image]: Elements.Image,
  [ElementType.Photos]: Elements.Photos,
  [ElementType.Markup]: Elements.Markup,
  [ElementType.Equipment]: Elements.Equipment,
  [ElementType.Time]: Elements.Time,
  [ElementType.Dataform]: Elements.Dataform
}

function getComponent<E extends Element>(element: E): JSXElementConstructor<ElementProps<E>> {
  return elementTypeComponents[element.ElementType] as JSXElementConstructor<ElementProps<E>>
}

function getErrorText(
  element: Element,
  state: ElementState | undefined,
  showRequiredErrors: boolean
): string | null {
  const errors = ((showRequiredErrors && state?.requiredError === true)
    ? [`${element.Text} is required`]
    : [])
    .concat(((state?.constraintError ?? false)
      ? [element.Constraint?.Message ?? 'Value does not match constraint']
      : []))
    .concat(Array.isArray(state?.circularDependency)
      ? ['Element has circular dependency']
      : [])
  const errorText = errors.join(', ') || null
  return errorText
}

export default function ElementInput<E extends Element>(props: Props<E>): JSX.Element | null {
  const elementDomainType = useDomainType('DataformElement', 'DataformElement')
  const Component = getComponent(props.element)
  const state = {
    ...defaultElementState,
    ...props.state
  }
  // TODO - lock on complete setting
  const locked = state.lockedByMilestones.length > 0
    || state.lockedByElements.length > 0
    || !isElementActive(state)
  const errorText = getErrorText(props.element, props.state, props.showRequiredErrors)
  const dropRef = useRef<HTMLDivElement | null>(null)
  const {
    drag,
    preview,
    drop,
    opacity
  } = useDragToReorder(
    props.element.Id,
    props.elementOrder,
    'element',
    dropRef,
    'vertical',
    props.onChangeElementOrder,
    props.onCommitElementOrder
  )
  const element = props.element
  const onElementValueChange = props.onElementValueChange
  const onChange = useCallback((value: ElementValueTypes[E['ElementType']] | null) => {
    return onElementValueChange(element, value)
  }, [onElementValueChange, element])
  const component = useMemo(() => {
    return (
      <Component
        dataform={props.dataform}
        group={props.group}
        element={element}
        onChange={onChange}
        value={props.state?.value ?? null}
        required={state.required}
        readOnly={props.formReadOnly === true || state.readOnly === true}
        locked={locked}
        expectedValue={state.expectedValue ?? null}
        error={errorText !== null}
        errorText={errorText} />

    )
  }, [Component, element, errorText, locked, onChange, props.dataform, props.formReadOnly, props.group, props.state?.value, state.expectedValue, state.readOnly, state.required])
  const buttonsPopover = useMemo(() => {
    if (isNullOrUndefined(elementDomainType)) {
      return null
    }
    return (
      <ButtonsPopover
        domainType={elementDomainType}
        instances={[props.element] as unknown[] as DomainTypeInstance[]}
        on='TableRow' />
    )
  }, [elementDomainType, props.element])
  const dragHandle = useMemo(() => {
    return (
      <TooltipIconButton
        icon={<DragHandle />}
        sx={{ cursor: 'move' }}
        tooltipText=''
        onClick={() => undefined} />
    )
  }, [])
  return (
    <div
      ref={node => {
        dropRef.current = node
        preview(drop(node))
      }}>
      <Box
        marginTop={1}
        marginBottom={1}
        gap={1}
        display='flex'
        justifyContent='space-between'
        sx={{ opacity }}>
        <Box flexGrow={1}>
          {component}
        </Box>
        {props.editable && (
          <>
            <div ref={drag}>
              {dragHandle}
            </div>
            {buttonsPopover}
          </>
        )}
      </Box>
    </div>
  )
}