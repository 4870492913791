import { Chip, Grid, Paper, Stack, Typography } from '@mui/material'
import { Dashboard } from 'components/dashboard'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import { useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeOverrider, Query } from 'types'
import { DomainTypeOverriderContext } from 'utils/context'
import { getDomainTypeSetting, getOverriddenDomainTypeSetting } from 'utils/helpers'
import { filterDashboardsForPage } from 'utils/helpers/dashboard'
import { useDashboards, useDomainType, useMenuSections } from 'utils/hooks'
import QueryCard from './QueryCard'

function getDomainTypeQueries(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[]
): Query[] {
  return (getDomainTypeSetting(domainTypes, domainType, 'Queries') ?? [])
    .concat(getOverriddenDomainTypeSetting(domainTypes, domainType, overriders, 'Queries') ?? [])
}

export default function HomePage(): JSX.Element | null {
  const queryDomainType = useDomainType('Query', null)
  const dashboardDomainType = useDomainType('Dashboard', 'Metadata')
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const menuSections = useMenuSections()
  const dashboards = useDashboards()
  const filteredDashboards = useMemo(() => {
    return filterDashboardsForPage(dashboards, 'home')
  }, [dashboards])
  const [dashboardIndex, setDashboardIndex] = useState(0)
  const visibleDashboard = useMemo(() => {
    return filteredDashboards[dashboardIndex]
  }, [dashboardIndex, filteredDashboards])
  const domainTypes = useSelector(getAllDomainTypes)
  const hasNoQueries = useMemo(() => {
    return menuSections
      .every(section => section.items
        .every(item => getDomainTypeQueries(domainTypes, item.domainType, overriders).length === 0))
  }, [domainTypes, menuSections, overriders])
  return (
    <Stack
      direction='column'
      gap={2}>
      <Paper sx={{ p: 1 }}>
        <Stack
          direction='column'
          gap={1}>
          {queryDomainType && (
            <DomainTypeHeading
              domainType={queryDomainType}
              isLoading={false}
              plural />
          )}
          {hasNoQueries
            ? (
              <Typography variant='body1'>
                Queries provide quick access to commonly performed searches. You can save custom queries on any find page and they will appear here.
              </Typography>
            )
            : menuSections.map((section, index) => {
              if (section.items
                .every(item => getDomainTypeQueries(domainTypes, item.domainType, overriders).length === 0)) {
                return null
              }
              return (
                <Grid
                  key={index}
                  container
                  spacing={1}>
                  {section.items.map(item => (
                    <QueryCard
                      key={item.domainType.Id}
                      domainType={item.domainType} />
                  ))}
                </Grid>
              )
            })}
        </Stack>
      </Paper>
      <Paper sx={{ p: 1 }}>
        <Stack
          direction='column'
          gap={1}>
          {dashboardDomainType && (
            <DomainTypeHeading
              domainType={dashboardDomainType}
              isLoading={false}
              plural>
              {filteredDashboards
                .map((dashboard, index) => (
                  <Chip
                    key={dashboard.Subcategory}
                    label={dashboard.Value}
                    color={visibleDashboard?.Subcategory === dashboard.Subcategory
                      ? 'primary'
                      : undefined}
                    onClick={() => setDashboardIndex(index)} />
                ))}
            </DomainTypeHeading>
          )}
          {filteredDashboards.length === 0 && (
            <Typography variant='body1'>
              Dashboards display your data in charts. They will appear here once created by an administrator to anyone with permission to view them.
            </Typography>
          )}
          {visibleDashboard !== undefined && (
            <Dashboard
              key={visibleDashboard.Value}
              dashboard={visibleDashboard} />
          )}
        </Stack>
      </Paper>
    </Stack>
  )
}