import { Box, FormControl, FormHelperText, FormLabel, ToggleButton } from '@mui/material'
import { useSelector } from 'react-redux'
import { getPerson } from 'state/reducers'
import { InitialsElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'

export default function Initials({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  expectedValue,
  error,
  errorText
}: ElementProps<InitialsElement>): JSX.Element | null {
  const person = useSelector(getPerson)
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={(expectedValue?.test('false') ?? false) || error}>
      <FormLabel>{element.Text}</FormLabel>
      <Box>
        <ToggleButton
          sx={{ minWidth: 150 }}
          value='check'
          size='small'
          color={expectedValue?.test('false') ?? false
            ? 'error'
            : 'primary'}
          selected={value !== null}
          disabled={readOnly || locked}
          onChange={() => {
            if (value !== null) {
              onChange(null)
            } else {
              onChange(person?.FullName ?? null)
            }
          }}>
          {value === null
            ? 'Click to fill'
            : value.match(/\b(\w)/g)?.join('.')}
        </ToggleButton>
      </Box>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}