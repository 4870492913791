import { AddOutlined } from '@mui/icons-material'
import { Autocomplete, Box, Button, Checkbox, Chip, Paper, TextField } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { HTMLAttributes, PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { EnumeratedValue, ListEnumAttributeValue } from 'types'
import { fixMultipleAutocompleteRequiredBehaviour, getSortedEnumeratedValues, toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import LabelledInput from '../LabelledInput'

function PaperComponent({
  onClick,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLElement>>): JSX.Element {
  return (
    <Paper {...props}>
      <Box
        p={1}>
        <Button
          variant='text'
          startIcon={<AddOutlined />}
          onMouseDown={event => {
            event.preventDefault()
          }}
          onClick={onClick}>
          Add All
        </Button>
      </Box>
      {props.children}
    </Paper>
  )
}

export default function ListEnumInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  onChange
}: NarrowedProps<ListEnumAttributeValue>): JSX.Element {
  const enumeratedValues = useMemo(() => {
    return getSortedEnumeratedValues(attributeValue.attribute.EnumeratedType)
  }, [attributeValue.attribute.EnumeratedType])
  const selectedValues = useMemo(() => {
    const stringValues = (attributeValue.value ?? []).map(String)
    return enumeratedValues.filter(ev => stringValues.includes(ev.Value))
  }, [attributeValue.value, enumeratedValues])
  const handleChange = useCallback((event: unknown, value: EnumeratedValue[] | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: value
        ?.map(enumeratedValue => enumeratedValue.Value) ?? null
    })
  }, [attributeValue.attribute, onChange])
  const [inputValue, setInputValue] = useState('')
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      multiple
      disabled={disabled}
      onChange={handleChange}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.Value}>
            <Checkbox
              size='small'
              sx={{
                width: 24,
                height: 24,
                marginRight: '8px'
              }}
              checked={selected} />
            {option.Description}
          </li>
        )
      }}
      inputValue={inputValue}
      onInputChange={(event, value, reason) => {
        if (reason !== 'reset') {
          setInputValue(value)
        }
      }}
      PaperComponent={PaperComponent}
      componentsProps={{
        paper: {
          onClick: () => handleChange(null, enumeratedValues)
        }
      }}
      getOptionLabel={(option: EnumeratedValue) => option.Description}
      value={selectedValues}
      options={enumeratedValues}
      readOnly={readOnly}
      renderTags={(value, getTagProps, ownerState) => {
        const numTags = value.length
        const limitTags = 1
        return (
          <>
            {value.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                onDelete={undefined}
                key={index}
                size='small'
                label={ownerState.getOptionLabel?.(option)} />
            ))}
            {numTags > limitTags && ` +${numTags - limitTags}`}
            &nbsp;
          </>
        )
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          onKeyDown={event => {
            if (event.key === 'Backspace') {
              event.stopPropagation()
            }
          }}
          fullWidth
          inputProps={fixMultipleAutocompleteRequiredBehaviour(params, attributeValue, selectedValues)} />
      )} />
  )
}
