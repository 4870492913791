import queryString from 'query-string'
import { Action, PERFORM_SIGN_IN_FULFILLED, SIGNOUT, SWITCH_COMPANY, User } from 'types'
import { UserCodec } from 'utils/codecs'

function getCompanyToken(user: User, companyId: string | undefined): string {
  return user.companies.find(c => c.id === companyId)?.token ?? ''
}

const getDefaultState = (): User | null => {
  const userString = window.localStorage.getItem('user')
  const selectedCompanyId = window.localStorage.getItem('selectedCompanyId')
  if (userString == null) {
    return null
  }
  let user = JSON.parse(userString)
  if (!UserCodec.is(user)) {
    return null
  }
  if (selectedCompanyId != null) {
    user.selectedCompanyId = selectedCompanyId
  }

  const queryParameters = queryString.parse(window.location.search)
  const companyId = queryParameters.company
  if (typeof companyId === 'string') {
    user = {
      ...user,
      token: user.token,
      selectedCompanyId: companyId
    }

    user.token = getCompanyToken(user, user.selectedCompanyId)
  }
  window.localStorage.user = JSON.stringify(user)
  return user
}

export default (state: User | null = getDefaultState(), action: Action): User | null => {
  switch (action.type) {
    case PERFORM_SIGN_IN_FULFILLED: {
      const user = {
        ...action.payload.response
      }

      const previouslySelectedCompany = window.localStorage.getItem('selectedCompanyId')
      if (typeof previouslySelectedCompany === 'string' && getCompanyToken(user, previouslySelectedCompany) !== '') {
        user.selectedCompanyId = previouslySelectedCompany
      } else {
        user.selectedCompanyId = user.companies[0]?.id ?? ''
      }

      user.token = getCompanyToken(user, user.selectedCompanyId)
      window.localStorage.setItem('selectedCompanyId', user.selectedCompanyId)
      window.localStorage.setItem('user', JSON.stringify(user))
      return user
    }
    case SWITCH_COMPANY: {
      if (!state) {
        return state
      }
      const selectedCompanyId = action.payload.companyId
      const newUser = {
        ...state,
        token: getCompanyToken(state, selectedCompanyId),
        selectedCompanyId
      }

      window.localStorage.setItem('selectedCompanyId', selectedCompanyId)
      window.localStorage.setItem('user', JSON.stringify(newUser))
      return newUser
    }
    case SIGNOUT: {
      window.localStorage.removeItem('user')
      return null
    }
    default:
      return state
  }
}

export function getUser(state: User | null): User | null {
  return state
}
