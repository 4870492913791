import MultiResultsInput from 'components/dataform/MultiResultsInput'
import { useMultiDataformResults } from 'utils/hooks'
import { MultiDataformResultsSection as MultiDataformResultsSectionType } from './useDetails'

interface Props {
  section: MultiDataformResultsSectionType
  isLoading: boolean
}

export default function MultiDataformResultsSection({
  section,
  isLoading
}: Props): JSX.Element | null {
  const [multiResults, errorMessage] = useMultiDataformResults(section.attributeValue, true)
  return (
    <MultiResultsInput
      key={JSON.stringify(section.attributeValue.value)}
      initialMultiResults={multiResults}
      attribute={section.attributeValue.attribute}
      errorMessage={errorMessage}
      readOnly
      isLoading={isLoading || (multiResults.length === 0 && errorMessage === undefined)} />
  )
}