import AttributeCell from 'components/attribute/AttributeCell'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { getAttributeValue, getEquipmentAttributes, getValue } from 'utils/helpers'
import { DomainTypeComponentProps } from '..'

interface CountProps {
  quantity: number | null
}
function Quantity({
  quantity: count
}: CountProps): JSX.Element | null {
  return count !== null
    ? (
      <>&nbsp;×&nbsp;{count}</>
    )
    : null
}

export default function EquipmentCell({
  attributeValue,
  columnWidth
}: DomainTypeComponentProps['cell']): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  if (attributeValue.value === null) {
    return null
  }
  const {
    isSerialisedAttribute,
    quantityAttribute,
    equipmentTypeAttribute,
    serialisedEquipmentAttribute
  } = getEquipmentAttributes(domainTypes)
  const isSerialised = getValue(attributeValue.value, isSerialisedAttribute) ?? false
  const quantity = getValue(attributeValue.value, quantityAttribute)
  if (isSerialised) {
    const serialisedEquipmentAttributeValue = getAttributeValue(attributeValue.value, serialisedEquipmentAttribute)
    return (
      <AttributeCell
        attributeChainValue={serialisedEquipmentAttributeValue}
        columnWidth={columnWidth} />
    )
  }
  const equipmentTypeAttributeValue = getAttributeValue(attributeValue.value, equipmentTypeAttribute)
  return (
    <>
      <AttributeCell
        attributeChainValue={equipmentTypeAttributeValue}
        columnWidth={columnWidth} />
      <Quantity quantity={quantity} />
    </>
  )
}