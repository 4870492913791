import { DeleteOutlined } from '@mui/icons-material'
import { Chip, chipClasses, CircularProgress, Portal, Stack } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ApiError, DomainType, Query } from 'types'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getRootDomainType } from 'utils/helpers'
import { SignedInApi, useApi, useCancellableApiSession } from 'utils/hooks'
import { v4 } from 'uuid'

interface QueryChipProps {
  readonly domainType: DomainType
  readonly query: Query
  readonly active: boolean
  readonly overriderDetails?: OverriderDetails
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

function QueryChip({
  domainType,
  query,
  active,
  overriderDetails,
  fetchTotal,
  onApplyQuery
}: QueryChipProps): JSX.Element {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const api = useApi()
  const search = useCancellableApiSession(api)
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType)
  const [total, setTotal] = useState<number | null>(null)
  const [currentDomainType, setCurrentDomainType] = useState(domainType)
  const [performedQuery, setPerformedQuery] = useState(false)
  useEffect(() => {
    if (performedQuery === true && currentDomainType === domainType) {
      return
    }
    const apiSession = search.cancelPreviousAndStartNew()
    async function doFetch() {
      if (rootDomainType === null || !apiSession.isSignedIn) {
        return search.cancel
      }
      const response = await fetchTotal(apiSession, query)
      if (E.isRight(response)) {
        setPerformedQuery(true)
        setCurrentDomainType(domainType)
        setTotal(response.right)
      }
    }
    doFetch()
    return search.cancel
  }, [api, fetchTotal, query, rootDomainType, search, performedQuery, currentDomainType, domainType])
  return (
    <>
      <Chip
        key={query.Id}
        sx={{
          [`& .${chipClasses.label}`]: {
            display: 'flex',
            gap: 1,
            alignItems: 'center'
          }
        }}
        label={(
          <>
            <span>{query.Title}</span>
            {total === null
              ? (
                <CircularProgress
                  size='1rem'
                  color='inherit' />
              )
              : total}
          </>
        )}
        color={active
          ? 'primary'
          : undefined}
        onClick={event => {
          event.stopPropagation()
          onApplyQuery(query)
        }}
        onDelete={query.Id !== 'all'
          ? (() => setDeleteDialogOpen(true))
          : undefined}
        deleteIcon={<DeleteOutlined />} />
      <Portal>
        <span
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='Queries'
            value={[query]}
            disabled={query.Id === 'all'}
            mode='delete'
            saveTo={overriderDetails ?? 'domainType'}
            title='Query'
            dialogOpen={deleteDialogOpen}
            onChangeDialogOpen={setDeleteDialogOpen} />
        </span>
      </Portal>
    </>
  )
}

interface Props {
  domainType: DomainType
  currentQuery: Query
  overriderQueries: [Query, OverriderDetails][]
  domainTypeQueries: Query[]
  hideAddButton?: boolean
  renderContainer?: boolean
  fetchTotal(api: SignedInApi, query: Query): Promise<E.Either<ApiError, number>>
  onApplyQuery(query: Query): void
}

export default function QueryChips({
  domainType,
  currentQuery,
  overriderQueries,
  domainTypeQueries,
  hideAddButton = false,
  renderContainer = false,
  fetchTotal,
  onApplyQuery
}: Props): JSX.Element {
  const value = useMemo(() => [
    {
      ...currentQuery,
      Id: v4(),
      Title: ''
    }
  ], [currentQuery])
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)

  const contents = useMemo(() => (
    <>
      {domainTypeQueries
        .map(query => (
          <QueryChip
            key={query.Id}
            domainType={domainType}
            query={query}
            active={query.Id === currentQuery.Id}
            fetchTotal={fetchTotal}
            onApplyQuery={onApplyQuery} />
        ))}
      {overriderQueries
        .map(([query, overriderDetails]) => (
          <QueryChip
            key={query.Id}
            domainType={domainType}
            query={query}
            active={query.Id === currentQuery.Id}
            overriderDetails={overriderDetails}
            fetchTotal={fetchTotal}
            onApplyQuery={onApplyQuery} />
        ))}
      {!hideAddButton && (
        <>
          <Chip
            key={currentQuery.Id}
            label='Add Query'
            variant='outlined'
            color='primary'
            onClick={() => setSaveDialogOpen(true)} />
          <SaveDomainTypeSettingButton
            domainType={domainType}
            setting='Queries'
            value={value}
            title='Query'
            onSaveSuccess={value => {
              if (value?.length === 1) {
                const firstValue = value[0]
                if (firstValue !== undefined) {
                  onApplyQuery(firstValue)
                }
              }
            }}
            dialogOpen={saveDialogOpen}
            onChangeDialogOpen={setSaveDialogOpen} />
        </>
      )}
    </>
  ), [currentQuery.Id, domainType, domainTypeQueries, fetchTotal, hideAddButton, onApplyQuery, overriderQueries, saveDialogOpen, value])
  if (!renderContainer) {
    return contents
  }
  return (
    <Stack
      direction='row'
      gap={1}
      alignItems='center'
      flexWrap='wrap'>
      {contents}
    </Stack>
  )
}
