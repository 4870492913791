import { ResultsInput } from 'components/dataform'
import { useDataformResults } from 'utils/hooks'
import { DataformResultsSection as DataformResultsSectionType } from './useDetails'

interface Props {
  section: DataformResultsSectionType
  isLoading: boolean
}

export default function DataformResultsSection({
  section,
  isLoading
}: Props): JSX.Element | null {
  const [dataform, results, errorMessage] = useDataformResults(section.attributeValue)
  return (
    <ResultsInput
      key={JSON.stringify(results)}
      dataform={dataform}
      initialResults={results}
      attribute={section.attributeValue.attribute}
      errorMessage={errorMessage}
      readOnly
      isLoading={isLoading || (dataform === null && results === null && errorMessage === undefined)} />
  )
}