import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/function'
import * as t from 'io-ts'
import { JsonFromString } from 'io-ts-types'
import { Action, SET_MENU_SECTION_OPEN } from 'types'

const getStateFromLocalStorage = (): (string | null)[] => {
  return pipe(
    window.localStorage.getItem('openMenuSections'),
    E.fromNullable([]),
    E.chain(JsonFromString.pipe(t.array(t.union([t.string, t.null]))).decode),
    E.match(
      () => [],
      list => list
    )
  )
}

export default (state: (string | null)[] = getStateFromLocalStorage(), action: Action): (string | null)[] => {
  switch (action.type) {
    case SET_MENU_SECTION_OPEN: {
      const openSections = getStateFromLocalStorage()
      if (action.payload.open) {
        const value = [...openSections, action.payload.id ?? null]
        window.localStorage.openMenuSections = JSON.stringify(value)
        return value
      }
      const value = openSections.filter(openSectionId => openSectionId !== action.payload.id)
      window.localStorage.openMenuSections = JSON.stringify(value)
      return value
    }
    default:
      return state
  }
}