import { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editDomainType, editDomainTypeOverrider } from 'state/actions/domainTypes'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeSettings, EditableDomainTypeSettings, OverridableDomainTypeSettings } from 'types'
import { DomainTypeOverriderContext } from 'utils/context'
import { getDomainTypeSetting, getOverridableDomainTypeSetting, isNullOrUndefined, isOverridableSetting } from 'utils/helpers'

export function useDomainTypeSetting<Setting extends keyof EditableDomainTypeSettings>(
  domainTypeOrDetails: DomainType | null | [name: string, databaseTable?: string | null],
  setting: Setting,
  defaultValue: NonNullable<EditableDomainTypeSettings[Setting]>
): [NonNullable<EditableDomainTypeSettings[Setting]>, (value: EditableDomainTypeSettings[Setting]) => void] {
  const domainTypes = useSelector(getAllDomainTypes)
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const dispatch = useDispatch()
  const domainType = useMemo(() => {
    if (!Array.isArray(domainTypeOrDetails)) {
      return domainTypeOrDetails
    }
    const [name, databaseTable = null] = domainTypeOrDetails
    return Object.values(domainTypes)
      .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
      .find(domainType => domainType.Name === name && (domainType.DatabaseTable ?? null) === databaseTable)
  }, [domainTypeOrDetails, domainTypes])
  const value = useMemo(() => {
    if (isNullOrUndefined(domainType)) {
      return defaultValue
    }
    if (isOverridableSetting(setting)) {
      return getOverridableDomainTypeSetting(
        domainTypes,
        domainType,
        overriders,
        setting,
        defaultValue as NonNullable<OverridableDomainTypeSettings[typeof setting]>
      )
    }
    return getDomainTypeSetting(domainTypes, domainType, setting) ?? defaultValue
  }, [defaultValue, domainType, domainTypes, overriders, setting])
  const setValue = useCallback((newValue: DomainTypeSettings[Setting]) => {
    if (isNullOrUndefined(domainType)) {
      return
    }
    if (isOverridableSetting(setting)) {
      const overriderDetails = overriderContext[overriderContext.length - 1]
      if (overriderDetails === undefined) {
        return
      }
      dispatch(editDomainTypeOverrider(
        overriderDetails,
        domainType.Id,
        setting,
        newValue as OverridableDomainTypeSettings[typeof setting]
      ))
    } else {
      dispatch(editDomainType({
        ...domainType,
        [setting]: newValue
      }))
    }
  }, [dispatch, domainType, overriderContext, setting])
  return [value, setValue]
}