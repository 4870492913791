import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { DateTime } from 'luxon'
import { Attribute, AttributeValue, AttributeValues, BoolAttribute, ChainValue, DataformResultsAttribute, DateAttribute, DateTimeAttribute, DomainType, DomainTypeAttribute, DomainTypeInstance, EnumAttribute, EnumeratedType, Filter, GuidAttribute, JsonString, ListAttribute, MultiDataformResultsAttribute, NonListAttribute, NumberAttribute, RefAttribute, StringAttribute } from 'types'
import { AttributeCodec } from 'utils/codecs'
import { getAttributeChain, getAttributeChainValues, getAttributeValue, getChainValue, getDataformResultsCompleteness, getDomainType, getDomainTypeSetting, getMultiDataformResultsCompleteness, getStringFields, isListAttribute, isNullOrUndefined, makeAttributeTypeGuard } from 'utils/helpers'
import { DateFilterValueCodec, DateRangeFilterValueCodec, DateTimeFilterValueCodec, DateTimeRangeFilterValueCodec } from './codecs'
import { caseInsensitiveIncludes, parseDateFilterValue, parseDateRangeFilterValue, parseDateTimeFilterValue, parseDateTimeRangeFilterValue } from './helpers'
import { ApiFilter, CONTEXT_PREFIX, FilterContext, FilterOperator, ListFilterOperator, ME_PREFIX, NonListFilterOperator } from './types'

export { getContextAttributeValues } from './helpers'
export { CONTEXT_PREFIX, ME_PREFIX } from './types'
export type { FilterContext } from './types'

const guidEq: NonListFilterOperator<GuidAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('guid'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value === inputValue
  }
}

const guidNe: NonListFilterOperator<GuidAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'ne',
  label: 'ne',
  canApply: makeAttributeTypeGuard('guid'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value !== inputValue
  }
}

const stringLike: NonListFilterOperator<StringAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'like',
  label: 'like',
  canApply: makeAttributeTypeGuard('string'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return caseInsensitiveIncludes(value, inputValue)
  }
}

const stringIn: NonListFilterOperator<StringAttribute, ListAttribute<StringAttribute>> = {
  operator: 'in',
  label: 'in',
  canApply: makeAttributeTypeGuard('string'),
  inputAttributeType: 'string',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return (inputValue ?? []).some(inputItem => value === inputItem)
  }
}

const stringEq: NonListFilterOperator<StringAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('string'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value === inputValue
  }
}

const stringNe: NonListFilterOperator<StringAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'ne',
  label: 'ne',
  canApply: makeAttributeTypeGuard('string'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value !== inputValue
  }
}

const stringNin: NonListFilterOperator<StringAttribute, ListAttribute<StringAttribute>> = {
  operator: 'nin',
  label: 'nin',
  canApply: makeAttributeTypeGuard('string'),
  inputAttributeType: 'string',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return (inputValue ?? []).every(inputItem => value !== inputItem)
  }
}

const dateEq: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value).hasSame(parsedValue, 'day')
  }
}

const dateGt: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'gt',
  label: 'gt',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value).startOf('day') > parsedValue
  }
}

const dateGte: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'gte',
  label: 'gte',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value).startOf('day') >= parsedValue
  }
}

const dateLt: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'lt',
  label: 'lt',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value).startOf('day') < parsedValue
  }
}

const dateLte: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'lte',
  label: 'lte',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value).startOf('day') <= parsedValue
  }
}

const dateInRange: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'in range',
  label: 'in range',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateRangeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateRangeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    const date = DateTime.fromISO(value).startOf('day')
    const [from, to] = parsedValue
    return date >= from
      && date <= to
  }
}

const dateNinRange: NonListFilterOperator<DateAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'nin range',
  label: 'nin range',
  canApply: makeAttributeTypeGuard('date'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateRangeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateRangeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    const date = DateTime.fromISO(value).startOf('day')
    const [from, to] = parsedValue
    return date < from
      || date > to
  }
}

const dateTimeGt: NonListFilterOperator<DateTimeAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'gt',
  label: 'gt',
  canApply: makeAttributeTypeGuard('dateTime'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateTimeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateTimeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value) > parsedValue
  }
}

const dateTimeLt: NonListFilterOperator<DateTimeAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'lt',
  label: 'lt',
  canApply: makeAttributeTypeGuard('dateTime'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateTimeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateTimeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    return DateTime.fromISO(value) < parsedValue
  }
}

const dateTimeInRange: NonListFilterOperator<DateTimeAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'in range',
  label: 'in range',
  canApply: makeAttributeTypeGuard('dateTime'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateTimeRangeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateTimeRangeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    const [from, to] = parsedValue
    const date = DateTime.fromISO(value)
    return date > from && date < to
  }
}

const dateTimeNinRange: NonListFilterOperator<DateTimeAttribute, NonListAttribute<DomainTypeAttribute>> = {
  operator: 'nin range',
  label: 'nin range',
  canApply: makeAttributeTypeGuard('dateTime'),
  inputAttributeType: 'domainType',
  listInput: false,
  inputAttributeProperties: {
    AttributeDomainType: 'DateTimeRangeFilterValue'
  },
  apply({ value }, { value: inputValue }) {
    const parsedValue = parseDateTimeRangeFilterValue(inputValue)
    if (value === null || parsedValue === null) {
      return false
    }
    const [from, to] = parsedValue
    const date = DateTime.fromISO(value)
    return date <= from || date >= to
  }
}

const numberEq: NonListFilterOperator<NumberAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('number'),
  inputAttributeType: 'number',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value === inputValue
  }
}

const numberGt: NonListFilterOperator<NumberAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'gt',
  label: 'gt',
  canApply: makeAttributeTypeGuard('number'),
  inputAttributeType: 'number',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    if (value === null || inputValue === null) {
      return false
    }
    return value > inputValue
  }
}

const numberGte: NonListFilterOperator<NumberAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'gte',
  label: 'gte',
  canApply: makeAttributeTypeGuard('number'),
  inputAttributeType: 'number',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    if (value === null || inputValue === null) {
      return false
    }
    return value >= inputValue
  }
}

const numberLt: NonListFilterOperator<NumberAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'lt',
  label: 'lt',
  canApply: makeAttributeTypeGuard('number'),
  inputAttributeType: 'number',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    if (value === null || inputValue === null) {
      return false
    }
    return value < inputValue
  }
}

const numberLte: NonListFilterOperator<NumberAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'lte',
  label: 'lte',
  canApply: makeAttributeTypeGuard('number'),
  inputAttributeType: 'number',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    if (value === null || inputValue === null) {
      return false
    }
    return value <= inputValue
  }
}

const boolEq: NonListFilterOperator<BoolAttribute, NonListAttribute<BoolAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('bool'),
  inputAttributeType: 'bool',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value === inputValue
  }
}

const domainTypeLike: NonListFilterOperator<DomainTypeAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'like',
  label: 'like',
  canApply: makeAttributeTypeGuard('domainType'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }, domainTypes, context) {
    const domainType = domainTypes[attribute.AttributeDomainType]
    if (domainType === undefined) {
      return false
    }
    const stringFields = getStringFields(domainTypes, [domainType])
    return stringFields.some(name => applyFilter(
      domainTypes,
      domainType,
      value ?? {},
      {
        Property: name,
        Operator: 'like',
        Value: stringifyFilterValue(inputValue)
      },
      context
    ))
  }
}

const domainTypeEq: NonListFilterOperator<DomainTypeAttribute, NonListAttribute<RefAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('domainType'),
  inputAttributeType: 'ref',
  listInput: false,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }, domainTypes) {
    const domainType = domainTypes[attribute.AttributeDomainType]
    if (domainType === undefined) {
      return false
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return value?.[identifier] === inputValue
  }
}

const domainTypeIn: NonListFilterOperator<DomainTypeAttribute, ListAttribute<RefAttribute>> = {
  operator: 'in',
  label: 'in',
  canApply: makeAttributeTypeGuard('domainType'),
  inputAttributeType: 'ref',
  listInput: true,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }, domainTypes) {
    const domainType = domainTypes[attribute.AttributeDomainType]
    if (domainType === undefined) {
      return false
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return (inputValue ?? []).includes(String(value?.[identifier] ?? ''))
  }
}

const domainTypeNe: NonListFilterOperator<DomainTypeAttribute, NonListAttribute<RefAttribute>> = {
  operator: 'ne',
  label: 'ne',
  canApply: makeAttributeTypeGuard('domainType'),
  inputAttributeType: 'ref',
  listInput: false,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }, domainTypes) {
    const domainType = domainTypes[attribute.AttributeDomainType]
    if (domainType === undefined) {
      return false
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return value?.[identifier] !== inputValue
  }
}

const domainTypeNin: NonListFilterOperator<DomainTypeAttribute, ListAttribute<RefAttribute>> = {
  operator: 'nin',
  label: 'nin',
  canApply: makeAttributeTypeGuard('domainType'),
  inputAttributeType: 'ref',
  listInput: true,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }, domainTypes) {
    const domainType = domainTypes[attribute.AttributeDomainType]
    if (domainType === undefined) {
      return false
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return !(inputValue ?? []).includes(String(value?.[identifier] ?? ''))
  }
}

const enumEq: NonListFilterOperator<EnumAttribute, NonListAttribute<EnumAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('enum'),
  inputAttributeType: 'enum',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return String(value) === String(inputValue)
  }
}

const enumNe: NonListFilterOperator<EnumAttribute, NonListAttribute<EnumAttribute>> = {
  operator: 'ne',
  label: 'ne',
  canApply: makeAttributeTypeGuard('enum'),
  inputAttributeType: 'enum',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return String(value ?? '') !== String(inputValue ?? '')
  }
}

const enumIn: NonListFilterOperator<EnumAttribute, ListAttribute<EnumAttribute>> = {
  operator: 'in',
  label: 'in',
  canApply: makeAttributeTypeGuard('enum'),
  inputAttributeType: 'enum',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return (inputValue ?? []).map(String).includes(String(value ?? ''))
  }
}

const enumNin: NonListFilterOperator<EnumAttribute, ListAttribute<EnumAttribute>> = {
  operator: 'nin',
  label: 'nin',
  canApply: makeAttributeTypeGuard('enum'),
  inputAttributeType: 'enum',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return !(inputValue ?? []).map(String).includes(String(value ?? ''))
  }
}

const enumLike: NonListFilterOperator<EnumAttribute, NonListAttribute<StringAttribute>> = {
  operator: 'like',
  label: 'like',
  canApply: makeAttributeTypeGuard('enum'),
  inputAttributeType: 'string',
  listInput: false,
  inputAttributeProperties: {},
  apply({ attribute, value }, { value: inputValue }) {
    const enumeratedValue = attribute.EnumeratedType.Values
      .find(v => v.Value === String(value))
    return caseInsensitiveIncludes(enumeratedValue?.Value ?? null, inputValue)
      || caseInsensitiveIncludes(enumeratedValue?.Description ?? null, inputValue)
  }
}

const refEq: NonListFilterOperator<RefAttribute, NonListAttribute<RefAttribute>> = {
  operator: 'eq',
  label: 'eq',
  canApply: makeAttributeTypeGuard('ref'),
  inputAttributeType: 'ref',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value === inputValue
  }
}

const refIn: NonListFilterOperator<RefAttribute, ListAttribute<RefAttribute>> = {
  operator: 'in',
  label: 'in',
  canApply: makeAttributeTypeGuard('ref'),
  inputAttributeType: 'ref',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return (inputValue ?? []).includes(value ?? '')
  }
}

const refNe: NonListFilterOperator<RefAttribute, NonListAttribute<RefAttribute>> = {
  operator: 'ne',
  label: 'ne',
  canApply: makeAttributeTypeGuard('ref'),
  inputAttributeType: 'ref',
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return value !== inputValue
  }
}

const refNin: NonListFilterOperator<RefAttribute, ListAttribute<RefAttribute>> = {
  operator: 'nin',
  label: 'nin',
  canApply: makeAttributeTypeGuard('ref'),
  inputAttributeType: 'ref',
  listInput: true,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return !(inputValue ?? []).includes(value ?? '')
  }
}

const listCountEq: ListFilterOperator<ListAttribute, NonListAttribute<NumberAttribute>> = {
  operator: 'count',
  label: 'count',
  canApply: isListAttribute,
  inputAttributeType: 'number',
  list: true,
  listInput: false,
  inputAttributeProperties: {},
  apply({ value }, { value: inputValue }) {
    return (value?.length ?? 0) === inputValue
  }
}

const dataformStatusEnumeratedType: EnumeratedType = {
  ExternalId: 'DATAFORMSTATUS',
  GlobalExternalId: 'DATAFORMSTATUS',
  Values: [
    {
      Id: '3',
      Value: '3',
      Description: 'Not Filled In'
    },
    {
      Id: '0',
      Value: '0',
      Description: 'Partially Complete'
    },
    {
      Id: '1',
      Value: '1',
      Description: 'Complete - Unexpected'
    },
    {
      Id: '2',
      Value: '2',
      Description: 'Complete - OK'
    }
  ]
}

const dataformResultsStatus: NonListFilterOperator<DataformResultsAttribute, ListAttribute<EnumAttribute>> = {
  operator: 'status',
  label: 'status',
  canApply: makeAttributeTypeGuard('dataformResults'),
  inputAttributeType: 'enum',
  listInput: true,
  inputAttributeProperties: {
    EnumeratedType: dataformStatusEnumeratedType
  },
  apply({ value }, { value: inputValue }) {
    return inputValue?.includes(String(getDataformResultsCompleteness(value))) ?? false
  }
}

const multiDataformResultsStatus: NonListFilterOperator<MultiDataformResultsAttribute, ListAttribute<EnumAttribute>> = {
  operator: 'status',
  label: 'status',
  canApply: makeAttributeTypeGuard('multiDataformResults'),
  inputAttributeType: 'enum',
  listInput: true,
  inputAttributeProperties: {
    EnumeratedType: dataformStatusEnumeratedType
  },
  apply({ value }, { value: inputValue }) {
    return inputValue?.includes(String(getMultiDataformResultsCompleteness(value))) ?? false
  }
}

export const filterOperators: FilterOperator<Attribute>[] = [
  guidEq,
  guidNe,
  stringLike,
  stringIn,
  stringEq,
  stringNe,
  stringNin,
  dateEq,
  dateGt,
  dateGte,
  dateLt,
  dateLte,
  dateInRange,
  dateNinRange,
  dateTimeGt,
  dateTimeLt,
  dateTimeInRange,
  dateTimeNinRange,
  numberEq,
  numberGt,
  numberGte,
  numberLt,
  numberLte,
  boolEq,
  domainTypeLike,
  domainTypeEq,
  domainTypeIn,
  domainTypeNe,
  domainTypeNin,
  enumEq,
  enumNe,
  enumIn,
  enumNin,
  enumLike,
  refEq,
  refIn,
  refNe,
  refNin,
  listCountEq,
  dataformResultsStatus,
  multiDataformResultsStatus
]

export function makeInputAttributeValue<A extends Attribute = Attribute, Input extends Attribute = A>(
  attribute: A,
  filterOperator: FilterOperator<A, Input>,
  value: JsonString | null,
  context: FilterContext,
  domainTypes: Partial<Record<string, DomainType>>
): AttributeValue<Attribute> {
  const inputAttributeProperties: Record<string, unknown> = {}
  if (t.type({ AttributeDomainType: t.string }).is(filterOperator.inputAttributeProperties)) {
    const [name, databaseTable = null] = filterOperator.inputAttributeProperties.AttributeDomainType.split(':')
    if (!isNullOrUndefined(name)) {
      inputAttributeProperties.AttributeDomainType = getDomainType(
        domainTypes,
        name,
        databaseTable
      )?.Id ?? filterOperator.inputAttributeProperties.AttributeDomainType
    }
  }
  const inputAttribute: unknown = Object.assign(
    {},
    attribute,
    filterOperator.inputAttributeProperties,
    inputAttributeProperties,
    {
      AttributeType: filterOperator.inputAttributeType,
      Title: 'Value',
      Required: false,
      List: filterOperator.listInput
    }
  )
  if (AttributeCodec.is(inputAttribute)) {
    return getAttributeValue(
      {
        [attribute.Name]: parseFilterValue(value, context)
      },
      inputAttribute
    )
  }
  throw new Error('This should never happen')
}

function parametersAreValid<A extends Attribute = Attribute, Input extends Attribute = A>(
  filterOperator: FilterOperator<A, Input>,
  parameters: readonly [AttributeValues, AttributeValue]
): parameters is [AttributeValues<A>, AttributeValue<Input>] {
  const [attributeValue, inputAttributeValue] = parameters
  return filterOperator.canApply(attributeValue.attribute)
    && inputAttributeValue.attribute.AttributeType === filterOperator.inputAttributeType
    && (inputAttributeValue.attribute.List ?? false) === filterOperator.listInput
}

function getFilterAttributeChainValues(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  filter: Filter,
  context: FilterContext
): AttributeValues | undefined {
  if (!filter.Property.startsWith(CONTEXT_PREFIX)) {
    return getAttributeChainValues(
      domainTypes,
      domainType,
      instance,
      filter.Property
    )
  }
  const [contextName, ...filterPropertyPaths] = filter.Property.split('_').slice(1)
  const contextAttributeValue = context[CONTEXT_PREFIX]
    .find(attributeValue => attributeValue.attribute.Name === contextName)
  if (isNullOrUndefined(contextAttributeValue)) {
    return undefined
  }
  if (filterPropertyPaths.length === 0) {
    return {
      attribute: contextAttributeValue.attribute,
      values: [contextAttributeValue.value]
    }
  }
  const contextDomainType = domainTypes[contextAttributeValue.attribute.AttributeDomainType]
  if (isNullOrUndefined(contextDomainType)
    || isNullOrUndefined(contextAttributeValue.value)) {
    return undefined
  }
  return getAttributeChainValues(
    domainTypes,
    contextDomainType,
    contextAttributeValue.value,
    filterPropertyPaths.join('_')
  )
}

function applyFilter(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  filter: Filter,
  context: FilterContext
): boolean {
  const attributeChainValues = getFilterAttributeChainValues(
    domainTypes,
    domainType,
    instance,
    filter,
    context
  )
  if (attributeChainValues === undefined) {
    return false
  }
  const filterOperator = filterOperators
    .find(f => f.canApply(attributeChainValues.attribute) && f.operator === filter.Operator)
  if (filterOperator === undefined) {
    return false
  }
  const inputAttributeValue = makeInputAttributeValue(
    attributeChainValues.attribute,
    filterOperator,
    filter.Value,
    context,
    domainTypes
  )
  if (!parametersAreValid(filterOperator, [attributeChainValues, inputAttributeValue])) {
    return false
  }
  return attributeChainValues.values.some(item => {
    if (filterOperator.list === true) {
      if (!Array.isArray(item)) {
        return false
      }
      return filterOperator.apply(
        {
          attribute: attributeChainValues.attribute,
          value: item
        },
        inputAttributeValue,
        domainTypes,
        context
      )
    }
    if (!Array.isArray(item)) {
      return filterOperator.apply(
        {
          attribute: attributeChainValues.attribute,
          value: item
        },
        inputAttributeValue,
        domainTypes,
        context
      )
    }
    return item.some(itemValue => filterOperator.apply(
      {
        attribute: attributeChainValues.attribute,
        value: itemValue
      },
      inputAttributeValue,
      domainTypes,
      context
    ))
  })
}

function expandFilterForClient(
  filter: Filter,
  context: FilterContext
): Filter[] {
  return expandFilter(filter, context)
    .map(filter => ({
      ...filter,
      Value: stringifyFilterValue(filter.Value)
    }))
}

export function applyAllFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  filters: Filter[],
  context: FilterContext
): boolean {
  return filters
    .flatMap(filter => expandFilterForClient(filter, context))
    .every(filter => applyFilter(
      domainTypes,
      domainType,
      instance,
      filter,
      context
    ))
}

export function applyAnyFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instance: DomainTypeInstance,
  filters: Filter[],
  context: FilterContext
): boolean {
  return filters
    .flatMap(filter => expandFilterForClient(filter, context))
    .some(filter => applyFilter(
      domainTypes,
      domainType,
      instance,
      filter,
      context
    ))
}

export function applyContextFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filters: Filter[],
  context: FilterContext
): boolean {
  return filters
    .filter(filter => filter.Property.startsWith(CONTEXT_PREFIX))
    .every(filter => applyFilter(
      domainTypes,
      domainType,
      {},
      filter,
      context
    ))
}

function getContextValues(
  context: FilterContext,
  property: string
): ChainValue[] {
  const [contextName, ...contextPaths] = property.split('_').slice(1)
  const contextAttributeValues = context[CONTEXT_PREFIX]
    .filter(attributeValue => attributeValue.attribute.Name === contextName)
  if (contextAttributeValues.length === 0) {
    return []
  }
  return contextAttributeValues
    .map(contextAttributeValue => {
      if (contextPaths.length === 0) {
        return contextAttributeValue.value
      }
      const contextDomainType = context.domainTypes[contextAttributeValue.attribute.AttributeDomainType]
      if (isNullOrUndefined(contextDomainType)
        || isNullOrUndefined(contextAttributeValue.value)) {
        return null
      }
      const attributeChain = getAttributeChain(
        context.domainTypes,
        contextDomainType,
        contextPaths.join('_')
      )
      if (isNullOrUndefined(attributeChain)) {
        return null
      }
      return getChainValue(
        contextAttributeValue.value,
        attributeChain
      )
    })
    .filter(value => !isNullOrUndefined(value))
    .filter((value, i, array) => array.indexOf(value) === i)
}

function getContextValueFilters(
  filter: Filter,
  context: FilterContext,
  property: string
): ApiFilter[] {
  return getContextValues(context, property)
    .map(value => ({
      ...filter,
      Value: value
    }))
}

const templateRegex = new RegExp(`\\$\\{(${CONTEXT_PREFIX}_[\\w:]+)\\}`, 'g')

export function getTemplateContextValues(
  templateString: string,
  context: FilterContext
): string[] {
  const matches = [...templateString.matchAll(templateRegex)]
  let templatedStrings: string[] = [templateString]
  let i = 0
  while (i < matches.length) {
    const [valueToReplace, property] = matches[i++] ?? []
    if (valueToReplace === undefined
      || property === undefined) {
      break
    }
    const contextValues = getContextValues(context, property)
    templatedStrings = templatedStrings
      .flatMap(templatedString => contextValues
        .map(value => templatedString.replace(valueToReplace, String(value))))
      .filter((value, i, array) => array.indexOf(value) === i)
  }
  return templatedStrings
}

function getTemplateContextValueFilters(
  filter: Filter,
  context: FilterContext,
  templateString: string
): ApiFilter[] {
  return getTemplateContextValues(templateString, context)
    .map(value => ({
      ...filter,
      Value: value
    }))
}

function parseFilterValue(
  value: JsonString | null,
  context: FilterContext
): unknown {
  if (value === null) {
    return null
  }
  try {
    const parsedValue = JSON.parse(value) as unknown
    if (parsedValue === ME_PREFIX) {
      return context[ME_PREFIX]
    }
    return parsedValue
  } catch {
    return null
  }
}

function expandFilter(
  filter: Filter,
  context: FilterContext
): ApiFilter[] {
  const parsedValue = parseFilterValue(filter.Value, context)
  if (typeof parsedValue === 'string'
    && parsedValue.startsWith(CONTEXT_PREFIX)) {
    return getContextValueFilters(
      filter,
      context,
      parsedValue
    )
  }
  if (typeof parsedValue === 'string'
    && parsedValue.match(templateRegex) !== null) {
    return getTemplateContextValueFilters(
      filter,
      context,
      parsedValue
    )
  }
  return [
    {
      ...filter,
      Value: parsedValue
    }
  ]
}

const filterValueTransformers = [
  {
    decode: DateFilterValueCodec.decode,
    transform: parseDateFilterValue
  },
  {
    decode: DateTimeFilterValueCodec.decode,
    transform: parseDateTimeFilterValue
  },
  {
    decode: DateRangeFilterValueCodec.decode,
    transform: parseDateRangeFilterValue
  },
  {
    decode: DateTimeRangeFilterValueCodec.decode,
    transform: parseDateTimeRangeFilterValue
  }
] as const

function transformFilterValueForApi(value: unknown): unknown {
  for (const transformer of filterValueTransformers) {
    const decodedValue = transformer.decode(value)
    if (E.isLeft(decodedValue)) {
      continue
    }
    return transformer.transform(decodedValue.right)
  }
  return value
}

export function stringifyFilterValue(value: unknown): JsonString | null {
  try {
    return JSON.stringify(value) as JsonString
  } catch {
    return null
  }
}

export function getSearchTextFilters(searchText: string, stringFields: string[]): Filter[] {
  if (!searchText) {
    return []
  }
  return stringFields.map(field => ({
    Property: field,
    Operator: 'like',
    Value: stringifyFilterValue(searchText.trim())
  }))
}

export function getAnyAllFilters(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filterLinkOperator: 'and' | 'or',
  filters: Filter[],
  searchText: string,
  additionalFilters?: Filter[]
): [anyFilters: Filter[], allFilters: Filter[]] {
  const searchTextFilters = searchText === ''
    ? []
    : getSearchTextFilters(searchText, getStringFields(domainTypes, [domainType]))
  const anyFilters = searchTextFilters
    .concat(filterLinkOperator === 'or' ? filters : [])
  const allFilters = (additionalFilters ?? [])
    .concat(filterLinkOperator === 'and' ? filters : [])
  return [anyFilters, allFilters]
}

function expandFilterForApi(
  filter: Filter,
  context: FilterContext
): ApiFilter[] {
  return expandFilter(filter, context)
    .map(filter => ({
      ...filter,
      Value: transformFilterValueForApi(filter.Value)
    }))
}

export function createApiSearchFilters(filters: Filter[], context: FilterContext): unknown {
  return filters
    .flatMap(filter => expandFilterForApi(filter, context))
    .filter(filter => {
      if (Array.isArray(filter.Value)) {
        return filter.Value.length > 0
      }
      if (typeof filter.Value === 'string') {
        return filter.Value.trim().length > 0
      }
      return filter.Value === false || filter.Value === 0 || Boolean(filter.Value)
    })
}