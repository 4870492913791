import { NonListStringAttributeValue } from 'types'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'

export default function StringCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListStringAttributeValue>): JSX.Element | null {
  if (attributeValue.value === null) {
    return null
  }
  return (
    <CellTooltip
      value={attributeValue.value}
      columnWidth={columnWidth} />
  )
}
