import { Box, styled } from '@mui/material'
import IsInRole from 'components/auth/IsInRole'
import SidePanelContainer from 'components/navigation/SidePanelContainer'
import { RemoteDetailsPage } from 'components/pages/DetailsPage'
import FindPage from 'components/pages/FindPage'
import HomePage from 'components/pages/HomePage'
import { useSelector } from 'react-redux'
import { Route, Routes, generatePath } from 'react-router-dom'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { PAGE_PADDING, PAGE_URL } from 'utils/constants'
import { getDomainTypeSetting } from 'utils/helpers'
import Unauthorised from './Unauthorised'

const PageContainer = styled(Box)(({ theme }) => ({
  height: 'calc(100% - 64px)',
  overflowY: 'scroll'
}))

export default function PortalRoutes(): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const findableDomainTypes = Object.values(domainTypes)
    .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
    .filter(domainType => domainType.Find)
  const apiDomainTypes = Object.values(domainTypes)
    .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
    .filter(domainType => domainType.Api)
  return (
    <>
      <Routes>
        <Route
          path={PAGE_URL.HOME}
          element={
            <PageContainer p={PAGE_PADDING}>
              <HomePage />
            </PageContainer>
          } />
        {findableDomainTypes.map(domainType => {
          return (
            <Route
              key={domainType.Id}
              path={generatePath(PAGE_URL.FIND, {
                databaseTable: domainType.DatabaseTable ?? domainType.Name,
                name: domainType.Name
              })}
              element={
                <IsInRole
                  role={getDomainTypeSetting(domainTypes, domainType, 'ViewRole') ?? null}
                  Unauthorised={Unauthorised}>
                  <PageContainer
                    key={domainType.Id}
                    p={PAGE_PADDING}
                    pt={0}>
                    <FindPage
                      domainType={domainType} />
                  </PageContainer>
                </IsInRole>
              } />
          )
        })}
        {apiDomainTypes.map(domainType => {
          return (
            <Route
              key={domainType.Id}
              path={generatePath(PAGE_URL.DETAILS, {
                databaseTable: domainType.DatabaseTable ?? domainType.Name,
                name: domainType.Name,
                id: ':id'
              })}
              element={
                <SidePanelContainer>
                  <RemoteDetailsPage rootDomainType={domainType} />
                </SidePanelContainer>
              } />
          )
        })}
      </Routes>
    </>
  )
}
