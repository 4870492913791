import { PaletteMode } from '@mui/material'
import { Action, CHANGE_THEME } from 'types'
import { PaletteModeCodec } from 'utils/codecs'

const getDefaultState = (): PaletteMode => {
  const themeString = window.localStorage.getItem('theme')
  if (PaletteModeCodec.is(themeString)) {
    return themeString
  } else {
    window.localStorage.theme = 'light'
    return 'light'
  }
}

export default (state: PaletteMode = getDefaultState(), action: Action): PaletteMode => {
  switch (action.type) {
    case CHANGE_THEME: {
      const theme = action.payload.theme
      window.localStorage.setItem('theme', theme)
      return theme
    }
    default:
      return state
  }
}