import { Paper, styled } from '@mui/material'
import { DataGrid, GridCellParams, GridColumnVisibilityChangeParams, GridColumnVisibilityModel, GridColumns, GridEnrichedColDef, GridFilterOperator, GridRenderCellParams, GridRowModel, GridRowParams, GridSelectionModel, GridSortModel, gridClasses } from '@mui/x-data-grid'
import ButtonsPopover from 'components/domainType/ButtonsPopover'
import { KeyboardEvent, useCallback, useContext, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import * as fromState from 'state/reducers'
import { Attribute, ChainValue, DomainType, DomainTypeAttribute, DomainTypeInstance, DomainTypeOverrider, Filter, Sort } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeOverriderContext } from 'utils/context'
import { filterOperators } from 'utils/filters'
import { getChainValue, getDomainTypeAttribute, getDomainTypeAttributes, getDomainTypeSetting, getDomainTypeSettingAttribute, getOverridableDomainTypeSetting, isNullOrUndefined, makeSortFunction, mergeHierarchicalDomainTypeListSetting } from 'utils/helpers'
import { v4 } from 'uuid'
import CustomCell from './CustomCell'
import CustomColumnHeader from './CustomColumnHeader'
import CustomColumnMenu from './CustomColumnMenu'
import CustomGridRow from './CustomGridRow'
import CustomPagination from './CustomPagination'
import TableViewContext from './TableViewContext'
import FilterInputComponent from './FilterInputComponent'

const PREFIX = 'TableView'

export const ROW_HEIGHT = 36

const classes = {
  root: `${PREFIX}-root`,
  row: `${PREFIX}-row`,
  cell: `${PREFIX}-cell`,
  columnHeaderTitle: `${PREFIX}-columnHeaderTitle`
}
const noPaginationClasses = {
  ...classes,
  row: `${PREFIX}-rowNoPagination`
}

const Root = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    border: 'none'
  },

  [`& .${classes.row}`]: {
    cursor: 'pointer'
  },

  [`& .${classes.columnHeaderTitle}`]: {
    fontWeight: 'bold'
  },

  [`& :last-child.${noPaginationClasses.row} .MuiDataGrid-cell`]: {
    border: 'none'
  },

  [`& .${gridClasses.virtualScroller}`]: {
    overflow: 'hidden'
  },

  [`& .${gridClasses.virtualScrollerContent}`]: {
    transition: 'height 500ms'
  },

  [`& .${gridClasses.menuIcon}`]: {
    width: '30px'
  },

  [`& .${gridClasses.toolbarContainer}`]: {
    justifyContent: 'space-between'
  }
}))

function makeValueGetter(attributeChain: Attribute[]) {
  return function valueGetter(params: GridRenderCellParams) {
    return getChainValue(params.row, attributeChain)
  }
}

function createContextMenu(
  domainType: DomainType
): GridEnrichedColDef {
  return {
    field: 'actions',
    headerName: 'Actions',
    sortable: false,
    width: 75,
    minWidth: 75,
    type: 'actions',
    renderCell: (params: GridRenderCellParams) => {
      return (
        <ButtonsPopover
          domainType={domainType}
          instances={[params.row]}
          on='TableRow' />
      )
    }
  }
}

function getFilterOperators(
  domainTypes: Partial<Record<string, DomainType>>,
  attribute: Attribute
): GridFilterOperator[] {
  return filterOperators
    .filter(filterOperator => filterOperator.canApply(attribute))
    .filter(filterOperator => {
      if (filterOperator.inputAttributeType !== 'ref'
        || attribute.AttributeType !== 'domainType') {
        return true
      }
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      return attributeDomainType?.Api === true
    })
    .map(filterOperator => {
      const gridFilterOperator: GridFilterOperator = {
        value: filterOperator.operator,
        label: filterOperator.label,
        getApplyFilterFn: (item, colDef) => null,
        InputComponent: FilterInputComponent
      }
      return gridFilterOperator
    })
}

export function getColumns(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: DomainType[],
  overriders: DomainTypeOverrider[],
  hideColumns: string[] | undefined,
  disableSorts: boolean,
  attributeChain: DomainTypeAttribute[] = [],
  topLevelColumns?: string[]
): GridColumns {
  if (domainTypeChain.length === 0) {
    return []
  }
  const domainType = domainTypeChain[domainTypeChain.length - 1]
  if (domainType === undefined) {
    return []
  }
  const columns = topLevelColumns ?? getOverridableDomainTypeSetting(domainTypes, domainType, overriders, 'Columns', [])
  return getDomainTypeAttributes(domainTypes, domainType).flatMap<GridEnrichedColDef>(attribute => {
    const newAttributeChain = [...attributeChain, attribute]
    const id = newAttributeChain.map(attribute => attribute.Name).join('_')
    if (hideColumns?.includes(id) ?? false) {
      return []
    }
    const extraColumns: GridEnrichedColDef[] = []
    if (attribute.AttributeType === 'domainType') {
      const attributeDomainType = domainTypes[attribute.AttributeDomainType]
      if (attributeDomainType === undefined) {
        return []
      }
      if (getDomainTypeSetting(domainTypes, attributeDomainType, 'ExpandColumns') ?? false) {
        extraColumns.push(...getColumns(
          domainTypes,
          [...domainTypeChain, attributeDomainType],
          overriders,
          hideColumns,
          disableSorts,
          [...attributeChain, attribute],
          columns
        ))
      }
    }
    const valueGetters: ((params: GridRenderCellParams) => ChainValue)[] = [
      (params: GridRenderCellParams) => params.row,
      ...newAttributeChain.map((attribute, index) => {
        return makeValueGetter(newAttributeChain.slice(0, index + 1))
      })
    ]

    const gridFilterOperators = getFilterOperators(domainTypes, attribute)
    return [
      {
        field: id,
        headerName: newAttributeChain.map(attribute => attribute.Title).join(PATH_SEPARATOR),
        flex: 1,
        valueGetter: valueGetters[valueGetters.length - 1],
        type: attribute.AttributeType,
        hide: !columns.includes(id),
        sortable: !disableSorts,
        filterable: gridFilterOperators.length > 0,
        filterOperators: gridFilterOperators,
        renderHeader: CustomColumnHeader,
        renderCell: params => {
          return (
            <CustomCell
              params={params}
              attribute={attribute}
              domainTypeChain={domainTypeChain}
              attributeChain={attributeChain}
              valueGetters={valueGetters} />
          )
        }
      },
      ...extraColumns
    ]
  }).sort(makeSortFunction(columns, column => column.field))
}

const components = {
  ColumnMenu: CustomColumnMenu,
  Pagination: CustomPagination,
  Row: CustomGridRow
}

interface Props {
  items: DomainTypeInstance[] | undefined
  domainType: DomainType
  isLoading: boolean
  hideColumns?: string[]
  sorts?: Sort[]
  page?: number
  pageSize?: number
  total?: number
  highlightedRowIds?: string[]
  checkedRowIds: string[]
  checkedItems: DomainTypeInstance[]
  filters: Filter[]
  onPageChange?(page: number): void
  onPageSizeChange?(pageSize: number): void
  onSortsChange?(sorts: Sort[]): void
  onRowClick?(id: string): void
  onCheckedRowIdsChange(ids: string[]): void
  onColumnVisibilityChange(column: string, visible: boolean): void
  onAddFilter(filter: Filter): void
  onClickFilterIcon(field: string): void
}

export default function TableView({
  items,
  domainType,
  isLoading,
  hideColumns,
  sorts,
  page,
  pageSize,
  total,
  highlightedRowIds,
  checkedRowIds,
  checkedItems,
  filters,
  onSortsChange,
  onPageChange,
  onPageSizeChange,
  onRowClick,
  onCheckedRowIdsChange,
  onColumnVisibilityChange: setColumnVisibility,
  onAddFilter,
  onClickFilterIcon
}: Props): JSX.Element {
  const disablePagination = typeof page !== 'number'
  const disableSorts = !Array.isArray(sorts)
  const dataGridRef = useRef<HTMLDivElement>(null)

  const addHighlightedClass = useCallback((gridRowModel: GridRowModel) => {
    return highlightedRowIds?.includes(String(gridRowModel.id)) === true ? 'Mui-selected' : ''
  }, [highlightedRowIds])

  const domainTypes = useSelector(fromState.getAllDomainTypes)

  const rows = useMemo(() => {
    return items || []
  }, [items])

  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])

  const contextMenuColumn = useMemo(() => {
    const buttons = mergeHierarchicalDomainTypeListSetting(domainTypes, domainType, 'Buttons')
      ?.filter(button => button.ShowOn.includes('TableRow'))
    if (!isNullOrUndefined(buttons) && buttons.length > 0) {
      return createContextMenu(domainType)
    }
    return null
  }, [domainTypes, domainType])

  const columns = useMemo(() => {
    const cols = getColumns(domainTypes, [domainType], overriders, hideColumns, disableSorts)
    if (contextMenuColumn != null) {
      cols.push(contextMenuColumn)
    }
    return cols
  }, [domainTypes, domainType, overriders, hideColumns, disableSorts, contextMenuColumn])

  const columnVisibilityModel = useMemo(() => {
    const visibilityModel: GridColumnVisibilityModel = {}
    return columns.reduce((model, column) => {
      model[column.field] = !(column.hide ?? false)
      return model
    }, visibilityModel)
  }, [columns])

  const onColumnVisibilityChange = useCallback((params: GridColumnVisibilityChangeParams) => {
    setColumnVisibility(params.field, params.isVisible)
  }, [setColumnVisibility])

  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    if (onSortsChange) {
      onSortsChange(sortModel.map(sort => ({
        Property: sort.field,
        Direction: sort.sort ?? null
      })))
    }
  }, [onSortsChange])

  const handleRowClick = useCallback((row: GridRowParams) => {
    if (onRowClick) {
      onRowClick(String(row.id))
    }
  }, [onRowClick])

  const onSelectionModelChange = useCallback((ids: GridSelectionModel) => {
    onCheckedRowIdsChange(ids.map(String))
  }, [onCheckedRowIdsChange])

  const componentsProps = useMemo(() => {
    return {
      footer: {
        style: {
          justifyContent: 'right'
        }
      },
      row: {
        items,
        domainType,
        checkedItems,
        onSelectionModelChange
      }
    }
  }, [items, domainType, checkedItems, onSelectionModelChange])

  const sortModel = useMemo(() => sorts?.map(sort => ({
    field: sort.Property,
    sort: sort.Direction
  })), [sorts])

  const identifierAttribute = useMemo(() => {
    return getDomainTypeSettingAttribute(domainTypes, domainType, 'Identifier')
      ?? getDomainTypeAttribute(domainTypes, domainType, 'Id')
  }, [domainType, domainTypes])

  const getRowId = useCallback((row: GridRowModel): string => {
    if (identifierAttribute !== undefined) {
      return String(row[identifierAttribute.Name])
    }
    row.Id = row.Id ?? v4()
    return row.Id
  }, [identifierAttribute])

  const onCellKeyDown = useCallback((
    params: GridCellParams,
    event: KeyboardEvent
  ) => {
    if (event.key === 'Enter'
      && event.target === event.currentTarget) {
      onRowClick?.(String(params.id))
    }
  }, [onRowClick])

  const context = useMemo(() => ({
    domainType,
    columns,
    page,
    setPage: onPageChange,
    pageSize,
    setPageSize: onPageSizeChange,
    total,
    rowCount: rows.length,
    isLoading,
    setColumnVisibility,
    disablePagination,
    disableSorts,
    checkedItems,
    onAddFilter,
    filters,
    onClickFilterIcon
  }), [domainType, columns, page, onPageChange, pageSize, onPageSizeChange, total, rows.length, isLoading, setColumnVisibility, disablePagination, disableSorts, checkedItems, onAddFilter, filters, onClickFilterIcon])
  return (
    <Root>
      <TableViewContext.Provider
        value={context}>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={isLoading}
          page={(page ?? 1) - 1}
          pageSize={pageSize}
          getRowClassName={addHighlightedClass}
          hideFooter={disablePagination}
          getRowId={getRowId}
          rowCount={total}
          sortingMode='server'
          filterMode='server'
          paginationMode='server'
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          components={components}
          componentsProps={componentsProps}
          classes={disablePagination ? noPaginationClasses : classes}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={onColumnVisibilityChange}
          onRowClick={handleRowClick}
          selectionModel={checkedRowIds}
          onSelectionModelChange={onSelectionModelChange}
          onCellKeyDown={onCellKeyDown}
          density='compact'
          checkboxSelection
          autoHeight
          disableSelectionOnClick
          disableVirtualization />
      </TableViewContext.Provider>
    </Root>
  )
}
