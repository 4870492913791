import { CalendarViewDayOutlined, CalendarViewMonthOutlined, CalendarViewWeekOutlined, SaveOutlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useState } from 'react'
import { DomainType } from 'types'
import { CalendarProps } from './types'

interface Props {
  readonly domainType: DomainType | null
  readonly view: CalendarProps['view']
  readonly onViewChange: CalendarProps['onViewChange']
  onClose(): void
}

export default function CalendarViewPanel({
  domainType,
  view,
  onViewChange,
  onClose
}: Props): JSX.Element {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  return (
    <>
      <MenuList>
        {view !== 'day' && (
          <MenuItem
            onClick={() => {
              onViewChange('day')
              onClose()
            }}>
            <ListItemIcon>
              <CalendarViewDayOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Day View</ListItemText>
          </MenuItem>
        )}
        {view !== 'week' && (
          <MenuItem
            onClick={() => {
              onViewChange('week')
              onClose()
            }}>
            <ListItemIcon>
              <CalendarViewWeekOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Week View</ListItemText>
          </MenuItem>
        )}
        {view !== 'month' && (
          <MenuItem
            onClick={() => {
              onViewChange('month')
              onClose()
            }}>
            <ListItemIcon>
              <CalendarViewMonthOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Month View</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => setSaveDialogOpen(true)}>
          <ListItemIcon>
            <SaveOutlined />
          </ListItemIcon>
          <ListItemText>Save Calendar View</ListItemText>
        </MenuItem>
      </MenuList>
      {domainType !== null && (
        <SaveDomainTypeSettingButton
          domainType={domainType}
          setting='CalendarView'
          value={view}
          dialogOpen={saveDialogOpen}
          onChangeDialogOpen={value => {
            setSaveDialogOpen(value)
            if (!value) {
              onClose()
            }
          }} />
      )}
    </>
  )
}