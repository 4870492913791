import { Action, TOGGLE_SIDE_MENU_EXPANDED } from 'types'

const getDefaultState = (): boolean => {
  return window.localStorage.getItem('sideMenuExpanded') !== String(false)
}

export default (state: boolean = getDefaultState(), action: Action): boolean => {
  switch (action.type) {
    case TOGGLE_SIDE_MENU_EXPANDED: {
      const sideMenuExpanded = !state
      window.localStorage.setItem('sideMenuExpanded', String(sideMenuExpanded))
      return sideMenuExpanded
    }
    default:
      return state
  }
}