import {
  AccountCircle as AccountCircleIcon, Business as BusinessIcon,
  ExpandMore as ChevronDownIcon,
  DarkMode as DarkModeIcon,
  LightMode as LightModeIcon, MoreVert as MoreIcon
} from '@mui/icons-material'
import { AppBar, Button, Divider, Icon, IconButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, MenuList, Toolbar, Tooltip, Typography } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import ThemeToggleButton from 'components/utils/ThemeToggleButton'
import { ReactComponent as PhalanxLogo } from 'phalanx_white.svg'
import { ComponentProps, MouseEvent, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'redux-first-history'
import { signOut, switchCompany } from 'state/actions/auth'
import { changeTheme } from 'state/actions/preferences'
import { getCompany, getPerson, getPreferences, getUser } from 'state/reducers'
import { DomainTypeInstance } from 'types'
import { PAGE_URL } from 'utils/constants'
import { isNullOrUndefined } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'

const PREFIX = 'PrimarySearchAppBar'

const classes = {
  grow: `${PREFIX}-grow`,
  appBar: `${PREFIX}-appBar`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  buttonContent: `${PREFIX}-buttonContent`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  sectionMobile: `${PREFIX}-sectionMobile`
}

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },

  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%'
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  [`& .${classes.buttonContent}`]: {
    textTransform: 'none',
    fontSize: 16,
    bottom: 0
  },
  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },

  [`& .${classes.sectionMobile}`]: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}))

const messages = defineMessages({
  'header.text': {
    id: 'appBar.header.text',
    defaultMessage: 'PHALANX'
  },
  'profileMenu.signOut': {
    id: 'appBar.profileMenu.signOut',
    defaultMessage: 'Sign Out'
  }
})

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <MenuList
      {...props}
      subheader={(
        <ListSubheader
          sx={{
            lineHeight: 'unset',
            backgroundColor: 'inherit'
          }}>
          Actions
        </ListSubheader>
      )} />
  ),
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

export default function PrimarySearchAppBar(): JSX.Element {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const user = useSelector(getUser)
  const person = useSelector(getPerson)
  const preferences = useSelector(getPreferences)
  const companyDomainType = useDomainType('Company', 'Company')
  const company = useSelector(getCompany)

  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const [companyAnchorEl, setCompanyAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const isMenuOpen = Boolean(anchorEl)
  const isCompanySelectorOpen = Boolean(companyAnchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleCompanySelectorMenuOpen = (event: MouseEvent): void => {
    setCompanyAnchorEl(event.currentTarget)
  }

  const handleProfileMenuOpen = (event: MouseEvent): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = (): void => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
    setCompanyAnchorEl(null)
    handleMobileMenuClose()
  }

  const handleSignOut = (): void => {
    dispatch(signOut())
    handleMenuClose()
  }

  const handleCompanyChange = (selectedCompanyId: string): void => {
    dispatch(push(PAGE_URL.HOME))
    dispatch(switchCompany(selectedCompanyId))
  }

  const handleChangeTheme = (darkMode: boolean): void => {
    dispatch(changeTheme(darkMode))
  }

  const handleMobileMenuOpen = (event: MouseEvent): void => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem
        onClick={handleSignOut}>{formatMessage(messages['profileMenu.signOut'])}</MenuItem>
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const mobileCompanySelectorId = 'primary-search-company-selector-mobile'
  const showCompanySelector = user !== null

  const companySelector = showCompanySelector
    ? (
      <Menu
        disableScrollLock
        anchorEl={companyAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        id={mobileCompanySelectorId}
        //keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isCompanySelectorOpen}
        onClose={handleMenuClose}>
        {isNullOrUndefined(companyDomainType)
          ? null
          : (
            <DomainTypeButtons
              domainType={companyDomainType}
              instances={isNullOrUndefined(company)
                ? []
                : [company as unknown as DomainTypeInstance]}
              on='DetailsHeader'
              components={components}
              onComplete={handleMenuClose} />
          )}
        <Divider />
        <MenuList
          sx={{ paddingTop: 1 }}
          subheader={(
            <ListSubheader
              sx={{
                lineHeight: 'unset',
                backgroundColor: 'inherit'
              }}>
              Companies
            </ListSubheader>
          )}>
          {user.companies.map(company => (
            <MenuItem
              disabled={company.id === user.selectedCompanyId}
              onClick={e => {
                const { companyid } = e.currentTarget.dataset
                if (companyid !== undefined) {
                  handleCompanyChange(companyid)
                }
                handleMenuClose()
              }}
              key={company.id}
              data-companyid={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    )
    : null

  const mobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='inherit'
          size='large'>
          <AccountCircleIcon />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <MenuItem onClick={(e) => { handleChangeTheme(preferences.theme === 'light') }}>
        {preferences.theme === 'dark'
          ? (
            <>
              <IconButton
                aria-label='switch to light mode'
                color='inherit'
                size='large'>
                <LightModeIcon />
              </IconButton>
              <p>Switch to Light Mode</p>
            </>
          )
          : (
            <>
              <IconButton
                aria-label='switch to dark mode'
                color='inherit'
                size='large'>
                <DarkModeIcon />
              </IconButton>
              <p>Switch to Dark Mode</p>
            </>
          )}
      </MenuItem>
      <MenuItem onClick={handleCompanySelectorMenuOpen}>
        <IconButton
          aria-label='company'
          aria-controls={mobileCompanySelectorId}
          aria-haspopup='true'
          color='inherit'
          size='large'>
          <BusinessIcon />
        </IconButton>
        <p>Company</p>
      </MenuItem>
    </Menu>
  )
  return (
    <Root>
      <AppBar
        position='fixed'
        color='spartanBlack'
        className={classes.appBar}>
        <Toolbar>
          <Typography
            className={classes.title}
            variant='h6'
            noWrap>
            <Link
              style={{
                color: 'white',
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
              }}
              to={PAGE_URL.HOME}>
              <PhalanxLogo
                height='40'
                width='200' />
            </Link>
          </Typography>
          <div className={classes.grow} />
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder='Search…'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }} />
          </div> */}
          <div className={classes.sectionDesktop}>
            <Tooltip
              title='Change Theme'>
              <ThemeToggleButton
                checked={preferences.theme === 'dark' ? true : false}
                onChange={(_e, checked: boolean) => { handleChangeTheme(checked) }}
                sx={{ m: 1 }} />
            </Tooltip>
            <Tooltip
              title='Account'>
              <Button
                aria-label='account of current user'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='inherit'
                size='large'
                startIcon={<AccountCircleIcon />}
                endIcon={<ChevronDownIcon />}>
                <div className={classes.buttonContent}>
                  {person?.FullName as string}
                </div>
              </Button>
            </Tooltip>
            <Tooltip
              title='Company'>
              <Button
                aria-label='company selector'
                aria-controls={mobileCompanySelectorId}
                aria-haspopup='true'
                onClick={handleCompanySelectorMenuOpen}
                color='inherit'
                endIcon={<ChevronDownIcon />}>
                <div className={classes.buttonContent}>
                  {user?.companies.find(c => c.id === user.selectedCompanyId)?.name}
                </div>
              </Button>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <Tooltip
              title='Show More'>
              <IconButton
                aria-label='show more'
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                onClick={handleMobileMenuOpen}
                color='inherit'
                size='large'>
                <MoreIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {mobileMenu}
      {renderMenu}
      {companySelector}
    </Root>
  )
}
