import { combineReducers, Reducer } from 'redux'
import { Action, PreferencesState } from 'types'
import openMenuSections from './openMenuSections'
import sideMenuExpanded from './sideMenuExpanded'
import theme from './theme'

const reducer: Reducer<PreferencesState, Action> = combineReducers({
  theme,
  sideMenuExpanded,
  openMenuSections
})

export default reducer

export function getPreferences(state: PreferencesState): PreferencesState {
  return state
}
