import { PropsWithChildren, useContext, useMemo } from 'react'
import { Attribute, DomainType, DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'

interface Props {
  newInstances?: [DomainType, DomainTypeInstance][]
  newAttributes?: Attribute[],
  newBatchInstances?: [DomainType, DomainTypeInstance][]
  onInvalidate?(): void
}

export default function AppendDomainTypeContext({
  newInstances = [],
  newAttributes = [],
  newBatchInstances = [],
  onInvalidate,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const context = useContext(DomainTypeContext)
  const domainTypeContext = useMemo(() => {
    return {
      instances: [...context.instances, ...newInstances],
      attributes: [...context.attributes, ...newAttributes],
      batchInstances: [...context.batchInstances, ...newBatchInstances],
      onInvalidate: onInvalidate ?? context.onInvalidate
    }
  }, [context.batchInstances, context.attributes, context.instances, context.onInvalidate, newBatchInstances, newAttributes, newInstances, onInvalidate])
  return (
    <DomainTypeContext.Provider value={domainTypeContext}>
      {children}
    </DomainTypeContext.Provider>
  )
}
