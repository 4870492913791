import { Alert, Button } from '@mui/material'
import { doesNotHaveRequiredSettings } from './helpers'
import { CalendarTimelineSettings } from './types'

interface Props {
  readonly domainTypeChain: CalendarTimelineSettings[]
  setPanelOpen(panel: string): void
  readonly view: 'calendar' | 'timeline'
}

export default function MissingStartDateAlert({
  domainTypeChain,
  setPanelOpen,
  view
}: Props): JSX.Element | null {
  if (!domainTypeChain.every(doesNotHaveRequiredSettings)) {
    return null
  }
  return (
    <Alert
      severity='info'
      action={(
        <Button
          color='inherit'
          size='small'
          onClick={() => setPanelOpen('calendarTimelineSettings')}>
          Open Settings Panel
        </Button>
      )}>
      {domainTypeChain[domainTypeChain.length - 1]?.domainType.PluralTitle} won&apos;t appear on the {view} until you select a Start Date in the Settings panel.
    </Alert>
  )
}