import { Autocomplete, TextField } from '@mui/material'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useEffect, useMemo, useState } from 'react'
import { Filter } from 'types'
import { Choice, DropdownElement, Metadata } from 'types/dataform'
import { MetadataCodec } from 'utils/codecs/dataform'
import { stringifyFilterValue } from 'utils/filters'
import { isNullOrUndefined } from 'utils/helpers'
import { useApi, useCancellableApiSession } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

function getMetadataFilters(
  category: string,
  subcategory: string
): Filter[] {
  return [
    {
      Property: 'Category',
      Operator: 'eq',
      Value: stringifyFilterValue(category)
    },
    {
      Property: 'Subcategory',
      Operator: 'eq',
      Value: stringifyFilterValue(subcategory)
    },
    {
      Property: 'deleted',
      Operator: 'eq',
      Value: stringifyFilterValue(false)
    }
  ]
}

function noChoiceWithValue(
  choices: Choice[],
  value: string | null
): value is string {
  return value !== null
    && !choices.find(choice => choice.Value === value)
}

function useChoices(
  dataformName: string,
  element: DropdownElement,
  value: string | null
): Choice[] {
  const [metadata, setMetadata] = useState<Metadata[] | null>(null)
  const category = isNullOrUndefined(element.ExtraParams?.MetadataChoices)
    ? element.ExtraParams?.MetadataCategory
    : 'DataformMetadata'
  const subcategory = isNullOrUndefined(element.ExtraParams?.MetadataChoices)
    ? element.ExtraParams?.MetadataSubcategory
    : `${dataformName}_${element.ExtraParams?.MetadataChoices}`
  const api = useApi()
  const search = useCancellableApiSession(api)
  useEffect(() => {
    const apiSession = search.cancelPreviousAndStartNew()
    async function load() {
      if (!apiSession.isSignedIn
        || isNullOrUndefined(category)
        || isNullOrUndefined(subcategory)) {
        return
      }
      const response = await apiSession.search(
        'Metadata',
        'Metadata',
        [],
        getMetadataFilters(category, subcategory),
        [],
        1,
        100
      )
      if (E.isRight(response)
        && t.array(MetadataCodec).is(response.right.results)) {
        setMetadata(response.right.results)
      } else {
        setMetadata([])
      }
    }
    load()
    return search.cancel
  }, [category, search, subcategory])
  return useMemo(() => {
    if (metadata === null) {
      return element.Choices ?? []
    }
    const metadataChoices = metadata.map(metadata => ({
      Text: metadata.Value,
      Value: metadata.Value
    }))
    if (noChoiceWithValue(metadataChoices, value)) {
      metadataChoices.push({
        Text: value,
        Value: value
      })
    }
    return metadataChoices
  }, [element.Choices, metadata, value])
}

export default function Dropdown({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<DropdownElement>): JSX.Element | null {
  const choices = useChoices(dataform.Name, element, value)
  return (
    <Autocomplete
      disabled={locked}
      size='small'
      options={choices}
      value={choices.find(choice => choice.Value === value) ?? null}
      getOptionLabel={choice => choice.Text}
      onChange={(event, value) => onChange(value?.Value ?? null)}
      readOnly={readOnly}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          required={required}
          label={element.Text}
          error={error}
          helperText={errorText} />
      )} />
  )
}