import { useSelector } from 'react-redux'
import { IntlProvider } from 'react-intl'
import translations from './utils/i18n/locales'
import { LOCALE } from './utils/constants'
import Layout from './components/Layout'
import { history } from './store'
import { accordionSummaryClasses, createTheme, ThemeProvider, StyledEngineProvider, tabClasses } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import ScrollToTop from 'components/navigation/ScrollToTop'
import enGbLocale from 'date-fns/locale/en-GB'
import { useMemo } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Preferences } from 'types'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { getPreferences } from 'state/reducers'

declare module '@mui/material/styles' {
  interface Palette {
    muiPrimary: Palette['primary']
    spartanRed: Palette['primary']
    spartanBlack: Palette['primary']
  }
  interface PaletteOptions {
    muiPrimary: PaletteOptions['primary']
    spartanRed: PaletteOptions['primary']
    spartanBlack: PaletteOptions['primary']
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    spartanBlack: true
  }
}

function getThemeOptions(
  mode: Preferences['theme']
): Parameters<typeof createTheme>[0] {
  return {
    typography: {
      fontFamily: [
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      mode,
      spartanRed: {
        main: '#b11921'
      },
      spartanBlack: {
        main: '#212121',
        contrastText: '#fff'
      },
      muiPrimary: mode === 'light'
        ? {
          main: '#1976d2',
          light: '#42a5f5',
          dark: '#1565c0',
          contrastText: '#fff'
        }
        : {
          main: '#90caf9',
          light: '#e3f2fd',
          dark: '#42a5f5',
          contrastText: 'rgba(0, 0, 0, 0.87)'
        }
    },
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            [`&.${tabClasses.root}`]: {
              minHeight: '48px'
            }
          }
        }
      },
      MuiIcon: {
        defaultProps: {
          baseClassName: 'material-icons-outlined'
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            [`& .${accordionSummaryClasses.content},
              &.${accordionSummaryClasses.expanded} .${accordionSummaryClasses.content}`]: {
              margin: 0
            }
          }
        }
      }
    }
  }
}

const App = function App(): JSX.Element {
  const preferences = useSelector(getPreferences)
  const theme = useMemo(
    () => createTheme(getThemeOptions(preferences.theme)),
    [preferences.theme]
  )
  const locale = LOCALE
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={enGbLocale.code}>
          <IntlProvider
            locale={locale}
            key={locale}
            messages={translations[locale]}>
            <DndProvider backend={HTML5Backend}>
              <Router history={history}>
                <ScrollToTop />
                <Layout />
              </Router>
            </DndProvider>
          </IntlProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
