import { Autocomplete, TextField } from '@mui/material'
import AttributeCell from 'components/attribute/AttributeCell'
import { useCallback, useMemo } from 'react'
import { EnumeratedValue, NonListEnumAttributeValue } from 'types'
import { getSortedEnumeratedValues, toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import LabelledInput from '../LabelledInput'

export default function NonListEnumInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  disableClearable = false,
  onChange
}: NarrowedProps<NonListEnumAttributeValue> & { disableClearable?: boolean }): JSX.Element {
  const enumeratedValues = useMemo(() => {
    return getSortedEnumeratedValues(attributeValue.attribute.EnumeratedType)
  }, [attributeValue.attribute.EnumeratedType])
  const selectedValue = useMemo(() => {
    return enumeratedValues.find(ev => ev.Value === String(attributeValue.value)) ?? null
  }, [attributeValue.value, enumeratedValues])
  const handleChange = useCallback((event: unknown, value: EnumeratedValue | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: value?.Value ?? null
    })
  }, [attributeValue.attribute, onChange])
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        {selectedValue === null
          ? <>&nbsp;</>
          : (
            <AttributeCell attributeChainValue={attributeValue} />
          )}
      </LabelledInput>
    )
  }
  return (
    <Autocomplete
      options={enumeratedValues}
      value={selectedValue}
      disabled={disabled}
      readOnly={readOnly}
      onChange={handleChange}
      getOptionLabel={(option: EnumeratedValue) => option.Description}
      disableClearable={disableClearable}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          required={attributeValue.attribute.Required ?? false}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth />
      )} />
  )
}
