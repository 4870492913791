export const LOCALE = 'en'

export const PAGE_URL = {
  HOME: '/',
  SIGNIN: '/',
  FIND: '/find/:databaseTable/:name',
  DETAILS: '/details/:databaseTable/:name/:id',
  NOT_HOME: '/:path',
  SIGNOUT: '/signout',
  RESET_PASSWORD: '/resetPassword/:username/:token'
}

export const LEGACY_PAGE_URL = {
  IMPORT_EXCEL: '/excel/import',
  IMPORT_EQUIPMENT_FILE_ATTACHMENTS: '/equipmentAttachedFiles/import',
  DATAFORM_ADMIN: '/dataforms',
  SEARCH_METADATA: '/metadata',
  TEMPLATES: '/templates/static',
  ALERT_SUBSCRIBERS: '/alerts',
  COMPANIES: '/companies',
  TRANSLATIONS: '/translations',
  LOGS: '/logs',
  SEARCH_INDEX_ADMIN: '/indexAdmin',
  REPLICATION_DB_ADMIN: '/replicationAdmin',
  API_KEYS_MANAGEMENT: '/apikeys',
  ACTIVE_DIRECTORY_SETUP: '/activedirectorysetup',
  BI_BRIDGE_CONFIGURATION: '/jsonreporting',
  LICENSING: '/licensing'
}

export const INVERTED_THEME = false

export const COLOUR = {
  RED: '#D32F2F',
  ORANGE: '#F57C00',
  GREEN: '#388E3C',
  GREY: '#455A64',
  DARK_GREY: '#222',
  MEDIUM_GREY: '#333',
  LIGHT_GREY: '#444',
  BACKGROUND: '#282828',
  HEADER: '#1b1c1b',
  TEXT: 'white',
  BLUE: '#2185d0'
}

export const ROLE = {
  ADMIN: 'Phalanx Administrator'
}

export const ENTITY_PROPERTY = {
  USER: {
    ID: 'id',
    NAME: 'name',
    USER_MAPPINGS: 'userMappings'
  },
  USER_MAPPING: {
    COMPANY_NAME: 'companyName',
    COMPANY_ID: 'companyId'
  },
  COMPANY: {
    ID: 'id',
    NAME: 'name'
  }
}

export const TOOLTIP_DELAY = 250

export const NOT_ALL_REQUIRED_VALUES_HAVE_BEEN_ENTERED = 'Not all required values have been entered'

export const PAGE_PADDING = 2

export const DATAFORM_DATE_ELEMENT_FORMAT = 'yyyy-MM-dd'
export const DATAFORM_TIME_ELEMENT_FORMAT = 'HH:mm'

export const PATH_SEPARATOR = ' ▸ '

export const OPERATIONS_DIALOG_KEY = '.'
export const FIND_DIALOG_KEY = '/'