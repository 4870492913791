import { useTheme } from '@mui/material'
import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import { DomainType } from 'types'
import { getDataformResultsIcon, getMultiDataformResultsIcon, isListAttributeValue } from 'utils/helpers'
import { useDomainType, useDomainTypeSetting } from 'utils/hooks'
import { DetailsPageSection } from './useDetails'

interface Props {
  readonly section: DetailsPageSection
  readonly domainType: DomainType
}

export default function SectionIcon({
  section,
  domainType
}: Props): JSX.Element | null {
  const theme = useTheme()
  const [dataformIcon] = useDomainTypeSetting(
    ['Dataform', 'Dataform'],
    'Icon',
    'dynamic_form'
  )
  const dashboardDomainType = useDomainType('Dashboard', 'Metadata')
  switch (section.type) {
    case 'summary':
      return (
        <AvatarCornerIcon
          avatarScale={1.2}
          background={theme.palette.primary.main}
          icon='summarize' />
      )
    case 'dataformResults': {
      if (isListAttributeValue(section.attributeValue)) {
        return null
      }
      const [cornerIcon, cornerIconColor] = getDataformResultsIcon(section.attributeValue.value, theme)
      return (
        <AvatarCornerIcon
          avatarScale={1.2}
          background={theme.palette.primary.main}
          icon={dataformIcon}
          cornerIcon={cornerIcon}
          cornerIconColor={cornerIconColor} />
      )
    }
    case 'multiDataformResults': {
      if (isListAttributeValue(section.attributeValue)) {
        return null
      }
      const [cornerIcon, cornerIconColor] = getMultiDataformResultsIcon(section.attributeValue.value, theme)
      return (
        <AvatarCornerIcon
          avatarScale={1.2}
          background={theme.palette.primary.main}
          icon={dataformIcon}
          cornerIcon={cornerIcon}
          cornerIconColor={cornerIconColor} />
      )
    }
    case 'table':
      return (
        <DomainTypeIcon
          avatar
          avatarScale={1.2}
          domainType={domainType}
          defaultIcon='table_rows' />
      )
    case 'dashboard':
      return dashboardDomainType !== null
        ? (
          <DomainTypeIcon
            avatar
            avatarScale={1.2}
            domainType={dashboardDomainType} />
        )
        : null
    case 'related':
      return (
        <DomainTypeIcon
          avatar
          avatarScale={1.2}
          domainType={domainType}
          defaultIcon='link' />
      )
  }
}