import { Dashboard } from 'types/dashboard'

export function filterDashboardsForPage(
  dashboards: Dashboard[],
  page: 'home' | 'details',
  parentDomainTypeIds: string[] = []
): Dashboard[] {
  return dashboards
    .map(dashboard => ({
      ...dashboard,
      Panels: dashboard.Panels
        ?.filter(panel => {
          if (page === 'home') {
            return panel.ShowOnHomePage
          }
          const variable = dashboard.Variables
            ?.find(variable => {
              return parentDomainTypeIds.includes(variable.DomainType)
            })
          if (variable === undefined) {
            return false
          }
          return panel.ShowOnDetailsPages?.includes(variable.Name) ?? false
        })
    }))
    .filter(dashboard => (dashboard.Panels?.length ?? 0) > 0)
}