import { Check, ErrorOutline } from '@mui/icons-material'
import { TreeItem } from '@mui/lab'
import { Box, Button, CircularProgress, Icon } from '@mui/material'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ActionEffect, ContextDomainTypeNode, ContextTree, CreateItemsSuccessEffectResult, DownloadFromFileStoreSuccessResult, DownloadInstanceSuccessResult, EffectResult, EffectResults, JobDetails, JobStatus } from 'types'
import { DownloadFileDataCodec } from 'utils/codecs'
import { wait } from 'utils/helpers'
import { useApi } from 'utils/hooks'
import ContextTreeView, { NODE_ID_SEPARATOR } from './ContextTreeView'

const RESULT_COLOUR = {
  Success: 'success',
  Error: 'error'
} as const

const RESULT_ICON = {
  Success: (
    <Icon color={RESULT_COLOUR.Success}>
      <Check />
    </Icon>
  ),
  Error: (
    <Icon color={RESULT_COLOUR.Error}>
      <ErrorOutline />
    </Icon>
  )
}

interface CreateItemsSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: CreateItemsSuccessEffectResult
}

function CreateItemsSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: CreateItemsSuccessEffectResultTreeItemProps): JSX.Element {
  return (
    <TreeItem
      nodeId={nodeId}
      label={(
        <Box
          display='flex'
          gap={1}>
          {effectResult.Name}
        </Box>
      )}>
      {effectResult.Items.map((createResult, index) => {
        if (effect.Type !== 'CreateItemsActionEffect') {
          return null
        }
        return (
          <TreeItem
            key={index}
            nodeId={[nodeId, index].join(NODE_ID_SEPARATOR)}
            label={(
              <Box
                display='flex'
                gap={1}>
                {RESULT_ICON[createResult.Result]}
                {createResult.Result === 'Success'
                  ? (
                    <DomainTypeCell
                      attributeValue={{
                        attribute: {
                          Name: 'Item',
                          Title: 'Item',
                          AttributeType: 'domainType',
                          AttributeDomainType: effect.DomainType
                        },
                        value: createResult.Item
                      }} />
                  )
                  : createResult.Message}
              </Box>
            )} />
        )
      })}
    </TreeItem>
  )
}

interface JobSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: { readonly Name: string, readonly Job: JobDetails }
}

function JobSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: JobSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  const [pollCounter, setPollCounter] = useState(0)
  const [jobStatus, setJobStatus] = useState<JobStatus | null>(null)
  const getJobStatus = useCallback(async () => {
    if (!api.isSignedIn) {
      return
    }
    if (effectResult.Job.Id === '') {
      return
    }
    const response = await api.getJobStatus(effectResult.Job.Id)
    if (E.isLeft(response)) {
      await wait(2000)
      setPollCounter(pollCounter + 1)
      return
    }
    setJobStatus(response.right)
    if (response.right.Status === 'Completed'
      || response.right.Status === 'Errored') {
      return
    }
    await wait(2000)
    setPollCounter(pollCounter + 1)
  }, [api, effectResult.Job.Id, pollCounter])
  useEffect(() => {
    getJobStatus()
  }, [getJobStatus])
  const label = useMemo(() => {
    if (jobStatus?.Status === 'Completed') {
      if (t.type({ errorText: t.string }).is(jobStatus.Data)
        && jobStatus.Data.errorText) {
        return (
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Error}
            {jobStatus.Data.errorText}
          </Box>
        )
      }
      if (effect.Type === 'QueueJobActionEffect'
        && effect.DownloadFile
        && DownloadFileDataCodec.is(jobStatus.Data)) {
        return (
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            <Button
              size='small'
              variant='text'
              onClick={async () => {
                if (!api.isSignedIn) {
                  return
                }
                if (DownloadFileDataCodec.is(jobStatus.Data)) {
                  await api.downloadFromCache(jobStatus.Data.fileName)
                }
              }}>
              Download
            </Button>
          </Box>
        )
      }
      return (
        <Box
          display='flex'
          gap={1}>
          {RESULT_ICON.Success}
          Success
        </Box>
      )
    }
    if (effectResult.Job.Id === '') {
      return (
        <Box
          display='flex'
          gap={1}>
          {RESULT_ICON.Error}
          {effectResult.Job.Message}
        </Box>
      )
    }
    if (jobStatus?.Status === 'Errored') {
      return (
        <Box
          display='flex'
          gap={1}>
          {RESULT_ICON.Error}
          {jobStatus.ErrorMessage}
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        gap={1}>
        <CircularProgress size={20} />
      </Box>
    )
  }, [api, effect, effectResult.Job.Id, effectResult.Job.Message, jobStatus])
  return (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={label} />
    </TreeItem>
  )
}

interface DownloadFromFileStoreSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: DownloadFromFileStoreSuccessResult
}

function DownloadFromFileStoreSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: DownloadFromFileStoreSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  return (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            <Button
              size='small'
              variant='text'
              onClick={async () => {
                if (!api.isSignedIn) {
                  return
                }
                await api.downloadFromInstance(
                  effectResult.DomainTypeName,
                  effectResult.Id,
                  effectResult.FileId,
                  effectResult.FileName
                )
              }}>
              Download
            </Button>
          </Box>
        )} />
    </TreeItem>
  )
}

interface DownloadInstanceSuccessEffectResultTreeItemProps {
  readonly nodeId: string
  readonly effect: ActionEffect
  readonly effectResult: DownloadInstanceSuccessResult
}

function DownloadInstanceSuccessEffectResultTreeItem({
  nodeId,
  effect,
  effectResult
}: DownloadInstanceSuccessEffectResultTreeItemProps): JSX.Element {
  const api = useApi()
  return (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            <Button
              size='small'
              variant='text'
              onClick={async () => {
                if (!api.isSignedIn) {
                  return
                }
                await api.downloadInstance(
                  effectResult.DomainTypeName,
                  effectResult.InstanceId
                )
              }}>
              Download
            </Button>
          </Box>
        )} />
    </TreeItem>
  )
}

interface EffectResultTreeItemProps {
  readonly nodeIdPath: string[]
  readonly effect: ActionEffect | undefined
  readonly effectResult: EffectResult
}

function EffectResultTreeItem({
  nodeIdPath,
  effect,
  effectResult
}: EffectResultTreeItemProps): JSX.Element {
  const nodeId = [...nodeIdPath, effectResult.Name].join(NODE_ID_SEPARATOR)
  if (effectResult.Result === 'Error') {
    return (
      <TreeItem
        nodeId={nodeId}
        label={effectResult.Name}>
        <TreeItem
          nodeId={[nodeId, 'Error'].join(NODE_ID_SEPARATOR)}
          label={(
            <Box
              display='flex'
              gap={1}>
              {RESULT_ICON.Error}
              {effectResult.Message}
            </Box>
          )} />
      </TreeItem>
    )
  }
  const defaultTreeItem = (
    <TreeItem
      nodeId={nodeId}
      label={effectResult.Name}>
      <TreeItem
        nodeId={[nodeId, 'Success'].join(NODE_ID_SEPARATOR)}
        label={(
          <Box
            display='flex'
            gap={1}>
            {RESULT_ICON.Success}
            Success
          </Box>
        )} />
    </TreeItem>
  )
  if (effect === undefined) {
    return defaultTreeItem
  }
  switch (effectResult.Type) {
    case 'CreateItemsActionEffect': {
      return (
        <CreateItemsSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    }
    case 'QueueJobActionEffect':
    case 'SendEmailActionEffect':
    case 'SendPushNotificationActionEffect':
      return (
        <JobSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    case 'DownloadFromFileStoreActionEffect':
      return (
        <DownloadFromFileStoreSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    case 'DownloadInstanceActionEffect':
      return (
        <DownloadInstanceSuccessEffectResultTreeItem
          nodeId={nodeId}
          effect={effect}
          effectResult={effectResult} />
      )
    default:
      return defaultTreeItem
  }
}

export interface ActionEffectResults {
  readonly PathEffectResults: ContextTree<EffectResults>
  readonly RequestEffectResults: EffectResult[]
}

interface Props {
  readonly effects: ActionEffect[]
  readonly actionEffectResults: ActionEffectResults
}

export default function ActionEffectResultsView({
  effects,
  actionEffectResults
}: Props): JSX.Element {
  const getAdditionalNodeIds = useCallback((
    node: ContextDomainTypeNode<EffectResults> | null,
    nodeIdPath: string[]
  ) => {
    if (node === null) {
      return actionEffectResults.RequestEffectResults.map(effectResult => effectResult.Name)
    }
    return node.EffectResults?.map(effectResult => [...nodeIdPath, effectResult.Name].join(NODE_ID_SEPARATOR)) ?? []
  }, [actionEffectResults.RequestEffectResults])
  const renderAdditionalNodes = useCallback((
    node: ContextDomainTypeNode<EffectResults> | null,
    nodeIdPath: string[]
  ) => {
    if (node === null) {
      return actionEffectResults.RequestEffectResults.map(effectResult => {
        const effect = effects.find(effect => effect.Name === effectResult.Name)
        return (
          <EffectResultTreeItem
            key={effectResult.Name}
            effect={effect}
            effectResult={effectResult}
            nodeIdPath={nodeIdPath} />
        )
      })
    }
    return node.EffectResults?.map(effectResult => {
      const effect = effects.find(effect => effect.Name === effectResult.Name)
      return (
        <EffectResultTreeItem
          key={effectResult.Name}
          effect={effect}
          effectResult={effectResult}
          nodeIdPath={nodeIdPath} />
      )
    }) ?? []
  }, [actionEffectResults.RequestEffectResults, effects])
  return (
    <ContextTreeView
      contextTree={actionEffectResults.PathEffectResults}
      getAdditionalNodeIds={getAdditionalNodeIds}
      renderAdditionalNodes={renderAdditionalNodes} />
  )
}