import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Sort } from 'types'
import { getDomainTypeSetting } from 'utils/helpers'

export function useDefaultSorts(domainType: DomainType): Sort[] {
  const domainTypes = useSelector(getAllDomainTypes)
  return useMemo((): Sort[] => {
    const defaultSort = getDomainTypeSetting(domainTypes, domainType, 'DefaultSort')
    const defaultSortDirection = getDomainTypeSetting(domainTypes, domainType, 'DefaultSortDirection')

    return defaultSort !== null && defaultSort !== undefined
      ? [{
        Direction: defaultSortDirection ?? 'asc',
        Property: defaultSort
      }]
      : []
  }, [domainType, domainTypes])
}