import { TabContext, TabPanel } from '@mui/lab'
import { Box, Paper, Stack, Tab, Tabs, styled } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useMemo } from 'react'
import { DomainType } from 'types'
import { PAGE_PADDING } from 'utils/constants'
import { useDomainTypeSetting } from 'utils/hooks'
import Section from './Section'
import SectionIcon from './SectionIcon'
import { DetailsPageSection, HighlightedRow } from './useDetails'

const Root = styled(Box)(({ theme }) => ({
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.3
  }
}))

interface SectionTabPanelProps {
  section: DetailsPageSection
  domainType: DomainType
  isLoading: boolean
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
  selectedTab: string
  tabOrientation: 'horizontal' | 'vertical'
}

function SectionTabPanel({
  section,
  domainType,
  isLoading,
  highlightedRow,
  onHighlightRow,
  selectedTab,
  tabOrientation
}: SectionTabPanelProps): JSX.Element {
  const saveDomainTypeSettingButton = useMemo(() => {
    return section.type !== 'summary' && (
      <SaveDomainTypeSettingButton
        domainType={domainType}
        setting='Sections'
        value={[
          {
            Id: section.id,
            Title: section.title,
            Hidden: section.hidden,
            Expanded: section.expanded
          }
        ]}
        mode='edit'
        title='Section' />
    )
  }, [domainType, section.expanded, section.hidden, section.id, section.title, section.type])
  return (
    <TabPanel
      key={section.id}
      value={section.id}
      sx={{
        flexGrow: 1,
        height: tabOrientation === 'horizontal'
          ? 'calc(100% - 48px)'
          : '100%',
        overflow: 'auto',
        padding: PAGE_PADDING,
        pt: 1,
        ...selectedTab === section.id
          ? {
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'flex-start'
          }
          : undefined
      }}>
      <Stack
        direction='row'
        gap={2}
        height='40px'
        alignItems='center'>
        <SectionIcon
          section={section}
          domainType={section.domainType} />
        {`${section.title} ${(section.type === 'table' ? `(${(section.rows ?? []).length})` : '')}`}
        <Box flexGrow={1} />
        {saveDomainTypeSettingButton}
      </Stack>
      <Box>
        <Section
          section={section}
          isLoading={isLoading}
          highlightedRow={highlightedRow}
          onHighlightRow={onHighlightRow} />
      </Box>
    </TabPanel>
  )
}

interface Props {
  domainType: DomainType
  isLoading: boolean
  sections: DetailsPageSection[]
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
  selectedTab: string
  onTabChange(id: string): void
}

export default function TabsView({
  domainType,
  isLoading,
  sections,
  highlightedRow,
  onHighlightRow,
  selectedTab,
  onTabChange
}: Props): JSX.Element | null {
  const [tabOrientation] = useDomainTypeSetting(
    domainType,
    'TabOrientation',
    'vertical'
  )
  const visibleSections = sections
    .filter(section => section.type !== 'related' || !section.hidden)
  return (
    <Root
      component={Paper}
      flexGrow={1}
      height='calc(100vh - 64px - 40px - 16px - 16px - 48px)'
      display={tabOrientation === 'vertical'
        ? 'flex'
        : undefined}>
      <TabContext value={selectedTab}>
        <Tabs
          value={selectedTab}
          onChange={(event, id: string) => onTabChange(id)}
          variant='scrollable'
          orientation={tabOrientation}
          sx={tabOrientation === 'vertical'
            ? {
              flexShrink: 0,
              borderRight: 1,
              borderColor: 'divider',
              maxHeight: '100%',
              alignItems: 'stretch'
            }
            : undefined}>
          {visibleSections.map(section => (
            <Tab
              key={section.id}
              sx={{
                gap: 1,
                p: 1,
                justifyContent: 'flex-start',
                maxWidth: '200px',
                textAlign: 'start',
                lineHeight: 1.1
              }}
              id={section.id}
              value={section.id}
              label={`${section.title} ${(section.type === 'table' ? `(${(section.rows ?? []).length})` : '')}`}
              icon={(
                <SectionIcon
                  section={section}
                  domainType={section.domainType} />
              )}
              iconPosition='start' />
          ))}
        </Tabs>
        {visibleSections.map(section => (
          <SectionTabPanel
            key={section.id}
            section={section}
            domainType={domainType}
            isLoading={isLoading}
            highlightedRow={highlightedRow}
            onHighlightRow={onHighlightRow}
            selectedTab={selectedTab}
            tabOrientation={tabOrientation} />
        ))}
      </TabContext>
    </Root>
  )
}