import { TextField } from '@mui/material'
import { Element, ElementType } from 'types/dataform'

interface Props {
  readonly element: Element
}

export default function NotSupported({
  element
}: Props): JSX.Element | null {
  return (
    <TextField
      fullWidth
      label={element.Text}
      id={element.Alias}
      variant='outlined'
      size='small'
      error
      helperText={`Elements of type ${ElementType[element.ElementType]} are not yet supported by this form`}
      inputProps={{
        readOnly: true
      }} />
  )
}
