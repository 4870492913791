import { useEffect, useRef } from 'react'

export default function useMountEffect(fun: (...args: unknown[]) => void): void {
  const hasRunRef = useRef(false)
  useEffect(() => {
    if (hasRunRef.current) {
      return
    }
    hasRunRef.current = true
    fun()
  }, [fun])
}