import { styled, TextField } from '@mui/material'
import { PickersDay, pickersDayClasses, PickersDayProps, StaticDatePicker } from '@mui/x-date-pickers'
import { DateTime, Interval } from 'luxon'
import { ComponentType } from 'react'
import { CalendarProps } from './types'

interface Props {
  readonly date: CalendarProps['date']
  readonly view: CalendarProps['view']
  readonly onDateChange: CalendarProps['onDateChange']
  onClose(): void
}

interface CustomPickerDayProps extends PickersDayProps<DateTime> {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: prop =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  [`&.${pickersDayClasses.selected}`]: {
    transition: 'none'
  },
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as ComponentType<CustomPickerDayProps>

export default function FindDatePickerPanel({
  date,
  view,
  onDateChange,
  onClose
}: Props): JSX.Element {
  const renderWeekPickerDay = (
    renderDate: DateTime,
    selectedDates: Array<DateTime | null>,
    pickersDayProps: PickersDayProps<DateTime>
  ) => {
    const value = DateTime.fromJSDate(date)
    const start = value.startOf(view)
    const end = value.endOf(view)

    const dayIsBetween = Interval.fromDateTimes(start, end).contains(renderDate)
    const isFirstDay = renderDate.hasSame(start, 'day')
    const isLastDay = renderDate.hasSame(end, 'day')
    return (
      <CustomPickersDay
        {...pickersDayProps}
        autoFocus={false}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay} />
    )
  }
  return (
    <StaticDatePicker
      displayStaticWrapperAs='desktop'
      label='Date'
      value={date}
      onChange={newValue => {
        if (newValue !== null) {
          const newDate = DateTime.fromISO(newValue.toString()).toJSDate()
          onDateChange(newDate)
        }
      }}
      showDaysOutsideCurrentMonth
      renderDay={renderWeekPickerDay}
      renderInput={(params) => (
        <TextField
          {...params}
          size='small' />
      )} />
  )
}