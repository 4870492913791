import { HorizontalSplitOutlined, List, Tab, VerticalSplitOutlined } from '@mui/icons-material'
import { Box, ButtonGroup, ClickAwayListener, Paper, Popper, Stack } from '@mui/material'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import TextIconButton from 'components/utils/TextIconButton'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { ComponentProps, useEffect, useRef, useState } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { useDomainTypeSetting } from 'utils/hooks'
import DetailsTabOrientationPanel from './DetailsTabOrientationPanel'
import DetailsViewPanel from './DetailsViewPanel'
import { DetailsPageView } from './detailsPageReducer'

interface Props {
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance
  readonly view: DetailsPageView
  onChangeView(view: DetailsPageView): void
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      {...props} />
  ),
  Button: props => (
    <TextIconButton
      iconOnly
      {...props} />
  ),
  Empty: null
}

export default function DetailsToolbar({
  domainType,
  instance,
  view,
  onChangeView
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    const rootElement = ref.current
    if (rootElement !== null) {
      setAnchorEl(rootElement)
    }
  }, [])
  const [panelOpen, setPanelOpen] = useState<string | false>(false)
  const handleClickAway = () => {
    setPanelOpen(false)
  }
  const [tabOrientation] = useDomainTypeSetting(
    domainType,
    'TabOrientation',
    'vertical'
  )
  return (
    <>
      <Stack
        ref={ref}
        direction='row'
        gap={2}
        alignItems='center'
        pb={1}
        flexWrap='nowrap'>
        <ButtonGroup variant='contained'>
          <TooltipIconButton
            icon={view === 'expandableList'
              ? <List />
              : <Tab />}
            tooltipText={view === 'expandableList'
              ? 'Expandable List View'
              : 'Tabs View'}
            color='primary'
            onClick={event => {
              event.preventDefault()
              setPanelOpen(panelOpen === 'view' ? false : 'view')
            }} />
          {view === 'tabs' && (
            <TooltipIconButton
              icon={tabOrientation === 'horizontal'
                ? <HorizontalSplitOutlined />
                : <VerticalSplitOutlined />}
              tooltipText={tabOrientation === 'horizontal'
                ? 'Horizontal Tabs'
                : 'Vertical Tabs'}
              color='primary'
              onClick={event => {
                event.preventDefault()
                setPanelOpen(panelOpen === 'tabOrientation' ? false : 'tabOrientation')
              }} />
          )}
        </ButtonGroup>
        <Box flexGrow={1} />
        <DomainTypeButtons
          domainType={domainType}
          instances={[instance]}
          on='DetailsHeader'
          components={components}
          priority='medium'
          renderPopoverButtonInsideContainer />
      </Stack>
      <Popper
        open={panelOpen !== false}
        anchorEl={anchorEl}
        placement='bottom-start'
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          zIndex: 1300
        }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={8}
            sx={{
              width: '100%',
              minWidth: '300px',
              maxHeight: '450px'
            }}>
            {panelOpen === 'view' && (
              <DetailsViewPanel
                domainType={domainType}
                view={view}
                onChangeView={onChangeView}
                onClose={handleClickAway} />
            )}
            {panelOpen === 'tabOrientation' && (
              <DetailsTabOrientationPanel
                domainType={domainType}
                onClose={handleClickAway} />
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
}