import DomainTypeIcon from 'components/domainType/DomainTypeIcon'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { DomainType, EnumeratedValue } from 'types'
import { PAGE_URL } from 'utils/constants'
import { getDomainTypeAttribute, getDomainTypeSetting, getParentDomainTypes, isInRole } from 'utils/helpers'
import { useDomainType } from 'utils/hooks'

interface MenuItem {
  readonly text: string
  readonly icon: JSX.Element
  readonly path: string
  readonly domainType: DomainType
}

interface MenuSection {
  readonly category: EnumeratedValue | null
  readonly items: MenuItem[]
}

export function useMenuSections(): MenuSection[] {
  const user = useSelector(getUser)
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeDomainType = useDomainType('DomainType', 'DomainType')
  const categoryEnumeratedType = useMemo(() => {
    if (domainTypeDomainType === null) {
      return null
    }
    const attribute = getDomainTypeAttribute(domainTypes, domainTypeDomainType, 'Category')
    if (attribute?.AttributeType !== 'enum') {
      return null
    }
    return attribute.EnumeratedType
  }, [domainTypeDomainType, domainTypes])

  return useMemo<MenuSection[]>(() => {
    const menuItems = Object.values(domainTypes)
      .filter((type: DomainType | undefined): type is DomainType => type !== undefined)
      .filter(domainType => domainType.Find)
      .filter(domainType => isInRole(user, getDomainTypeSetting(domainTypes, domainType, 'ViewRole') ?? null))
      .sort((v1, v2) => {
        const v1Parents = getParentDomainTypes(domainTypes, v1).map(dt => dt.PluralTitle).reverse().join('')
        const v2Parents = getParentDomainTypes(domainTypes, v2).map(dt => dt.PluralTitle).reverse().join('')
        return v1Parents > v2Parents ? 1 : -1
      })
      .map((domainType): readonly [EnumeratedValue | null, MenuItem] => [
        categoryEnumeratedType?.Values
          .find(enumeratedValue => enumeratedValue.Value === domainType.Category) ?? null,
        {
          text: domainType.PluralTitle,
          icon: (
            <DomainTypeIcon
              avatar
              avatarScale={1.2}
              domainType={domainType} />
          ),
          path: generatePath(
            PAGE_URL.FIND,
            {
              databaseTable: domainType.DatabaseTable ?? domainType.Name,
              name: domainType.Name
            }
          ),
          domainType
        }
      ] as const)
    return (categoryEnumeratedType?.Values ?? [])
      .sort((v1, v2) => (v1.Order ?? 0) > (v2.Order ?? 0) ? 1 : -1)
      .map((enumeratedValue): MenuSection => ({
        category: enumeratedValue,
        items: menuItems
          .filter(([category]) => category?.Value === enumeratedValue.Value)
          .map(([, menuItem]) => menuItem)
      }))
      .concat([{
        category: null,
        items: menuItems
          .filter(([category]) => category === null)
          .map(([, menuItem]) => menuItem)
      }])
      .filter(section => section.items.length > 0)
  }, [categoryEnumeratedType?.Values, domainTypes, user])
}