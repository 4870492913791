import { TextField } from '@mui/material'
import { ChangeEvent, useCallback } from 'react'
import { NonListStringAttributeValue } from 'types'
import { toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'
import ColourInput from './ColourInput'
import JSONInput from './JSONInput'

export default function NonListStringInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<NonListStringAttributeValue>): JSX.Element {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: event.target.value
    })
  }, [attributeValue.attribute, onChange])
  if (attributeValue.attribute.Format === 'colour') {
    return (
      <ColourInput
        attributeValue={attributeValue}
        onChange={onChange}
        readOnly={readOnly}
        pathError={pathError} />
    )
  }
  if (attributeValue.attribute.Format === 'JSON') {
    return (
      <JSONInput
        attributeValue={attributeValue}
        onChange={onChange}
        readOnly={readOnly}
        pathError={pathError} />
    )
  }
  return (
    <TextField
      value={attributeValue.value ?? ''}
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      variant='standard'
      fullWidth
      size='small'
      required={attributeValue.attribute.Required ?? false}
      error={pathError !== undefined}
      helperText={toErrorText(pathError)}
      onChange={handleChange}
      type={attributeValue.attribute.Format === 'password' ? 'password' : undefined}
      inputProps={{
        readOnly
      }} />
  )
}
