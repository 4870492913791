import { ExpandLessOutlined, ExpandMoreOutlined } from '@mui/icons-material'
import { Collapse, Divider, Fade, List, ListItemButton, ListItemIcon, ListItemText, Toolbar, useTheme } from '@mui/material'
import AvatarCornerIcon from 'components/utils/AvatarCornerIcon'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { setMenuSectionOpen } from 'state/actions/preferences'
import { getPreferences } from 'state/reducers'
import { getContrastingColour, isNullOrUndefined } from 'utils/helpers'
import { useMenuSections } from 'utils/hooks'
import ScrollBar from '../ScrollBar'
import Drawer from './Drawer'
import classes from './SideMenu.classes'

export default function SideMenu(): JSX.Element {
  const menuSections = useMenuSections()
  const {
    sideMenuExpanded,
    openMenuSections
  } = useSelector(getPreferences)
  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  return (
    <Drawer
      open={sideMenuExpanded}
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper
      }}>
      <Toolbar />
      <ScrollBar>
        {menuSections.map((section, i) => {
          const id = section.category?.Value ?? null
          const open = openMenuSections.includes(id)
          const colour = getContrastingColour(
            section.category?.Colour ?? theme.palette.primary.main,
            theme.palette.mode,
            theme.palette.contrastThreshold
          )
          return (
            <div key={i}>
              <List
                disablePadding>
                <ListItemButton
                  sx={{
                    borderLeft: `8px solid ${colour}`
                  }}
                  onClick={() => {
                    dispatch(setMenuSectionOpen(id, !open))
                  }}>
                  {isNullOrUndefined(section.category?.Icon)
                    ? null
                    : (
                      <Collapse
                        orientation='horizontal'
                        in={!open}>
                        <Fade in={!open}>
                          <ListItemIcon>
                            <AvatarCornerIcon
                              avatarScale={1.2}
                              background={colour}
                              icon={section.category?.Icon ?? ''} />
                          </ListItemIcon>
                        </Fade>
                      </Collapse>
                    )}
                  <ListItemText
                    primary={section.category?.Description ?? 'Other'}
                    primaryTypographyProps={{
                      fontWeight: 600
                    }} />
                  {open
                    ? <ExpandLessOutlined />
                    : <ExpandMoreOutlined />}
                </ListItemButton>
                <Collapse
                  in={open}
                  timeout='auto'>
                  <List
                    dense
                    sx={{
                      borderLeft: `8px solid ${colour}`
                    }}>
                    {section.items.map((item) => (
                      <ListItemButton
                        key={item.text}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}>
                        <Collapse
                          orientation='horizontal'
                          in={open}>
                          <Fade
                            in={open}>
                            <ListItemIcon>
                              {item.icon}
                            </ListItemIcon>
                          </Fade>
                        </Collapse>
                        <ListItemText primary={item.text} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </List>
              <Divider />
            </div>
          )
        })}
      </ScrollBar>
    </Drawer>
  )
}