import * as t from 'io-ts'
import { JsonFromString } from 'io-ts-types'
import {
  ActionContext,
  ActionDomainTypeButton,
  ActionEffect,
  ActionEffectBase,
  ActionEffectValue,
  ActionParameter,
  ActionParameterBase,
  ActionPaths,
  ActionResponse,
  AdalProperties,
  AddToListActionEffect,
  AddToListErrorEffectResult,
  AddToListSuccessEffectResult,
  ApiError,
  AssignTaskActionEffect,
  AssignTaskErrorEffectResult,
  AssignTaskSuccessEffectResult,
  Attribute,
  AttributeActionParameter,
  AttributeType,
  BaseAttribute,
  BaseDomainTypeButton,
  BaseJobStatus,
  BoolAttribute,
  ButtonLocation,
  Company,
  CompanyActionEffectValue,
  CompletedJobStatus,
  ContextActionEffectValue,
  ContextRefAttribute,
  CopyToFileStoreActionEffect,
  CopyToFileStoreErrorResult,
  CopyToFileStoreSuccessResult,
  CreateDomainTypeButton,
  CreateErrorResult,
  CreateItemsActionEffect,
  CreateItemsErrorEffectResult,
  CreateItemsSuccessEffectResult,
  CreateResult,
  CreateSuccessResult,
  DataformResultsAttribute,
  DateAttribute,
  DateTimeAttribute,
  DeleteDomainTypeButton,
  DomainType,
  DomainTypeAction,
  DomainTypeActionEffectValue,
  DomainTypeAttribute,
  DomainTypeButton,
  DomainTypeInstance,
  DomainTypeOverride,
  DomainTypeOverrider,
  DomainTypeSettings,
  DownloadFileData,
  DownloadFromFileStoreActionEffect,
  DownloadFromFileStoreErrorResult,
  DownloadFromFileStoreSuccessResult,
  DownloadInstanceActionEffect,
  DownloadInstanceErrorResult,
  DownloadInstanceSuccessResult,
  EditableDomainTypeSettings,
  EditDomainTypeButton,
  EffectResult,
  EffectResultBase,
  EnumAttribute,
  EnumeratedType,
  EnumeratedValue,
  ErroredJobStatus,
  FileActionParameter,
  Filter,
  GuidAttribute,
  InputAttributeActionParameter,
  InstanceActionEffectValue,
  JobDetails,
  JobStatus,
  MultiDataformResultsAttribute,
  NumberAttribute,
  ParametersActionEffectValue,
  PathError,
  PathErrors,
  Person,
  PersonActionEffectValue,
  Query,
  QueueJobActionEffect,
  QueueJobErrorResult,
  QueueJobSuccessResult,
  ReadyJobStatus,
  RefAttribute,
  ReservedJobStatus,
  SaveUpdatesErrorResult,
  SaveUpdatesSuccessResult,
  SearchResponse,
  Section,
  SendEmailActionEffect,
  SendEmailErrorResult,
  SendEmailSuccessResult,
  SendPushNotificationActionEffect,
  SendPushNotificationErrorResult,
  SendPushNotificationSuccessResult,
  ServerError,
  SetValueActionEffect,
  SetValueErrorEffectResult,
  SetValueSuccessEffectResult,
  Sort,
  StaticActionParameter,
  StringAttribute,
  Theme,
  User,
  ValueTypes
} from 'types'
import { ResultsCodec } from './dataform'

export const JsonFromUnknown = t.string.pipe(JsonFromString)

interface JsonStringBrand {
  readonly JsonString: unique symbol
}

export const JsonStringCodec = t.brand(
  t.string,
  (value): value is t.Branded<string, JsonStringBrand> => {
    if (value === '') {
      return true
    }
    try {
      JSON.parse(value)
      return true
    } catch {
      return false
    }
  },
  'JsonString'
)

const BaseFilterCodec = t.readonly(t.type({
  Property: t.string,
  Operator: t.string,
  Value: t.union([JsonStringCodec, t.null])
}))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const JsonFilterCodec: t.Type<t.TypeOf<typeof BaseFilterCodec>, any> = BaseFilterCodec

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FilterCodec: t.Type<Filter, any> = BaseFilterCodec

export const EnumeratedValueCodec: t.Type<EnumeratedValue> = t.intersection([
  t.type({
    Id: t.string,
    Value: t.string,
    Description: t.string
  }),
  t.partial({
    Order: t.union([t.number, t.null]),
    Icon: t.union([t.string, t.null]),
    Colour: t.union([t.string, t.null])
  })
])

export const EnumeratedTypeCodec: t.Type<EnumeratedType> = t.readonly(t.type({
  ExternalId: t.string,
  GlobalExternalId: t.string,
  Values: t.array(EnumeratedValueCodec)
}))

export const JsonSortCodec = t.readonly(t.type({
  Property: t.string,
  Direction: t.union([t.literal('asc'), t.literal('desc'), t.null])
}))

export const SortCodec: t.Type<Sort> = JsonSortCodec

export const AttributeTypeCodecs: { [T in AttributeType]: t.Type<ValueTypes[T]> } = {
  string: t.string,
  bool: t.boolean,
  number: t.number,
  guid: t.string,
  domainType: t.UnknownRecord,
  date: t.string,
  dateTime: t.string,
  enum: t.union([t.string, t.number]),
  ref: t.string,
  contextRef: t.string,
  dataformResults: ResultsCodec,
  multiDataformResults: t.record(t.string, t.union([ResultsCodec, t.null, t.undefined]))
}

const BaseAttributeCodec: t.Type<BaseAttribute> = t.intersection([
  t.readonly(t.type({
    Name: t.string,
    Title: t.string
  })),
  t.readonly(t.partial({
    Id: t.string,
    List: t.boolean,
    Single: t.union([t.boolean, t.null]),
    Required: t.union([t.boolean, t.null]),
    BypassDomainTypeFilters: t.union([t.array(t.string), t.null])
  }))
])

export const StringAttributeCodec: t.Type<StringAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.intersection([
    t.type({
      AttributeType: t.literal('string')
    }),
    t.partial({
      Format: t.union([t.literal('colour'), t.literal('JSON'), t.literal('email'), t.literal('password'), t.null]),
      Translated: t.union([t.boolean, t.null])
    })
  ]))
])

export const NumberAttributeCodec: t.Type<NumberAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('number')
  }))
])

export const BoolAttributeCodec: t.Type<BoolAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('bool')
  }))
])

export const DateAttributeCodec: t.Type<DateAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('date')
  }))
])

export const DateTimeAttributeCodec: t.Type<DateTimeAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('dateTime')
  }))
])

export const GuidAttributeCodec: t.Type<GuidAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('guid')
  }))
])

export const DomainTypeAttributeCodec: t.Type<DomainTypeAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('domainType'),
    AttributeDomainType: t.string
  })),
  t.partial({
    Filters: t.union([t.array(FilterCodec), t.null])
  })
])

export const RefAttributeCodec: t.Type<RefAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('ref'),
    AttributeDomainType: t.string
  })),
  t.partial({
    Filters: t.union([t.array(FilterCodec), t.null])
  })
])

export const ContextRefAttributeCodec: t.Type<ContextRefAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('contextRef'),
    ContextDomainType: t.string,
    ContextAttributeName: t.string,
    AttributeDomainType: t.string
  })),
  t.partial({
    Filters: t.union([t.array(FilterCodec), t.null])
  })
])

export const EnumAttributeCodec: t.Type<EnumAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.type({
    AttributeType: t.literal('enum'),
    EnumeratedType: EnumeratedTypeCodec
  }))
])

export const DataformResultsAttributeCodec: t.Type<DataformResultsAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.intersection([
    t.type({
      AttributeType: t.literal('dataformResults'),
      SelectBy: t.union([
        t.literal('name'),
        t.literal('selectionCriteria')
      ])
    }),
    t.partial({
      DataformName: t.union([t.string, t.null]),
      SelectionCategory: t.union([t.string, t.null]),
      SelectionSubcategory: t.union([t.string, t.null]),
      DefaultSelectionProperties: t.union([t.array(t.string), t.null]),
      Purpose: t.union([t.string, t.null])
    })
  ]))
])

export const MultiDataformResultsAttributeCodec: t.Type<MultiDataformResultsAttribute> = t.intersection([
  BaseAttributeCodec,
  t.readonly(t.intersection([
    t.type({
      AttributeType: t.literal('multiDataformResults'),
      SelectBy: t.union([
        t.literal('name'),
        t.literal('selectionCriteria')
      ])
    }),
    t.partial({
      DataformNames: t.union([t.array(t.string), t.null]),
      SelectionCategory: t.union([t.string, t.null]),
      SelectionSubcategory: t.union([t.string, t.null]),
      DefaultSelectionProperties: t.union([t.array(t.string), t.null]),
      Purpose: t.union([t.string, t.null])
    })
  ]))
])

export const AttributeCodec: t.Type<Attribute> = t.union([
  StringAttributeCodec,
  NumberAttributeCodec,
  BoolAttributeCodec,
  DateAttributeCodec,
  DateTimeAttributeCodec,
  GuidAttributeCodec,
  DomainTypeAttributeCodec,
  RefAttributeCodec,
  ContextRefAttributeCodec,
  EnumAttributeCodec,
  DataformResultsAttributeCodec,
  MultiDataformResultsAttributeCodec
])

export const DomainTypeInstanceCodec: t.Type<DomainTypeInstance> = t.UnknownRecord

export const SearchResponseCodec: t.Type<SearchResponse> = t.readonly(t.type({
  totalHits: t.number,
  results: t.array(DomainTypeInstanceCodec)
}))

export const EffectResultBaseCodec: t.Type<EffectResultBase> = t.type({
  Name: t.string
})

export const SetValueSuccessEffectResultCodec: t.Type<SetValueSuccessEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SetValueActionEffect'),
    Result: t.literal('Success')
  })
])

export const SetValueErrorEffectResultCodec: t.Type<SetValueErrorEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SetValueActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const CreateSuccessResultCodec: t.Type<CreateSuccessResult> = t.type({
  Result: t.literal('Success'),
  Item: DomainTypeInstanceCodec
})

export const CreateErrorResultCodec: t.Type<CreateErrorResult> = t.type({
  Result: t.literal('Error'),
  Item: DomainTypeInstanceCodec,
  Message: t.string
})

export const CreateResultCodec: t.Type<CreateResult> = t.union([
  CreateSuccessResultCodec,
  CreateErrorResultCodec
])

export const JobDetailsCodec: t.Type<JobDetails> = t.intersection([
  t.type({
    Id: t.string
  }),
  t.partial({
    Message: t.string
  })
])

export const BaseJobStatusCodec: t.Type<BaseJobStatus> = t.type({
  Id: t.string,
  JobName: t.string,
  LastModified: t.string
})

export const ReadyJobStatusCodec: t.Type<ReadyJobStatus> = t.intersection([
  BaseJobStatusCodec,
  t.type({
    Status: t.literal('Ready')
  })
])

export const ReservedJobStatusCodec: t.Type<ReservedJobStatus> = t.intersection([
  BaseJobStatusCodec,
  t.type({
    Status: t.literal('Reserved')
  })
])

export const ErroredJobStatusCodec: t.Type<ErroredJobStatus> = t.intersection([
  BaseJobStatusCodec,
  t.type({
    Status: t.literal('Errored'),
    ErrorMessage: t.string
  })
])

export const CompletedJobStatusCodec: t.Type<CompletedJobStatus> = t.intersection([
  BaseJobStatusCodec,
  t.type({
    Status: t.literal('Completed'),
    Data: t.unknown
  })
])

export const DownloadFileDataCodec: t.Type<DownloadFileData> = t.type({
  fileName: t.string,
  downloadAs: t.string,
  absoluteFilePath: t.string
})

export const JobStatusCodec: t.Type<JobStatus> = t.union([
  ReadyJobStatusCodec,
  ReservedJobStatusCodec,
  ErroredJobStatusCodec,
  CompletedJobStatusCodec
])

export const CreateItemsSuccessEffectResultCodec: t.Type<CreateItemsSuccessEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('CreateItemsActionEffect'),
    Result: t.literal('Success'),
    Items: t.array(CreateResultCodec)
  })
])

export const CreateItemsErrorEffectResultCodec: t.Type<CreateItemsErrorEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('CreateItemsActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const AddToListSuccessEffectResultCodec: t.Type<AddToListSuccessEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('AddToListActionEffect'),
    Result: t.literal('Success')
  })
])

export const AddToListErrorEffectResultCodec: t.Type<AddToListErrorEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('AddToListActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const AssignTaskSuccessEffectResultCodec: t.Type<AssignTaskSuccessEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('AssignTaskActionEffect'),
    Result: t.literal('Success')
  })
])

export const AssignTaskErrorEffectResultCodec: t.Type<AssignTaskErrorEffectResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('AssignTaskActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const SendPushNotificationSuccessResultCodec: t.Type<SendPushNotificationSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SendPushNotificationActionEffect'),
    Result: t.literal('Success'),
    Job: JobDetailsCodec
  })
])

export const SendPushNotificationErrorResultCodec: t.Type<SendPushNotificationErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SendPushNotificationActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const SendEmailSuccessResultCodec: t.Type<SendEmailSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SendEmailActionEffect'),
    Result: t.literal('Success'),
    Job: JobDetailsCodec
  })
])

export const SendEmailErrorResultCodec: t.Type<SendEmailErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SendEmailActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const QueueJobSuccessResultCodec: t.Type<QueueJobSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('QueueJobActionEffect'),
    Result: t.literal('Success'),
    Job: JobDetailsCodec
  })
])

export const QueueJobErrorResultCodec: t.Type<QueueJobErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('QueueJobActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const CopyToFileStoreSuccessResultCodec: t.Type<CopyToFileStoreSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('CopyToFileStoreActionEffect'),
    Result: t.literal('Success')
  })
])

export const CopyToFileStoreErrorResultCodec: t.Type<CopyToFileStoreErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('CopyToFileStoreActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const DownloadFromFileStoreSuccessResultCodec: t.Type<DownloadFromFileStoreSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('DownloadFromFileStoreActionEffect'),
    Result: t.literal('Success'),
    DomainTypeName: t.string,
    Id: t.string,
    FileId: t.string,
    FileName: t.string
  })
])

export const DownloadFromFileStoreErrorResultCodec: t.Type<DownloadFromFileStoreErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('DownloadFromFileStoreActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const DownloadInstanceSuccessResultCodec: t.Type<DownloadInstanceSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('DownloadInstanceActionEffect'),
    Result: t.literal('Success'),
    DomainTypeName: t.string,
    InstanceId: t.string
  })
])

export const DownloadInstanceErrorResultCodec: t.Type<DownloadInstanceErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('DownloadInstanceActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const SaveUpdatesSuccessResultCodec: t.Type<SaveUpdatesSuccessResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SaveUpdatesActionEffect'),
    Result: t.literal('Success')
  })
])

export const SaveUpdatesErrorResultCodec: t.Type<SaveUpdatesErrorResult> = t.intersection([
  EffectResultBaseCodec,
  t.type({
    Type: t.literal('SaveUpdatesActionEffect'),
    Result: t.literal('Error'),
    Message: t.string
  })
])

export const EffectResultCodec: t.Type<EffectResult> = t.union([
  SetValueSuccessEffectResultCodec,
  SetValueErrorEffectResultCodec,
  CreateItemsSuccessEffectResultCodec,
  CreateItemsErrorEffectResultCodec,
  AddToListSuccessEffectResultCodec,
  AddToListErrorEffectResultCodec,
  AssignTaskSuccessEffectResultCodec,
  AssignTaskErrorEffectResultCodec,
  SendPushNotificationSuccessResultCodec,
  SendPushNotificationErrorResultCodec,
  SendEmailSuccessResultCodec,
  SendEmailErrorResultCodec,
  QueueJobSuccessResultCodec,
  QueueJobErrorResultCodec,
  CopyToFileStoreSuccessResultCodec,
  CopyToFileStoreErrorResultCodec,
  DownloadFromFileStoreSuccessResultCodec,
  DownloadFromFileStoreErrorResultCodec,
  DownloadInstanceSuccessResultCodec,
  DownloadInstanceErrorResultCodec,
  SaveUpdatesSuccessResultCodec,
  SaveUpdatesErrorResultCodec
])

export const ActionPathsCodec: t.Type<ActionPaths> = t.recursion('ActionPaths', () => {
  return t.array(t.intersection([
    t.record(t.string, t.union([t.string, ActionPathsCodec, t.array(EffectResultCodec), t.undefined])),
    t.partial({
      EffectResults: t.array(EffectResultCodec)
    })
  ]))
})

export const ActionResponseCodec: t.Type<ActionResponse> = t.type({
  Paths: ActionPathsCodec,
  EffectResults: t.array(EffectResultCodec)
})

export const QueryCodec: t.Type<Query> = t.recursion('Query', () => t.intersection([
  t.type({
    Id: t.string,
    Title: t.string,
    Filters: t.array(FilterCodec),
    Sorts: t.array(SortCodec)
  }),
  t.partial({
    SearchText: t.union([t.string, t.null]),
    FilterLinkOperator: t.union([t.literal('and'), t.literal('or'), t.null]),
    BypassDomainTypeFilter: t.union([t.array(t.string), t.null]),
    BypassSearchIndex: t.union([t.boolean, t.null]),
    DomainTypeOverriders: t.union([t.array(DomainTypeOverrideCodec), t.null])
  })
]))

export const SectionCodec: t.Type<Section> = t.readonly(t.intersection([
  t.type({
    Id: t.string
  }),
  t.partial({
    Title: t.union([t.string, t.null]),
    Hidden: t.union([t.boolean, t.null]),
    Expanded: t.union([t.boolean, t.null])
  })
]))

export const ActionContextCodec: t.Type<ActionContext> = t.intersection([
  t.readonly(t.type({
    Name: t.string,
    DomainType: t.string,
    Batch: t.boolean
  })),
  t.partial({
    Parent: t.union([t.boolean, t.null])
  })
])

const ActionParameterBaseCodec: t.Type<ActionParameterBase> = t.readonly(t.type({
  Name: t.string,
  Required: t.boolean
}))

const AttributeActionParameterCodec: t.Type<AttributeActionParameter> = t.readonly(t.intersection([
  ActionParameterBaseCodec,
  t.type({
    Type: t.literal('AttributeActionParameter'),
    Attribute: t.string,
    PerformByEditing: t.boolean
  })
]))

const InputAttributeActionParameterCodec: t.Type<InputAttributeActionParameter> = t.readonly(t.intersection([
  ActionParameterBaseCodec,
  t.type({
    Type: t.literal('InputAttributeActionParameter'),
    Attribute: AttributeCodec
  })
]))

const FileActionParameterCodec: t.Type<FileActionParameter> = t.intersection([
  ActionParameterBaseCodec,
  t.type({
    Type: t.literal('FileActionParameter'),
    UploadType: t.union([t.literal('cache'), t.literal('custom')])
  }),
  t.partial({
    Accept: t.union([t.array(t.string), t.null])
  })
])

const StaticActionParameterCodec: t.Type<StaticActionParameter> = t.readonly(t.intersection([
  ActionParameterBaseCodec,
  t.type({
    Type: t.literal('StaticActionParameter'),
    Value: t.string
  })
]))

export const ActionParameterCodec: t.Type<ActionParameter> = t.union([
  AttributeActionParameterCodec,
  InputAttributeActionParameterCodec,
  FileActionParameterCodec,
  StaticActionParameterCodec
])

export const InstanceActionEffectValueCodec: t.Type<InstanceActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('InstanceActionEffectValue')
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const PersonActionEffectValueCodec: t.Type<PersonActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('PersonActionEffectValue')
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const ContextActionEffectValueCodec: t.Type<ContextActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('ContextActionEffectValue'),
    Context: t.string
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const ParametersActionEffectValueCodec: t.Type<ParametersActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('ParametersActionEffectValue'),
    Parameter: t.string
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const CompanyActionEffectValueCodec: t.Type<CompanyActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('CompanyActionEffectValue')
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const DomainTypeActionEffectValueCodec: t.Type<DomainTypeActionEffectValue> = t.intersection([
  t.type({
    ReadFrom: t.literal('DomainTypeActionEffectValue')
  }),
  t.partial({
    Property: t.union([t.string, t.null])
  })
])

export const ActionEffectValueCodec: t.Type<ActionEffectValue> = t.union([
  InstanceActionEffectValueCodec,
  PersonActionEffectValueCodec,
  ContextActionEffectValueCodec,
  ParametersActionEffectValueCodec,
  CompanyActionEffectValueCodec,
  DomainTypeActionEffectValueCodec
])

export const ActionEffectBaseCodec: t.Type<ActionEffectBase> = t.intersection([
  t.type({
    Name: t.string,
    Target: t.union([
      t.literal('instance'),
      t.literal('context'),
      t.literal('rootInstance'),
      t.literal('request')
    ])
  }),
  t.partial({
    Context: t.union([t.string, t.null])
  })
])

export const JobQueueActionEffectCodec: t.Type<QueueJobActionEffect> = t.readonly(t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('QueueJobActionEffect'),
    DownloadFile: t.boolean
  })
]))

export const CreateItemsActionEffectCodec: t.Type<CreateItemsActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('CreateItemsActionEffect'),
    DomainType: t.string,
    Transformer: t.string
  })
])

export const AddToListActionEffectCodec: t.Type<AddToListActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('AddToListActionEffect')
  })
])

export const SendPushNotificationActionEffectCodec: t.Type<SendPushNotificationActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('SendPushNotificationActionEffect')
  })
])

export const AssignTaskActionEffectCodec: t.Type<AssignTaskActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('AssignTaskActionEffect')
  })
])

export const SetValueActionEffectCodec: t.Type<SetValueActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('SetValueActionEffect')
  })
])

export const SendEmailActionEffectCodec: t.Type<SendEmailActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('SendEmailActionEffect')
  })
])

export const CopyToFileStoreActionEffectCodec: t.Type<CopyToFileStoreActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('CopyToFileStoreActionEffect')
  })
])

export const DownloadFromFileStoreActionEffectCodec: t.Type<DownloadFromFileStoreActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('DownloadFromFileStoreActionEffect')
  })
])

export const DownloadInstanceActionEffectCodec: t.Type<DownloadInstanceActionEffect> = t.intersection([
  ActionEffectBaseCodec,
  t.type({
    Type: t.literal('DownloadInstanceActionEffect')
  })
])

export const ActionEffectCodec: t.Type<ActionEffect> = t.union([
  CreateItemsActionEffectCodec,
  AddToListActionEffectCodec,
  SendPushNotificationActionEffectCodec,
  AssignTaskActionEffectCodec,
  SetValueActionEffectCodec,
  JobQueueActionEffectCodec,
  SendEmailActionEffectCodec,
  CopyToFileStoreActionEffectCodec,
  DownloadFromFileStoreActionEffectCodec,
  DownloadInstanceActionEffectCodec
])

export const DomainTypeActionCodec: t.Type<DomainTypeAction> = t.readonly(t.intersection([
  t.strict({
    Name: t.string,
    Batch: t.boolean,
    Role: t.string
  }),
  t.partial({
    Conditions: t.union([t.array(FilterCodec), t.null]),
    Context: t.union([t.array(ActionContextCodec), t.null]),
    Parameters: t.union([t.array(ActionParameterCodec), t.null]),
    Effects: t.union([t.array(ActionEffectCodec), t.null])
  })
]))

export const ButtonLocationCodec: t.Type<ButtonLocation> = t.readonly(t.union([
  t.literal('DetailsHeader'),
  t.literal('TableToolbar'),
  t.literal('TableRow')
]))

export const BaseDomainTypeButtonCodec: t.Type<BaseDomainTypeButton> = t.readonly(t.intersection([
  t.strict({
    Name: t.string,
    Type: t.string,
    Role: t.string,
    ShowOn: t.array(ButtonLocationCodec)
  }),
  t.partial({
    Conditions: t.union([t.array(FilterCodec), t.null]),
    Priority: t.union([t.literal('high'), t.literal('medium'), t.literal('low'), t.null])
  })
]))

export const ActionDomainTypeButtonCodec: t.Type<ActionDomainTypeButton> = t.readonly(t.intersection([
  BaseDomainTypeButtonCodec,
  t.type({
    Type: t.literal('ActionButton'),
    Icon: t.string,
    Action: t.string
  })
]))

export const CreateDomainTypeButtonCodec: t.Type<CreateDomainTypeButton> = t.readonly(t.intersection([
  BaseDomainTypeButtonCodec,
  t.type({
    Type: t.literal('CreateButton')
  })
]))

export const EditDomainTypeButtonCodec: t.Type<EditDomainTypeButton> = t.readonly(t.intersection([
  BaseDomainTypeButtonCodec,
  t.type({
    Type: t.literal('EditButton')
  })
]))

export const DeleteDomainTypeButtonCodec: t.Type<DeleteDomainTypeButton> = t.readonly(t.intersection([
  BaseDomainTypeButtonCodec,
  t.type({
    Type: t.literal('DeleteButton')
  })
]))

export const DomainTypeButtonCodec: t.Type<DomainTypeButton> = t.union([
  ActionDomainTypeButtonCodec,
  CreateDomainTypeButtonCodec,
  EditDomainTypeButtonCodec,
  DeleteDomainTypeButtonCodec
])

export const GlobalDomainTypeSettingsCodec = t.readonly(t.partial({
  View: t.union([t.literal('expandableList'), t.literal('tabs'), t.null]),
  TabOrientation: t.union([t.literal('vertical'), t.literal('horizontal'), t.null])
}))

export const OverridableNotGlobalDomainTypeSettingsCodec = t.readonly(t.partial({
  Columns: t.union([t.array(t.string), t.null]),
  Sections: t.union([t.array(SectionCodec), t.null]),
  DefaultSort: t.union([t.string, t.null]),
  DefaultSortDirection: t.union([t.literal('asc'), t.literal('desc'), t.null]),
  Queries: t.union([t.array(QueryCodec), t.null]),
  FindView: t.union([t.literal('table'), t.literal('cards'), t.literal('calendar'), t.literal('timeline'), t.null]),
  Tab: t.union([t.string, t.null]),
  Summary: t.union([t.array(t.string), t.null]),
  StartDate: t.union([t.string, t.null]),
  EndDate: t.union([t.string, t.null]),
  CalendarView: t.union([t.literal('day'), t.literal('week'), t.literal('month'), t.null]),
  TimelineItems: t.union([t.string, t.null]),
  TimelineGroupBy: t.union([t.string, t.null])
}))

export const OverridableDomainTypeSettingsCodec = t.intersection([
  GlobalDomainTypeSettingsCodec,
  OverridableNotGlobalDomainTypeSettingsCodec
])

export const EditableNotOverridableDomainTypeSettingsCodec = t.readonly(t.partial({
  Icon: t.union([t.string, t.null]),
  CornerIcon: t.union([t.string, t.null]),
  Parent: t.union([t.string, t.null]),
  Heading: t.union([t.string, t.null]),
  ExpandColumns: t.union([t.boolean, t.null]),
  ExpandableRow: t.union([t.boolean, t.null]),
  Find: t.union([t.boolean, t.null]),
  ViewRole: t.union([t.string, t.null]),
  CreateRole: t.union([t.string, t.null]),
  EditRole: t.union([t.string, t.null]),
  Actions: t.union([t.array(DomainTypeActionCodec), t.null]),
  CreateForm: t.union([t.array(t.string), t.null]),
  EditForm: t.union([t.array(t.string), t.null]),
  Buttons: t.union([t.array(DomainTypeButtonCodec), t.null]),
  Category: t.union([t.string, t.null]),
  Colour: t.union([t.string, t.null])
}))

export const EditableDomainTypeSettingsCodec: t.Type<EditableDomainTypeSettings> = t.intersection([
  OverridableDomainTypeSettingsCodec,
  EditableNotOverridableDomainTypeSettingsCodec
])

export const DomainTypeSettingsCodec: t.Type<DomainTypeSettings> = t.intersection([
  EditableDomainTypeSettingsCodec,
  t.readonly(t.partial({
    DatabaseTable: t.union([t.string, t.null]),
    Parent: t.union([t.string, t.null]),
    Api: t.union([t.boolean, t.null]),
    Subtype: t.union([t.string, t.null]),
    SerializationType: t.union([t.string, t.null]),
    Identifier: t.union([t.string, t.null])
  }))
])

export const DomainTypeCodec: t.Type<DomainType> = t.intersection([
  t.readonly(t.type({
    Id: t.string,
    Name: t.string,
    Title: t.string,
    PluralTitle: t.string,
    Attributes: t.array(AttributeCodec)
  })),
  DomainTypeSettingsCodec
])

export const DomainTypeOverrideCodec: t.Type<DomainTypeOverride> = t.intersection([
  t.readonly(t.type({
    Id: t.string
  })),
  OverridableNotGlobalDomainTypeSettingsCodec
])

export const DomainTypeOverriderCodec: t.Type<DomainTypeOverrider> = t.intersection([
  t.type({
    Id: t.string
  }),
  t.partial({
    GlobalDomainTypeSettingsCodec: t.union([GlobalDomainTypeSettingsCodec, t.null]),
    DomainTypeOverrides: t.union([t.array(DomainTypeOverrideCodec), t.null])
  })
])

export const AdalPropertiesCodec: t.Type<AdalProperties> = t.readonly(
  t.type({
    authorityUrl: t.string,
    tenantId: t.string,
    clientId: t.string
  }))

export const UserCodec: t.Type<User> = t.intersection([
  t.type({
    id: t.string,
    token: t.string,
    companies: t.array(t.type({
      id: t.string,
      name: t.string,
      token: t.string
    })),
    roles: t.array(t.string)
  }),
  t.partial({
    selectedCompanyId: t.string
  })
])

export const PathErrorCodec: t.Type<PathError> = t.recursion('PathErrorCodec', () => t.union([
  t.string,
  t.record(t.string, t.union([PathErrorCodec, t.undefined])),
  t.array(PathErrorCodec)
]))

export const PathErrorsCodec: t.Type<PathErrors> = t.record(t.string, t.union([PathErrorCodec, t.undefined]))

export const ServerErrorCodec: t.Type<ServerError> = t.readonly(t.exact(t.type({
  Message: t.string
})))

export const ApiErrorCodec: t.Type<ApiError> = t.intersection([
  t.type({
    errorCode: t.string
  }),
  t.partial({
    status: t.number,
    pathErrors: PathErrorsCodec
  })
])

export const PersonCodec: t.Type<Person> = t.intersection([
  t.type({
    Id: t.string,
    FullName: t.string
  }),
  t.partial({
    GlobalDomainTypeSettings: t.union([GlobalDomainTypeSettingsCodec, t.null]),
    DomainTypeOverrides: t.union([t.array(DomainTypeOverrideCodec), t.null])
  })
])

export const ThemeCodec: t.Type<Theme> = t.readonly(t.type({
  Primary: t.string
}))

export const CompanyCodec: t.Type<Company> = t.readonly(t.intersection([
  t.type({
    Id: t.string,
    Name: t.string
  }),
  t.partial({
    Theme: t.union([ThemeCodec, t.null])
  })
]))

export const PaletteModeCodec: t.Type<'light' | 'dark'> = t.union([t.literal('light'), t.literal('dark')])
