import * as E from 'fp-ts/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { DomainTypeInstance } from 'types'
import { Dataform, MultiDataformMapping } from 'types/dataform'
import { DomainTypeInstanceCodec, JsonFromUnknown } from 'utils/codecs'
import { DataformCodec, MultiDataformMappingCodec } from 'utils/codecs/dataform'
import { stringifyFilterValue } from 'utils/filters'
import { SignedInApi } from 'utils/hooks'
import { isNullOrUndefined } from '.'

interface DataformWithKey {
  readonly key: string
  readonly dataform: Dataform
}

interface MultiDataformMappingWithKey {
  readonly key: string
  readonly multiDataformMapping: MultiDataformMapping
}

interface TaskEquipment {
  readonly EquipmentType: DomainTypeInstance | null
  readonly SerialisedEquipment: DomainTypeInstance | null
}

export function getEquipmentFromTask(task: DomainTypeInstance | null): TaskEquipment {
  const type = String(task?.Type)
  if (type === 'Installation') {
    return {
      EquipmentType: pipe(
        treeGet(task, ['InstalledEquipmentType']),
        DomainTypeInstanceCodec.decode,
        E.match(() => null, value => value)
      ),
      SerialisedEquipment: null
    }
  }
  const paths = {
    Service: [['ServicedEquipment']],
    Inspection: [['ServicedEquipment']],
    FieldService: [['ServicedEquipment']],
    Delivery: [['AllocatedEquipment'], ['RequiredEquipment']],
    Return: [['AllocatedEquipment'], ['RequiredEquipment']],
    TransferIn: [['AllocatedEquipment'], ['RequiredEquipment']],
    TransferOut: [['AllocatedEquipment'], ['RequiredEquipment']],
    Maintenance: [['Equipment']],
    PMR: [['Equipment']],
    Move: [['EquipmentLine', 'Pick']]
  }[type]
  const equipment = paths
    ?.map(path => treeGet(task, path))
    .find(DomainTypeInstanceCodec.is)
  return {
    EquipmentType: pipe(
      treeGet(equipment, ['EquipmentType']) ?? treeGet(equipment, ['SerialisedEquipment', 'EquipmentType']),
      DomainTypeInstanceCodec.decode,
      E.match(() => null, value => value)
    ),
    SerialisedEquipment: pipe(
      treeGet(equipment, ['SerialisedEquipment']),
      DomainTypeInstanceCodec.decode,
      E.match(() => null, value => value)
    )
  }
}

export function treeGet(from: unknown, path: string[]): unknown {
  if (path.length === 0) {
    return from
  }
  if (!t.UnknownRecord.is(from)) {
    return null
  }
  const property = path[0]
  if (property === undefined) {
    return null
  }
  if (property === 'SerializedCargo') {
    return treeGet(from, path.slice(1))
  }
  return treeGet(from[property], path.slice(1))
}

async function getMappingsUsingObjectSelectionCriteria(
  api: SignedInApi,
  parsedSelectionCriteria: Partial<Record<string, string[]>>,
  context: Record<string, unknown>,
  defaultSelectionProperties: string[],
  purpose?: string
): Promise<MultiDataformMappingWithKey[]> {
  const mappings: MultiDataformMappingWithKey[] = []
  for (const prefix of Object.keys(parsedSelectionCriteria)) {
    const selectionProperties = parsedSelectionCriteria[prefix]
    const searchStrings = [
      prefix,
      ...getSearchStrings(
        selectionProperties ?? defaultSelectionProperties,
        context
      )
    ]
    mappings.push(...(await getMappingsBySearchStrings(
      api,
      searchStrings,
      purpose
    )).map(mapping => ({
      key: prefix,
      multiDataformMapping: mapping.multiDataformMapping
    })))
  }
  return mappings
}

function getSearchStrings(
  selectionProperties: string[],
  context: Record<string, unknown>
): string[] {
  return selectionProperties
    .map(path => treeGet(context, path.split('.')))
    .filter(value => !isNullOrUndefined(value) && !t.literal('').is(value))
    .map(String)
}

async function getMappingsByName(
  api: SignedInApi,
  name: string,
  purpose?: string
): Promise<MultiDataformMapping[]> {
  const response = await api.search(
    'Metadata',
    'Metadata',
    [],
    [
      {
        Property: 'Category',
        Operator: 'eq',
        Value: stringifyFilterValue('MultiDataformMapping')
      },
      {
        Property: 'Subcategory',
        Operator: 'eq',
        Value: stringifyFilterValue(name)
      }
    ],
    [],
    1,
    100
  )
  if (E.isLeft(response)) {
    return []
  }
  const result = t.array(MultiDataformMappingCodec).decode(response.right.results)
  if (E.isLeft(result)) {
    return []
  }
  return result.right
    .filter(mapping => isNullOrUndefined(mapping.Purpose) || mapping.Purpose === purpose)
}

async function getMappingsBySearchStrings(
  api: SignedInApi,
  searchStrings: string[],
  purpose?: string
): Promise<MultiDataformMappingWithKey[]> {
  const mappingNames = searchStrings
    .map((_, i) => searchStrings.slice(0, searchStrings.length - i).join(''))
  for (const mappingName of mappingNames) {
    const mappings = await getMappingsByName(
      api,
      mappingName,
      purpose
    )
    if (mappings.length > 0) {
      return mappings.map(mapping => ({
        key: mapping.Value,
        multiDataformMapping: mapping
      }))
    }
  }
  return []
}

export async function getBySelectionCriteria(
  api: SignedInApi,
  selectionCategory: string,
  selectionSubcategory: string,
  context: Record<string, unknown>,
  defaultSelectionProperties: string[] = [],
  purpose?: string
): Promise<DataformWithKey[]> {
  const workType = treeGet(context, ['Work', 'Type'])
  if (isNullOrUndefined(purpose)
    && typeof workType === 'string') {
    purpose = workType
  }
  const response = await api.search(
    'Metadata',
    'Metadata',
    [],
    [
      {
        Property: 'Category',
        Operator: 'eq',
        Value: stringifyFilterValue(selectionCategory)
      },
      {
        Property: 'Subcategory',
        Operator: 'eq',
        Value: stringifyFilterValue(selectionSubcategory)
      }
    ],
    [],
    1,
    1
  )
  const value = E.isRight(response)
    ? response.right.results[0]?.Value
    : null
  const result = JsonFromUnknown.pipe(t.union([t.record(t.string, t.array(t.string)), t.array(t.string)])).decode(value)
  const parsedSelectionCriteria = E.isRight(result)
    ? result.right
    : null
  const mappings = t.record(t.string, t.array(t.string)).is(parsedSelectionCriteria)
    ? await getMappingsUsingObjectSelectionCriteria(
      api,
      parsedSelectionCriteria,
      context,
      defaultSelectionProperties,
      purpose
    )
    : await getMappingsBySearchStrings(
      api,
      getSearchStrings(
        parsedSelectionCriteria ?? defaultSelectionProperties,
        context
      ),
      purpose
    )
  const orderedMappings = mappings
    .sort(mapping => parseInt(mapping.multiDataformMapping.Order ?? ''))
  const dataforms: DataformWithKey[] = []
  for (const mapping of orderedMappings) {
    const dataformResponse = await api.search(
      'Dataform',
      'Dataform',
      [],
      [
        {
          Property: 'Name',
          Operator: 'eq',
          Value: stringifyFilterValue(mapping.multiDataformMapping.Value)
        }
      ],
      [],
      1,
      1
    )
    if (E.isLeft(dataformResponse)) {
      continue
    }
    const result = DataformCodec.decode(dataformResponse.right.results[0])
    if (E.isLeft(result)) {
      continue
    }
    dataforms.push({
      key: mapping.key,
      dataform: result.right
    })
  }
  return dataforms
}
