import PushDomainTypeOverriderContext from 'components/domainType/PushDomainTypeOverriderContext'
import { useMemo, useState } from 'react'
import { Query } from 'types'
import { DomainTypeContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { FindView, useFind, useFindQueries } from '../FindPage'
import { RelatedSection as RelatedSectionType } from './useDetails'

interface Props {
  section: RelatedSectionType
  isLoading: boolean
}

interface RelatedSectionContentProps {
  section: RelatedSectionType
  isLoading: boolean
  domainTypeQueries: Query[]
  overriderQueries: [Query, OverriderDetails][]
  currentQuery: Query
}

function RelatedSectionContent({
  section,
  isLoading: sectionIsLoading,
  domainTypeQueries,
  overriderQueries,
  currentQuery
}: RelatedSectionContentProps): JSX.Element {
  const [selectedPermanentFilterOption, setSelectedPermanentFilterOption] = useState(0)
  const attributeChain = section.attributeChains[selectedPermanentFilterOption]
  const permanentFilterOptions = useMemo(() => {
    return section.attributeChains.map(attributeChain => attributeChain.title)
  }, [section.attributeChains])
  const {
    items,
    total,
    calendarItems,
    calendarProps,
    isLoading,
    searchText,
    page,
    pageSize,
    filterLinkOperator,
    filters,
    sorts,
    checkedRowIds,
    isExporting,
    view,
    snackPack,
    onSearch,
    onPageChange,
    onPageSizeChange,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onSortsChange,
    onRowClick,
    fetchTotal,
    onApplyQuery,
    onCheckedRowIdsChange,
    onClickExport,
    onViewChange
  } = useFind(section.domainType, `${section.id}_`, attributeChain?.filters)
  const relationship = useMemo(() => {
    if (attributeChain === undefined) {
      return undefined
    }
    return {
      attributeChain: attributeChain.attributes,
      instance: section.instance,
      id: attributeChain.id
    }
  }, [attributeChain, section.instance])
  return (
    <DomainTypeContext.Provider
      value={{
        instances: [],
        attributes: [],
        batchInstances: [],
        relationship,
        onInvalidate: onSearch
      }}>
      <FindView
        domainType={section.domainType}
        isLoading={isLoading || sectionIsLoading}
        items={items}
        calendarItems={calendarItems}
        calendarProps={calendarProps}
        total={total}
        page={page}
        pageSize={pageSize}
        searchText={searchText}
        sorts={sorts}
        filterLinkOperator={filterLinkOperator}
        filters={filters}
        permanentFilters={attributeChain?.filters}
        permanentFilterOptions={permanentFilterOptions}
        selectedPermanentFilterOption={selectedPermanentFilterOption}
        overriderQueries={overriderQueries}
        domainTypeQueries={domainTypeQueries}
        currentQuery={currentQuery}
        isExporting={isExporting}
        checkedRowIds={checkedRowIds}
        view={view}
        snackPack={snackPack}
        onSearch={onSearchTextChange}
        onFilterLinkOperatorChange={onFilterLinkOperatorChange}
        onFiltersChange={onFiltersChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortsChange={onSortsChange}
        onRowClick={onRowClick}
        fetchTotal={fetchTotal}
        onApplyQuery={onApplyQuery}
        onClickExport={onClickExport}
        onCheckedRowIdsChange={onCheckedRowIdsChange}
        onViewChange={onViewChange}
        onPermanentFilterOptionChange={setSelectedPermanentFilterOption} />
    </DomainTypeContext.Provider>
  )
}

export default function RelatedSection({
  section,
  isLoading: sectionIsLoading
}: Props): JSX.Element {
  const {
    currentQuery,
    domainTypeQueriesWithAll,
    overriderQueries,
    overriderDetails
  } = useFindQueries(section.domainType, `${section.id}_`)
  return (
    <PushDomainTypeOverriderContext overriderDetails={overriderDetails}>
      <RelatedSectionContent
        section={section}
        isLoading={sectionIsLoading}
        domainTypeQueries={domainTypeQueriesWithAll}
        overriderQueries={overriderQueries}
        currentQuery={currentQuery} />
    </PushDomainTypeOverriderContext>
  )
}
