import { CalendarTodayOutlined, CalendarViewDayOutlined, CalendarViewMonthOutlined, CalendarViewWeekOutlined, GridViewOutlined, NavigateBeforeOutlined, NavigateNextOutlined, SettingsOutlined, TableViewOutlined, TodayOutlined, ViewTimelineOutlined } from '@mui/icons-material'
import { Box, Button, ButtonGroup, ClickAwayListener, Paper, Popper, Stack, Typography } from '@mui/material'
import { GridColumnIcon, GridColumns } from '@mui/x-data-grid'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import TextIconButton from 'components/utils/TextIconButton'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { DateTime, Interval } from 'luxon'
import { ComponentProps, MutableRefObject, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DomainType, DomainTypeInstance, Filter } from 'types'
import { dateToString } from 'utils/helpers'
import CalendarViewPanel from './CalendarViewPanel'
import ColumnsPanel from './ColumnsPanel'
import FindDatePickerPanel from './DatePickerPanel'
import FilterPanel from './FilterPanel'
import FindViewPanel from './FindViewPanel'
import SearchInput from './SearchInput'
import CalendarTimelineSettingsPanel from './CalendarTimelineSettingsPanel'
import { CalendarProps, FindPageView } from './types'

interface Props {
  domainType: DomainType | null
  panelOpen: string | false
  setPanelOpen(value: string | false): void
  selectedColumn: string | undefined
  setSelectedColumn(value: string | undefined): void
  filterLinkOperator: 'and' | 'or'
  filtersRef: MutableRefObject<{
    filterLinkOperator: 'and' | 'or',
    filters: Filter[]
  }>
  filters: Filter[] | undefined
  permanentFilters: Filter[] | undefined
  permanentFilterOptions: string[] | undefined
  selectedPermanentFilterOption: number | undefined
  onPermanentFilterOptionChange?(index: number): void
  columns: GridColumns
  setColumnVisibility(field: string, isVisible: boolean): void
  disableFilters: boolean
  checkedItems: DomainTypeInstance[]
  isExporting: boolean
  onClickExport?(columns: string[]): void
  searchText: string
  autoFocusSearchInput?: boolean
  onSearch(searchText: string): void
  onSearchTextChange?(searchText: string): void
  onFiltersChange?(filters: Filter[]): void
  view: FindPageView
  onViewChange(view: FindPageView): void
  calendarProps: CalendarProps
}

function incrementDate(
  date: Date,
  view: CalendarProps['view'],
  direction: 'forwards' | 'backwards'
): Date {
  const dateTime = DateTime.fromJSDate(date)
  const operation = direction === 'forwards'
    ? 'plus'
    : 'minus'
  switch (view) {
    case 'day':
      return dateTime[operation]({ days: 1 }).toJSDate()
    case 'week':
      return dateTime[operation]({ weeks: 1 }).toJSDate()
    case 'month':
      return dateTime[operation]({ months: 1 }).toJSDate()
  }
}

function formatDate(
  date: Date,
  view: CalendarProps['view']
): string | null {
  const dateTime = DateTime.fromJSDate(date)
  switch (view) {
    case 'day':
      return dateToString(dateTime.toUTC().toISO())
    case 'week':
      return Interval
        .fromDateTimes(dateTime.startOf('week'), dateTime.endOf('week'))
        .toLocaleString({
          month: 'short',
          year: 'numeric'
        })
    case 'month':
      return DateTime.fromJSDate(date).toLocaleString({
        month: 'short',
        year: 'numeric'
      })
  }
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      {...props} />
  ),
  Button: props => (
    <TextIconButton
      iconOnly
      {...props} />
  ),
  Empty: null
}

const viewIcons = {
  table: <TableViewOutlined />,
  cards: <GridViewOutlined />,
  calendar: <CalendarTodayOutlined />,
  timeline: <ViewTimelineOutlined />
}

const viewTooltipText = {
  table: 'Table View',
  cards: 'Cards View',
  calendar: 'Calendar View',
  timeline: 'Timeline View'
}

const calendarViewIcons = {
  day: <CalendarViewDayOutlined />,
  week: <CalendarViewWeekOutlined />,
  month: <CalendarViewMonthOutlined />
}

const calendarViewTooltipText = {
  day: 'Day View',
  week: 'Week View',
  month: 'Month View'
}

export default memo(function FindToolbar({
  columns,
  panelOpen,
  setPanelOpen,
  disableFilters,
  filterLinkOperator,
  filters,
  permanentFilters,
  permanentFilterOptions,
  selectedPermanentFilterOption,
  onPermanentFilterOptionChange,
  isExporting,
  onClickExport,
  domainType,
  checkedItems,
  searchText,
  autoFocusSearchInput,
  onSearch,
  onSearchTextChange,
  onFiltersChange,
  setColumnVisibility,
  selectedColumn,
  setSelectedColumn,
  filtersRef,
  view,
  onViewChange,
  calendarProps
}: Props): JSX.Element {
  const ref = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  useEffect(() => {
    const rootElement = ref.current
    if (rootElement !== null) {
      setAnchorEl(rootElement)
    }
  }, [])
  const handleClickAway = useCallback(() => {
    setPanelOpen(false)
    setSelectedColumn(undefined)
  }, [setPanelOpen, setSelectedColumn])
  const additionalButtons = useMemo(() => onClickExport !== undefined
    ? [
      {
        text: 'Export',
        icon: isExporting
          ? 'downloading'
          : 'save_alt_rounded',
        disabled: isExporting,
        onClick: () => {
          onClickExport(columns
            .filter(column => column.type !== 'actions')
            .filter(column => !(column.hide ?? false))
            .map(column => column.field))
        }
      }
    ]
    : [], [columns, isExporting, onClickExport])
  const panel = useMemo(() => {
    return (
      <>
        {panelOpen === 'view' && (
          <FindViewPanel
            domainType={domainType}
            view={view}
            onViewChange={onViewChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'columns' && (
          <ColumnsPanel
            columns={columns}
            domainType={domainType}
            setColumnVisibility={setColumnVisibility} />
        )}
        {panelOpen === 'filters' && (
          <FilterPanel
            columns={columns}
            domainType={domainType}
            onClose={handleClickAway}
            selectedColumn={selectedColumn}
            filtersRef={filtersRef} />
        )}
        {panelOpen === 'calendarView' && (
          <CalendarViewPanel
            domainType={domainType}
            view={calendarProps.view}
            onViewChange={calendarProps.onViewChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'datePicker' && (
          <FindDatePickerPanel
            view={calendarProps.view}
            date={calendarProps.date}
            onDateChange={calendarProps.onDateChange}
            onClose={handleClickAway} />
        )}
        {panelOpen === 'calendarTimelineSettings' && domainType !== null && (
          <CalendarTimelineSettingsPanel
            domainType={domainType}
            view={view}
            onClose={handleClickAway} />
        )}
      </>
    )
  }, [calendarProps.date, calendarProps.onDateChange, calendarProps.onViewChange, calendarProps.view, columns, domainType, filtersRef, handleClickAway, onViewChange, panelOpen, selectedColumn, setColumnVisibility, view])
  return (
    <>
      <Stack
        ref={ref}
        direction='row'
        gap={2}
        alignItems='center'
        pb={1}
        flexWrap='nowrap'>
        <ButtonGroup variant='contained'>
          <TooltipIconButton
            icon={viewIcons[view]}
            tooltipText={viewTooltipText[view]}
            color='primary'
            onClick={event => {
              event.preventDefault()
              setPanelOpen(panelOpen === 'view' ? false : 'view')
            }} />
          {['table', 'cards'].includes(view)
            ? (
              <TooltipIconButton
                icon={<GridColumnIcon />}
                tooltipText='Columns'
                color='primary'
                onClick={event => {
                  event.preventDefault()
                  setPanelOpen(panelOpen === 'columns' ? false : 'columns')
                }} />
            )
            : (
              <>
                <TooltipIconButton
                  icon={calendarViewIcons[calendarProps.view]}
                  tooltipText={calendarViewTooltipText[calendarProps.view]}
                  color='primary'
                  onClick={event => {
                    event.preventDefault()
                    setPanelOpen(panelOpen === 'calendarView' ? false : 'calendarView')
                  }} />
                <TooltipIconButton
                  icon={<SettingsOutlined />}
                  tooltipText='Settings'
                  color='primary'
                  onClick={event => {
                    event.preventDefault()
                    setPanelOpen(panelOpen === 'calendarTimelineSettings' ? false : 'calendarTimelineSettings')
                  }} />
              </>
            )}
        </ButtonGroup>
        {['calendar', 'timeline'].includes(view) && (
          <ButtonGroup variant='contained'>
            <TooltipIconButton
              icon={<TodayOutlined />}
              tooltipText='Jump To Today'
              color='primary'
              onClick={() => {
                calendarProps.onDateChange(DateTime.now().toJSDate())
              }} />
            <TooltipIconButton
              icon={<NavigateBeforeOutlined />}
              sx={{ p: '4px' }}
              size='small'
              tooltipText={`Previous ${calendarProps.view[0]?.toUpperCase()}${calendarProps.view.slice(1)}`}
              color='primary'
              onClick={() => {
                calendarProps.onDateChange(
                  incrementDate(calendarProps.date, calendarProps.view, 'backwards')
                )
              }} />
            <TooltipIconButton
              icon={<NavigateNextOutlined />}
              sx={{ p: '4px' }}
              size='small'
              tooltipText={`Next ${calendarProps.view[0]?.toUpperCase()}${calendarProps.view.slice(1)}`}
              color='primary'
              onClick={() => {
                calendarProps.onDateChange(
                  incrementDate(calendarProps.date, calendarProps.view, 'forwards')
                )
              }} />
            <Button
              variant='text'
              sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              onClick={event => {
                event.preventDefault()
                setPanelOpen(panelOpen === 'datePicker' ? false : 'datePicker')
              }}>
              <Typography
                variant='button'>
                {formatDate(calendarProps.date, calendarProps.view)}
              </Typography>
            </Button>
          </ButtonGroup>
        )}
        {domainType !== null && (
          <>
            <Box flexGrow={1}>
              <SearchInput
                domainType={domainType}
                searchText={searchText}
                autoFocus={autoFocusSearchInput}
                filterLinkOperator={filterLinkOperator}
                filters={filters}
                permanentFilters={permanentFilters}
                permanentFilterOptions={permanentFilterOptions}
                selectedPermanentFilterOption={selectedPermanentFilterOption}
                panelOpen={panelOpen}
                disableFilters={disableFilters}
                onSearch={onSearch}
                onSearchTextChange={onSearchTextChange}
                onFiltersChange={onFiltersChange}
                onPermanentFilterOptionChange={onPermanentFilterOptionChange}
                setPanelOpen={setPanelOpen} />
            </Box>
            <DomainTypeButtons
              domainType={domainType}
              instances={checkedItems}
              on='TableToolbar'
              components={components}
              priority='medium'
              renderPopoverButtonInsideContainer
              additionalButtons={additionalButtons} />
          </>
        )}
      </Stack>
      <Popper
        open={panelOpen !== false}
        anchorEl={anchorEl}
        placement='bottom-start'
        onResize={undefined}
        onResizeCapture={undefined}
        sx={{
          zIndex: 1300
        }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={8}
            sx={{
              width: '100%',
              minWidth: '300px',
              maxHeight: '450px'
            }}>
            {panel}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  )
})