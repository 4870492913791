import { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { getHeading } from 'utils/helpers'
import DomainTypeIcon from './DomainTypeIcon'
import { useTheme } from '@mui/material/styles'
import { Box, BoxProps, CircularProgress } from '@mui/material'

interface Props {
  domainType: DomainType
  instance?: DomainTypeInstance
  isLoading: boolean
  title?: string
  plural?: boolean
  count?: number
  flexWrap?: BoxProps['flexWrap']
  hideHeading?: boolean
}

export default function DomainTypeHeading({
  domainType,
  instance,
  isLoading,
  title,
  plural = false,
  count,
  flexWrap,
  hideHeading = false,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const heading = getHeading(domainTypes, domainType, instance ?? {})
  const theme = useTheme()
  return (
    <Box
      display='flex'
      alignItems='center'
      gap={1}
      padding={0}
      flexWrap={flexWrap ?? 'nowrap'}
      width='100%'>
      {!hideHeading && (
        <>
          {title !== undefined
            ? (
              <h3 style={{ margin: 0 }}>{title}</h3>
            )
            : null}
          <DomainTypeIcon
            avatar
            avatarScale={1.2}
            domainType={domainType} />
          <h3
            style={{
              margin: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: theme.palette.text.secondary
            }}>
            {!isLoading && `${count !== undefined && count > 1 ? `${count} ` : ''}${plural ? domainType.PluralTitle : heading}`}
          </h3>
        </>
      )}
      {isLoading
        ? (
          <CircularProgress size='1.5rem' />
        )
        : children}
    </Box>
  )
}
