import { IconButton } from '@mui/material'
import { GridColumnHeaderParams, GridColumnHeaderTitle, GridFilterAltIcon } from '@mui/x-data-grid'
import { useContext } from 'react'
import TableViewContext from './TableViewContext'

export default function CustomColumnHeader(params: GridColumnHeaderParams): JSX.Element {
  const { filters, onClickFilterIcon } = useContext(TableViewContext)
  return (
    <>
      <GridColumnHeaderTitle
        label={params.colDef.headerName ?? ''}
        columnWidth={params.colDef.computedWidth} />
      {filters.some(filter => filter.Property === params.field) && (
        <IconButton
          onClick={event => {
            event.stopPropagation()
            onClickFilterIcon(params.field)
          }}
          color='default'
          size='small'
          tabIndex={-1}>
          <GridFilterAltIcon className='MuiDataGrid-filterIcon' />
        </IconButton>
      )}
    </>
  )
}