import { TextField } from '@mui/material'
import { TextBoxWithQuestionElement } from 'types/dataform'
import { useDebouncedOnChange } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

export default function TextBoxWithQuestion({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<TextBoxWithQuestionElement>): JSX.Element | null {
  const [debouncedValue, debouncedOnChange] = useDebouncedOnChange(onChange, value, 100)
  return (
    <TextField
      disabled={locked}
      fullWidth
      size='small'
      label={element.Text}
      multiline={element.ExtraParams?.multiline === true}
      value={debouncedValue ?? ''}
      onChange={event => debouncedOnChange(event.target.value || null)}
      required={required}
      error={error}
      helperText={errorText}
      rows={4}
      inputProps={{
        readOnly: readOnly || element.ExtraParams?.readonly === true
      }} />
  )
}