import { Icon, useTheme } from '@mui/material'

interface Props {
  readonly icon: string
  readonly cornerIcon?: string | null
  readonly color?: string
  readonly cornerIconColor?: string
  readonly textShadowColor?: string
}

export default function CornerIcon({
  icon,
  cornerIcon,
  color,
  cornerIconColor,
  textShadowColor
}: Props): JSX.Element {
  const theme = useTheme()
  textShadowColor = textShadowColor ?? (theme.palette.mode === 'light'
    ? '#fff'
    : '#000')
  return (
    <Icon sx={{
      position: 'relative',
      overflow: 'visible',
      color
    }}>
      <Icon>
        {icon}
      </Icon>
      <Icon
        sx={{
          color: cornerIconColor ?? color,
          position: 'absolute',
          right: '-0.25em',
          bottom: '-0.25em',
          fontSize: '0.5em !important',
          fontWeight: '600 !important',
          textShadow: `-1px -1px 0 ${textShadowColor}, 1px -1px 0 ${textShadowColor}, -1px 1px 0 ${textShadowColor}, 1px 1px 0 ${textShadowColor}`
        }}>
        {cornerIcon}
      </Icon>
    </Icon>
  )
}