import { LoadingButton } from '@mui/lab'
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import DomainTypeHeading from 'components/domainType/DomainTypeHeading'
import DialogAlert from 'components/utils/DialogAlert'
import { useMemo } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { useDelete } from 'utils/hooks'

interface Props {
  readonly open: boolean
  readonly instances: DomainTypeInstance[]
  readonly domainType: DomainType
  onClose(): void
  onDeleteSuccess(): void
}

export default function DeleteDialog({
  open,
  instances,
  domainType,
  onClose,
  onDeleteSuccess
}: Props): JSX.Element {
  const {
    isDeleting,
    errorCode,
    onDelete
  } = useDelete(domainType, instances, onDeleteSuccess)
  const singleInstance = useMemo(() => {
    return instances.length === 1
      ? instances[0]
      : undefined
  }, [instances])
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={isDeleting ? undefined : onClose}
      onKeyDown={event => event.stopPropagation()}>
      <DialogTitle>
        <DomainTypeHeading
          domainType={domainType}
          count={instances.length}
          plural={instances.length > 1}
          instance={singleInstance}
          isLoading={false}
          title='Delete:' />
      </DialogTitle>
      <DialogAlert
        isLoading={isDeleting}
        errorCode={errorCode}
        hideEmptyErrorCode />
      <DialogActions>
        <Button
          disabled={isDeleting}
          variant='text'
          onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          disabled={isDeleting}
          loading={isDeleting}
          onClick={onDelete}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}