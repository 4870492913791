import { Paper } from '@mui/material'
import { DomainTypeGridSummary } from 'components/domainType/DomainTypeSummary'
import { SummarySection as SummarySectionType } from './useDetails'

interface Props {
  section: SummarySectionType
}

export default function SummarySection({
  section
}: Props): JSX.Element {
  return (
    <Paper
      sx={{
        position: 'relative'
      }}>
      <DomainTypeGridSummary
        domainType={section.domainType}
        instance={section.instance}
        columnsSetting='Summary'
        hideHeading
        showSettingPopper />
    </Paper>
  )
}