import { Box } from '@mui/material'
import { DomainTypeGridSummary } from 'components/domainType/DomainTypeSummary'
import { ComponentProps } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { TOOLTIP_DELAY } from 'utils/constants'
import LightTooltip from '../utils/LightTooltip'

interface Props {
  domainType: DomainType,
  instance: DomainTypeInstance,
  children: ComponentProps<typeof LightTooltip>['children']
}

export default function DomainTypeTooltip({
  domainType,
  instance,
  children
}: Props): JSX.Element | null {
  const tooltipTitle = (
    <Box>
      <DomainTypeGridSummary
        domainType={domainType}
        instance={instance}
        columnsSetting='Summary'
        hideButtons />
    </Box>
  )
  return (
    <LightTooltip
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}
      title={tooltipTitle}
      minWidth='300px'
      maxWidth='300px'>
      {children}
    </LightTooltip>
  )
}