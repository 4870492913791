import DataForm from 'components/attribute/AttributeForm'
import { NotSupportedInput } from 'components/attribute/AttributeInput'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeAttributeValue, DomainTypeInstance, PathError } from 'types'
import { FormModeContext } from 'utils/context'
import { isListAttributeValue, isNullOrUndefined } from 'utils/helpers'
import { useDomainTypeForm } from 'utils/hooks'

interface Props {
  readonly attributeValue: DomainTypeAttributeValue
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: DomainTypeAttributeValue): void
}

interface InlineDomainTypeFormProps {
  readonly domainType: DomainType
  readonly instance: DomainTypeInstance | null
  readonly readOnly: boolean
  readonly disabled?: boolean
  readonly pathError: PathError | undefined
  onInstanceChange(instance: DomainTypeInstance): void
}

function InlineDomainTypeForm({
  domainType,
  instance,
  readOnly,
  disabled,
  pathError,
  onInstanceChange
}: InlineDomainTypeFormProps): JSX.Element {
  const formMode = useContext(FormModeContext)
  const {
    attributeValues,
    onChange
  } = useDomainTypeForm(
    domainType,
    instance,
    formMode ?? 'edit',
    onInstanceChange
  )
  return (
    <DataForm
      attributeValues={attributeValues}
      readOnly={readOnly}
      disabled={disabled}
      pathErrors={typeof pathError === 'object' && !Array.isArray(pathError)
        ? pathError
        : {}}
      onChange={onChange} />
  )
}

export default function InlineInput({
  attributeValue,
  readOnly,
  disabled = false,
  pathError,
  onChange
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  if (isNullOrUndefined(domainType)) {
    return null
  }
  return (
    <InlineDomainTypeForm
      domainType={domainType}
      instance={attributeValue.value}
      pathError={pathError}
      readOnly={readOnly}
      disabled={disabled}
      onInstanceChange={instance => onChange?.({
        ...attributeValue,
        value: instance
      })} />

  )
}