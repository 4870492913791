import { Backdrop, CircularProgress, Paper, styled } from '@mui/material'
import ActionDialog from 'components/domainType/ActionDialog'
import * as O from 'fp-ts/Option'
import { DateTime } from 'luxon'
import { ContextType, useContext, useMemo } from 'react'
import { Calendar, View, luxonLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { Attribute, DateAttribute, DateTimeAttribute, DomainType, DomainTypeInstance, NonListAttribute, User } from 'types'
import { DomainTypeContext, DomainTypeOverriderContext } from 'utils/context'
import { FilterContext } from 'utils/filters'
import { getDomainTypeActions, getDomainTypeSetting, getHeading, getIdentifier, getOverridableDomainTypeSettingAttribute, getRootDomainType, getSubtype, getValue, isValidStartEndDateAttribute } from 'utils/helpers'
import { SnackPack, getActionButton, useFilterContext } from 'utils/hooks'
import { ActionDetails } from 'utils/hooks/useActions'
import MissingStartDateAlert from '../MissingStartDateAlert'
import { canEditInstancesAttributes, canPerformAction, getActionParameterValues, getEdgeDates, getItemActionDetails, getMouseDownMessage, isMoveResizeAction } from '../helpers'
import { CalendarProps, CalendarTimelineSettings } from '../types'
import CustomCalendarEventWrapper from './CustomCalendarEventWrapper'

interface Props extends CalendarProps {
  isLoading: boolean
  domainType: DomainType
  items: DomainTypeInstance[] | undefined
  snackPack: SnackPack
  setPanelOpen(panel: string): void
  onItemClick(id: string): void
}

export interface CustomEvent {
  readonly id: unknown
  readonly title: string
  readonly start: Date
  readonly end: Date
  readonly allDay: boolean
  readonly colour: string | undefined
  readonly subtype: DomainType
  readonly instance: DomainTypeInstance
  readonly moveResizeActionDetails?: ActionDetails
  readonly canEdit: 'never' | 'state' | 'permission' | true
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DragAndDropCalendar = withDragAndDrop<CustomEvent>(Calendar as any)

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 1 })

const allViews: View[] = ['day', 'week', 'month']

const Root = styled(Paper)((
  {
    theme
  }
) => {
  const dividerPrimary = theme.palette.grey[{
    light: '300' as const,
    dark: '700' as const
  }[theme.palette.mode]]
  const dividerSecondary = theme.palette.grey[{
    light: '200' as const,
    dark: '800' as const
  }[theme.palette.mode]]
  return {
    '& .rbc-time-header.rbc-overflowing': {
      maxHeight: '250px',
      overflowY: 'scroll',
      marginRight: '0px!important',
      borderRight: '0px!important'
    },
    '& .rbc-time-header-cell': {
      top: 0,
      zIndex: 200,
      position: 'sticky',
      background: theme.palette.background.paper
    },
    '& .rbc-event': {
      background: theme.palette.primary.main,
      border: `1px solid ${dividerSecondary}!important`
    },
    '& .rbc-month-view, & .rbc-time-view': {
      border: 'none'
    },
    '& .rbc-off-range': {
      color: theme.palette.text.disabled
    },
    '& .rbc-off-range-bg': {
      background: 'inherit'
    },
    '& .rbc-today': {
      background: 'inherit'
    },
    '& .rbc-date-cell.rbc-now button': {
      fontWeight: 'bold'
    },
    '& .rbc-time-header': {
      overflowY: 'scroll',
      height: 'auto',
      marginRight: '0px!important',
      borderRight: '0px!important'
    },
    '& .rbc-time-header-content': {
      borderLeft: `1px solid ${dividerSecondary}`,
      height: 'fit-content'
    },
    '& .rbc-show-more': {
      background: 'transparent',
      color: theme.palette.primary.main
    },
    '.rbc-show-more:hover, .rbc-show-more:focus': {
      color: theme.palette.primary.dark
    },
    '& .rbc-day-slot .rbc-time-slot': {
      borderTop: `1px solid ${dividerSecondary} !important`
    },
    '& .rbc-time-content .rbc-day-slot .rbc-time-slot:first-child': {
      borderTop: 'none!important'
    },
    '& .rbc-time-header-gutter, & .rbc-time-gutter': {
      width: '1.5rem!important'
    },
    '& .rbc-timeslot-group': {
      borderLeft: `1px solid ${dividerSecondary}`,
      borderBottom: `1px solid ${dividerPrimary}`
    },
    '& .rbc-day-bg + .rbc-day-bg': {
      borderLeft: `1px solid ${dividerSecondary}`
    },
    '& .rbc-month-row + .rbc-month-row': {
      borderTop: `1px solid ${dividerSecondary}`
    },
    '& .rbc-header + .rbc-header': {
      borderLeft: `1px solid ${dividerSecondary}`
    },
    '& .rbc-header': {
      borderBottom: `1px solid ${dividerSecondary}`,
      fontWeight: 'normal'
    },
    '& .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus': {
      outline: 'none'
    },
    '& .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot': {
      fontSize: '0.75rem'
    },
    '& .rbc-time-content': {
      borderTop: `2px solid ${dividerPrimary}`
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor': {
      display: 'flex',
      alignItems: 'center',
      top: 0
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor .rbc-addons-dnd-resize-ew-icon': {
      display: 'block',
      width: '10px',
      marginTop: '-2px',
      marginBottom: '-2px',
      height: 'calc(100% + 4px)',
      border: 'none'
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:first-child': {
      left: '-5px'
    },
    '& .rbc-addons-dnd .rbc-addons-dnd-resize-ew-anchor:last-child': {
      right: '-5px'
    }
  }
})

function isAllDay(start: Attribute | null, end: Attribute | null) {
  if (start === null || end === null) {
    return false
  }
  if (start.AttributeType === 'date' && end.AttributeType === 'date') {
    return true
  }
  return false
}

declare module 'react-big-calendar' {
  interface EventWrapperProps {
    children: React.ReactElement
  }
}

function getCalendarItem(
  domainTypes: Partial<Record<string, DomainType>>,
  rootDomainType: DomainType,
  instance: DomainTypeInstance,
  identifier: string,
  user: User | null,
  domainTypeChain: CalendarTimelineSettings[],
  filterContext: FilterContext,
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  editingItem: CalendarProps['editingItem'],
  startDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute>,
  endDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null,
  allDay: boolean
): CustomEvent[] {
  const subtype = getSubtype(domainTypes, rootDomainType, instance) ?? rootDomainType
  const colour = getDomainTypeSetting(domainTypes, subtype, 'Colour')
  const id = String(instance[identifier])
  const itemActions = getDomainTypeActions(domainTypes, subtype)
  const moveResizeAction = itemActions
    .find(([actionDomainType, action]) => isMoveResizeAction(
      action,
      domainTypeChain[domainTypeChain.length - 1]
    ))
  const moveResizeActionDetails = getItemActionDetails(
    user,
    moveResizeAction,
    domainTypeChain,
    [],
    [instance],
    domainTypeContext,
    filterContext,
    {},
    {}
  )
  const startDate = getValue(instance, startDateAttribute)
  const endDate = getValue(instance, endDateAttribute ?? startDateAttribute)
  if (startDate === null) {
    return []
  }
  const [startValue, endValue] = getEdgeDates(
    startDateAttribute,
    endDateAttribute,
    startDate,
    endDate
  )
  return [
    {
      id,
      title: getHeading(domainTypes, subtype, instance),
      start: id === editingItem?.itemId
        ? DateTime.fromMillis(editingItem.startDate).toJSDate()
        : DateTime.fromMillis(startValue).toJSDate(),
      end: id === editingItem?.itemId
        ? DateTime.fromMillis(editingItem.endDate).toJSDate()
        : DateTime.fromMillis(endValue).toJSDate(),
      allDay,
      colour: colour ?? undefined,
      subtype,
      instance,
      moveResizeActionDetails,
      canEdit: canEditInstancesAttributes(
        domainTypes,
        subtype,
        filterContext,
        [instance],
        user,
        [startDateAttribute.Name, endDateAttribute?.Name ?? startDateAttribute.Name]
      )
    }
  ]
}

export default function CalendarView({
  isLoading,
  domainType,
  items,
  view,
  date,
  editingItem,
  actionDetails,
  actionDialogOpen,
  snackPack,
  setPanelOpen,
  onCloseActionDialog,
  onPerformAction,
  onChangeItemDates,
  onItemClick,
  onViewChange,
  onDateChange
}: Props): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, domainType) ?? domainType
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const startDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'StartDate')
  const endDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'EndDate')
  const allDay = isAllDay(startDateAttribute, endDateAttribute)
  const user = useSelector(getUser)
  const identifier = getIdentifier(domainTypes, domainType)
  const domainTypeChain = useMemo(() => ([
    {
      domainType,
      startDateAttribute,
      endDateAttribute,
      groupByAttribute: null,
      itemsAttribute: null,
      identifier
    }
  ]), [domainType, endDateAttribute, identifier, startDateAttribute])
  const domainTypeContext = useContext(DomainTypeContext)
  const filterContext = useFilterContext()
  const actionButton = useMemo(() => {
    if (actionDetails === undefined
      || editingItem === null) {
      return null
    }
    return getActionButton(
      domainTypes,
      actionDetails,
      getActionParameterValues(
        domainTypeChain,
        actionDetails.action,
        editingItem.startDate,
        editingItem.endDate,
        editingItem.groupByValue
      )
    )
  }, [actionDetails, domainTypeChain, domainTypes, editingItem])
  const {
    addMessage
  } = snackPack
  const events = useMemo(() => {
    if (startDateAttribute === null
      || !isValidStartEndDateAttribute(startDateAttribute)
      || (endDateAttribute !== null && !isValidStartEndDateAttribute(endDateAttribute))) {
      return []
    }
    return items?.flatMap<CustomEvent>(instance => {
      return getCalendarItem(
        domainTypes,
        rootDomainType,
        instance,
        identifier,
        user,
        domainTypeChain,
        filterContext,
        domainTypeContext,
        editingItem,
        startDateAttribute,
        endDateAttribute,
        allDay
      )
    })
  }, [allDay, domainTypeChain, domainTypeContext, domainTypes, editingItem, endDateAttribute, filterContext, identifier, items, rootDomainType, startDateAttribute, user])
  return (
    <Root
      sx={{
        height: 'calc(100vh - 64px - 40px - 16px - 16px - 48px)',
        position: 'relative'
      }}>
      {actionButton !== null && (
        <ActionDialog
          open={actionDialogOpen}
          actionButton={actionButton}
          onClose={onCloseActionDialog}
          onPerform={onPerformAction} />
      )}
      <MissingStartDateAlert
        domainTypeChain={domainTypeChain}
        setPanelOpen={setPanelOpen}
        view='calendar' />
      <Backdrop
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          color: theme => theme.palette.primary.main,
          background: theme => theme.palette.action.hover,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={isLoading || editingItem !== null}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <DragAndDropCalendar
        localizer={localizer}
        view={view}
        onEventDrop={({ event, start, end }) => {
          const startDate = typeof start === 'string'
            ? DateTime.fromISO(start)
            : DateTime.fromJSDate(start)
          const endDate = typeof end === 'string'
            ? DateTime.fromISO(end)
            : DateTime.fromJSDate(end)
          onChangeItemDates?.(
            String(event.id),
            domainTypeChain,
            [],
            [event.instance],
            startDate.toMillis(),
            endDate.toMillis(),
            undefined,
            event.moveResizeActionDetails
          )
        }}
        selected={null}
        draggableAccessor={event => canPerformAction(event.moveResizeActionDetails, event.canEdit)}
        resizableAccessor={event => canPerformAction(event.moveResizeActionDetails, event.canEdit)
          && endDateAttribute !== null}
        resizable={startDateAttribute !== endDateAttribute}
        onEventResize={({ event, start, end }) => {
          const startDate = typeof start === 'string'
            ? DateTime.fromISO(start)
            : DateTime.fromJSDate(start)
          const endDate = typeof end === 'string'
            ? DateTime.fromISO(end)
            : DateTime.fromJSDate(end)
          onChangeItemDates?.(
            String(event.id),
            domainTypeChain,
            [],
            [event.instance],
            startDate.toMillis(),
            endDate.toMillis(),
            undefined,
            event.moveResizeActionDetails
          )
        }}
        date={date}
        onView={view => {
          if (view === 'agenda' || view === 'work_week') {
            return
          }
          onViewChange(view)
        }}
        views={allViews}
        events={events}
        onDoubleClickEvent={event => onItemClick(String(event.id))}
        doShowMoreDrillDown={false}
        onShowMore={(events, newDate) => {
          onViewChange('day')
          onDateChange(newDate)
        }}
        formats={{
          timeGutterFormat: 'HH',
          dayFormat: 'EEE dd'
        }}
        startAccessor='start'
        endAccessor='end'
        titleAccessor='title'
        onNavigate={onDateChange}
        components={{
          toolbar: () => null,
          eventWrapper: props => {
            return (
              <CustomCalendarEventWrapper
                event={props.event}
                onMouseDown={(event: React.MouseEvent) => {
                  if (event.button !== 0) {
                    return
                  }
                  const mouseDownMessage = getMouseDownMessage(props.event, user)
                  if (O.isSome(mouseDownMessage)) {
                    addMessage(mouseDownMessage.value)
                  }
                }}>
                {props.children}
              </CustomCalendarEventWrapper>
            )
          }
        }}
        tooltipAccessor={event => ''}
        eventPropGetter={(event, start, end, isSelected) => ({
          event,
          start,
          end,
          style: { backgroundColor: event.colour }
        })} />
    </Root>
  )
}

