import { Edit, Quiz, Visibility } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Select, Stack, ToggleButtonGroup } from '@mui/material'
import { ResultsInput } from 'components/dataform'
import AppendDomainTypeContext from 'components/domainType/AppendDomainTypeContext'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import OperationsDialog from 'components/domainType/OperationsDialog'
import TextIconButton from 'components/utils/TextIconButton'
import TooltipToggleButton from 'components/utils/TooltipToggleButton'
import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'
import { DomainType, DomainTypeInstance } from 'types'
import { Dataform, Results } from 'types/dataform'
import { DataformCodec } from 'utils/codecs/dataform'
import { stringifyFilterValue } from 'utils/filters'
import { isNullOrUndefined } from 'utils/helpers'
import { useApi, useCancellableApiSession, useNavigate } from 'utils/hooks'
import { DomainTypeComponentProps } from '..'
import DomainTypeHeading from '../../domainType/DomainTypeHeading'

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: props => (
    <ButtonGroup
      variant='contained'
      {...props} />
  ),
  Button: props => (
    <TextIconButton
      iconOnly
      {...props} />
  ),
  Empty: null
}

function getVersionComponents(version: string): number[] {
  try {
    return version
      .split('.')
      .map(component => parseInt(component))
  } catch {
    return []
  }
}

function compareVersions(d1: Dataform, d2: Dataform): number {
  const v1 = getVersionComponents(d1.Version)
  const v2 = getVersionComponents(d2.Version)
  const maxLength = Math.max(v1.length, v2.length)
  for (let i = 0; i < maxLength; i++) {
    const v1i = v1[i] ?? 0
    const v2i = v2[i] ?? 0
    if (v1i > v2i) {
      return -1
    }
    if (v1i < v2i) {
      return 1
    }
  }
  return 0
}

export default function DataformDetails({
  isLoading,
  domainType,
  instance,
  onInvalidate
}: DomainTypeComponentProps['details']): JSX.Element {
  const dataform = useMemo<Dataform | null>(() => {
    return DataformCodec.is(instance)
      ? instance
      : null
  }, [instance])
  const [results] = useState<Results | null>({
    DataformId: String(instance.Id),
    Answers: {},
    Aliases: {}
  })
  const [mode, setMode] = useState('view')
  const [open, setOpen] = useState(false)
  const [otherVersions, setOtherVersions] = useState<Dataform[] | null>(null)
  const onContextInvalidate = useCallback(() => {
    setOtherVersions(null)
    onInvalidate?.()
  }, [onInvalidate])
  const api = useApi()
  const loadOtherVersions = useCancellableApiSession(api)
  useEffect(() => {
    const apiSession = loadOtherVersions.cancelPreviousAndStartNew()
    async function load() {
      if (!apiSession.isSignedIn
        || !isNullOrUndefined(otherVersions)
        || isNullOrUndefined(dataform)) {
        return
      }
      const response = await apiSession.search(
        'Dataform',
        'Dataform',
        [],
        [
          {
            Property: 'Name',
            Operator: 'eq',
            Value: stringifyFilterValue(dataform.Name)
          }
        ],
        [],
        1,
        100,
        true
      )
      if (E.isRight(response)
        && t.array(DataformCodec).is(response.right.results)) {
        setOtherVersions(response.right.results)
      } else {
        setOtherVersions([])
      }
    }
    load()
    return loadOtherVersions.cancel
  }, [dataform, loadOtherVersions, otherVersions])
  const navigate = useNavigate()
  const newInstances = useMemo<[DomainType, DomainTypeInstance][]>(
    () => [[domainType, instance]],
    [domainType, instance]
  )
  const instances = useMemo(() => {
    return [instance]
  }, [instance])
  return (
    <AppendDomainTypeContext
      newInstances={newInstances}
      onInvalidate={onContextInvalidate}>
      <OperationsDialog
        domainType={domainType}
        instances={instances}
        on='DetailsHeader' />
      <Dialog
        maxWidth='md'
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>
          Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>
              Edits made will update the existing dataform.
            </p>
            <p>
              All existing runs using this version of the dataform will be updated. This cannot be undone.
            </p>
            <p>
              Do you want to continue?
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              setMode('edit')
              setOpen(false)
            }}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Stack gap={1}>
        <Stack
          sx={{
            height: '40px',
            mt: 1
          }}
          direction='row'
          alignItems='center'>
          <DomainTypeHeading
            domainType={domainType}
            instance={instance}
            isLoading={isLoading}
            title='Details:'>
            <ToggleButtonGroup
              value={mode}
              exclusive
              color='primary'
              size='small'
              onChange={(event, value) => {
                if (isNullOrUndefined(value)) {
                  return
                }
                if (value === 'edit') {
                  setOpen(true)
                } else {
                  setMode(value)
                }
              }}>
              <TooltipToggleButton
                TooltipProps={{ title: 'View' }}
                value='view'>
                <Visibility />
              </TooltipToggleButton>
              <TooltipToggleButton
                TooltipProps={{ title: 'Edit' }}
                value='edit'>
                <Edit />
              </TooltipToggleButton>
              <TooltipToggleButton
                TooltipProps={{ title: 'Test' }}
                value='test'>
                <Quiz />
              </TooltipToggleButton>
            </ToggleButtonGroup>
            {!isNullOrUndefined(dataform) && !isNullOrUndefined(otherVersions) && (
              <Select
                variant='outlined'
                size='small'
                value={dataform.Id}
                onChange={event => {
                  const dataform = otherVersions
                    .find(otherVersion => otherVersion.Id === event.target.value)
                  if (isNullOrUndefined(dataform)) {
                    return
                  }
                  navigate.toDetailsPage(domainType, dataform as unknown as DomainTypeInstance)
                }}>
                {otherVersions
                  .slice()
                  .sort(compareVersions)
                  .map(otherVersion => (
                    <MenuItem
                      key={otherVersion.Id}
                      value={otherVersion.Id}>
                      {otherVersion.Version}
                    </MenuItem>
                  ))}
              </Select>
            )}
            <Box flexGrow={1} />
            <DomainTypeButtons
              domainType={domainType}
              instances={[instance]}
              on='DetailsHeader'
              components={components}
              priority='medium'
              renderPopoverButtonInsideContainer />
          </DomainTypeHeading>
        </Stack>
        <Box>
          <ResultsInput
            key={`${dataform?.Id ?? ''}${dataform?.Version ?? ''}${mode}`}
            dataform={dataform}
            initialResults={results}
            attribute={null}
            readOnly={mode !== 'test'}
            editable={mode === 'edit'} />
        </Box>
      </Stack>
    </AppendDomainTypeContext>
  )
}