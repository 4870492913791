import { DomainType, DomainTypeInstance } from 'types'
import DetailsPage from './DetailsPage'
import useDetails from './useDetails'

interface Props {
  rootDomainType: DomainType,
  instance: DomainTypeInstance
  onClose?(): void
}

export default function LocalDetailsPage({
  rootDomainType,
  instance,
  onClose
}: Props): JSX.Element {
  const {
    sections,
    domainType,
    view,
    onChangeView,
    onListItemClick,
    selectedTab,
    onTabChange
  } = useDetails(rootDomainType, instance)
  return (
    <DetailsPage
      isLoading={false}
      sections={sections}
      domainType={domainType}
      instance={instance}
      view={view}
      onChangeView={onChangeView}
      onListItemClick={onListItemClick}
      selectedTab={selectedTab}
      onTabChange={onTabChange}
      onClose={onClose} />
  )
}