import { combineReducers, Reducer } from 'redux'
import { Action, AdalProperties, AuthState, Company, Person, User } from 'types'
import adalProperties, * as fromAdalProperties from './adalProperties'
import company, * as fromCompany from './company'
import person, * as fromPerson from './person'
import user, * as fromUser from './user'

const reducer: Reducer<AuthState, Action> = combineReducers({
  user,
  person,
  company,
  adalProperties
})

export default reducer

export function getAdalProperties(state: AuthState): AdalProperties | null {
  return fromAdalProperties.getAdalProperties(state.adalProperties)
}

export function getUser(state: AuthState): User | null {
  return fromUser.getUser(state.user)
}

export function getPerson(state: AuthState): Person | null {
  return fromPerson.getPerson(state.person)
}

export function getCompany(state: AuthState): Company | null {
  return fromCompany.getCompany(state.company)
}