import ButtonsPopover from 'components/domainType/ButtonsPopover'
import { ElementType, JSXElementConstructor, PropsWithChildren, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { AttributeValue, ButtonLocation, DomainType, DomainTypeInstance } from 'types'
import { isInRole, isNullOrUndefined } from 'utils/helpers'
import { useButtons } from 'utils/hooks'
import DomainTypeButton from './DomainTypeButton'

export type ContainerProps = PropsWithChildren<unknown>

export interface ButtonProps {
  readonly text: string
  readonly icon: string
  readonly disabled?: boolean
  readonly onClick: () => void
  readonly component?: ElementType<unknown> | string
}

interface Components {
  readonly Container: JSXElementConstructor<ContainerProps>
  readonly Button: JSXElementConstructor<ButtonProps>
  readonly Empty: JSXElementConstructor<unknown> | null
}

interface Props {
  readonly domainType: DomainType
  readonly instances: DomainTypeInstance[]
  readonly on: ButtonLocation | null
  readonly components: Components
  readonly additionalButtons?: ButtonProps[]
  readonly priority?: 'high' | 'medium' | 'low'
  readonly renderPopoverButtonInsideContainer?: boolean
  readonly parameterValues?: AttributeValue[]
  readonly leafNodeType?: 'active' | 'nested'
  readonly onComplete?: () => void
}

export default function DomainTypeButtons({
  domainType,
  instances,
  on,
  components,
  additionalButtons,
  parameterValues,
  priority,
  renderPopoverButtonInsideContainer = false,
  leafNodeType = 'active',
  onComplete
}: Props): JSX.Element | null {
  const popover = useMemo(() => (
    <ButtonsPopover
      key='popover'
      domainType={domainType}
      instances={instances}
      on={on}
      priority={priority === 'high'
        ? 'medium'
        : 'low'}
      leafNodeType={leafNodeType} />
  ), [domainType, instances, leafNodeType, on, priority])
  const isNotLowPriority = priority !== 'low' && priority !== undefined
  const user = useSelector(getUser)
  const buttons = useButtons(domainType, instances, on, parameterValues, leafNodeType)
  const buttonComponents = useMemo(() => [
    ...(additionalButtons ?? [])
      .map(additionalButton => (
        <components.Button
          key={additionalButton.text}
          text={additionalButton.text}
          icon={additionalButton.icon}
          disabled={additionalButton.disabled}
          onClick={additionalButton.onClick} />
      )),
    ...buttons
      .filter(button => {
        if (priority === undefined) {
          return true
        }
        if (priority === 'high') {
          return button.priority === 'high'
        }
        if (priority === 'medium') {
          return button.priority === 'high' || button.priority === 'medium'
        }
        return button.priority === 'low' || isNullOrUndefined(button.priority)
      })
      .filter(button => isInRole(user, button.role))
      .map(button => (
        <DomainTypeButton
          key={`${button.domainType.Id}_${button.name}`}
          instances={instances}
          button={button}
          Component={components.Button}
          onComplete={onComplete} />
      )),
    ...isNotLowPriority && renderPopoverButtonInsideContainer
      ? [popover]
      : []
  ], [additionalButtons, buttons, isNotLowPriority, renderPopoverButtonInsideContainer, popover, components, priority, user, instances, onComplete])

  return (
    <>
      {buttonComponents.length === 0
        ? components.Empty !== null
          ? (
            <components.Container>
              <components.Empty />
            </components.Container>
          )
          : null
        : (
          <components.Container>
            {buttonComponents}
          </components.Container>
        )}
      {isNotLowPriority && !renderPopoverButtonInsideContainer && popover}
    </>
  )
}