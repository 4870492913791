import { useCallback } from 'react'
import { DomainType, DomainTypeInstance, ListRefAttributeValue, PathError } from 'types'
import { getDomainTypeSetting } from 'utils/helpers'
import MultiSelectAutocompleteInput from '../MultiSelectAutocompleteInput'

interface ListRefProps {
  readonly domainTypes: Partial<Record<string, DomainType>>
  readonly domainType: DomainType
  readonly attributeValue: ListRefAttributeValue
  readonly disabled?: boolean
  readonly readOnly: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: ListRefAttributeValue): void
}

export default function ListRefInput({
  domainTypes,
  domainType,
  attributeValue,
  disabled,
  readOnly,
  pathError,
  onChange
}: ListRefProps): JSX.Element | null {
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'

  const handleChange = useCallback((value: DomainTypeInstance[] | null) => {
    if (onChange === undefined) {
      return
    }

    const instanceIds = value?.map(i => String(i[identifier])) ?? null

    onChange({
      attribute: attributeValue.attribute,
      value: instanceIds
    })
  }, [attributeValue.attribute, identifier, onChange])

  return (
    <MultiSelectAutocompleteInput
      domainTypes={domainTypes}
      domainType={domainType}
      attributeValue={attributeValue}
      disabled={disabled}
      readOnly={readOnly}
      pathError={pathError}
      onChange={handleChange} />
  )
}