import { RefObject } from 'react'
import { ConnectDragPreview, ConnectDragSource, ConnectDropTarget, useDrag, useDrop } from 'react-dnd'
import { isNullOrUndefined } from 'utils/helpers'

interface DragItem {
  readonly id: string
  readonly originalIndex: number
}

interface UseDragToReorderOutput {
  readonly drag: ConnectDragSource
  readonly preview: ConnectDragPreview
  readonly drop: ConnectDropTarget
  readonly opacity: number
}

export function useDragToReorder(
  itemId: string,
  itemOrder: string[],
  itemName: string,
  dropRef: RefObject<HTMLElement | null>,
  direction: 'vertical' | 'horizontal',
  onChangeItemOrder: (itemOrder: string[]) => void,
  onCommitItemOrder: (itemOrder: string[]) => void
): UseDragToReorderOutput {
  const originalIndex = itemOrder.indexOf(itemId)
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: itemName,
    item: {
      id: itemId,
      originalIndex
    },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging()
      }
    },
    end(dragItem: DragItem, monitor) {
      if (!monitor.didDrop()) {
        const newItemOrder = [...itemOrder]
        newItemOrder.splice(itemOrder.indexOf(dragItem.id), 1)
        newItemOrder.splice(dragItem.originalIndex, 0, dragItem.id)
        onChangeItemOrder(newItemOrder)
      } else {
        onCommitItemOrder(itemOrder)
      }
    }
  }), [itemId, originalIndex, itemOrder, onChangeItemOrder, onCommitItemOrder])
  const [, drop] = useDrop(() => ({
    accept: itemName,
    hover(dragItem: DragItem, monitor) {
      if (dragItem.id === itemId
        || isNullOrUndefined(dropRef.current)) {
        return
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      const start = {
        horizontal: 'left',
        vertical: 'top'
      } as const
      const end = {
        horizontal: 'right',
        vertical: 'bottom'
      } as const
      const hoverMiddle = (hoverBoundingRect[end[direction]] - hoverBoundingRect[start[direction]]) / 2
      const clientOffset = monitor.getClientOffset()
      if (isNullOrUndefined(clientOffset)) {
        return
      }
      const coordinate = ({
        horizontal: 'x',
        vertical: 'y'
      } as const)[direction]
      const hoverClient = clientOffset[coordinate] - hoverBoundingRect[start[direction]]
      const hoverDirection = hoverClient > hoverMiddle
      const indexDirection = itemOrder.indexOf(dragItem.id) > itemOrder.indexOf(itemId)
      if (hoverDirection === indexDirection) {
        return
      }
      const newItemOrder = [...itemOrder]
      newItemOrder.splice(itemOrder.indexOf(dragItem.id), 1)
      newItemOrder.splice(itemOrder.indexOf(itemId), 0, dragItem.id)
      onChangeItemOrder(newItemOrder)
    }
  }), [itemId, itemOrder, onChangeItemOrder])
  const opacity = isDragging ? 0.4 : 1
  return {
    drag,
    preview,
    drop,
    opacity
  }
}