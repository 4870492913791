import { MuiColorInput, MuiColorInputValue } from 'mui-color-input'
import { useEffect, useState } from 'react'
import { NonListStringAttributeValue } from 'types'
import { toErrorText } from 'utils/helpers'
import { NarrowedProps } from '../AttributeInput'

const defaultColour = '#00000'

export default function ColourInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<NonListStringAttributeValue>): JSX.Element {
  const [colourValue, setColourValue] = useState<MuiColorInputValue | null>(defaultColour)
  useEffect(() => {
    const value = attributeValue.value
    if (value == null) {
      setColourValue(defaultColour)
    } else {
      setColourValue(value)
    }
  }, [attributeValue.value])
  return (
    <MuiColorInput
      format='hex'
      isAlphaHidden
      label={attributeValue.attribute.Title}
      required={attributeValue.attribute.Required ?? false}
      helperText={toErrorText(pathError)}
      variant='standard'
      disabled={readOnly}
      value={colourValue as MuiColorInputValue}
      onChange={(colour) => {
        onChange?.({
          attribute: attributeValue.attribute,
          value: colour
        })
      }}
      InputProps={{
        readOnly,
        size: 'small'
      }} />
  )
}
