import * as E from 'fp-ts/Either'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { getDomainTypeSetting, getRootDomainType } from 'utils/helpers'
import { useApi } from './useApi'
import { useEditListAttribute } from './useEditListAttribute'

interface UseDeleteOutput {
  isDeleting: boolean
  errorCode: string
  onDelete(): void
}

export function useDelete(
  instanceDomainType: DomainType,
  instances: DomainTypeInstance[],
  onDeleteSuccess: () => void
): UseDeleteOutput {
  const domainTypes = useSelector(getAllDomainTypes)
  const rootDomainType = getRootDomainType(domainTypes, instanceDomainType)
  const api = useApi()
  const [isDeleting, setIsDeleting] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const isApiDomainType = getDomainTypeSetting(domainTypes, instanceDomainType, 'Api') ?? false
  const identifier = getDomainTypeSetting(domainTypes, instanceDomainType, 'Identifier') ?? 'Id'
  const {
    isEditing: isEditingListAttribute,
    onEditListAttribute
  } = useEditListAttribute(instanceDomainType, onDeleteSuccess)
  const onDelete = useCallback(() => {
    if (!isApiDomainType) {
      onEditListAttribute(instances.map(original => ({
        original,
        edited: {
          ...original,
          _delete: true
        }
      })))
      return
    }
    setErrorCode('')
    async function performDelete() {
      if (rootDomainType === null || !api.isSignedIn) {
        return
      }
      setIsDeleting(true)
      const successfullyDeleted: DomainTypeInstance[] = []
      let errorCode: string | undefined = undefined
      for (const instance of instances) {
        const response = await api.delete(
          rootDomainType.Name,
          String(instance[identifier])
        )
        if (E.isRight(response)) {
          successfullyDeleted.push(instance)
        } else {
          errorCode = response.left.errorCode
        }
      }

      if (successfullyDeleted.length > 0) {
        onDeleteSuccess()
      } else if (errorCode !== undefined) {
        setErrorCode(errorCode)
      }
      setIsDeleting(false)
    }
    performDelete()
  }, [api, identifier, instances, isApiDomainType, onDeleteSuccess, onEditListAttribute, rootDomainType])
  return {
    isDeleting: isDeleting || isEditingListAttribute,
    errorCode,
    onDelete
  }
}