import { Avatar, iconClasses, useTheme } from '@mui/material'
import { ComponentProps } from 'react'
import CornerIcon from './CornerIcon'

interface Props extends Omit<ComponentProps<typeof CornerIcon>, 'color'> {
  readonly background: string
  readonly avatarScale?: number
}

export default function AvatarCornerIcon({
  background,
  avatarScale = 1,
  ...props
}: Props): JSX.Element {
  const theme = useTheme()
  return (
    <Avatar
      variant='rounded'
      sx={{
        background,
        width: `${1.5 * avatarScale}rem`,
        height: `${1.5 * avatarScale}rem`,
        [`& .${iconClasses.root}`]: {
          fontSize: `${avatarScale}rem`
        }
      }}>
      <CornerIcon
        {...props}
        color={theme.palette.getContrastText(background)}
        textShadowColor={background} />
    </Avatar>
  )
}