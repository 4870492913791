import { NonListDateAttributeValue } from 'types'
import { dateToString } from 'utils/helpers'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'

export default function DateCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListDateAttributeValue>): JSX.Element | null {
  const value = dateToString(attributeValue.value)

  if (value === null) {
    return null
  }
  return (
    <CellTooltip
      value={value}
      columnWidth={columnWidth} />
  )
}
