import SearchIcon from '@mui/icons-material/Search'
import { Box, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'
import Paper from '@mui/material/Paper'
import { GridFilterListIcon } from '@mui/x-data-grid'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { FormEvent, useEffect, useRef, useState } from 'react'
import { DomainType, Filter } from 'types'
import { FIND_DIALOG_KEY, OPERATIONS_DIALOG_KEY } from 'utils/constants'
import FilterChip from './FilterChip'
import filtersReducer, { deleteFilter } from './filtersReducer'

interface Props {
  domainType: DomainType
  searchText: string
  onSearch(searchText: string): void
  onSearchTextChange?(searchText: string): void
  filterLinkOperator: 'and' | 'or'
  filters: Filter[] | undefined
  onFiltersChange?(filters: Filter[]): void
  permanentFilters?: Filter[]
  permanentFilterOptions?: string[]
  selectedPermanentFilterOption?: number
  onPermanentFilterOptionChange?(index: number): void
  panelOpen: string | false
  setPanelOpen(panel: string | false): void
  disableFilters: boolean
  autoFocus?: boolean
}

export default function SearchInput({
  domainType,
  searchText,
  onSearch,
  onSearchTextChange,
  filterLinkOperator,
  filters = [],
  onFiltersChange,
  permanentFilters = [],
  permanentFilterOptions = [],
  selectedPermanentFilterOption = 0,
  onPermanentFilterOptionChange,
  panelOpen,
  setPanelOpen,
  disableFilters,
  autoFocus = false
}: Props): JSX.Element {
  const [internalSearchText, setSearchText] = useState(searchText)
  useEffect(() => setSearchText(searchText), [searchText])
  useEffect(() => {
    if (onSearchTextChange !== undefined) {
      onSearchTextChange(internalSearchText)
    }
  }, [internalSearchText, onSearchTextChange])
  const inputRef = useRef<HTMLInputElement>()
  return (
    <Box>
      <Paper
        component='form'
        elevation={2}
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault()
          onSearch(internalSearchText)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '40px'
        }}>
        {!disableFilters && (
          <TooltipIconButton
            icon={<GridFilterListIcon />}
            tooltipText='Filters'
            color='primary'
            onClick={event => {
              event.preventDefault()
              setPanelOpen(panelOpen === 'filters' ? false : 'filters')
            }} />
        )}
        {permanentFilters.concat(filters).length > 0 && (
          <Stack
            direction='row'
            gap={1}
            p={1}
            pr={0}
            alignItems='center'
            flexWrap='wrap'
            flexShrink={1}>
            {permanentFilters.map((filter, i) => (
              <FilterChip
                key={`${filter.Property}${filter.Operator}${filter.Value}`}
                domainType={domainType}
                filter={filter}
                permanent
                permanentFilterOptions={permanentFilterOptions}
                selectedPermanentFilterOption={selectedPermanentFilterOption}
                onPermanentFilterOptionChange={onPermanentFilterOptionChange} />
            ))}
            {filters
              .map((filter, i) => (
                <FilterChip
                  key={`${filter.Property}${filter.Operator}${filter.Value}`}
                  domainType={domainType}
                  filter={filter}
                  ChipProps={{
                    onDelete() {
                      onFiltersChange?.(filtersReducer(filters, deleteFilter(i)))
                    },
                    onClick() {
                      setPanelOpen(panelOpen === 'filters' ? false : 'filters')
                    }
                  }} />
              ))
              .reduce((prev: JSX.Element[], curr, i) => {
                return prev.concat([curr, <span key={i}>{filterLinkOperator}</span>])
              }, [])}
          </Stack>
        )}
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            flexBasis: '200px'
          }}
          inputRef={inputRef}
          color='error'
          placeholder='Search'
          fullWidth
          value={internalSearchText}
          onKeyDown={event => {
            if (internalSearchText !== ''
              || (event.key !== OPERATIONS_DIALOG_KEY && event.key !== FIND_DIALOG_KEY)) {
              event.stopPropagation()
            } else {
              event.currentTarget.blur()
            }
          }}
          onChange={event => setSearchText(event.target.value)}
          inputProps={{ 'aria-label': 'search' }}
          autoFocus={autoFocus} />
        <IconButton
          tabIndex={-1}
          type='submit'
          sx={{ p: '10px' }}
          aria-label='search'>
          <SearchIcon />
        </IconButton>
      </Paper>
    </Box>
  )
}
