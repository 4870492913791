import { useSelector } from 'react-redux'
import { ChildrenNoProps } from 'types'
import * as fromState from '../../state/reducers'

export default function IsAuthenticated({
  children
}: ChildrenNoProps): JSX.Element | null {
  const user = useSelector(fromState.getUser)
  const isAuthenticated = !!user && !!user.token
  return isAuthenticated
    ? (
      <>
        {children}
      </>
    )
    : null
}
