import { Clear } from '@mui/icons-material'
import { Box, ButtonGroup, FormControl, FormHelperText, FormLabel, Paper } from '@mui/material'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { AttachedFileHandle, SignatureElement } from 'types/dataform'
import { EntityWithAttachedFilesCodec } from 'utils/codecs/dataform'
import { blobToBase64 } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

interface SignatureCanvasRef {
  isEmpty(): boolean
  clear(): void
  fromDataURL(base64String: string): void
  toDataURL(): void
  off(): void
  on(): void
}

const SIGNATURE_WIDTH = 500
const SIGNATURE_ASPECT_RATIO = 0.43
const SIGNATURE_HEIGHT = SIGNATURE_ASPECT_RATIO * SIGNATURE_WIDTH

export default function Signature({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<SignatureElement>): JSX.Element | null {
  const signatureRef = useRef<SignatureCanvasRef>()
  const work = useDomainTypeContextInstance('Work', 'Work', EntityWithAttachedFilesCodec)
  const decodedValue: E.Either<string, string | AttachedFileHandle | null> = useMemo(() => {
    if (value === null) {
      return E.right(null)
    }
    if (value.startsWith('data:')) {
      return E.right(value)
    }
    if (work === null) {
      return E.left('No work was found from which to load the signature')
    }
    const matchingFileHandle = work.AttachedFiles
      .find(attachedFile => attachedFile.Id === value)
    if (matchingFileHandle === undefined) {
      return E.left('No signature was found on the work')
    }
    return E.right(matchingFileHandle)
  }, [value, work])
  const signatureValue = E.isRight(decodedValue)
    ? decodedValue.right
    : null
  const signatureError = E.isLeft(decodedValue)
    ? decodedValue.left
    : null
  const api = useApi()
  useEffect(() => {
    if (signatureValue === null) {
      signatureRef.current?.clear()
    } else if (typeof signatureValue === 'string') {
      signatureRef.current?.fromDataURL(signatureValue)
    } else if (typeof signatureValue === 'object') {
      const attachedFile = signatureValue
      const loadImage = async () => {
        if (!api.isSignedIn || work === null) {
          return
        }
        const response = await api.getFileBlob(
          'Work',
          work.Id,
          attachedFile.FileId
        )
        if (E.isLeft(response)) {
          return
        }
        const value = await blobToBase64(response.right, 'image/jpeg')
        if (value !== null) {
          signatureRef.current?.fromDataURL(value)
        }
      }
      loadImage()
    }
  }, [api, signatureValue, work])
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error || signatureError !== null}>
      <FormLabel>{element.Text}</FormLabel>
      <Box
        display='flex'
        alignItems='flex-start'
        gap={1}>
        <Paper
          sx={{
            width: SIGNATURE_WIDTH,
            height: SIGNATURE_HEIGHT,
            background: '#FFF'
          }}>
          <SignatureCanvas
            ref={ref => {
              signatureRef.current = ref as SignatureCanvasRef | undefined
              if (readOnly || locked) {
                signatureRef.current?.off()
              }
            }}
            onEnd={() => {
              onChange(signatureRef.current?.toDataURL() ?? null)
            }}
            canvasProps={{
              width: SIGNATURE_WIDTH,
              height: SIGNATURE_HEIGHT,
              className: 'sigCanvas'
            }} />
        </Paper>
        {readOnly !== true && (
          <ButtonGroup
            orientation='vertical'
            variant='contained'>
            <TooltipIconButton
              icon={<Clear />}
              tooltipText='Clear'
              disabled={locked}
              onClick={() => onChange(null)} />
          </ButtonGroup>
        )}
      </Box>
      <FormHelperText>{signatureError ?? errorText}</FormHelperText>
    </FormControl>
  )
}