import { Box, Button, Divider } from '@mui/material'
import { Dataform } from 'types/dataform'

interface FooterButtonsProps {
  readonly activeGroup: number
  readonly dataform: Dataform | null
  readonly complete: boolean
  onBack(): void
  onNext(): void
}

export default function FooterButtons({
  activeGroup,
  dataform,
  complete,
  onBack,
  onNext
}: FooterButtonsProps): JSX.Element {
  const lastGroupIsActive = activeGroup === (dataform?.Groups.length ?? 0) - 1
  const showBackButton = activeGroup !== 0
  const showNextCompleteButton = !lastGroupIsActive || !complete
  return (
    <>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          minHeight: '48px'
        }}>
        {showBackButton && (
          <Button
            size='small'
            color='inherit'
            variant='text'
            disabled={!showBackButton}
            sx={{ mr: 1 }}
            onClick={onBack}>
            Back
          </Button>
        )}
        <Box sx={{ flex: '1 1 auto' }} />
        {showNextCompleteButton && (
          <Button
            size='small'
            variant='text'
            onClick={onNext}
            disabled={!showNextCompleteButton}>
            {lastGroupIsActive
              ? 'Complete'
              : 'Next'}
          </Button>
        )}
      </Box>
    </>
  )
}