import { DefaultValueContext, Element, Results } from 'types/dataform'
import { ElementTypeCodecs } from 'utils/codecs/dataform'
import { applyDependencies, changeGroup, decrementGroup, incrementGroupOrCompleteIfNoErrors, reinitialiseElementValues, setAsExpected, setConstraintError, setInitialElementValues, setRequiredError, setValueToDefault, setValueToNull, updateFollowingMilestones, updatePrecedingElementsIfMilestone } from './effects'
import { getCycle, getDependencyGraph, getElementDefaultValue, getElementExpectedValue, getElementValue, isElementRequired, stateToResults } from './selectors'
import { Action, ElementGraph, ElementState, INITIALISED, NotInitialisedState, State } from './types'

export const defaultElementState: ElementState = {
  hidden: false,
  required: false,
  value: null,
  resultsValue: null,
  defaultValue: null,
  expectedValue: null,
  requiredError: false,
  constraintError: false,
  lockedByMilestones: [],
  lockedByElements: [],
  disabledByDependencies: [],
  asExpected: true,
  readOnly: false,
  circularDependency: null,
  circularCalculation: null
}

function updateElementState<K extends keyof ElementState>(
  state: State,
  element: Element,
  key: K,
  value: ElementState[K]
): State {
  return {
    ...state,
    elements: {
      ...state.elements,
      [element.Id]: {
        ...defaultElementState,
        ...state.elements[element.Id],
        [key]: value
      }
    }
  }
}

type ElementStateListNames = {
  [Key in keyof ElementState]: ElementState[Key] extends unknown[] ? Key : never
}[keyof ElementState]
type ElementStateLists = {
  [Key in ElementStateListNames]: ElementState[Key]
}
function addToElementStateList<K extends keyof ElementStateLists>(
  state: State,
  element: Element,
  key: K,
  value: ElementStateLists[K][number]
): State {
  const existingList: unknown[] = state.elements[element.Id]?.[key] ?? []
  return {
    ...state,
    elements: {
      ...state.elements,
      [element.Id]: {
        ...defaultElementState,
        ...state.elements[element.Id],
        [key]: existingList
          .filter(item => item !== value)
          .concat([value])
      }
    }
  }
}
function removeFromElementStateList<K extends keyof ElementStateLists>(
  state: State,
  element: Element,
  key: K,
  value: ElementStateLists[K][number]
): State {
  const existingList: unknown[] = state.elements[element.Id]?.[key] ?? []
  return {
    ...state,
    elements: {
      ...state.elements,
      [element.Id]: {
        ...defaultElementState,
        ...state.elements[element.Id],
        [key]: existingList
          .filter(item => item !== value)
      }
    }
  }
}

interface StateWithActions {
  readonly state: State
  readonly actions: Action[]
}

function runWithActions(stateWithActions: StateWithActions): State {
  if (stateWithActions.actions.length === 0) {
    return stateWithActions.state
  }
  return stateWithActions.actions.reduce(
    (prev, curr) => dataformReducer(prev, curr),
    stateWithActions.state
  )
}

type Effect<A extends Action> = (state: State, action: A) => Action[]

function withEffects<A extends Action>(state: State, action: A, effects: Effect<A>[]): State {
  return runWithActions({
    state,
    actions: effects.flatMap(effect => effect(state, action))
  })
}

function initialiseElementState(
  element: Element,
  results: Results | null,
  defaultValueContext: DefaultValueContext,
  dependencyGraph: ElementGraph
): ElementState<Element> | undefined {
  return {
    ...defaultElementState,
    // TODO - filters + masks
    hidden: false,
    required: isElementRequired(element),
    // TODO - masks
    readOnly: false,
    resultsValue: getElementValue(results, element.Id, element.ElementType),
    defaultValue: getElementDefaultValue(
      element.ElementType,
      element.DefaultResult,
      defaultValueContext
    ),
    expectedValue: getElementExpectedValue(element.ExpectedResult),
    circularDependency: getCycle(element.Id, dependencyGraph)
  }
}

function reinitialiseElementState(
  element: Element,
  currentState: ElementState | undefined,
  results: Results | null,
  defaultValueContext: DefaultValueContext,
  dependencyGraph: ElementGraph
): ElementState<Element> | undefined {
  return {
    ...defaultElementState,
    ...currentState,
    value: ElementTypeCodecs[element.ElementType].is(currentState?.value)
      ? currentState?.value ?? null
      : null,
    // TODO - filters + masks
    hidden: false,
    required: isElementRequired(element),
    // TODO - masks
    readOnly: false,
    resultsValue: getElementValue(results, element.Id, element.ElementType),
    defaultValue: getElementDefaultValue(
      element.ElementType,
      element.DefaultResult,
      defaultValueContext
    ),
    expectedValue: getElementExpectedValue(element.ExpectedResult),
    circularDependency: getCycle(element.Id, dependencyGraph)
  }
}

const actions: { [A in Action as A['type']]: (state: State, action: A) => State } = {
  INITIALISED(state, action) {
    const dependencyGraph = getDependencyGraph(action.payload.dataform)
    return withEffects(
      {
        type: 'initialised',
        dataform: action.payload.dataform,
        activeGroup: 0,
        showRequiredErrors: false,
        complete: action.payload.results?.Complete ?? false,
        elements: action.payload.dataform.Groups.flatMap(group => group.Elements)
          .reduce((elements, element) => {
            elements[element.Id] = initialiseElementState(
              element,
              action.payload.results,
              action.payload.defaultValueContext,
              dependencyGraph
            )
            return elements
          }, {} as State['elements'])
      },
      action,
      [
        setInitialElementValues
        // TODO - perform calculations for elements with null value
      ]
    )
  },
  DATAFORM_EDITED(state, action) {
    const dependencyGraph = getDependencyGraph(action.payload.dataform)
    return withEffects(
      {
        ...state,
        dataform: action.payload.dataform,
        complete: false,
        elements: action.payload.dataform.Groups.flatMap(group => group.Elements)
          .reduce((elements, element) => {
            elements[element.Id] = reinitialiseElementState(
              element,
              state.elements[element.Id],
              stateToResults(state),
              action.payload.defaultValueContext,
              dependencyGraph
            )
            return elements
          }, {} as State['elements'])
      },
      action,
      [
        reinitialiseElementValues
        // TODO - perform calculations for elements with null value
      ]
    )
  },
  ELEMENT_VALUE_CHANGED(state, action) {
    return withEffects(
      updateElementState(state, action.payload.element, 'value', action.payload.value),
      action,
      [
        updatePrecedingElementsIfMilestone,
        applyDependencies,
        setAsExpected,
        setConstraintError,
        setRequiredError
        // TODO - perform calculations for all elements
      ]
    )
  },
  ELEMENT_ENABLED_BY_DEPENDENCY(state, action) {
    return withEffects(
      removeFromElementStateList(state, action.payload.element, 'disabledByDependencies', action.payload.dependency),
      action,
      [
        setValueToDefault
      ]
    )
  },
  ELEMENT_DISABLED_BY_DEPENDENCY(state, action) {
    return withEffects(
      addToElementStateList(state, action.payload.element, 'disabledByDependencies', action.payload.dependency),
      action,
      [
        setValueToNull
      ]
    )
  },
  ELEMENT_LOCKED_BY_MILESTONE(state, action) {
    return addToElementStateList(state, action.payload.element, 'lockedByMilestones', action.payload.milestone)
  },
  ELEMENT_UNLOCKED_BY_MILESTONE(state, action) {
    return removeFromElementStateList(state, action.payload.element, 'lockedByMilestones', action.payload.milestone)
  },
  MILESTONE_LOCKED_BY_ELEMENT(state, action) {
    return addToElementStateList(state, action.payload.milestone, 'lockedByElements', action.payload.element)
  },
  MILESTONE_UNLOCKED_BY_ELEMENT(state, action) {
    return removeFromElementStateList(state, action.payload.milestone, 'lockedByElements', action.payload.element)
  },
  ELEMENT_AS_EXPECTED(state, action) {
    // TODO - no unexpected answers setting for milestones
    return updateElementState(state, action.payload.element, 'asExpected', true)
  },
  ELEMENT_NOT_AS_EXPECTED(state, action) {
    // TODO - no unexpected answers setting for milestones
    return updateElementState(state, action.payload.element, 'asExpected', false)
  },
  ELEMENT_CONSTRAINT_ERROR_REMOVED(state, action) {
    return withEffects(
      updateElementState(state, action.payload.element, 'constraintError', false),
      action,
      [
        updateFollowingMilestones
      ]
    )
  },
  ELEMENT_CONSTRAINT_ERROR_ADDED(state, action) {
    return withEffects(
      updateElementState(state, action.payload.element, 'constraintError', true),
      action,
      [
        updateFollowingMilestones
      ]
    )
  },
  ELEMENT_REQUIRED_ERROR_REMOVED(state, action) {
    return withEffects(
      updateElementState(state, action.payload.element, 'requiredError', false),
      action,
      [
        updateFollowingMilestones
      ]
    )
  },
  ELEMENT_REQUIRED_ERROR_ADDED(state, action) {
    return withEffects(
      updateElementState(state, action.payload.element, 'requiredError', true),
      action,
      [
        updateFollowingMilestones
      ]
    )
  },
  GROUP_STEPPER_CLICKED(state, action) {
    return withEffects(
      state,
      action,
      [
        changeGroup
      ]
    )
  },
  BACK_BUTTON_CLICKED(state, action) {
    return withEffects(
      state,
      action,
      [
        decrementGroup
      ]
    )
  },
  NEXT_BUTTON_CLICKED(state, action) {
    return withEffects(
      {
        ...state,
        showRequiredErrors: true
      },
      action,
      [
        incrementGroupOrCompleteIfNoErrors
      ]
    )
  },
  ACTIVE_GROUP_CHANGED(state, action) {
    return withEffects(
      {
        ...state,
        activeGroup: action.payload.index,
        showRequiredErrors: false
      },
      action,
      []
    )
  },
  COMPLETED(state, action) {
    return withEffects(
      {
        ...state,
        complete: true,
        showRequiredErrors: false
      },
      action,
      []
    )
  },
  UNLOCKED(state, action) {
    return withEffects(
      {
        ...state,
        complete: false
      },
      action,
      []
    )
  }
}

export default function dataformReducer<S extends State | NotInitialisedState>(state: S, action: Action): S
export default function dataformReducer(
  state: State | NotInitialisedState,
  action: Action
): State | NotInitialisedState {
  if (state.type === 'notInitialised') {
    if (action.type === INITIALISED) {
      return actions.INITIALISED({} as State, action)
    } else {
      return state
    }
  }
  return (actions[action.type] as (state: State, action: Action) => State)(state, action)
}

