import { Box, Drawer, styled, Toolbar } from '@mui/material'
import React, { useCallback, useContext, useState } from 'react'
import { PAGE_PADDING } from 'utils/constants'
import SidePanelContext from 'utils/context/SidePanelContext'

interface Props {
  children: JSX.Element | null
  drawerWidth: number
  setDrawerWidth: (width: number) => void
}

export const defaultDrawerWidth = 300
const minDrawerWidth = 400
const maxDrawerWidth = 2000

const DragHandle = styled('div', {
  shouldForwardProp: prop => prop !== 'isDragging'
})<{ isDragging: boolean }>(({ theme, isDragging }) => ({
  width: '5px',
  cursor: isDragging
    ? 'default'
    : 'ew-resize',
  padding: '4px 0 0',
  borderTop: '1px solid #ddd',
  top: 0,
  position: 'absolute',
  left: 0,
  bottom: 0,
  zIndex: 100,
  backgroundColor: isDragging
    ? theme.palette.primary.main
    : 'lightGray',
  paddingBottom: '100%',
  height: '100%',
  boxSizing: undefined,
  '&:hover': {
    backgroundColor: theme.palette.primary.main
  }
}))

export default function ResizableDrawer({
  drawerWidth,
  children,
  setDrawerWidth
}: Props): JSX.Element {
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    setIsDragging(false)
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback((e: MouseEvent) => {
    e.preventDefault()
    const newWidth = window.document.body.clientWidth - e.clientX + 2
    // console.log(newWidth)
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth)
    }
  }, [setDrawerWidth])

  const { sidePanel } = useContext(SidePanelContext)

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth
        },
        pointerEvents: sidePanel === null
          ? 'none'
          : undefined
      }}
      variant='persistent'
      anchor='right'
      open={sidePanel !== null}
      PaperProps={{
        style: {
          width: drawerWidth,
          overflow: 'hidden'
        }
      }}>
      <div />
      <DragHandle
        isDragging={isDragging}
        onMouseDown={handleMouseDown} />
      <Toolbar />
      <Box
        sx={{
          position: 'relative',
          p: PAGE_PADDING,
          pt: 0,
          height: '100%',
          width: '100%',
          overflowY: 'scroll'
        }}>
        {children}
      </Box>
    </Drawer>
  )
}
