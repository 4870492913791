import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

export type UploadFileInputHandle = {
  open: () => Promise<File[] | null | undefined>
}

interface Props {
  readonly multiple?: boolean
  readonly accept?: string[]
}

function resolveAndClearFiles(
  input: HTMLInputElement,
  resolve: (fileList: File[]) => void) {
  const files = input.files
  if (files !== null) {
    resolve(Array.from(files))
  } else {
    resolve([])
  }
  input.files = null
}

export default forwardRef<UploadFileInputHandle, Props>(function UploadFileInput(
  {
    multiple = false,
    accept = []
  }: Props,
  ref
): JSX.Element {
  const inputRef = useRef(null)
  const [filesSelected, setFilesSelected] = useState(false)

  useImperativeHandle(ref, () => ({
    async open() {
      const htmlInputElem = inputRef.current as HTMLInputElement | null
      if (htmlInputElem == null) {
        return new Promise((resolve) => resolve(null))
      }
      setFilesSelected(false)
      const promise = new Promise<File[] | null>((resolve, reject) => {
        const changeListener = () => {
          setFilesSelected(true)
          resolveAndClearFiles(htmlInputElem, resolve)
        }

        // if the user clicks cancel, no event is raised from the input
        // this is invoked before the onChange handler above, hence the timeout to 
        // ensure the onChange handler is invoked first
        const blurListener = () => {
          setTimeout(() => {
            if (!filesSelected) {
              resolveAndClearFiles(htmlInputElem, resolve)
            }
            htmlInputElem.files = null
            document.body.onfocus = null
          }, 500)
        }
        htmlInputElem.addEventListener('change', changeListener, { once: true })
        document.body.onfocus = blurListener
      })

      htmlInputElem.click()

      return promise
    }
  }))

  return (
    <input
      hidden
      ref={inputRef}
      multiple={multiple}
      type='file'
      accept={accept.join(',')} />
  )
})

