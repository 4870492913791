import { Backdrop, Box, CircularProgress, ClickAwayListener, Icon, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover, Snackbar, Typography, styled, useTheme } from '@mui/material'
import AttributeCell, { DomainTypeTooltip } from 'components/attribute/AttributeCell'
import ActionDialog from 'components/domainType/ActionDialog'
import DomainTypeButtons from 'components/domainType/DomainTypeButtons'
import * as O from 'fp-ts/Option'
import * as t from 'io-ts'
import { DateTime, Duration } from 'luxon'
import { ComponentProps, ContextType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Timeline, { DateHeader, DateHeaderProps, LabelFormat, SidebarHeader, TimelineHeaders, TimelineMarkers, TodayMarker } from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { useSelector } from 'react-redux'
import { getAllDomainTypes, getUser } from 'state/reducers'
import { AttributeValue, DomainType, DomainTypeAction, DomainTypeInstance, User } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { DomainTypeContext, DomainTypeOverriderContext } from 'utils/context'
import { FilterContext } from 'utils/filters'
import { getDomainTypeActions, getDomainTypeAttribute, getDomainTypeSetting, getHeading, getIdentifier, getOverridableDomainTypeSettingAttribute, getRootDomainType, getSortableValue, getSubtype, getValue, isDomainTypeListAttribute, isNullOrUndefined, isValidTimelineGroupByAttribute } from 'utils/helpers'
import { SnackPack, getActionButton, useFilterContext, useNavigate } from 'utils/hooks'
import MissingStartDateAlert from '../MissingStartDateAlert'
import { canEditInstancesAttributes, canPerformAction, getActionParameterValues, getAttributeValueInvalidReason, getEdgeDates, getFirstNotNullValueFromChain, getItemActionDetails, getItemBoundaries, getItemDomainTypeContext, getItemFilterContext, getMouseDownMessage, getTimelineItemChains, isChangeGroupAction, isMoveResizeAction } from '../helpers'
import { CalendarProps, CalendarTimelineSettings, CustomTimelineGroup, CustomTimelineItem } from '../types'

interface Props extends CalendarProps {
  isLoading: boolean
  domainType: DomainType
  items: DomainTypeInstance[]
  snackPack: SnackPack
  setPanelOpen(panel: string): void
  onItemClick(id: string): void
}

const Root = styled(Paper)((
  {
    theme
  }
) => {
  const dividerPrimary = theme.palette.grey[{
    light: '300' as const,
    dark: '700' as const
  }[theme.palette.mode]]
  const dividerSecondary = theme.palette.grey[{
    light: '200' as const,
    dark: '800' as const
  }[theme.palette.mode]]
  return {
    '& .react-calendar-timeline .rct-dateHeader': {
      background: `${theme.palette.background.default}!important`,
      borderLeft: 'none',
      borderRight: `1px ${dividerSecondary} solid`,
      borderBottom: 'none',
      borderTop: `1px ${dividerPrimary} solid`,
      fontSize: '0.875rem'
    },
    '& .react-calendar-timeline .rct-dateHeader-primary': {
      borderTop: 'none',
      color: theme.palette.text.primary
    },
    '& .rct-header-root': {
      background: `${theme.palette.background.default}!important`,
      borderRight: `1px ${theme.palette.background.default} solid`
    },
    '& .navigationBox': {
      background: `${theme.palette.background.default}!important`
    },
    '& .rct-item ': {
      background: theme.palette.primary.main,
      margin: 0,
      borderRadius: '5px',
      textAlign: 'left',
      overflowX: 'clip',
      cursor: 'pointer!important',
      fontSize: '1rem!important',
      border: `1px solid ${dividerSecondary}!important`,
      userSelect: 'none',
      minWidth: '20px'
    },
    '& .react-calendar-timeline .rct-item .rct-item-content': {
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      lineHeight: 'initial'
    },
    '& .rct-item-handler-left': {
      left: '0px !important'
    },
    '& .rct-item-handler': {
      position: 'absolute',
      top: '4px',
      bottom: 0,
      width: 'unset!important',
      maxWidth: 'unset!important',
      minWidth: 'unset!important',
      display: 'flex',
      alignItems: 'center'
    },
    '& .rct-item-handler-right': {
      right: '0px !important'
    },
    '& .rct-item-handler-icon': {
      display: 'block',
      width: '10px',
      marginTop: 'auto',
      marginBottom: 'auto',
      height: '100%',
      cursor: 'ew-resize'
    },
    '& .rct-hl-odd': {
      background: `${theme.palette.background.paper}!important`
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd': {
      background: `${theme.palette.background.paper}!important`
    },
    '& .react-calendar-timeline .rct-scroll': {
      overflowX: 'hidden !important'
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd': {
      borderBottom: `1px ${dividerSecondary} solid`
    },
    '& .react-calendar-timeline .rct-horizontal-lines .rct-hl-even:last-child, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd:last-child': {
      borderBottom: 'none'
    },
    '& .react-calendar-timeline .rct-vertical-lines .rct-vl': {
      borderLeft: 'none',
      borderRight: `1px ${dividerSecondary} solid`
    },
    '& .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0': {
      background: 'unset'
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row': {
      borderBottom: `1px ${dividerSecondary} solid`,
      display: 'flex',
      lineHeight: 'unset!important',
      alignItems: 'center'
    },
    '& .react-calendar-timeline .rct-sidebar .rct-sidebar-row:last-child': {
      borderBottom: 'none'
    },
    '& .react-calendar-timeline .rct-sidebar': {
      borderRight: `1px ${dividerPrimary} solid`,
      fontSize: '0.875rem'
    },
    '& .react-calendar-timeline .rct-header-root': {
      borderBottom: `1px ${dividerPrimary} solid`
    },
    '& .react-calendar-timeline .rct-header-root > div:first-child': {
      borderRight: `1px ${dividerPrimary} solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.875rem',
      padding: '4px'
    },
    '& .react-calendar-timeline .rct-header-root > div:last-child:not(.rct-calendar-header)': {
      borderLeft: `1px ${dividerPrimary} solid`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.875rem',
      padding: '4px'
    },
    '& .rct-scroll': {
      borderRight: `1px ${dividerPrimary} solid`
    },
    '& .react-calendar-timeline .rct-sidebar.rct-sidebar-right': {
      borderLeft: 'none'
    },
    '& .react-calendar-timeline .rct-calendar-header': {
      border: 'none'
    }
  }
})

const NULL_GROUP_ID = '__null__'

type GroupsAndItems = [CustomTimelineGroup[], CustomTimelineItem[]]

const EMPTY_GROUPS_AND_ITEMS: GroupsAndItems = [
  [
    {
      id: NULL_GROUP_ID,
      title: ''
    }
  ],
  []
]

const formatOptions: LabelFormat = {
  year: {
    long: 'YYYY',
    mediumLong: 'YYYY',
    medium: 'YYYY',
    short: 'YY'
  },
  month: {
    long: 'MMMM YYYY',
    mediumLong: 'MMMM',
    medium: 'MMMM',
    short: 'MM/YY'
  },
  week: {
    long: 'w',
    mediumLong: 'w',
    medium: 'w',
    short: 'w'
  },
  day: {
    long: 'ddd D',
    mediumLong: 'ddd D',
    medium: 'dd D',
    short: 'DD'
  },
  hour: {
    long: 'HH:00',
    mediumLong: 'HH:00',
    medium: 'HH:00',
    short: 'HH'
  },
  minute: {
    long: 'HH:mm',
    mediumLong: 'HH:mm',
    medium: 'HH:mm',
    short: 'mm'
  }
}

const labelFormat: Exclude<DateHeaderProps<unknown>['labelFormat'], string | undefined> = (
  [startTime, endTime],
  unit,
  labelWidth
) => {
  if (unit === 'second' || unit === 'isoWeek') {
    return ''
  }
  if (labelWidth < 50) {
    return startTime.format(formatOptions[unit].short)
  } else if (labelWidth < 100) {
    return startTime.format(formatOptions[unit].medium)
  } else if (labelWidth < 150) {
    return startTime.format(formatOptions[unit].mediumLong)
  }
  return startTime.format(formatOptions[unit].long)
}

function getTimelineGroup(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[]
): CustomTimelineGroup {
  const item = itemChain[itemChain.length - 1] ?? {}
  const groupByAttribute = domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute
  if (!isValidTimelineGroupByAttribute(groupByAttribute)) {
    return {
      id: NULL_GROUP_ID,
      title: ''
    }
  }
  if (groupByAttribute.AttributeType !== 'domainType') {
    const value = getValue(item, groupByAttribute)
    return {
      id: value ?? NULL_GROUP_ID,
      title: '',
      attributeValue: {
        attribute: groupByAttribute,
        value
      }
    }
  }
  const domainType = domainTypes[groupByAttribute.AttributeDomainType]
  if (domainType === undefined) {
    return {
      id: NULL_GROUP_ID,
      title: ''
    }
  }
  const value = getValue(item, groupByAttribute)
  const identifier = getDomainTypeSetting(
    domainTypes,
    domainType,
    'Identifier'
  ) ?? 'Id'
  return {
    id: String(value?.[identifier] ?? NULL_GROUP_ID),
    title: '',
    attributeValue: {
      attribute: groupByAttribute,
      value
    }
  }
}

function getTimelineItem(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[],
  groupId: string | number,
  editingItem: CalendarProps['editingItem'],
  subtypesCache: Partial<Record<string, DomainType[]>>
): Omit<CustomTimelineItem, 'itemsSubtype' | 'context' | 'canEdit' | 'moveResizeActionDetails' | 'changeGroupActionDetails'> | null {
  const {
    startDate,
    endDate,
    startDateAttribute,
    endDateAttribute
  } = getItemBoundaries(domainTypes, domainTypeChain, itemChain)
  const colour = getFirstNotNullValueFromChain(
    domainTypes,
    domainTypeChain,
    itemChain,
    (domainTypes, settings, item) => {
      if (settings === undefined) {
        return null
      }
      const { domainType } = settings
      const subtype = getSubtype(domainTypes, domainType, item, subtypesCache) ?? domainType
      return getDomainTypeSetting(domainTypes, subtype, 'Colour')
    }
  ) ?? undefined
  if (startDateAttribute === null || startDate === null) {
    return null
  }
  const [startValue, endValue] = getEdgeDates(
    startDateAttribute,
    endDateAttribute,
    startDate,
    endDate
  )
  const items = itemChain.slice(-1)
  const groupItems = itemChain.slice(0, -1)
  const groupItemsWithAtLeastOne = itemChain.slice(0, 1)
    .concat(itemChain.slice(1, -1))
  const id = groupItemsWithAtLeastOne
    .map((item, i) => {
      const identifier = getIdentifier(domainTypes, domainTypeChain[i]?.domainType)
      return item[identifier]
    })
    .concat([startDate, endDate, groupId])
    .join('_')
  return {
    id,
    group: id === editingItem?.itemId
      ? editingItem.groupByValue?.id ?? groupId
      : groupId,
    title: groupItemsWithAtLeastOne
      .map((item, i) => {
        const domainType = domainTypeChain[i]?.domainType
        if (domainType === undefined) {
          return ''
        }
        const subtype = getSubtype(domainTypes, domainType, item, subtypesCache) ?? domainType
        return getHeading(domainTypes, subtype, item)
      })
      .join(' ▸ '),
    start_time: id === editingItem?.itemId
      ? editingItem.startDate
      : startValue,
    end_time: id === editingItem?.itemId
      ? editingItem.endDate
      : endValue,
    colour,
    groupItems,
    items
  }
}

function getGroupSortableValue(
  domainTypes: Partial<Record<string, DomainType>>,
  group: CustomTimelineGroup
): AttributeValue['value'] {
  if (group.id === NULL_GROUP_ID) {
    return null
  }
  return getSortableValue(
    domainTypes,
    group.attributeValue
  )
}

function getGroupSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  const parentInstance = timelineItem.groupItems[timelineItem.groupItems.length - 1]
  if (parentInstance === undefined) {
    return itemsDomainType
  }
  const itemsAttribute = domainTypeChain[domainTypeChain.length - 2]?.itemsAttribute
  if (isNullOrUndefined(itemsAttribute)) {
    return itemsDomainType
  }
  const rootDomainType = getRootDomainType(
    domainTypes,
    domainTypeChain[domainTypeChain.length - 2]?.domainType
  )
  if (rootDomainType === null) {
    return itemsDomainType
  }
  const parentSubtype = getSubtype(
    domainTypes,
    rootDomainType,
    parentInstance,
    subtypesCache
  ) ?? rootDomainType
  const overridenItemsAttribute = getDomainTypeAttribute(
    domainTypes,
    parentSubtype,
    itemsAttribute.Name
  )
  if (overridenItemsAttribute?.AttributeType !== 'domainType') {
    return itemsDomainType
  }
  return domainTypes[overridenItemsAttribute.AttributeDomainType] ?? itemsDomainType
}

function getSingleItemSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  const singleInstance = timelineItem.items[0]
  if (singleInstance === undefined) {
    return itemsDomainType
  }
  const rootDomainType = getRootDomainType(
    domainTypes,
    itemsDomainType
  ) ?? itemsDomainType
  return getSubtype(
    domainTypes,
    rootDomainType,
    singleInstance,
    subtypesCache
  ) ?? rootDomainType
}

function getItemsSubtype(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  timelineItem: NonNullable<ReturnType<typeof getTimelineItem>>
): DomainType {
  if (timelineItem.groupItems.length > 0) {
    return getGroupSubtype(
      domainTypes,
      domainTypeChain,
      itemsDomainType,
      subtypesCache,
      timelineItem
    )
  }
  return getSingleItemSubtype(
    domainTypes,
    itemsDomainType,
    subtypesCache,
    timelineItem
  )
}

function getTimelineGroupsAndItems(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemsDomainType: DomainType,
  itemChains: DomainTypeInstance[][],
  editingItem: CalendarProps['editingItem'],
  user: User | null,
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  filterContext: FilterContext,
  subtypesCache: Partial<Record<string, DomainType[]>>
): GroupsAndItems {
  const timelineGroups: CustomTimelineGroup[] = []
  const partialTimelineItems: NonNullable<ReturnType<typeof getTimelineItem>>[] = []
  for (const itemChain of itemChains) {
    const newGroup = getTimelineGroup(
      domainTypes,
      domainTypeChain,
      itemChain
    )
    const existingGroup = timelineGroups.find(group => group.id === newGroup.id)
    if (existingGroup === undefined) {
      timelineGroups.push(newGroup)
    }
    const newItem = getTimelineItem(
      domainTypes,
      domainTypeChain,
      itemChain,
      newGroup.id,
      editingItem,
      subtypesCache
    )
    if (newItem === null) {
      continue
    }
    const existingItem = partialTimelineItems.find(item => item.id === newItem.id)
    if (existingItem === undefined) {
      partialTimelineItems.push(newItem)
    } else {
      existingItem.items.push(...newItem.items)
    }
  }
  const parentDomainTypesCache: Partial<Record<string, DomainType[]>> = {}
  const actionsCache: Partial<Record<string, [DomainType, DomainTypeAction][]>> = {}
  const timelineItems = partialTimelineItems
    .map(timelineItem => getFullTimelineItem(
      domainTypeChain,
      domainTypeContext,
      domainTypes,
      itemsDomainType,
      user,
      filterContext,
      subtypesCache,
      parentDomainTypesCache,
      actionsCache,
      timelineItem
    ))
  if (timelineGroups.length === 0) {
    return EMPTY_GROUPS_AND_ITEMS
  }
  timelineGroups.sort((group1, group2) => {
    const group1Value = getGroupSortableValue(domainTypes, group1)
    const group2Value = getGroupSortableValue(domainTypes, group2)
    if (group1Value === null) {
      return -1
    }
    if (group2Value === null) {
      return 1
    }
    if (group1Value < group2Value) {
      return -1
    }
    if (group1Value > group2Value) {
      return 1
    }
    return 0
  })
  return [timelineGroups, timelineItems]
}

function getFullTimelineItem(
  domainTypeChain: CalendarTimelineSettings[],
  domainTypeContext: ContextType<typeof DomainTypeContext>,
  domainTypes: Partial<Record<string, DomainType>>,
  itemsDomainType: DomainType,
  user: User | null,
  filterContext: FilterContext,
  subtypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>,
  actionsCache: Partial<Record<string, [DomainType, DomainTypeAction][]>>,
  timelineItem: Omit<CustomTimelineItem, 'itemsSubtype' | 'context' | 'canEdit' | 'moveResizeActionDetails' | 'changeGroupActionDetails'>
): CustomTimelineItem {
  const itemDomainTypeContext = getItemDomainTypeContext(
    domainTypeChain,
    timelineItem.groupItems,
    domainTypeContext
  )
  const itemsSubtype = getItemsSubtype(
    domainTypes,
    domainTypeChain,
    itemsDomainType,
    subtypesCache,
    timelineItem
  )
  const itemActions = actionsCache[itemsSubtype.Id]
    ?? (actionsCache[itemsSubtype.Id] = getDomainTypeActions(domainTypes, itemsSubtype))
  const itemTimelineSettings = domainTypeChain[domainTypeChain.length - 1]
  const moveResizeAction = itemActions
    .find(([actionDomainType, action]) => isMoveResizeAction(action, itemTimelineSettings))
  const changeGroupAction = itemActions
    .find(([actionDomainType, action]) => isChangeGroupAction(action, itemTimelineSettings))
  const moveResizeActionDetails = getItemActionDetails(
    user,
    moveResizeAction,
    domainTypeChain,
    timelineItem.groupItems,
    timelineItem.items,
    itemDomainTypeContext,
    filterContext,
    subtypesCache,
    parentDomainTypesCache
  )
  const changeGroupActionDetails = getItemActionDetails(
    user,
    changeGroupAction,
    domainTypeChain,
    timelineItem.groupItems,
    timelineItem.items,
    itemDomainTypeContext,
    filterContext,
    subtypesCache,
    parentDomainTypesCache
  )
  const editButtonFilterContext = getItemFilterContext(
    domainTypes,
    itemDomainTypeContext ?? domainTypeContext,
    timelineItem.items,
    undefined,
    itemsSubtype,
    user
  )
  const canEditStartEndDate = canEditInstancesAttributes(
    domainTypes,
    itemsSubtype,
    editButtonFilterContext,
    timelineItem.items,
    user,
    [itemTimelineSettings?.startDateAttribute?.Name, itemTimelineSettings?.endDateAttribute?.Name]
      .filter(t.string.is)
  )
  const canEditGroup = canEditInstancesAttributes(
    domainTypes,
    itemsSubtype,
    editButtonFilterContext,
    timelineItem.items,
    user,
    [itemTimelineSettings?.startDateAttribute?.Name, itemTimelineSettings?.endDateAttribute?.Name, itemTimelineSettings?.groupByAttribute?.Name]
      .filter(t.string.is)
  )
  const canMove = canPerformAction(moveResizeActionDetails, canEditStartEndDate)
  return Object.assign(
    timelineItem,
    {
      itemsSubtype,
      context: itemDomainTypeContext ?? undefined,
      moveResizeActionDetails: moveResizeActionDetails?.visible === true
        ? moveResizeActionDetails
        : undefined,
      changeGroupActionDetails: changeGroupActionDetails?.visible === true
        ? changeGroupActionDetails
        : undefined,
      canMove,
      canEdit: canEditStartEndDate,
      canResize: canMove && !isNullOrUndefined(itemTimelineSettings?.endDateAttribute)
        ? 'both'
        : false,
      canChangeGroup: canPerformAction(changeGroupActionDetails, canEditGroup)
    }
  )
}

function getMinMaxTimes(date: Date, view: CalendarProps['view']): [number, number] {
  const dateTime = DateTime.fromJSDate(date)
  switch (view) {
    case 'day':
      return [dateTime.startOf('day').toMillis(), dateTime.endOf('day').toMillis()]
    case 'week':
      return [dateTime.startOf('week').toMillis(), dateTime.endOf('week').toMillis()]
    case 'month':
    default:
      return [dateTime.startOf('month').toMillis(), dateTime.endOf('month').toMillis()]
  }
}

const components: ComponentProps<typeof DomainTypeButtons>['components'] = {
  Container: MenuList,
  Button: props => (
    <MenuItem
      disabled={props.disabled}
      onClick={props.onClick}>
      <ListItemIcon>
        <Icon>{props.icon}</Icon>
      </ListItemIcon>
      <ListItemText>{props.text}</ListItemText>
    </MenuItem>
  ),
  Empty: () => (
    <MenuItem
      disabled>
      <ListItemText>No Actions</ListItemText>
    </MenuItem>
  )
}

const DAY_MILLIS = Duration.fromDurationLike({ days: 1 }).toMillis()

export default function TimelineView({
  isLoading,
  domainType,
  items,
  view,
  date,
  editingItem,
  actionDetails,
  actionDialogOpen,
  snackPack,
  setPanelOpen,
  onChangeItemDates,
  onCloseActionDialog,
  onPerformAction,
  onItemClick
}: Props): JSX.Element | null {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [items])
  const domainTypes = useSelector(getAllDomainTypes)
  const overriderContext = useContext(DomainTypeOverriderContext)
  const overriders = useMemo(() => overriderContext.map(details => details.overrider), [overriderContext])
  const timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineItems')
  let itemsDomainType = domainType
  if (isDomainTypeListAttribute(timelineItemsAttribute)) {
    itemsDomainType = domainTypes[timelineItemsAttribute.AttributeDomainType] ?? domainType
  }
  const user = useSelector(getUser)
  const [domainTypeChain, itemChains] = useMemo(() => {
    return getTimelineItemChains(domainTypes, domainType, overriders, items)
  }, [domainType, domainTypes, items, overriders])
  const [minTime, maxTime] = getMinMaxTimes(date, view)
  const [[visibleTimeStart, visibleTimeEnd], setVisibleTimes] = useState([minTime, maxTime])
  useEffect(() => {
    setVisibleTimes([minTime, maxTime])
  }, [minTime, maxTime])
  const domainTypeContext = useContext(DomainTypeContext)
  const filterContext = useFilterContext()
  const subtypesCache = useMemo<Partial<Record<string, DomainType[]>>>(() => ({}), [])
  const [_timelineGroups, _timelineItems] = useMemo(() => getTimelineGroupsAndItems(
    domainTypes,
    domainTypeChain,
    itemsDomainType,
    itemChains,
    editingItem,
    user,
    domainTypeContext,
    filterContext,
    subtypesCache
  ), [domainTypes, domainTypeChain, itemsDomainType, itemChains, editingItem, user, domainTypeContext, filterContext, subtypesCache])
  const timelineItems = _timelineItems
    .filter(item => item.start_time <= maxTime && item.end_time >= minTime)
  const timelineGroups = _timelineGroups
    .map(group => ({ ...group }))
  const selected = useMemo(() => {
    return timelineItems.map(item => item.id) as unknown[] as number[]
  }, [timelineItems])
  const actionButton = useMemo(() => {
    if (actionDetails === undefined
      || editingItem === null) {
      return null
    }
    const item = timelineItems
      .find(item => item.id === editingItem.itemId)
    if (item === undefined) {
      return null
    }
    return getActionButton(
      domainTypes,
      actionDetails,
      getActionParameterValues(
        domainTypeChain,
        actionDetails.action,
        editingItem.startDate,
        editingItem.endDate,
        editingItem.groupByValue
      )
    )
  }, [actionDetails, domainTypeChain, domainTypes, editingItem, timelineItems])
  const [contextMenuItemId, setContextMenuItemId] = useState<string | null>(null)
  const contextMenuItem = useMemo(() => {
    return timelineItems.find(item => item.id === contextMenuItemId)
  }, [contextMenuItemId, timelineItems])
  const contextMenuDomainType = useMemo(() => {
    if (contextMenuItem === undefined) {
      return undefined
    }
    return contextMenuItem.itemsSubtype
  }, [contextMenuItem])
  const navigate = useNavigate()
  const isApiDomainType = getDomainTypeSetting(domainTypes, contextMenuDomainType, 'Api') ?? false
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number
    mouseY: number
  } | null>(null)
  const handleContextMenu = useCallback((event: React.MouseEvent, id: string) => {
    event.preventDefault()
    setContextMenuItemId(id)
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4
        }
        : null
    )
  }, [contextMenu])
  const handleClose = () => {
    setContextMenu(null)
  }
  const contextMenuInstance = useMemo(() => {
    if (contextMenuItem === undefined) {
      return undefined
    }
    return contextMenuItem.groupItems.length > 0
      ? contextMenuItem.groupItems[0]
      : contextMenuItem.items[0]
  }, [contextMenuItem])
  const additionalButtons = useMemo(() => {
    return (isApiDomainType
      && contextMenuDomainType !== undefined
      && contextMenuInstance !== undefined)
      ? [
        {
          text: 'Open In New Tab',
          icon: 'open_in_new',
          onClick: () => navigate.toDetailsPage(contextMenuDomainType, contextMenuInstance, true)
        }
      ]
      : undefined
  }, [isApiDomainType, contextMenuDomainType, contextMenuInstance, navigate])
  const anchorPosition = useMemo(() => {
    return contextMenu !== null
      ? {
        top: contextMenu.mouseY,
        left: contextMenu.mouseX
      }
      : undefined
  }, [contextMenu])
  const contextMenuItemDomainTypeContext = useMemo(() => ({
    ...domainTypeContext,
    ...contextMenuItem?.context
  }), [contextMenuItem?.context, domainTypeContext])
  const {
    open: snackbarOpen,
    message,
    addMessage,
    handleClose: handleSnackbarClose,
    handleExited
  } = snackPack
  const minTimeRange = Duration.fromObject({ hours: 12 }).toMillis()
  const maxTimeRange = maxTime - minTime
  const dragSnap = useMemo(() => {
    return domainTypeChain[domainTypeChain.length - 1]?.startDateAttribute?.AttributeType === 'date'
      ? DAY_MILLIS
      : undefined
  }, [domainTypeChain])
  const theme = useTheme()
  return (
    <Root style={{
      overflow: 'visible',
      height: 'auto',
      position: 'relative'
    }}>
      <MissingStartDateAlert
        domainTypeChain={domainTypeChain}
        setPanelOpen={setPanelOpen}
        view='timeline' />
      <Backdrop
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          color: theme => theme.palette.primary.main,
          background: theme => theme.palette.action.hover,
          zIndex: theme => theme.zIndex.drawer + 1
        }}
        open={isLoading || editingItem !== null}>
        <CircularProgress color='inherit' />
      </Backdrop>
      {actionButton !== null && (
        <DomainTypeContext.Provider
          value={{
            ...domainTypeContext,
            ...timelineItems.find(item => item.id === editingItem?.itemId)?.context
          }}>
          <ActionDialog
            open={actionDialogOpen}
            actionButton={actionButton}
            onClose={onCloseActionDialog}
            onPerform={onPerformAction} />
        </DomainTypeContext.Provider>
      )}
      <Timeline
        groups={timelineGroups}
        items={timelineItems}
        stackItems
        minResizeWidth={0}
        rightSidebarWidth={150}
        minZoom={minTimeRange}
        maxZoom={maxTimeRange}
        useResizeHandle
        onTimeChange={(newVisibleTimeStart, newVisibleTimeEnd, updateScrollCanvas) => {
          if (newVisibleTimeStart < minTime && newVisibleTimeEnd > maxTime) {
            setVisibleTimes([minTime, maxTime])
          } else if (newVisibleTimeStart < minTime) {
            setVisibleTimes([minTime, Math.min(minTime + (newVisibleTimeEnd - newVisibleTimeStart), maxTime)])
          } else if (newVisibleTimeEnd > maxTime) {
            setVisibleTimes([Math.max(maxTime - (newVisibleTimeEnd - newVisibleTimeStart), minTime), maxTime])
          } else {
            setVisibleTimes([newVisibleTimeStart, newVisibleTimeEnd])
          }
        }}
        selected={selected}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        lineHeight={31}
        itemHeightRatio={30 / 31}
        dragSnap={dragSnap}
        onItemResize={(itemId, time, edge) => {
          const item = timelineItems.find(timelineItem => timelineItem.id === itemId)
          if (item === undefined) {
            return
          }
          if (edge === 'left') {
            onChangeItemDates?.(
              String(itemId),
              domainTypeChain,
              item.groupItems,
              item.items,
              time,
              item.end_time,
              undefined,
              item.moveResizeActionDetails
            )
          } else {
            onChangeItemDates?.(
              String(itemId),
              domainTypeChain,
              item.groupItems,
              item.items,
              item.start_time,
              time,
              undefined,
              item.moveResizeActionDetails
            )
          }
        }}
        onItemMove={(itemId, dragTime, groupIndex) => {
          const item = timelineItems.find(timelineItem => timelineItem.id === itemId)
          if (item === undefined) {
            return
          }
          const group = timelineGroups[groupIndex]
          if (group === undefined) {
            return
          }
          if (item.group !== group.id) {
            const itemFilterContext = getItemFilterContext(
              domainTypes,
              item.context ?? domainTypeContext,
              item.items,
              item.changeGroupActionDetails,
              item.itemsSubtype,
              user
            )
            const invalidReason = getAttributeValueInvalidReason(
              group.attributeValue,
              itemFilterContext,
              item.changeGroupActionDetails?.action
            )
            if (O.isSome(invalidReason)) {
              addMessage(invalidReason.value)
              return
            }
          }
          onChangeItemDates?.(
            String(itemId),
            domainTypeChain,
            item.groupItems,
            item.items,
            dragTime,
            dragTime + (item.end_time - item.start_time),
            group,
            item.group !== group.id
              ? item.changeGroupActionDetails
              : item.moveResizeActionDetails
          )
        }}
        onItemDoubleClick={itemId => {
          const id = String(itemId).split('_')[0]
          if (id === undefined) {
            return
          }
          onItemClick(id)
        }}
        itemRenderer={({
          item,
          itemContext,
          getItemProps,
          getResizeProps
        }) => {
          const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
          return (
            <DomainTypeTooltip
              domainType={domainType}
              instance={item.groupItems[0] ?? item.items[0] ?? {}}>
              <div
                {...getItemProps({
                  ...item.itemProps,
                  style: {
                    background: item.colour
                  }
                })}
                title={undefined}
                onMouseDown={event => {
                  event.stopPropagation()
                  if (event.button !== 0) {
                    return
                  }
                  if (item.canMove === true) {
                    return
                  }
                  const mouseDownMessage = getMouseDownMessage(item, user)
                  if (O.isSome(mouseDownMessage)) {
                    addMessage(mouseDownMessage.value)
                  }
                }}
                onContextMenu={event => {
                  handleContextMenu(event, String(item.id))
                }}>
                {item.canResize === 'both'
                  ? (
                    <div {...leftResizeProps}>
                      <div className='rct-item-handler-icon' />
                    </div>
                  )
                  : ''}
                <div
                  className='rct-item-content'
                  style={{ maxHeight: `${itemContext.dimensions.height}` }}>
                  {item.items.length > 1 && (
                    <Box
                      sx={{
                        bgcolor: theme => theme.palette.augmentColor({
                          color: {
                            main: item.colour ?? theme.palette.primary.main
                          }
                        }).light,
                        ml: '-6px',
                        p: 0.5,
                        mr: 0.5
                      }}>
                      {item.items.length}
                    </Box>
                  )}
                  {itemContext.title}
                </div>
                {item.canResize === 'both'
                  ? (
                    <div {...rightResizeProps}>
                      <div className='rct-item-handler-icon' />
                    </div>
                  )
                  : ''}
              </div>
            </DomainTypeTooltip>
          )
        }}
        groupRenderer={({
          group,
          isRightSidebar
        }) => {
          if (isRightSidebar === true) {
            const total = timelineItems
              .filter(item => item.group === group.id)
              .filter(item => item.start_time < visibleTimeEnd && item.end_time > visibleTimeStart)
              .map(item => item.items.length)
              .reduce((a, b) => a + b, 0)
            return (
              <span>{total} {total === 1 ? itemsDomainType.Title : itemsDomainType.PluralTitle}</span>
            )
          }
          if (group.id === NULL_GROUP_ID
            && !isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)) {
            return (
              <Typography
                variant='body1'
                fontSize='inherit'
                color='GrayText'>
                None
              </Typography>
            )
          }
          return group.attributeValue !== undefined
            ? (
              <AttributeCell attributeChainValue={group.attributeValue} />
            )
            : group.title
        }}>
        <TimelineHeaders
          style={{
            top: 0,
            position: 'sticky',
            zIndex: 200,
            background: 'white'
          }}>
          <SidebarHeader>
            {({ getRootProps }) => {
              const title = isNullOrUndefined(domainTypeChain[domainTypeChain.length - 1]?.groupByAttribute)
                ? ''
                : domainTypeChain
                  .map(timelineSettings => {
                    return timelineSettings.itemsAttribute?.Title ?? timelineSettings.groupByAttribute?.Title
                  })
                  .filter(Boolean)
                  .join(PATH_SEPARATOR)
              return (
                <div
                  {...getRootProps()}>{title}</div>
              )
            }}
          </SidebarHeader>
          <SidebarHeader variant='right'>
            {({ getRootProps }) => (
              <div {...getRootProps()}>Total</div>
            )}
          </SidebarHeader>
          <DateHeader
            unit='primaryHeader'
            labelFormat={labelFormat} />
          <DateHeader
            labelFormat={labelFormat} />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker date={new Date()}>
            {({ styles }) => (
              <div
                style={{
                  ...styles,
                  backgroundColor: theme.palette.primary.main
                }} />
            )}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
      <ClickAwayListener
        onClickAway={handleClose}>
        <Popover
          open={anchorPosition !== undefined}
          onClose={handleClose}
          anchorReference='anchorPosition'
          anchorPosition={anchorPosition}>
          <DomainTypeContext.Provider
            value={contextMenuItemDomainTypeContext}>
            <DomainTypeButtons
              domainType={contextMenuDomainType ?? domainType}
              instances={contextMenuItem?.items ?? []}
              on='TableRow'
              components={components}
              additionalButtons={additionalButtons}
              leafNodeType={domainTypeChain.length > 1
                ? 'nested'
                : 'active'}
              onComplete={handleClose}
              parameterValues={
                contextMenuItem === undefined
                  ? []
                  : getActionParameterValues(
                    domainTypeChain,
                    contextMenuItem.changeGroupActionDetails?.action,
                    contextMenuItem.start_time,
                    contextMenuItem.end_time
                  )} />
          </DomainTypeContext.Provider>
        </Popover>
      </ClickAwayListener>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        TransitionProps={{
          onExited: handleExited
        }}
        message={message} />
    </Root>
  )
}