import { ContentCopy } from '@mui/icons-material'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { MouseEvent } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { NonListGuidAttributeValue } from 'types'
import { NarrowedProps } from './AttributeCell'

const messages = defineMessages({
  'tooltip.copy': {
    id: 'guidCell.tooltip.text',
    defaultMessage: 'Copy'
  },
  'toast.copied': {
    id: 'guidCell.toast.text',
    defaultMessage: 'Copied'
  }
})

export default function GuidCell({
  attributeValue
}: NarrowedProps<NonListGuidAttributeValue>): JSX.Element | null {
  const { formatMessage } = useIntl()
  if (attributeValue.value === null) {
    return null
  }
  function copyToClipboard(e: MouseEvent) {
    if (attributeValue.value !== null) {
      navigator.clipboard.writeText(attributeValue.value)
    }
  }
  return (
    <TooltipIconButton
      tooltipText={formatMessage(messages['tooltip.copy'])}
      toastText={formatMessage(messages['toast.copied'])}
      icon={<ContentCopy />}
      onClick={copyToClipboard} />
  )
}
