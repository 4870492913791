import { CalendarTodayOutlined, GridViewOutlined, SaveOutlined, TableViewOutlined, ViewTimelineOutlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useState } from 'react'
import { DomainType } from 'types'
import { FindPageView } from './types'

interface Props {
  readonly domainType: DomainType | null
  readonly view: FindPageView
  onViewChange?(view: FindPageView): void
  onClose(): void
}

export default function FindViewPanel({
  domainType,
  view,
  onViewChange,
  onClose
}: Props): JSX.Element {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  return (
    <>
      <MenuList>
        {view !== 'table' && (
          <MenuItem
            onClick={() => {
              onViewChange?.('table')
              onClose()
            }}>
            <ListItemIcon>
              <TableViewOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Table View</ListItemText>
          </MenuItem>
        )}
        {view !== 'cards' && (
          <MenuItem
            onClick={() => {
              onViewChange?.('cards')
              onClose()
            }}>
            <ListItemIcon>
              <GridViewOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Cards View</ListItemText>
          </MenuItem>
        )}
        {view !== 'calendar' && (
          <MenuItem
            onClick={() => {
              onViewChange?.('calendar')
              onClose()
            }}>
            <ListItemIcon>
              <CalendarTodayOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Calendar View</ListItemText>
          </MenuItem>
        )}
        {view !== 'timeline' && (
          <MenuItem
            onClick={() => {
              onViewChange?.('timeline')
              onClose()
            }}>
            <ListItemIcon>
              <ViewTimelineOutlined />
            </ListItemIcon>
            <ListItemText>Switch To Timeline View</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => setSaveDialogOpen(true)}>
          <ListItemIcon>
            <SaveOutlined />
          </ListItemIcon>
          <ListItemText>Save Find View</ListItemText>
        </MenuItem>
      </MenuList>
      {domainType !== null && (
        <SaveDomainTypeSettingButton
          domainType={domainType}
          setting='FindView'
          value={view}
          dialogOpen={saveDialogOpen}
          onChangeDialogOpen={value => {
            setSaveDialogOpen(value)
            if (!value) {
              onClose()
            }
          }} />
      )}
    </>
  )
}