import { useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType } from 'types'
import { getContrastingColour, getDomainTypeAttribute, getDomainTypeSetting } from 'utils/helpers'
import { useDomainType } from './useDomainType'

export function useDomainTypeColour(domainType: DomainType | null): string {
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeDomainType = useDomainType('DomainType', 'DomainType')
  const categoryEnumeratedType = useMemo(() => {
    if (domainTypeDomainType === null) {
      return null
    }
    const attribute = getDomainTypeAttribute(domainTypes, domainTypeDomainType, 'Category')
    if (attribute?.AttributeType !== 'enum') {
      return null
    }
    return attribute.EnumeratedType
  }, [domainTypeDomainType, domainTypes])
  const theme = useTheme()
  const hasApi = getDomainTypeSetting(domainTypes, domainType, 'Api') ?? false
  const category = getDomainTypeSetting(domainTypes, domainType, 'Category')
  return useMemo(() => {
    const colour = getDomainTypeSetting(domainTypes, domainType, 'Colour')
      ?? categoryEnumeratedType?.Values
        .find(enumeratedValue => enumeratedValue.Value === category)
        ?.Colour
      ?? (hasApi
        ? theme.palette.muiPrimary.main
        : theme.palette.primary.main)
    return getContrastingColour(
      colour,
      theme.palette.mode,
      theme.palette.contrastThreshold
    )
  }, [category, categoryEnumeratedType?.Values, domainType, domainTypes, hasApi, theme.palette.contrastThreshold, theme.palette.mode, theme.palette.muiPrimary.main, theme.palette.primary.main])
}