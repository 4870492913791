import { Edit, Visibility } from '@mui/icons-material'
import { Dialog, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import { ResultsInput } from 'components/dataform'
import CornerIcon from 'components/utils/CornerIcon'
import { useCallback, useRef, useState } from 'react'
import { DataformResultsAttributeValue } from 'types'
import { Results } from 'types/dataform'
import { getDataformResultsIcon, isListAttributeValue, isNullOrUndefined, toErrorText } from 'utils/helpers'
import { useDataformResults } from 'utils/hooks'
import { NarrowedProps } from '../AttributeInput'
import NotSupportedInput from '../NotSupportedInput'

export default function DataformResultsInput({
  attributeValue,
  readOnly,
  pathError,
  onChange
}: NarrowedProps<DataformResultsAttributeValue>): JSX.Element | null {
  const [open, setOpen] = useState(false)
  const [dataform, results, errorMessage] = useDataformResults(attributeValue)
  const handleChange = useCallback((value: Results | null) => {
    onChange?.({
      ...attributeValue,
      value
    })
  }, [attributeValue, onChange])
  const resultsRef = useRef<Results | null>(results)
  const applyEdits = useCallback(() => {
    setOpen(false)
    handleChange(resultsRef.current)
  }, [handleChange])
  const theme = useTheme()
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  const [cornerIcon, cornerIconColor] = getDataformResultsIcon(results, theme)
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={applyEdits}>
        <ResultsInput
          dataform={dataform}
          initialResults={results}
          attribute={attributeValue.attribute}
          errorMessage={errorMessage}
          readOnly={readOnly}
          maxHeight='50vh'
          onChange={results => {
            resultsRef.current = results
          }}
          onComplete={applyEdits}
          onClose={() => setOpen(false)} />
      </Dialog>
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        variant='standard'
        fullWidth
        size='small'
        value=''
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined || !isNullOrUndefined(errorMessage)}
        helperText={toErrorText(pathError) ?? errorMessage}
        InputProps={{
          readOnly: true,
          startAdornment: cornerIcon !== null
            ? (
              <InputAdornment position='start'>
                <CornerIcon
                  icon='dynamic_form'
                  cornerIcon={cornerIcon}
                  cornerIconColor={cornerIconColor} />
              </InputAdornment>
            )
            : null,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                aria-label='open dataform dialog'
                edge='end'
                disabled={isNullOrUndefined(dataform)}
                onClick={() => {
                  setOpen(true)
                }}>
                {(readOnly || attributeValue.value?.Complete === true)
                  ? <Visibility />
                  : <Edit />}
              </IconButton>
            </InputAdornment>
          )
        }} />
    </>
  )
}
