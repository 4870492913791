import * as t from 'io-ts'
import { useMemo } from 'react'
import { DomainType } from 'types'
import { useQueries, useSearchParams } from 'utils/hooks'

const codecs = {
  queryId: t.string
}

export default function useFindQueries(
  domainType: DomainType,
  urlPrefix = ''
): ReturnType<typeof useQueries> {
  const defaults = useMemo(() => ({
    queryId: 'all'
  }), [])
  const [
    {
      queryId
    }
  ] = useSearchParams(codecs, defaults, urlPrefix)
  return useQueries(domainType, queryId)
}
