import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import OperationsDialog from 'components/domainType/OperationsDialog'
import PushDomainTypeOverriderContext from 'components/domainType/PushDomainTypeOverriderContext'
import { ContextType, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, Query } from 'types'
import { DomainTypeContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getDomainTypeSetting } from 'utils/helpers'
import FindView from './FindView'
import useFind from './useFind'
import useFindQueries from './useFindQueries'

interface Props {
  domainType: DomainType
}

const emptyDomainTypeContext: ContextType<typeof DomainTypeContext> = {
  instances: [],
  attributes: [],
  batchInstances: []
}

interface FindPageContentProps {
  domainType: DomainType
  domainTypeQueries: Query[]
  overriderQueries: [Query, OverriderDetails][]
  currentQuery: Query
}

function FindPageContent({
  domainType,
  domainTypeQueries,
  overriderQueries,
  currentQuery
}: FindPageContentProps): JSX.Element {
  const {
    view,
    isLoading,
    items,
    total,
    calendarItems,
    calendarProps,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    isExporting,
    checkedRowIds,
    snackPack,
    onSearch,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange,
    onPageSizeChange,
    onSortsChange,
    onRowClick,
    fetchTotal,
    onApplyQuery,
    onClickExport,
    onCheckedRowIdsChange,
    onViewChange
  } = useFind(domainType)
  const domainTypes = useSelector(getAllDomainTypes)
  const checkedInstances = useMemo(() => {
    if (checkedRowIds.length === 0) {
      return []
    }
    const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
    return items.filter(item => {
      const itemId = String(item[identifier])
      return checkedRowIds.includes(itemId)
    })
  }, [checkedRowIds, domainType, domainTypes, items])
  return (
    <DomainTypeContext.Provider
      value={{
        ...emptyDomainTypeContext,
        onInvalidate: onSearch
      }}>
      <DomainTypeTheme domainType={domainType}>
        <OperationsDialog
          domainType={domainType}
          instances={checkedInstances}
          on='TableToolbar' />
        <FindView
          domainType={domainType}
          isLoading={isLoading}
          items={items}
          calendarItems={calendarItems}
          calendarProps={calendarProps}
          total={total}
          page={page}
          pageSize={pageSize}
          searchText={searchText}
          sorts={sorts}
          filterLinkOperator={filterLinkOperator}
          filters={filters}
          overriderQueries={overriderQueries}
          domainTypeQueries={domainTypeQueries}
          currentQuery={currentQuery}
          isExporting={isExporting}
          checkedRowIds={checkedRowIds}
          view={view}
          snackPack={snackPack}
          autoFocusSearchInput
          onSearch={onSearchTextChange}
          onFilterLinkOperatorChange={onFilterLinkOperatorChange}
          onFiltersChange={onFiltersChange}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortsChange={onSortsChange}
          onRowClick={onRowClick}
          fetchTotal={fetchTotal}
          onApplyQuery={onApplyQuery}
          onClickExport={onClickExport}
          onCheckedRowIdsChange={onCheckedRowIdsChange}
          onViewChange={onViewChange} />
      </DomainTypeTheme>
    </DomainTypeContext.Provider>
  )
}

export default function FindPage({
  domainType
}: Props): JSX.Element {
  const {
    currentQuery,
    domainTypeQueriesWithAll,
    overriderQueries,
    overriderDetails
  } = useFindQueries(domainType)
  return (
    <PushDomainTypeOverriderContext overriderDetails={overriderDetails}>
      <FindPageContent
        domainType={domainType}
        currentQuery={currentQuery}
        domainTypeQueries={domainTypeQueriesWithAll}
        overriderQueries={overriderQueries} />
    </PushDomainTypeOverriderContext>
  )
}
