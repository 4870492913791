import { Box } from '@mui/material'
import { Attribute, ListAttributeValue } from 'types'
import AttributeCell from '.'
import LightTooltip from '../../utils/LightTooltip'

interface ListCellProps<A extends Attribute> {
  readonly attributeValue: ListAttributeValue<A>
  readonly columnWidth?: number
}

export default function ListCell<A extends Attribute>({
  attributeValue,
  columnWidth
}: ListCellProps<A>): JSX.Element | null {
  if (attributeValue.value === null || attributeValue.value.length === 0) {
    return null
  }
  return (
    <LightTooltip
      placement='bottom-start'
      enterDelay={250}
      enterNextDelay={250}
      minWidth={300}
      title={(
        <Box
          p={1}>
          {attributeValue.value.map((value, i) => (
            <Box
              key={i}
              paddingTop={i === 0 ? 0 : 1}>
              <AttributeCell
                attributeChainValue={{
                  attribute: {
                    ...attributeValue.attribute,
                    List: false
                  },
                  value
                }}
                columnWidth={columnWidth} />
            </Box>
          ))}
        </Box>
      )}>
      <Box
        component='span'
        sx={{
          color: 'text.secondary',
          cursor: 'pointer'
        }}>
        {attributeValue.value.length} values
      </Box>
    </LightTooltip>
  )
}