import { Filter } from 'types'

const ADD_FILTER = 'ADD_FILTER'
type AddFilterAction = {
  readonly type: typeof ADD_FILTER
  readonly payload: {
    readonly filter: Filter
  }
}
export const addFilter = (filter: Filter): AddFilterAction => ({
  type: ADD_FILTER,
  payload: {
    filter
  }
})

const EDIT_FILTER = 'EDIT_FILTER'
interface EditFilterAction<Key extends keyof Filter> {
  readonly type: typeof EDIT_FILTER
  readonly payload: {
    readonly index: number
    readonly key: Key
    readonly value: Filter[Key]
  }
}
export const editFilter = <Key extends keyof Filter>(index: number, key: Key, value: Filter[Key]): EditFilterAction<Key> => ({
  type: EDIT_FILTER,
  payload: {
    index,
    key,
    value
  }
})

const DELETE_FILTER = 'DELETE_FILTER'
type DeleteFilterAction = {
  readonly type: typeof DELETE_FILTER
  readonly payload: {
    readonly index: number
  }
}
export const deleteFilter = (index: number): DeleteFilterAction => ({
  type: DELETE_FILTER,
  payload: {
    index
  }
})

export type FiltersAction =
  | AddFilterAction
  | EditFilterAction<keyof Filter>
  | DeleteFilterAction

export default function filtersReducer(state: Filter[] = [], action: FiltersAction): Filter[] {
  switch (action.type) {
    case ADD_FILTER:
      return [
        ...state,
        action.payload.filter
      ]
    case EDIT_FILTER: {
      const filter = state[action.payload.index]
      if (filter === undefined) {
        return state
      }
      return [
        ...state.slice(0, action.payload.index),
        {
          ...filter,
          [action.payload.key]: action.payload.value
        },
        ...state.slice(action.payload.index + 1)
      ]
    }
    case DELETE_FILTER:
      return [
        ...state.slice(0, action.payload.index),
        ...state.slice(action.payload.index + 1)
      ]
    default:
      return state
  }
}