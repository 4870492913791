import { Clear, Edit } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import EditDialog from 'components/domainType/EditDialog'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, NonListDomainTypeAttributeValue, PathError } from 'types'
import { getHeading, toErrorText } from 'utils/helpers'

interface NonListNonApiDomainTypeProps {
  readonly domainType: DomainType
  readonly attributeValue: NonListDomainTypeAttributeValue
  readonly disabled?: boolean
  readonly pathError?: PathError
  onChange?(attributeValue: NonListDomainTypeAttributeValue): void
}

export default function NonListNonApiDomainTypeInput({
  domainType,
  attributeValue,
  disabled,
  pathError,
  onChange
}: NonListNonApiDomainTypeProps): JSX.Element | null {
  const [open, setOpen] = useState(false)
  const domainTypes = useSelector(getAllDomainTypes)
  const heading = attributeValue.value === null
    ? ''
    : getHeading(domainTypes, domainType, attributeValue.value)
  return (
    <>
      <EditDialog
        open={open}
        instance={attributeValue.value}
        pathError={pathError}
        domainType={domainType}
        bypassApi
        onClose={() => setOpen(false)}
        onEditSuccess={instance => {
          setOpen(false)
          if (onChange === undefined) {
            return
          }
          onChange({
            attribute: attributeValue.attribute,
            value: instance
          })
        }} />
      <TextField
        label={attributeValue.attribute.Title}
        id={attributeValue.attribute.Name}
        disabled={disabled}
        variant='standard'
        fullWidth
        size='small'
        value={heading}
        required={attributeValue.attribute.Required ?? false}
        error={pathError !== undefined}
        helperText={toErrorText(pathError)}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              {attributeValue.value !== null && (
                <IconButton
                  aria-label='open create dialog'
                  edge='end'
                  disabled={disabled}
                  onClick={() => {
                    if (onChange === undefined) {
                      return
                    }
                    onChange({
                      attribute: attributeValue.attribute,
                      value: null
                    })
                  }}>
                  <Clear />
                </IconButton>
              )}
              <IconButton
                aria-label='open create dialog'
                edge='end'
                disabled={disabled}
                onClick={() => {
                  setOpen(true)
                }}>
                <Edit />
              </IconButton>
            </InputAdornment>
          )
        }} />
    </>
  )
}