import { useCallback, useMemo, useRef } from 'react'
import { NotSignedInApi, SignedInApi } from './useApi'

interface UseCancellableApiSessionOutput {
  readonly cancelPreviousAndStartNew: () => SignedInApi | NotSignedInApi
  readonly cancel: () => void
}

export function useCancellableApiSession(api: SignedInApi | NotSignedInApi): UseCancellableApiSessionOutput {
  const abortControllerRef = useRef<AbortController>()
  const cancelPreviousAndStartNew = useCallback(() => {
    abortControllerRef.current?.abort()
    const abortController = new AbortController()
    abortControllerRef.current = abortController
    return api.withRequestContext({ signal: abortController.signal })
  }, [api])
  const cancel = useCallback(() => {
    abortControllerRef.current?.abort()
    abortControllerRef.current = undefined
  }, [])
  return useMemo(() => ({
    cancelPreviousAndStartNew,
    cancel
  }), [cancel, cancelPreviousAndStartNew])
}