import { Autocomplete, TextField } from '@mui/material'
import { useCallback } from 'react'
import { BoolAttributeValue } from 'types'
import { isListAttributeValue, isRequired, toErrorText } from 'utils/helpers'
import AttributeCell from '../AttributeCell'
import { NarrowedProps } from './AttributeInput'
import LabelledInput from './LabelledInput'
import NotSupportedInput from './NotSupportedInput'

export default function BoolInput({
  attributeValue,
  readOnly,
  disabled = false,
  pathError,
  onChange
}: NarrowedProps<BoolAttributeValue>): JSX.Element | null {
  const handleChange = useCallback((event: unknown, value: string | null) => {
    if (onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: {
        Yes: true,
        No: false
      }[String(value)] ?? null
    })
  }, [attributeValue.attribute, onChange])
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  if (readOnly) {
    return (
      <LabelledInput
        label={attributeValue.attribute.Title}
        required={attributeValue.attribute.Required ?? false}>
        <AttributeCell attributeChainValue={attributeValue} />
      </LabelledInput>
    )
  }
  const required = isRequired(attributeValue)
  return (
    <Autocomplete
      disabled={disabled}
      disableClearable={required}
      readOnly={readOnly}
      onChange={handleChange}
      value={{
        true: 'Yes',
        false: 'No'
      }[String(attributeValue.value)] ?? null}
      options={['Yes', 'No']}
      renderInput={params => (
        <TextField
          {...params}
          variant='standard'
          size='small'
          required={required}
          error={pathError !== undefined}
          helperText={toErrorText(pathError)}
          label={attributeValue.attribute.Title}
          fullWidth />
      )} />
  )
}
