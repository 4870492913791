import { JSXElementConstructor, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from 'state/reducers'
import { isInRole } from 'utils/helpers'

interface Props {
  readonly role: string | null
  readonly Unauthorised?: JSXElementConstructor<unknown>
}

export default function IsInRole({
  role,
  Unauthorised,
  children
}: PropsWithChildren<Props>): JSX.Element | null {
  const user = useSelector(getUser)
  return role === null || isInRole(user, role)
    ? (
      <>
        {children}
      </>
    )
    : Unauthorised
      ? <Unauthorised />
      : null
}