import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { constNull, constant, identity, pipe } from 'fp-ts/lib/function'
import { DateTime, DurationLike } from 'luxon'
import { Lens } from 'monocle-ts'
import { ContextType } from 'react'
import { ActionParameter, AttributeValue, DateAttribute, DateTimeAttribute, DomainType, DomainTypeAction, DomainTypeInstance, DomainTypeOverrider, NonListAttribute, User } from 'types'
import { DomainTypeContext } from 'utils/context'
import { FilterContext, applyAllFilters } from 'utils/filters'
import { getAttributeValue, getBatchInstances, getContextTree, getDomainTypeButtons, getDomainTypeSetting, getHeading, getOverridableDomainTypeSettingAttribute, getValue, isDomainTypeListAttribute, isInRole, isNullOrUndefined, isOfType, isValidStartEndDateAttribute } from 'utils/helpers'
import { getFilterContext, isDisabled } from 'utils/hooks'
import { ActionDetails, getActionDetails } from 'utils/hooks/useActions'
import { CalendarTimelineSettings, GroupByValue } from './types'

export function getItemActionDetails(
  user: User | null,
  itemAction: [DomainType, DomainTypeAction] | undefined,
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  items: DomainTypeInstance[],
  itemDomainTypeContext: ContextType<typeof DomainTypeContext> | null,
  filterContext: FilterContext,
  childDomainTypesCache: Partial<Record<string, DomainType[]>>,
  parentDomainTypesCache: Partial<Record<string, DomainType[]>>
): ActionDetails | undefined {
  if (itemAction === undefined) {
    return undefined
  }
  if (itemDomainTypeContext === null) {
    return undefined
  }
  const [actionDomainType, action] = itemAction
  const contextTree = getContextTree(
    itemDomainTypeContext,
    actionDomainType,
    items,
    groupItems.length > 0
      ? 'nested'
      : 'active'
  )
  const apiDomainType = itemDomainTypeContext.instances[0]?.[0] ?? domainTypeChain[0]?.domainType
  if (apiDomainType === undefined) {
    return undefined
  }
  const pageDomainType = domainTypeChain[domainTypeChain.length - 1]?.domainType
  if (pageDomainType === undefined) {
    return undefined
  }
  return getActionDetails(
    filterContext.domainTypes,
    apiDomainType,
    pageDomainType,
    childDomainTypesCache,
    parentDomainTypesCache,
    contextTree,
    filterContext,
    user,
    actionDomainType,
    action
  )
}

export function canPerformAction(
  actionDetails: ActionDetails | undefined,
  canEdit: ReturnType<typeof canEditInstancesAttributes>
): boolean {
  return actionDetails?.visible === true
    ? !actionDetails.disabled
    : canEdit === true
}

export function getItemDomainTypeContext(
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  domainTypeContext: ContextType<typeof DomainTypeContext>
): ContextType<typeof DomainTypeContext> | null {
  const itemDomainTypeContext: ContextType<typeof DomainTypeContext> = {
    instances: domainTypeContext.instances.slice(),
    attributes: domainTypeContext.attributes.slice(),
    batchInstances: domainTypeContext.batchInstances.slice()
  }
  for (let i = 0; i < groupItems.length; i++) {
    const groupItem = groupItems[i]
    const timelineSettings = domainTypeChain[i]
    if (groupItem === undefined
      || timelineSettings === undefined
      || timelineSettings.itemsAttribute === null) {
      return null
    }
    itemDomainTypeContext.instances.push([
      timelineSettings.domainType,
      groupItem
    ])
    itemDomainTypeContext.attributes.push(timelineSettings.itemsAttribute)
  }
  return itemDomainTypeContext
}

export function isAttributeParameter(name: string): (parameter: ActionParameter) => boolean {
  return parameter => parameter.Type === 'AttributeActionParameter'
    && parameter.Attribute === name
}

export function isMoveResizeAction(
  action: DomainTypeAction,
  timelineSettings: CalendarTimelineSettings | undefined
): boolean {
  if (timelineSettings === undefined) {
    return false
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = timelineSettings
  if (startDateAttribute === null) {
    return false
  }
  if (isNullOrUndefined(action.Parameters)) {
    return false
  }
  return action.Parameters.some(isAttributeParameter(startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(endDateAttribute?.Name ?? startDateAttribute.Name))
    && (groupByAttribute === null || !action.Parameters.some(isAttributeParameter(groupByAttribute.Name)))
}

export function getCalendarTimelineSettings(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[]
): CalendarTimelineSettings {
  const startDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'StartDate')
  const endDateAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'EndDate')
  const groupByAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineGroupBy')
  const itemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, domainType, overriders, 'TimelineItems')
  const identifier = getDomainTypeSetting(
    domainTypes,
    domainType,
    'Identifier'
  ) ?? 'Id'
  return {
    domainType,
    startDateAttribute,
    endDateAttribute,
    groupByAttribute,
    itemsAttribute,
    identifier
  }
}

const attributeNameLens = Lens.fromPath<AttributeValue>()(['attribute', 'Name'])

export function getActionParameterValues(
  domainTypeChain: CalendarTimelineSettings[],
  action: DomainTypeAction | undefined,
  startDate: number,
  endDate: number,
  groupByValue?: GroupByValue
): AttributeValue[] {
  if (action === undefined) {
    return []
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = domainTypeChain[domainTypeChain.length - 1] ?? {}
  if (isNullOrUndefined(startDateAttribute)
    || isNullOrUndefined(endDateAttribute)) {
    return []
  }
  const startDateParameter = action.Parameters
    ?.find(isAttributeParameter(startDateAttribute.Name))
  const endDateParameter = action.Parameters
    ?.find(isAttributeParameter(endDateAttribute.Name))
  if (startDateParameter === undefined
    || endDateParameter === undefined) {
    return []
  }
  const parameterValues = [
    attributeNameLens.set(startDateParameter.Name)(getAttributeValue(
      {
        [startDateAttribute.Name]: DateTime.fromMillis(startDate).toUTC().toISO()
      },
      startDateAttribute
    )),
    attributeNameLens.set(endDateParameter.Name)(getAttributeValue(
      {
        [endDateAttribute.Name]: DateTime.fromMillis(endDate).toUTC().toISO()
      },
      endDateAttribute
    ))
  ]
  if (!isNullOrUndefined(groupByAttribute)) {
    if (groupByValue?.attributeValue === undefined) {
      return parameterValues
    }
    const groupByParameter = action.Parameters
      ?.find(isAttributeParameter(groupByAttribute.Name))
    if (groupByParameter === undefined) {
      return parameterValues
    }
    parameterValues.push(attributeNameLens.set(groupByParameter.Name)(
      groupByValue.attributeValue
    ))
  }
  return parameterValues
}

export function isChangeGroupAction(
  action: DomainTypeAction,
  timelineSettings: CalendarTimelineSettings | undefined
): boolean {
  if (timelineSettings === undefined) {
    return false
  }
  const {
    startDateAttribute,
    endDateAttribute,
    groupByAttribute
  } = timelineSettings
  if (startDateAttribute === null
    || groupByAttribute === null) {
    return false
  }
  if (isNullOrUndefined(action.Parameters)) {
    return false
  }
  return action.Parameters.some(isAttributeParameter(startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(endDateAttribute?.Name ?? startDateAttribute.Name))
    && action.Parameters.some(isAttributeParameter(groupByAttribute.Name))
}

export function getChangeItemDatesPatchRequestBody(
  domainTypeChain: CalendarTimelineSettings[],
  groupItems: DomainTypeInstance[],
  items: DomainTypeInstance[],
  startDate: number,
  endDate: number,
  groupByValue: GroupByValue | undefined
): DomainTypeInstance | null {
  const domainTypeTimelineSettings = domainTypeChain[0]
  if (domainTypeTimelineSettings === undefined) {
    return null
  }
  const {
    identifier,
    startDateAttribute,
    endDateAttribute,
    itemsAttribute
  } = domainTypeTimelineSettings
  if (groupItems.length === 0) {
    const groupByProperties = groupByValue?.attributeValue !== undefined
      ? {
        [groupByValue.attributeValue.attribute.Name]: groupByValue.attributeValue.value
      }
      : {}
    const item = items[0]
    if (item === undefined) {
      return null
    }
    if (startDateAttribute === null) {
      return null
    }
    return {
      [identifier]: item[identifier],
      [startDateAttribute.Name]: DateTime.fromMillis(startDate).toUTC().toISO(),
      [endDateAttribute?.Name ?? startDateAttribute.Name]: DateTime.fromMillis(endDate).toUTC().toISO(),
      ...groupByProperties
    }
  }
  const groupItem = groupItems[0]
  if (groupItem === undefined) {
    return null
  }
  if (!isDomainTypeListAttribute(itemsAttribute)) {
    return null
  }
  if (groupItems.length > 1) {
    return {
      [identifier]: groupItem[identifier],
      [itemsAttribute.Name]: getChangeItemDatesPatchRequestBody(
        domainTypeChain.slice(1),
        groupItems.slice(1),
        items,
        startDate,
        endDate,
        groupByValue
      )
    }
  }
  return {
    [identifier]: groupItem[identifier],
    [itemsAttribute.Name]: items.map(item => {
      return getChangeItemDatesPatchRequestBody(
        domainTypeChain.slice(1),
        [],
        [item],
        startDate,
        endDate,
        groupByValue
      )
    })
  }
}

export function getTimelineItemChains(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  overriders: DomainTypeOverrider[],
  items: DomainTypeInstance[] = []
): [domainTypeChain: CalendarTimelineSettings[], itemChains: DomainTypeInstance[][]] {
  const domainTypeChain: CalendarTimelineSettings[] = [
    getCalendarTimelineSettings(domainTypes, domainType, overriders)
  ]
  let itemChains: DomainTypeInstance[][] = items.map(item => [item])
  let currentDomainType = domainType
  let timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, currentDomainType, overriders, 'TimelineItems')
  while (timelineItemsAttribute !== null
    && isDomainTypeListAttribute(timelineItemsAttribute)) {
    const itemDomainType = domainTypes[timelineItemsAttribute.AttributeDomainType]
    if (itemDomainType === undefined) {
      break
    }
    currentDomainType = itemDomainType
    domainTypeChain.push(getCalendarTimelineSettings(domainTypes, currentDomainType, overriders))
    const previousItemChains = itemChains
    itemChains = []
    for (const itemChain of previousItemChains) {
      const childItems = getValue(itemChain[itemChain.length - 1] ?? {}, timelineItemsAttribute)
      itemChains.push(...childItems?.map(childItem => [...itemChain, childItem]) ?? [])
    }
    timelineItemsAttribute = getOverridableDomainTypeSettingAttribute(domainTypes, currentDomainType, overriders, 'TimelineItems')
  }
  return [domainTypeChain, itemChains]
}

function getItemBatchInstances(
  instances: DomainTypeInstance[],
  actionDetails: ActionDetails | undefined,
  itemsDomainType: DomainType
): [DomainType, DomainTypeInstance][] {
  return actionDetails
    ? getBatchInstances(actionDetails.contextTree)
    : instances.map((item): [DomainType, Readonly<DomainTypeInstance>] => [itemsDomainType, item])
}

export function getItemFilterContext(
  domainTypes: Partial<Record<string, DomainType>>,
  itemDomainTypeContext: ContextType<typeof DomainTypeContext>,
  instances: DomainTypeInstance[],
  actionDetails: ActionDetails | undefined,
  itemsSubtype: DomainType,
  user: User | null
): FilterContext {
  return getFilterContext(
    domainTypes,
    {
      ...itemDomainTypeContext,
      batchInstances: getItemBatchInstances(
        instances,
        actionDetails,
        itemsSubtype
      )
    },
    user
  )
}

function getIsAttributeRequiredMessage(
  action: DomainTypeAction | undefined,
  attributeValue: AttributeValue
): string | null {
  if (attributeValue.value !== null) {
    return null
  }
  const parameter = action?.Parameters
    ?.find(isAttributeParameter(attributeValue.attribute.Name))
  if (parameter !== undefined) {
    if (parameter.Required) {
      return `${parameter.Name} is required`
    }
    return null
  }
  if (attributeValue.attribute.Required === true) {
    return `${attributeValue.attribute.Title} is required`
  }
  return null
}

const fromNullable = E.fromNullable<O.Option<string>>(O.none)

export function getAttributeValueInvalidReason(
  attributeValue: AttributeValue | null | undefined,
  filterContext: FilterContext,
  action?: DomainTypeAction
): O.Option<string> {
  return pipe(
    E.Do,
    E.apS('filterContext', E.right(filterContext)),
    E.apS('action', E.right(action)),
    E.apS('attributeValue', fromNullable(attributeValue)),
    E.chainFirst(({
      attributeValue,
      action
    }) => pipe(
      E.fromNullable(constNull)(getIsAttributeRequiredMessage(
        action,
        attributeValue
      )),
      E.swap,
      E.mapLeft(O.some)
    )),
    E.bind(
      'domainTypeAttributeValue',
      ({ attributeValue }) => isOfType(attributeValue, 'domainType', false)
        ? E.right(attributeValue)
        : E.left<O.Option<string>>(O.none)
    ),
    E.bind(
      'attributeDomainType',
      ({ filterContext, domainTypeAttributeValue }) => fromNullable(
        filterContext.domainTypes[domainTypeAttributeValue.attribute.AttributeDomainType]
      )
    ),
    E.bind(
      'instance',
      ({ domainTypeAttributeValue }) => fromNullable(
        domainTypeAttributeValue.value
      )
    ),
    E.chain(({ attributeDomainType, instance, domainTypeAttributeValue }) => applyAllFilters(
      filterContext.domainTypes,
      attributeDomainType,
      instance,
      domainTypeAttributeValue.attribute.Filters ?? [],
      filterContext
    )
      ? E.left(O.none)
      : E.left(O.some(`${getHeading(filterContext.domainTypes, attributeDomainType, instance)} is an invalid ${domainTypeAttributeValue.attribute.Title} value for this item`))
    ),
    E.match(identity, constant(O.none))
  )
}

export function canEditInstancesAttributes(
  domainTypes: Partial<Record<string, DomainType>>,
  subtype: DomainType,
  filterContext: FilterContext,
  instances: DomainTypeInstance[],
  user: User | null,
  attributes: string[]
): 'never' | 'state' | 'permission' | true {
  const editButton = getDomainTypeButtons(domainTypes, subtype)
    .map(([, button]) => button)
    .find(button => button.Type === 'EditButton')
  const editForm = getDomainTypeSetting(domainTypes, subtype, 'EditForm')
  if (editButton === undefined) {
    return 'never'
  }
  if (isDisabled(
    editButton,
    domainTypes,
    subtype,
    filterContext,
    instances
  )) {
    return 'state'
  }
  if (!isInRole(user, editButton.Role)) {
    return 'permission'
  }
  if (!isNullOrUndefined(editForm)
    && attributes.some(name => !editForm.includes(name))) {
    return 'permission'
  }
  return true
}

interface GenericItem {
  readonly moveResizeActionDetails?: ActionDetails
  readonly canEdit: ReturnType<typeof canEditInstancesAttributes>
}

export function getMouseDownMessage(
  item: GenericItem,
  user: User | null
): O.Option<string> {
  if (canPerformAction(item.moveResizeActionDetails, item.canEdit)) {
    return O.none
  }
  if (item.moveResizeActionDetails === undefined) {
    switch (item.canEdit) {
      case 'state':
        return O.some('This item is in a state which does not allow it to be moved or resized')
      case 'permission':
        return O.some('You do not have permission to move or resize this item')
      case 'never':
        return O.some('This item cannot be moved or resized')
      default:
        return O.none
    }
  }
  if (item.moveResizeActionDetails.disabled === true) {
    if (item.moveResizeActionDetails.roles.every(role => isInRole(user, role))) {
      return O.some('This item is in a state which does not allow it to be moved or resized')
    } else {
      return O.some('You do not have permission to move or resize this item')
    }
  }
  return O.none
}

export function getFirstNotNullValueFromChain<T>(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[],
  selector: (
    domainTypes: Partial<Record<string, DomainType>>,
    settings: CalendarTimelineSettings | undefined,
    item: DomainTypeInstance
  ) => T | null
): T | null {
  for (let i = domainTypeChain.length - 1; i >= 0; i--) {
    const value = selector(
      domainTypes,
      domainTypeChain[i],
      itemChain[i] ?? {}
    )
    if (value !== null) {
      return value
    }
  }
  return null
}

interface ItemBoundaries {
  startDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null
  endDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null
  startDate: string | null
  endDate: string | null
}

export function getItemBoundaries(
  domainTypes: Partial<Record<string, DomainType>>,
  domainTypeChain: CalendarTimelineSettings[],
  itemChain: DomainTypeInstance[]
): ItemBoundaries {
  const defaultStartEndDate = {
    startDateAttribute: null,
    endDateAttribute: null,
    startDate: null,
    endDate: null
  }
  return getFirstNotNullValueFromChain(
    domainTypes,
    domainTypeChain,
    itemChain,
    (domainTypes, settings, item) => {
      if (settings === undefined) {
        return null
      }
      const { startDateAttribute, endDateAttribute } = settings
      if (!isValidStartEndDateAttribute(startDateAttribute)
        || (endDateAttribute !== null && !isValidStartEndDateAttribute(endDateAttribute))) {
        return null
      }
      const startDateValue = getValue(item, startDateAttribute)
      const endDateValue = getValue(item, endDateAttribute ?? startDateAttribute)
      if (startDateValue === null || endDateValue === null) {
        return null
      }
      return {
        startDateAttribute,
        endDateAttribute: endDateAttribute ?? startDateAttribute,
        startDate: startDateValue,
        endDate: endDateValue
      }
    }
  ) ?? defaultStartEndDate
}

const DEFAULT_WIDTH: DurationLike = { minutes: 15 }

function getEndOfDayAccountingForSQLRoundingOfDateTimesBy3Milliseconds(dateTime: DateTime): number {
  if (dateTime.equals(dateTime.startOf('day'))) {
    return dateTime.minus({ day: 1 }).endOf('day').toMillis()
  }
  return dateTime.endOf('day').toMillis()
}

export function getEdgeDates(
  startDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute>,
  endDateAttribute: NonListAttribute<DateAttribute | DateTimeAttribute> | null,
  startDate: string,
  endDate: string | null
): [number, number] {
  const startDateTime = DateTime.fromISO(startDate)
  const start = startDateAttribute.AttributeType === 'date'
    ? startDateTime.startOf('day').toMillis()
    : startDateTime.toMillis()
  if (endDateAttribute === null
    || endDate === null
    || endDate === startDate) {
    const end = startDateAttribute.AttributeType === 'date'
      ? startDateTime.endOf('day').toMillis()
      : startDateTime.plus(DEFAULT_WIDTH).toMillis()
    return [start, end]
  }
  const endDateTime = DateTime.fromISO(endDate).toLocal()
  const end = endDateAttribute.AttributeType === 'date'
    ? getEndOfDayAccountingForSQLRoundingOfDateTimesBy3Milliseconds(endDateTime)
    : endDateTime.toMillis()
  return [start, end]
}

export function doesNotHaveRequiredSettings(
  settings: CalendarTimelineSettings | undefined
): boolean {
  if (settings === undefined) {
    return true
  }
  const {
    startDateAttribute,
    endDateAttribute
  } = settings
  return startDateAttribute === null
    || !isValidStartEndDateAttribute(startDateAttribute)
    || (endDateAttribute !== null && !isValidStartEndDateAttribute(endDateAttribute))
}