import { Box, Button, ClickAwayListener, Paper, Popper } from '@mui/material'
import IsInRole from 'components/auth/IsInRole'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import SortableToggleList from 'components/utils/SortableToggleList'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { AttributeValue, DomainType, EditableDomainTypeSettings } from 'types'
import { getAttributeName, isOverridableSetting, isRequired, makeSortFunction } from 'utils/helpers'
import { useDomainTypeSetting } from 'utils/hooks'

const MAX_HEIGHT = 380

type EditableStringListSetting = NonNullable<{
  [Key in keyof EditableDomainTypeSettings]: EditableDomainTypeSettings[Key] extends string[] | null | undefined ? Key : never
}[keyof EditableDomainTypeSettings]>

interface Props {
  domainType: DomainType
  allAttributeValues: AttributeValue[]
  setting: EditableStringListSetting
  alwaysShowRequired?: boolean
}

export default function AttributeListSettingPopper({
  domainType,
  allAttributeValues,
  setting,
  alwaysShowRequired = false
}: Props): JSX.Element {
  const domainTypes = useSelector(getAllDomainTypes)
  const defaultItemOrder = useMemo(() => {
    return allAttributeValues
      .filter(attributeValue => {
        if (alwaysShowRequired) {
          return isRequired(attributeValue)
        }
        return true
      })
      .map(getAttributeName)
  }, [allAttributeValues, alwaysShowRequired])
  const [itemOrder, onChangeItemOrder] = useDomainTypeSetting(
    domainType,
    setting,
    defaultItemOrder
  )
  const filteredSortedAttributeValues = useMemo(() => {
    return allAttributeValues
      .filter(attributeValue => {
        if (alwaysShowRequired && isRequired(attributeValue)) {
          return true
        }
        return itemOrder.includes(attributeValue.attribute.Name)
      })
      .sort(makeSortFunction(itemOrder, getAttributeName))
  }, [allAttributeValues, alwaysShowRequired, itemOrder])
  const items = useMemo(() => {
    return allAttributeValues
      .filter(attributeValue => !(attributeValue.hidden ?? false))
      .map(attributeValue => ({
        id: attributeValue.attribute.Name,
        title: attributeValue.attribute.Title,
        disableToggle: alwaysShowRequired && isRequired(attributeValue),
        checked: filteredSortedAttributeValues
          .map(getAttributeName)
          .includes(attributeValue.attribute.Name)
      }))
  }, [allAttributeValues, alwaysShowRequired, filteredSortedAttributeValues])
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [popperOpen, setPopperOpen] = useState(false)
  const onClickMore = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setPopperOpen(true)
  }, [])
  const onClickAway = useCallback(() => {
    setPopperOpen(false)
    setAnchorEl(null)
  }, [])
  const onToggle = useCallback((name: string) => {
    const newValue = itemOrder.includes(name)
      ? itemOrder.filter(itemName => itemName !== name)
      : [...itemOrder, name]
    onChangeItemOrder(newValue)
  }, [itemOrder, onChangeItemOrder])
  const domainTypeDomainType = Object.values(domainTypes)
    .find(domainType => domainType?.Name === 'DomainType')
  const viewRole = domainTypeDomainType?.ViewRole ?? null
  return (
    <IsInRole
      role={isOverridableSetting(setting)
        ? null
        : viewRole}>
      <Box
        component='div'
        display='flex'
        justifyContent='flex-end'>
        <Button
          tabIndex={-1}
          variant='text'
          onClick={onClickMore}>
          More
        </Button>
        <Popper
          open={popperOpen}
          anchorEl={anchorEl}
          sx={theme => ({
            zIndex: theme.zIndex.modal,
            minWidth: '300px'
          })}
          placement='top-end'
          onResize={undefined}
          onResizeCapture={undefined}>
          <ClickAwayListener onClickAway={onClickAway}>
            <Paper elevation={8}>
              <SortableToggleList
                textFieldLabel='Filter attributes'
                textFieldPlaceholder='Attribute title'
                items={items}
                itemOrder={itemOrder}
                maxHeight={MAX_HEIGHT}
                onToggle={onToggle}
                onChangeItemOrder={onChangeItemOrder}>
                <Box display='flex'>
                  <SaveDomainTypeSettingButton
                    domainType={domainType}
                    setting={setting}
                    value={itemOrder} />
                </Box>
              </SortableToggleList>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </Box>
    </IsInRole>
  )
}