import { Button, Dialog, DialogActions, DialogContent, useTheme } from '@mui/material'
import DomainTypeTheme from 'components/domainType/DomainTypeTheme'
import PushDomainTypeOverriderContext from 'components/domainType/PushDomainTypeOverriderContext'
import { OverridableDomainTypeComponent } from 'components/overrides'
import * as E from 'fp-ts/Either'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { Query } from 'types'
import { DomainTypeContext, SidePanelContext } from 'utils/context'
import { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getDomainTypeSetting } from 'utils/helpers'
import { SignedInApi, useQueries } from 'utils/hooks'
import { FindView, useInMemoryFind } from '../FindPage'
import LocalDetailsPage from './LocalDetailsPage'
import { HighlightedRow, TableSection as TableSectionType } from './useDetails'

interface Props {
  section: TableSectionType
  isLoading: boolean
  highlightedRow: HighlightedRow | null
  onHighlightRow?(highlightedRow: HighlightedRow | null): void
}

interface DefaultTableSectionContentProps extends Props {
  domainTypeQueries: Query[]
  overriderQueries: [Query, OverriderDetails][]
  currentQuery: Query
  setQueryId: (queryId: string) => void
}

function DefaultTableSectionContent({
  section,
  isLoading,
  highlightedRow,
  onHighlightRow,
  domainTypeQueries,
  overriderQueries,
  currentQuery,
  setQueryId
}: DefaultTableSectionContentProps): JSX.Element {
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)
  const {
    items,
    calendarItems,
    total,
    page,
    pageSize,
    searchText,
    sorts,
    filterLinkOperator,
    filters,
    checkedRowIds,
    view,
    snackPack,
    calendarProps,
    onSearchTextChange,
    onFilterLinkOperatorChange,
    onFiltersChange,
    onPageChange,
    onPageSizeChange,
    onSortsChange,
    getTotal,
    onApplyQuery,
    onCheckedRowIdsChange,
    onViewChange
  } = useInMemoryFind(
    section.domainType,
    section.rows ?? [],
    currentQuery.Id,
    setQueryId
  )
  const domainTypes = useSelector(getAllDomainTypes)
  const identifier = getDomainTypeSetting(domainTypes, section.domainType, 'Identifier') ?? 'Id'
  const highlightedRowIds = useMemo(() => {
    return highlightedRow?.section === section.id
      ? [highlightedRow.id]
      : []
  }, [highlightedRow?.id, highlightedRow?.section, section.id])

  const onRowClick = useCallback((id: string) => {
    if (!onHighlightRow) {
      setSelectedRowId(id)
      setDetailsDialogOpen(true)
      return
    }
    if (highlightedRow?.section === section.id
      && highlightedRow.id === id) {
      onHighlightRow(null)
    } else {
      onHighlightRow({
        section: section.id,
        id
      })
    }
  }, [highlightedRow?.id, highlightedRow?.section, onHighlightRow, section.id])
  const { sidePanel, setSidePanel } = useContext(SidePanelContext)

  const domainTypeContext = useContext(DomainTypeContext)
  const theme = useTheme()
  useEffect(() => {
    if (onHighlightRow === undefined) {
      return
    }
    if (highlightedRow === null) {
      setSidePanel(null)
      return
    }
    if (highlightedRow.section !== section.id) {
      return
    }
    const highlightedRowInstance = section.rows?.find(row => row[identifier] === highlightedRow.id)
    if (!highlightedRowInstance) {
      setSidePanel(null)
    } else {
      setSidePanel({
        id: `${section.id}_${highlightedRow.id}`,
        type: 'details',
        context: domainTypeContext,
        domainType: section.domainType,
        instance: highlightedRowInstance,
        title: section.attribute.Title,
        attribute: section.attribute,
        theme
      })
    }
  }, [domainTypeContext, highlightedRow, identifier, onHighlightRow, section.attribute, section.domainType, section.id, section.rows, section.title, setSidePanel, sidePanel?.id, theme])
  useEffect(() => {
    if (onHighlightRow !== undefined
      && sidePanel === null) {
      onHighlightRow(null)
    }
  }, [onHighlightRow, sidePanel])
  const onCloseDetailsDialog = useCallback(() => {
    setDetailsDialogOpen(false)
  }, [])
  const selectedRow = useMemo(() => {
    if (selectedRowId === null) {
      return null
    }
    return section.rows?.find(row => row[identifier] === selectedRowId)
  }, [identifier, section.rows, selectedRowId])
  const fetchTotal = useCallback(async (api: SignedInApi, query: Query) => {
    return E.right(getTotal(query))
  }, [getTotal])
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        open={detailsDialogOpen}
        onClose={onCloseDetailsDialog}>
        <DialogContent>
          <LocalDetailsPage
            rootDomainType={section.domainType}
            instance={selectedRow ?? {}} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCloseDetailsDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <FindView
        domainType={section.domainType}
        items={items}
        isLoading={isLoading}
        onFilterLinkOperatorChange={onFilterLinkOperatorChange}
        onFiltersChange={onFiltersChange}
        sorts={sorts}
        onSortsChange={onSortsChange}
        filterLinkOperator={filterLinkOperator}
        filters={filters}
        page={page}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        total={total}
        onRowClick={onRowClick}
        highlightedRowIds={highlightedRowIds}
        searchText={searchText}
        currentQuery={currentQuery}
        overriderQueries={overriderQueries}
        domainTypeQueries={domainTypeQueries}
        onSearch={onSearchTextChange}
        onApplyQuery={onApplyQuery}
        checkedRowIds={checkedRowIds}
        onCheckedRowIdsChange={onCheckedRowIdsChange}
        calendarItems={calendarItems}
        isExporting={false}
        view={view}
        calendarProps={calendarProps}
        onViewChange={onViewChange}
        fetchTotal={fetchTotal}
        snackPack={snackPack}
        hideHeading />
    </>
  )
}

function DefaultTableSection({
  section,
  isLoading,
  highlightedRow,
  onHighlightRow
}: Props): JSX.Element {
  const [queryId, setQueryId] = useState('all')
  const {
    currentQuery,
    overriderDetails,
    overriderQueries,
    domainTypeQueriesWithAll
  } = useQueries(section.domainType, queryId)
  return (
    <PushDomainTypeOverriderContext overriderDetails={overriderDetails}>
      <DefaultTableSectionContent
        domainTypeQueries={domainTypeQueriesWithAll}
        overriderQueries={overriderQueries}
        currentQuery={currentQuery}
        section={section}
        isLoading={isLoading}
        highlightedRow={highlightedRow}
        onHighlightRow={onHighlightRow}
        setQueryId={setQueryId} />
    </PushDomainTypeOverriderContext>
  )
}

export default function TableSection(props: Props): JSX.Element {
  const {
    section: {
      domainType
    }
  } = props
  return (
    <DomainTypeTheme domainType={domainType}>
      <OverridableDomainTypeComponent<'tableSection'>
        component='tableSection'
        domainType={domainType}
        props={props}
        DefaultComponent={DefaultTableSection} />
    </DomainTypeTheme>
  )
}
