import * as E from 'fp-ts/Either'
import * as t from 'io-ts'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { Dispatch } from 'redux'
import { push, replace } from 'redux-first-history'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Codecs = Record<string, t.Type<any, string, unknown>>
type Decoded<C extends Codecs> = {
  [Key in keyof C]: t.TypeOf<C[Key]>
}
type Encoded<C extends Codecs, P extends string> = Record<`${P}${keyof C & string}`, string>
type Actions<C extends Codecs> = Record<keyof typeof navigateActions, (newParams: Partial<Decoded<C>>) => void>

function paramsToObject(searchParams: URLSearchParams): Record<string, string> {
  const result = {} as Record<string, string>
  for (const [key, value] of searchParams) {
    result[key] = value
  }
  return result
}

function decodeParams<C extends Codecs>(
  searchParams: URLSearchParams,
  codecs: C,
  defaults: Decoded<C>,
  urlPrefix: string
): Decoded<C> {
  const decodedParams = {} as Decoded<C>
  for (const key of Object.keys(codecs)) {
    const codec = codecs[key]
    if (codec === undefined) {
      throw Error('Codec was found to be undefined')
    }
    decodedParams[key as keyof C] = E.getOrElse(() => defaults[key])(
      codec.decode(searchParams.get(`${urlPrefix}${key}`))
    )
  }
  return decodedParams
}

const navigateActions = {
  push: push,
  replace: replace
}

function encodeParams<C extends Codecs>(
  codecs: C,
  params: Decoded<C>,
  urlPrefix: string
): Encoded<C, typeof urlPrefix> {
  const encodedParams = {} as Encoded<C, typeof urlPrefix>
  for (const key of Object.keys(codecs)) {
    const codec = codecs[key]
    if (codec === undefined) {
      throw Error('Codec was found to be undefined')
    }
    encodedParams[`${urlPrefix}${key}` as `${typeof urlPrefix}${keyof C & string}`] = codec.encode(params[key])
  }
  return encodedParams
}

function makeActions<C extends Codecs>(
  dispatch: Dispatch,
  codecs: C,
  defaults: Decoded<C>,
  pathname: string,
  urlPrefix: string
) {
  const actions = {} as Actions<C>
  for (const key of Object.keys(navigateActions)) {
    actions[key as keyof typeof navigateActions] = (newParams: Partial<Decoded<C>>) => {
      const searchParams = new URLSearchParams(window.location.search)
      const decodedParams = decodeParams(searchParams, codecs, defaults, urlPrefix)
      const encodedParams = encodeParams(
        codecs,
        {
          ...defaults,
          ...decodedParams,
          ...newParams
        },
        urlPrefix
      )
      dispatch(navigateActions[key as keyof typeof navigateActions]({
        pathname,
        search: new URLSearchParams({
          ...paramsToObject(searchParams),
          ...encodedParams
        }).toString()
      }))
    }
  }
  return actions
}

export function useSearchParams<C extends Codecs>(
  codecs: C,
  defaults: Decoded<C>,
  urlPrefix: string
): [Decoded<C>, Actions<C>] {
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()
  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const decodedParams = decodeParams(searchParams, codecs, defaults, urlPrefix)
    const actions = makeActions(dispatch, codecs, defaults, pathname, urlPrefix)
    return [decodedParams, actions]
  }, [codecs, defaults, dispatch, pathname, search, urlPrefix])
}
