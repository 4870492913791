import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import createRootReducer from './state/reducers'

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() })

export const store = configureStore(
  {
    reducer: createRootReducer(routerReducer),
    middleware: [routerMiddleware],
    devTools: true
  }
)

export const history = createReduxHistory(store)
