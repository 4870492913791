import { chain } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { Json, JsonRecord, NumberFromString } from 'io-ts-types'
import { DateTime } from 'luxon'
import { AttachedFileHandle, BaseElement, CalculationExtraParams, CheckboxElement, Choice, Condition, Constraint, ContextualMask, Dataform, DataformElement, DataformElementExtraParams, DateElement, DateTimeElement, Dependency, DropdownElement, Element, ElementType, ElementValueTypes, EntityWithAttachedFiles, Equipment, EquipmentElement, EquipmentElementExtraParams, EquipmentType, FileContextElementExtraParams, Group, ImageElement, ImageElementExtraParams, InitialsElement, InlineRadioElement, MarkupElement, Metadata, MultiDataformMapping, NumberElement, NumberRange, PercentageElement, PhotosElement, RadioElement, Results, SelectElementExtraParams, SerialisedEquipment, Settings, SignatureElement, StatementElement, StatementElementExtraParams, TextBoxElement, TextBoxWithQuestionElement, TextElementExtraParams, TimeElement } from 'types/dataform'
import { DATAFORM_DATE_ELEMENT_FORMAT, DATAFORM_TIME_ELEMENT_FORMAT } from 'utils/constants'

const SettingsCodec: t.Type<Settings> = t.type({
  DisplayText: t.union([t.string, t.null]),
  LockOnComplete: t.boolean,
  NoUnexpectedAnswers: t.boolean
})

const DependencyCodec: t.Type<Dependency> = t.partial({
  ControllingId: t.union([t.string, t.null]),
  Value: t.union([t.string, t.null]),
  Expression: t.union([t.string, t.null])
})

const ChoiceCodec: t.Type<Choice> = t.type({
  Value: t.string,
  Text: t.string
})

const ConditionCodec: t.Type<Condition> = t.partial({
  Selector: t.union([t.string, t.null]),
  Value: t.union([t.string, t.null]),
  Expression: t.union([t.string, t.null])
})

const ContextualMaskCodec: t.Type<ContextualMask> = t.type({
  Name: t.string,
  Appearance: t.union([t.literal('hidden'), t.literal('readonly'), t.literal('removed')]),
  Conditions: t.array(ConditionCodec)
})

const ConstraintCodec: t.Type<Constraint> = t.type({
  Type: t.union([t.literal('regex'), t.literal('numberRange'), t.literal('feelExpression')]),
  Rule: t.string,
  Message: t.string
})

const NullableStringCodec = t.union([t.string, t.null])

const BaseElementCodec: t.Type<BaseElement> = t.intersection([
  t.type({
    Id: t.string,
    Alias: t.string,
    Text: t.string
  }),
  t.partial({
    ExpectedResult: NullableStringCodec,
    DefaultResult: NullableStringCodec,
    Milestone: t.union([t.boolean, t.null]),
    Required: t.union([t.boolean, t.null]),
    DepsId: t.union([t.array(DependencyCodec), t.null]),
    Choices: t.union([t.array(ChoiceCodec), t.null]),
    Filters: t.union([t.array(t.string), t.null]),
    ContextualMasks: t.union([t.array(ContextualMaskCodec), t.null]),
    Constraint: t.union([ConstraintCodec, t.null])
  })
])

const CalculationExtraParamsCodec: t.Type<CalculationExtraParams> = t.partial({
  calculation: NullableStringCodec
})

const SelectElementExtraParamsCodec: t.Type<SelectElementExtraParams> = t.intersection([
  CalculationExtraParamsCodec,
  t.partial({
    MetadataCategory: NullableStringCodec,
    MetadataSubcategory: NullableStringCodec,
    MetadataChoices: NullableStringCodec
  })
])

const TextElementExtraParamsCodec: t.Type<TextElementExtraParams> = t.intersection([
  CalculationExtraParamsCodec,
  t.partial({
    multiline: t.union([t.boolean, t.null]),
    readonly: t.union([t.boolean, t.null])
  })
])

const StatementElementExtraParamsCodec: t.Type<StatementElementExtraParams> = t.partial({
  appearance: t.union([t.literal('subheading'), t.null])
})

const ImageElementExtraParamsCodec: t.Type<ImageElementExtraParams> = t.type({
  appearance: t.literal('file')
})

const FileContextElementExtraParamsCodec: t.Type<FileContextElementExtraParams> = t.type({
  fileContext: t.union([t.literal('work'), t.literal('task')])
})

const EquipmentElementExtraParamsCodec: t.Type<EquipmentElementExtraParams> = t.intersection([
  t.type({
    skipQuantity: t.boolean,
    activeTerm: t.union([
      t.literal('serialisedEquipmentExternalId'),
      t.literal('equipmentTypeExternalId'),
      t.literal('description')
    ]),
    resultType: t.union([
      t.literal('SERIAL'),
      t.literal('NONSERIAL'),
      t.literal('BOTH'),
      t.literal('TYPES')
    ])
  }),
  t.partial({
    searchValue: NullableStringCodec,
    STEP_CONFIG_MODE: NullableStringCodec,
    CONFIG_MODE: NullableStringCodec
  })
])

const DataformElementExtraParamsCodec: t.Type<DataformElementExtraParams> = t.intersection([
  t.type({
    inline: t.boolean
  }),
  t.partial({
    dataformName: NullableStringCodec,
    selectionCategory: NullableStringCodec,
    selectionSubcategory: NullableStringCodec
  })
])

const RadioElementCodec: t.Type<RadioElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.Radio)
    }),
    t.partial({
      ExtraParams: t.union([SelectElementExtraParamsCodec, t.null])
    })
  ])
])

const CheckboxElementCodec: t.Type<CheckboxElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Checkbox)
  }),
  t.partial({
    ExtraParams: t.union([CalculationExtraParamsCodec, t.null])
  })
])

const NumberElementCodec: t.Type<NumberElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Number)
  }),
  t.partial({
    ExtraParams: t.union([CalculationExtraParamsCodec, t.null])
  })
])

const TextBoxElementCodec: t.Type<TextBoxElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.TextBox)
    }),
    t.partial({
      ExtraParams: t.union([TextElementExtraParamsCodec, t.null])
    })
  ])
])

const TextBoxWithQuestionElementCodec: t.Type<TextBoxWithQuestionElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.TextBoxWithQuestion)
    }),
    t.partial({
      ExtraParams: t.union([TextElementExtraParamsCodec, t.null])
    })
  ])
])

const StatementElementCodec: t.Type<StatementElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.Statement)
    }),
    t.partial({
      ExtraParams: t.union([StatementElementExtraParamsCodec, t.null])
    })
  ])
])

const DropdownElementCodec: t.Type<DropdownElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.Dropdown)
    }),
    t.partial({
      ExtraParams: t.union([SelectElementExtraParamsCodec, t.null])
    })
  ])
])

const InlineRadioElementCodec: t.Type<InlineRadioElement> = t.intersection([
  BaseElementCodec,
  t.intersection([
    t.type({
      ElementType: t.literal(ElementType.InlineRadio)
    }),
    t.partial({
      ExtraParams: t.union([SelectElementExtraParamsCodec, t.null])
    })
  ])
])

const DateElementCodec: t.Type<DateElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Date)
  }),
  t.partial({
    ExtraParams: t.union([CalculationExtraParamsCodec, t.null])
  })
])

const DateTimeElementCodec: t.Type<DateTimeElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.DateTime)
  }),
  t.partial({
    ExtraParams: t.union([CalculationExtraParamsCodec, t.null])
  })
])

const SignatureElementCodec: t.Type<SignatureElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Signature)
  })
])

const InitialsElementCodec: t.Type<InitialsElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Initials)
  })
])

const PercentageElementCodec: t.Type<PercentageElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Percentage)
  })
])

const ImageElementCodec: t.Type<ImageElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Image),
    ExtraParams: ImageElementExtraParamsCodec
  })
])

const PhotosElementCodec: t.Type<PhotosElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Photos),
    ExtraParams: FileContextElementExtraParamsCodec
  })
])

const MarkupElementCodec: t.Type<MarkupElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Markup),
    ExtraParams: FileContextElementExtraParamsCodec
  })
])

const EquipmentElementCodec: t.Type<EquipmentElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Equipment),
    ExtraParams: EquipmentElementExtraParamsCodec
  })
])

const TimeElementCodec: t.Type<TimeElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Time)
  }),
  t.partial({
    ExtraParams: t.union([CalculationExtraParamsCodec, t.null])
  })
])

const DataformElementCodec: t.Type<DataformElement> = t.intersection([
  BaseElementCodec,
  t.type({
    ElementType: t.literal(ElementType.Dataform),
    ExtraParams: DataformElementExtraParamsCodec
  })
])

const ElementCodec: t.Type<Element> = t.union([
  RadioElementCodec,
  CheckboxElementCodec,
  NumberElementCodec,
  TextBoxElementCodec,
  TextBoxWithQuestionElementCodec,
  StatementElementCodec,
  DropdownElementCodec,
  InlineRadioElementCodec,
  DateElementCodec,
  DateTimeElementCodec,
  SignatureElementCodec,
  InitialsElementCodec,
  PercentageElementCodec,
  ImageElementCodec,
  PhotosElementCodec,
  MarkupElementCodec,
  EquipmentElementCodec,
  TimeElementCodec,
  DataformElementCodec
])

export const BooleanFromCaseInsensitiveString = new t.Type<boolean, string, unknown>(
  'BooleanFromCaseInsensitiveString',
  t.boolean.is,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      chain(s => (s.toLowerCase() === 'true' ? t.success(true) : s.toLowerCase() === 'false' ? t.success(false) : t.failure(u, c)))
    ),
  String
)

export const NumberRangeCodec = <L extends number, U extends number>(lower: L, upper: U): t.BrandC<t.NumberC, NumberRange<L, U>> => t.brand(
  t.number,
  (n: number): n is t.Branded<number, NumberRange<L, U>> => n >= lower && n <= upper,
  'NumberRange'
)

export const PercentageCodec = t.number.pipe(NumberRangeCodec(0, 100))

export const DateFromString = new t.Type<DateTime, string, unknown>(
  'DateFromString',
  (u): u is DateTime => u instanceof DateTime,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      chain(s => {
        if (s.toLowerCase() === 'now') {
          return t.success(DateTime.now())
        }
        const d = DateTime.fromFormat(s, DATAFORM_DATE_ELEMENT_FORMAT)
        return d.isValid ? t.failure(u, c) : t.success(d)
      })
    ),
  a => a.toFormat(DATAFORM_DATE_ELEMENT_FORMAT)
)

export const DateTimeFromString = new t.Type<DateTime, string | null, unknown>(
  'DateTimeFromString',
  (u): u is DateTime => u instanceof DateTime,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      chain(s => {
        if (s.toLowerCase() === 'now') {
          return t.success(DateTime.now())
        }
        const d = DateTime.fromISO(s)
        return d.isValid ? t.failure(u, c) : t.success(d)
      })
    ),
  a => a.toUTC().toISO()
)

export const TimeFromString = new t.Type<DateTime, string, unknown>(
  'TimeFromString',
  (u): u is DateTime => u instanceof DateTime,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      chain(s => {
        if (s.toLowerCase() === 'now') {
          return t.success(DateTime.now())
        }
        const d = DateTime.fromFormat(s, DATAFORM_TIME_ELEMENT_FORMAT)
        return d.isValid ? t.failure(u, c) : t.success(d)
      })
    ),
  a => a.toFormat(DATAFORM_TIME_ELEMENT_FORMAT)
)

export const PercentageFromString = NumberFromString.pipe(PercentageCodec)

export const SerialisedEquipmentCodec: t.Type<SerialisedEquipment> = t.type({
  IsSerialised: t.literal(true),
  SerialisedEquipment: t.string,
  Quantity: t.literal(1)
})

export const EquipmentTypeCodec: t.Type<EquipmentType> = t.type({
  IsSerialised: t.literal(false),
  EquipmentType: t.string,
  Quantity: t.number
})

export const EquipmentCodec: t.Type<Equipment> = t.union([
  SerialisedEquipmentCodec,
  EquipmentTypeCodec
])

export const ResultsCodec: t.Type<Results> = t.readonly(t.intersection([
  t.type({
    DataformId: t.string,
    Aliases: t.record(t.string, t.union([t.string, t.undefined])),
    Answers: t.record(t.string, t.union([t.unknown, t.undefined]))
  }),
  t.partial({
    Complete: t.union([t.boolean, t.null]),
    AsExpected: t.union([t.boolean, t.null])
  })
]))

export const ElementTypeCodecs: { [T in ElementType]: t.Type<ElementValueTypes[T], Json, unknown> } = {
  [ElementType.Radio]: t.string,
  [ElementType.Checkbox]: t.union([BooleanFromCaseInsensitiveString, t.boolean]),
  [ElementType.Number]: t.union([NumberFromString, t.number]),
  [ElementType.TextBox]: t.string,
  [ElementType.TextBoxWithQuestion]: t.string,
  [ElementType.Statement]: t.null,
  [ElementType.Dropdown]: t.string,
  [ElementType.InlineRadio]: t.string,
  [ElementType.Date]: DateFromString,
  [ElementType.DateTime]: DateTimeFromString,
  [ElementType.Signature]: t.string,
  [ElementType.Initials]: t.string,
  [ElementType.Percentage]: t.union([PercentageFromString, PercentageCodec]),
  [ElementType.Image]: t.null,
  [ElementType.Photos]: t.array(t.string),
  [ElementType.Markup]: t.string,
  [ElementType.Equipment]: JsonRecord.pipe(t.any).pipe(EquipmentCodec),
  [ElementType.Time]: TimeFromString,
  [ElementType.Dataform]: JsonRecord.pipe(t.any).pipe(ResultsCodec)
}

export const ElementTypeDefaultValueCodecs: { [T in ElementType]: t.Type<ElementValueTypes[T], string | null, unknown> | t.Type<null, string | null, unknown> } = {
  [ElementType.Radio]: t.string,
  [ElementType.Checkbox]: BooleanFromCaseInsensitiveString,
  [ElementType.Number]: NumberFromString,
  [ElementType.TextBox]: t.string,
  [ElementType.TextBoxWithQuestion]: t.string,
  [ElementType.Statement]: t.null,
  [ElementType.Dropdown]: t.string,
  [ElementType.InlineRadio]: t.string,
  [ElementType.Date]: DateFromString,
  [ElementType.DateTime]: DateTimeFromString,
  [ElementType.Signature]: t.string,
  [ElementType.Initials]: t.string,
  [ElementType.Percentage]: PercentageFromString,
  [ElementType.Image]: t.null,
  [ElementType.Photos]: t.null,
  [ElementType.Markup]: t.null,
  [ElementType.Equipment]: t.null,
  [ElementType.Time]: TimeFromString,
  [ElementType.Dataform]: t.null
}

export const ElementTypeRequiredValidation: { [T in ElementType]: (value: ElementValueTypes[T]) => boolean } = {
  [ElementType.Radio]: value => true,
  [ElementType.Checkbox]: value => true,
  [ElementType.Number]: value => true,
  [ElementType.TextBox]: value => true,
  [ElementType.TextBoxWithQuestion]: value => true,
  [ElementType.Statement]: value => true,
  [ElementType.Dropdown]: value => true,
  [ElementType.InlineRadio]: value => true,
  [ElementType.Date]: value => true,
  [ElementType.DateTime]: value => true,
  [ElementType.Signature]: value => true,
  [ElementType.Initials]: value => true,
  [ElementType.Percentage]: value => true,
  [ElementType.Image]: value => true,
  [ElementType.Photos]: value => true,
  [ElementType.Markup]: value => true,
  [ElementType.Equipment]: value => true,
  [ElementType.Time]: value => true,
  [ElementType.Dataform]: value => value.Complete === true
}

export const RegExpFromString = new t.Type<RegExp, string, unknown>(
  'RegExpFromString',
  (value: unknown): value is RegExp => value instanceof RegExp,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      chain(s => {
        if (typeof s !== 'string') {
          return t.failure(u, c)
        }
        if (s === '') {
          return t.failure(u, c)
        }
        try {
          const regex = new RegExp(s)
          return t.success(regex)
        } catch {
          return t.failure(u, c)
        }
      })
    ),
  String
)

export const MetadataCodec: t.Type<Metadata> = t.type({
  Category: t.string,
  Subcategory: t.string,
  Value: t.string
})

const GroupCodec: t.Type<Group> = t.intersection([
  t.type({
    Id: t.string,
    Title: t.string,
    Type: t.union([
      t.literal('group'),
      t.literal('photos'),
      t.literal('markup'),
      t.literal('signature'),
      t.literal('equipment'),
      t.literal('dataform')
    ]),
    Elements: t.array(ElementCodec)
  }),
  t.partial({
    Required: t.union([t.boolean, t.null])
  })
])

export const DataformCodec: t.Type<Dataform> = t.readonly(t.type({
  Id: t.string,
  Name: t.string,
  Settings: SettingsCodec,
  ShowDisabledElements: t.boolean,
  Version: t.string,
  Groups: t.array(GroupCodec)
}))

export const AttachedFileHandleCodec: t.Type<AttachedFileHandle> = t.readonly(t.type({
  Id: t.string,
  FileId: t.string
}))

export const EntityWithAttachedFilesCodec: t.Type<EntityWithAttachedFiles> = t.readonly(t.type({
  Id: t.string,
  AttachedFiles: t.array(AttachedFileHandleCodec)
}))

export const MultiDataformMappingCodec: t.Type<MultiDataformMapping> = t.intersection([
  t.type({
    Category: t.literal('MultiDataformMapping'),
    Subcategory: t.string,
    Value: t.string
  }),
  t.partial({
    Purpose: t.union([t.string, t.null])
  })
])
