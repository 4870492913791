import { useEffect, useRef } from 'react'

type Handler<K extends keyof WindowEventMap> = (ev: WindowEventMap[K]) => unknown

export function useWindowEventListener<K extends keyof WindowEventMap>(
  type: K,
  handler: Handler<K>
): void {
  const savedHandler = useRef<Handler<K>>()
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])
  useEffect(
    () => {
      const eventListener = (event: WindowEventMap[K]) => savedHandler.current?.(event)
      window.addEventListener(type, eventListener)
      return () => {
        window.removeEventListener(type, eventListener)
      }
    },
    [type]
  )
}