import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import DataForm from 'components/attribute/AttributeForm'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import * as E from 'fp-ts/Either'
import * as O from 'fp-ts/Option'
import { pipe } from 'fp-ts/lib/function'
import * as t from 'io-ts'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editPersonGlobalDomainTypeSetting } from 'state/actions/auth'
import { editDomainType, editDomainTypeOverrider } from 'state/actions/domainTypes'
import { getAllDomainTypes, getPerson } from 'state/reducers'
import { Attribute, DomainType, DomainTypeInstance, EditableDomainTypeSettings, PathErrors } from 'types'
import { DomainTypeCodec, DomainTypeInstanceCodec, EditableNotOverridableDomainTypeSettingsCodec, GlobalDomainTypeSettingsCodec, OverridableNotGlobalDomainTypeSettingsCodec, PathErrorsCodec, PersonCodec } from 'utils/codecs'
import DomainTypeOverriderContext, { OverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { getAttributeValues, getDomainTypeAttributes, getDomainTypeSetting, getErrorAtPath, isDomainTypeListAttribute, isEditableNotOverridableSetting, isGlobalSetting, isInRole, isOverridableNotGlobalSetting, isOverridableSetting, makeOverrideLens, validateRequiredAttributes } from 'utils/helpers'
import { SignedInApi, useApi, usePathErrors } from 'utils/hooks'
import CornerIcon from '../utils/CornerIcon'
import DomainTypeHeading from './DomainTypeHeading'

interface EditBeforeSaveFormProps<Setting extends keyof EditableDomainTypeSettings> {
  readonly setting: Setting
  readonly defaultValue: EditableDomainTypeSettings[Setting]
  readonly currentValue: EditableDomainTypeSettings[Setting]
  readonly attribute: Attribute | undefined
  readonly pathErrors: PathErrors | undefined
  readonly fullEdit: boolean
  onChange(value: EditableDomainTypeSettings[Setting]): void
  removeErrorAtPath(path: string): void
}

function isSingleDomainTypeList(value: unknown): value is [DomainTypeInstance] {
  return t.tuple([DomainTypeInstanceCodec]).is(value)
}

function isValueValid<Setting extends keyof EditableDomainTypeSettings>(
  setting: Setting,
  value: unknown
): value is EditableDomainTypeSettings[Setting] {
  if (isGlobalSetting(setting)) {
    return GlobalDomainTypeSettingsCodec.type.props[setting].is(value)
  }
  if (isOverridableNotGlobalSetting(setting)) {
    return OverridableNotGlobalDomainTypeSettingsCodec.type.props[setting].is(value)
  }
  if (isEditableNotOverridableSetting(setting)) {
    return EditableNotOverridableDomainTypeSettingsCodec.type.props[setting].is(value)
  }
  return false
}

function EditBeforeSaveForm<Setting extends keyof EditableDomainTypeSettings>({
  setting,
  defaultValue,
  currentValue,
  attribute,
  pathErrors,
  fullEdit,
  onChange,
  removeErrorAtPath
}: EditBeforeSaveFormProps<Setting>): JSX.Element | null {
  const domainTypes = useSelector(getAllDomainTypes)
  if (attribute === undefined
    || !isDomainTypeListAttribute(attribute)
    || !isSingleDomainTypeList(defaultValue)
    || !isSingleDomainTypeList(currentValue)) {
    return null
  }
  const domainType = domainTypes[attribute.AttributeDomainType]
  if (domainType === undefined) {
    return null
  }
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const defaultAttributeValues = getAttributeValues(defaultValue[0], attributes)
  const defaultPathErrors = validateRequiredAttributes(defaultAttributeValues)
  const editAttributes = attributes
    .filter(attribute => attribute.Name !== identifier
      && (fullEdit || attribute.Name in (defaultPathErrors || {})))
  const editAttributeValues = getAttributeValues(currentValue[0], editAttributes)
  return (
    <DialogContent>
      <DataForm
        attributeValues={editAttributeValues}
        onChange={attributeValue => {
          const newValue = [
            {
              ...currentValue[0],
              [attributeValue.attribute.Name]: attributeValue.value
            }
          ]
          if (isValueValid(setting, newValue)) {
            removeErrorAtPath(attributeValue.attribute.Name)
            onChange(newValue)
          }
        }}
        pathErrors={pathErrors ?? {}} />
    </DialogContent>
  )
}

interface BaseProps<Setting extends keyof EditableDomainTypeSettings> {
  readonly domainType: DomainType
  readonly setting: Setting
  readonly value: EditableDomainTypeSettings[Setting]
  readonly title?: string
  readonly mode?: 'save' | 'edit' | 'delete'
  readonly saveTo?: OverriderDetails | 'domainType'
  readonly disabled?: boolean
  readonly dialogOpen?: boolean
  onChangeDialogOpen?(value: boolean): void
  onSaveSuccess?(value: EditableDomainTypeSettings[Setting]): void
}

interface UncontrolledProps<Setting extends keyof EditableDomainTypeSettings> extends BaseProps<Setting> {
  readonly dialogOpen?: never
  readonly onChangeDialogOpen?: never
}

interface ControlledProps<Setting extends keyof EditableDomainTypeSettings> extends BaseProps<Setting> {
  readonly dialogOpen: boolean
  onChangeDialogOpen(value: boolean): void
}

type Props<Setting extends keyof EditableDomainTypeSettings> =
  | UncontrolledProps<Setting>
  | ControlledProps<Setting>

function getTitle(
  title: string | undefined,
  settingAttribute: Attribute | undefined,
  setting: string
): string {
  return title ?? settingAttribute?.Title ?? setting
}

function getApiValue<Setting extends keyof EditableDomainTypeSettings>(
  valueToSave: EditableDomainTypeSettings[Setting],
  mode: Props<Setting>['mode']
): NonNullable<EditableDomainTypeSettings[Setting]> | null {
  if (Array.isArray(valueToSave)
    && typeof valueToSave[0] === 'object'
    && mode === 'delete') {
    const settingValue = []
    for (const item of valueToSave) {
      if (typeof item === 'object') {
        settingValue.push({
          ...item,
          _delete: true
        })
      }
    }
    return settingValue as unknown as NonNullable<EditableDomainTypeSettings[Setting]> | null
  }
  return valueToSave ?? null
}

export default function SaveDomainTypeSettingButton<Setting extends keyof EditableDomainTypeSettings>({
  domainType,
  setting,
  value,
  title,
  mode = 'save',
  saveTo,
  disabled = false,
  dialogOpen,
  onChangeDialogOpen,
  onSaveSuccess
}: Props<Setting>): JSX.Element {
  const [localOpen, localSetOpen] = useState(false)
  const [open, setOpen] = typeof dialogOpen === 'boolean'
    ? [dialogOpen, onChangeDialogOpen]
    : [localOpen, localSetOpen]
  const [valueToSave, setValueToSave] = useState(value)
  const {
    pathErrors,
    setPathErrors,
    removeErrorAtPath
  } = usePathErrors()
  useEffect(() => {
    setValueToSave(value)
    setPathErrors(undefined)
  }, [open, setPathErrors, value])
  const overriderContext = useContext(DomainTypeOverriderContext)
  const saveLocations = useMemo(() => {
    return saveTo !== undefined
      ? [saveTo]
      : isOverridableSetting(setting)
        ? ['domainType' as const, ...overriderContext]
        : ['domainType' as const]
  }, [overriderContext, saveTo, setting])
  const [isSavingIndex, setIsSavingIndex] = useState<number | 'personGlobal' | false>(false)
  const api = useApi()
  const person = useSelector(getPerson)
  const domainTypes = useSelector(getAllDomainTypes)
  const domainTypeDomainType = Object.values(domainTypes)
    .find(domainType => domainType?.Name === 'DomainType')
  const settingAttribute = getDomainTypeAttributes(domainTypes, domainTypeDomainType)
    .find(attribute => attribute.Name === setting)
  title = getTitle(title, settingAttribute, setting)
  const canEditDomainType = isInRole(api.user, domainTypeDomainType?.ViewRole)
  const dispatch = useDispatch()
  const saveToOverrider = useCallback(async function saveToOverrider(
    api: SignedInApi,
    apiValue: NonNullable<EditableDomainTypeSettings[Setting]> | null,
    overriderDetails: OverriderDetails
  ) {
    const overridableSetting: string = setting
    if (!isOverridableSetting(overridableSetting)) {
      return
    }
    if (person === null) {
      return
    }
    const root = overriderDetails.root === 'domainType'
      ? { Id: overriderDetails.id }
      : person
    const lens = makeOverrideLens(overriderDetails.path, domainType.Id)
    const body = lens.modify(previous => ({
      ...previous,
      [setting]: apiValue
    }))({
      Id: root.Id
    })
    if (!DomainTypeInstanceCodec.is(body)) {
      setIsSavingIndex(false)
      return
    }
    const response = await api.patch(
      overriderDetails.root,
      body
    )
    setIsSavingIndex(false)
    pipe(
      response,
      E.match(
        apiError => {
          const initialPath = overriderDetails.path
            .flatMap(pathStep => {
              if (pathStep.type === 'override') {
                return ['DomainTypeOverrides', 0]
              }
              return ['Queries', 0]
            })
          const path = isDomainTypeListAttribute(settingAttribute)
            ? [...initialPath, 'DomainTypeOverrides', 0, overridableSetting, 0]
            : [...initialPath, 'DomainTypeOverrides', 0, overridableSetting]
          const responsePathError = getErrorAtPath(
            apiError.pathErrors,
            path
          )
          if (PathErrorsCodec.is(responsePathError)) {
            setPathErrors(responsePathError)
          }
        },
        modifiedInstance => {
          setOpen(false)
          const newValue = pipe(
            modifiedInstance,
            lens.getOption,
            O.map(override => override[overridableSetting] ?? null),
            O.toNullable
          )
          dispatch(editDomainTypeOverrider(overriderDetails, domainType.Id, overridableSetting, newValue))
          onSaveSuccess?.(valueToSave)
        }
      )
    )
  }, [setting, person, domainType, settingAttribute, setPathErrors, setOpen, dispatch, onSaveSuccess, valueToSave])
  const saveToDomainType = useCallback(async function saveToDomainType(
    api: SignedInApi,
    apiValue: NonNullable<EditableDomainTypeSettings[Setting]> | null
  ) {
    const response = await api.patch(
      'DomainType',
      {
        Id: domainType.Id,
        [setting]: apiValue
      }
    )
    setIsSavingIndex(false)
    if (E.isRight(response) && DomainTypeCodec.is(response.right)) {
      setOpen(false)
      dispatch(editDomainType({
        ...domainType,
        [setting]: response.right[setting]
      }))
      if (onSaveSuccess !== undefined) {
        onSaveSuccess(valueToSave)
      }
    } else if (E.isLeft(response)) {
      const path = settingAttribute !== undefined && isDomainTypeListAttribute(settingAttribute)
        ? [setting, 0]
        : [setting]
      const responsePathError = getErrorAtPath(
        response.left.pathErrors,
        path
      )
      if (PathErrorsCodec.is(responsePathError)) {
        setPathErrors(responsePathError)
      }
    }
  }, [dispatch, domainType, onSaveSuccess, setOpen, setPathErrors, setting, settingAttribute, valueToSave])
  const save = useCallback(async function save(saveLocationIndex: number) {
    const saveLocation = saveLocations[saveLocationIndex]
    if (saveLocation === undefined) {
      return
    }
    if (!api.isSignedIn) {
      return
    }
    const apiValue = getApiValue(valueToSave, mode)
    setIsSavingIndex(saveLocationIndex)
    if (saveLocation === 'domainType') {
      saveToDomainType(api, apiValue)
    } else {
      saveToOverrider(api, apiValue, saveLocation)
    }
  }, [saveLocations, api, valueToSave, mode, saveToDomainType, saveToOverrider])
  const saveAsMyGlobalPreference = useCallback(async function save() {
    const globalSetting: string = setting
    if (!isGlobalSetting(globalSetting)) {
      return
    }
    if (person === null || !api.isSignedIn) {
      return
    }
    const apiValue = getApiValue(valueToSave, mode)
    setIsSavingIndex('personGlobal')
    const response = await api.patch(
      'Person',
      {
        Id: person.Id,
        GlobalDomainTypeSettings: {
          ...person.GlobalDomainTypeSettings,
          [setting]: apiValue
        }
      }
    )
    setIsSavingIndex(false)
    if (E.isRight(response) && PersonCodec.is(response.right)) {
      setOpen(false)
      const newValue = response.right.GlobalDomainTypeSettings?.[globalSetting]
      dispatch(editPersonGlobalDomainTypeSetting(globalSetting, newValue))
      onSaveSuccess?.(valueToSave)
    } else if (E.isLeft(response)) {
      const path = settingAttribute !== undefined && isDomainTypeListAttribute(settingAttribute)
        ? ['GlobalDomainTypeSettings', globalSetting, 0]
        : ['GlobalDomainTypeSettings', globalSetting]
      const responsePathError = getErrorAtPath(
        response.left.pathErrors,
        path
      )
      if (PathErrorsCodec.is(responsePathError)) {
        setPathErrors(responsePathError)
      }
    }
  }, [setting, person, api, valueToSave, mode, setOpen, dispatch, onSaveSuccess, settingAttribute, setPathErrors])
  const action = {
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete'
  }[mode]
  const dialog = useMemo(() => (
    <Dialog
      open={open || isSavingIndex !== false}
      onClose={isSavingIndex !== false ? undefined : (() => setOpen(false))}
      onKeyDown={event => event.stopPropagation()}>
      <DialogTitle>
        <DomainTypeHeading
          domainType={domainType}
          isLoading={false}
          title={`${action} ${title}:`} />
      </DialogTitle>
      <EditBeforeSaveForm
        setting={setting}
        defaultValue={value}
        currentValue={valueToSave}
        attribute={settingAttribute}
        pathErrors={pathErrors}
        fullEdit={mode === 'edit'}
        onChange={setValueToSave}
        removeErrorAtPath={removeErrorAtPath} />
      <DialogActions>
        {saveLocations.map((saveLocation, index) => (
          <LoadingButton
            key={index}
            disabled={isSavingIndex !== false
              || (saveLocation === 'domainType' && !canEditDomainType)}
            loading={isSavingIndex === index}
            onClick={() => save(index)}>
            {saveLocation === 'domainType'
              ? 'For All Users'
              : saveLocation.type === 'person'
                ? 'For Me'
                : `For ${saveLocation.overrider.Title} Query`}
          </LoadingButton>
        ))}

      </DialogActions>
      {saveTo === undefined && isGlobalSetting(setting) && (
        <>
          <Divider />
          <DialogActions>
            <LoadingButton
              disabled={isSavingIndex !== false}
              loading={isSavingIndex === 'personGlobal'}
              onClick={saveAsMyGlobalPreference}>
              Save As My Global Preference
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  ), [action, canEditDomainType, domainType, isSavingIndex, mode, open, pathErrors, removeErrorAtPath, save, saveAsMyGlobalPreference, saveLocations, saveTo, setOpen, setting, settingAttribute, title, value, valueToSave])
  return typeof dialogOpen === 'boolean'
    ? dialog
    : (
      <span
        onClick={event => event.stopPropagation()}
        onFocus={event => event.stopPropagation()}>
        {dialog}
        <TooltipIconButton
          disabled={disabled}
          tooltipText={`${action} ${title}`}
          icon={(
            <CornerIcon
              icon='settings'
              cornerIcon={mode} />
          )}
          onClick={() => setOpen(true)} />
      </span>
    )
}