import { Box, FormControl, FormHelperText, FormLabel, ImageList, ImageListItem } from '@mui/material'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { AttachedFileHandle, PhotosElement } from 'types/dataform'
import { EntityWithAttachedFilesCodec } from 'utils/codecs/dataform'
import { blobToBase64 } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

export default function Photos({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<PhotosElement>): JSX.Element | null {
  const work = useDomainTypeContextInstance('Work', 'Work', EntityWithAttachedFilesCodec)
  const task = useDomainTypeContextInstance('Task', 'Task', EntityWithAttachedFilesCodec)
  const decodedValue: E.Either<string, (AttachedFileHandle | null)[] | null> = useMemo(() => {
    if (value === null) {
      return E.right(null)
    }
    if (work === null) {
      return E.left('No work was found from which to load the image files')
    }
    const contextEntity = {
      work,
      task
    }[element.ExtraParams.fileContext]
    if (contextEntity === null) {
      return E.left(`No ${element.ExtraParams.fileContext} was found from which to load the image files`)
    }
    if (contextEntity.AttachedFiles.length === 0) {
      return E.left(`No image files were found on the ${element.ExtraParams.fileContext}`)
    }
    const matchingFileHandles = value.map(id => {
      return contextEntity.AttachedFiles.find(fileHandle => fileHandle.Id === id) ?? null
    })
    return E.right(matchingFileHandles)
  }, [element.ExtraParams.fileContext, task, value, work])
  const photosValue = E.isRight(decodedValue)
    ? decodedValue.right
    : null
  const photosError = E.isLeft(decodedValue)
    ? decodedValue.left
    : null
  const [photos, setPhotos] = useState<(string | null)[]>([])
  const api = useApi()
  useEffect(() => {
    const fileHandles = photosValue
    const loadImage = async () => {
      if (!api.isSignedIn
        || work === null
        || fileHandles === null) {
        return
      }
      const files: (string | null)[] = []
      for (const fileHandle of fileHandles) {
        if (fileHandle === null) {
          files.push(null)
          continue
        }
        const response = await api.getFileBlob(
          'Work',
          work.Id,
          fileHandle.FileId
        )
        if (E.isLeft(response)) {
          files.push(null)
          continue
        }
        const value = await blobToBase64(response.right, 'image/jpeg')
        if (value !== null) {
          files.push(value)
        } else {
          files.push(null)
        }
      }
      setPhotos(files)
    }
    loadImage()
  }, [api, photosValue, work])
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error || photosError !== null || !readOnly}>
      <FormLabel>{element.Text}</FormLabel>
      {photos.length > 0 && (
        <Box>
          <ImageList
            variant='masonry'
            cols={6}
            gap={8}>
            {photos.map((photo, i) => (
              <ImageListItem key={i}>
                {photo === null
                  ? (
                    <img
                      alt='Not found' />
                  )
                  : (
                    <img
                      src={photo}
                      alt='' />
                  )}
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      )}
      <FormHelperText>
        {readOnly
          ? photosError ?? errorText
          : 'Photos cannot be taken on the PHALANX Portal'}
      </FormHelperText>
    </FormControl>
  )
}