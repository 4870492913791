import { debounce } from '@mui/material'
import * as E from 'fp-ts/Either'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DomainType, DomainTypeInstance, Filter } from 'types'
import { getSearchTextFilters, stringifyFilterValue } from 'utils/filters'
import { getDomainTypeSetting, getRootDomainType, getStringFields } from 'utils/helpers'
import { useApi, useCancellableApiSession } from 'utils/hooks'

interface ApiDomainTypeAutocompleteOutput {
  readonly open: boolean
  readonly setOpen: (value: boolean) => void
  readonly rootDomainType: DomainType
  readonly options: DomainTypeInstance[]
  readonly loading: boolean
  readonly searchText: string
  readonly setSearchText: (value: string) => void
}

export function useApiDomainTypeAutocomplete(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filters?: Filter[] | null,
  includeIds?: string[],
  includeAll = false,
  bypassDomainTypeFilters: string[] = []
): ApiDomainTypeAutocompleteOutput {
  const rootDomainType = getRootDomainType(domainTypes, domainType) ?? domainType
  const api = useApi()
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [options, setOptions] = useState<DomainTypeInstance[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const identifier = getDomainTypeSetting(domainTypes, domainType, 'Identifier') ?? 'Id'
  const search = useCancellableApiSession(api)
  const performSearch = useCallback(function doSearch(
    searchText: string,
    domainTypes: Partial<Record<string, DomainType>>,
    domainType: DomainType,
    rootDomainType: DomainType
  ): () => void {
    const apiSession = search.cancelPreviousAndStartNew()
    if (!apiSession.isSignedIn) {
      return search.cancel
    }
    const anyFilters = [
      ...getSearchTextFilters(searchText, getStringFields(domainTypes, [rootDomainType])),
      {
        Property: identifier,
        Operator: 'in',
        Value: stringifyFilterValue(includeIds ?? [])
      }
    ]
    apiSession.search(
      rootDomainType.Name,
      domainType.Name,
      anyFilters,
      filters ?? [],
      [],
      1,
      includeAll
        ? 10000
        : 30 + (includeIds?.length ?? 0),
      false,
      bypassDomainTypeFilters
    ).then(response => {
      if (E.isRight(response)) {
        setLoading(false)
        setOptions(response.right.results)
      }
    })
    return search.cancel
  }, [filters, identifier, includeAll, includeIds, search, bypassDomainTypeFilters])
  const debouncedPerformSearch = useMemo(() => debounce(performSearch, 300), [performSearch])
  useEffect(() => {
    if (searchText || includeIds !== undefined) {
      setLoading(true)
    }
  }, [searchText, includeIds])
  useEffect(() => {
    if (!loading) {
      return
    }
    return debouncedPerformSearch(
      searchText,
      domainTypes,
      domainType,
      rootDomainType
    )
  }, [debouncedPerformSearch, domainType, domainTypes, loading, rootDomainType, searchText])
  return {
    open,
    setOpen,
    rootDomainType,
    options,
    loading,
    searchText,
    setSearchText
  }
}