import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material'
import { DomainTypeCell } from 'components/attribute/AttributeCell'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { ApiError, DomainTypeInstance, NonListDomainTypeAttributeValue } from 'types'
import { EquipmentElement } from 'types/dataform'
import { isNullOrUndefined } from 'utils/helpers'
import { useApi, useDomainType } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

export default function Equipment({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<EquipmentElement>): JSX.Element | null {
  const equipmentDomainType = useDomainType('Equipment', null)
  const api = useApi()
  const [equipment, setEquipment] = useState<DomainTypeInstance | null>(null)
  const [equipmentLoadError, setEquipmentLoadError] = useState<string | null>(null)
  useEffect(() => {
    const loadEquipment = async () => {
      if (!api.isSignedIn
        || isNullOrUndefined(value)) {
        return
      }
      let response: E.Either<ApiError, DomainTypeInstance>
      if (value.IsSerialised) {
        response = await api.get(
          'SerialisedEquipment',
          value.SerialisedEquipment
        )
      } else {
        response = await api.get(
          'EquipmentType',
          value.EquipmentType
        )
      }
      if (E.isLeft(response)) {
        setEquipmentLoadError('Failed to load equipment')
        return
      }
      setEquipmentLoadError(null)
      setEquipment(value.IsSerialised
        ? {
          IsSerialised: value.IsSerialised,
          SerialisedEquipment: response.right,
          Quantity: value.Quantity
        }
        : {
          IsSerialised: value.IsSerialised,
          EquipmentType: response.right,
          Quantity: value.Quantity
        }
      )
    }
    loadEquipment()
  }, [api, value])
  const attributeValue = useMemo<NonListDomainTypeAttributeValue | null>(() => {
    return isNullOrUndefined(equipmentDomainType)
      ? null
      : {
        attribute: {
          Name: element.Alias,
          Title: element.Text,
          AttributeType: 'domainType',
          AttributeDomainType: equipmentDomainType.Id
        },
        value: equipment
      }
  }, [element.Alias, element.Text, equipmentDomainType, equipment])
  return (
    <FormControl
      required={required}
      disabled={locked}
      error={!isNullOrUndefined(equipmentLoadError) || error || !readOnly}>
      <FormLabel>{element.Text}</FormLabel>
      {!isNullOrUndefined(attributeValue) && (
        <Box>
          <DomainTypeCell attributeValue={attributeValue} />
        </Box>
      )}
      <FormHelperText>
        {readOnly
          ? equipmentLoadError ?? errorText
          : 'Equipment cannot be captured on the PHALANX Portal'}
      </FormHelperText>
    </FormControl>
  )
}