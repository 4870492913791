import { FindPagination } from 'components/pages/FindPage'
import { memo, useContext } from 'react'
import TableViewContext from './TableViewContext'

export default memo(function CustomPagination(): JSX.Element {
  const { page, total, pageSize, rowCount, isLoading, setPage, setPageSize } = useContext(TableViewContext)
  return (
    <FindPagination
      page={page}
      total={total}
      pageSize={pageSize}
      rowCount={rowCount}
      isLoading={isLoading}
      setPage={setPage}
      setPageSize={setPageSize} />
  )
})