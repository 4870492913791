import { Alert, AlertTitle, Box, Paper, Stack } from '@mui/material'
import { ApiError } from 'types'

interface Props {
  apiError: ApiError
}

function toText(apiError: ApiError): string {
  if (apiError.status === undefined) {
    return 'Something went wrong'
  }
  switch (apiError.status) {
    case 404:
      return 'Not found'
    case 500:
      return 'Server error'
    default:
      return `Error status ${apiError.status}`
  }
}

export default function ApiErrorView({
  apiError
}: Props): JSX.Element {
  return (
    <Stack
      direction='column'
      spacing={1}
      alignItems='center'
      justifyContent='center'
      sx={{
        width: '100%',
        height: '100%'
      }}>
      <Box flexGrow={1} />
      <Paper>
        <Alert
          severity='error'
          variant='filled'>
          <AlertTitle>Error</AlertTitle>
          {toText(apiError)}
        </Alert>
      </Paper>
      <Box flexGrow={2} />
    </Stack>
  )
}