import { ChangeThemeAction, CHANGE_THEME, SetMenuSectionOpenAction, SET_MENU_SECTION_OPEN, ToggleSideMenuExpandedAction, TOGGLE_SIDE_MENU_EXPANDED } from 'types'

export function changeTheme(darkMode: boolean): ChangeThemeAction {
  return {
    type: CHANGE_THEME,
    payload: {
      theme: darkMode ? 'dark' : 'light'
    }
  }
}

export function toggleSideMenuExpanded(): ToggleSideMenuExpandedAction {
  return {
    type: TOGGLE_SIDE_MENU_EXPANDED
  }
}

export function setMenuSectionOpen(
  id: string | null,
  open: boolean
): SetMenuSectionOpenAction {
  return {
    type: SET_MENU_SECTION_OPEN,
    payload: {
      id,
      open
    }
  }
}