import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { push } from 'redux-first-history'
import { getAllDomainTypes } from 'state/reducers'
import { DomainType, DomainTypeInstance } from 'types'
import { PAGE_URL } from 'utils/constants'
import { getDomainTypeSetting, getRootDomainType } from 'utils/helpers'

interface UseNavigateOutput {
  toDetailsPage(
    domainType: DomainType,
    instance: DomainTypeInstance,
    newTab?: boolean
  ): void
  toFindPage(
    domainType: DomainType,
    newTab?: boolean
  ): void
}

export function useNavigate(): UseNavigateOutput {
  const globalDispatch = useDispatch()
  const domainTypes = useSelector(getAllDomainTypes)

  const toDetailsPage = useCallback((
    domainType: DomainType,
    instance: DomainTypeInstance,
    newTab: boolean | undefined = false
  ) => {
    const rootDomainType = getRootDomainType(domainTypes, domainType)
    if (rootDomainType === null) {
      return
    }
    const identifier = getDomainTypeSetting(domainTypes, rootDomainType, 'Identifier') ?? 'Id'
    const pathname = generatePath(
      PAGE_URL.DETAILS,
      {
        databaseTable: rootDomainType.DatabaseTable ?? rootDomainType.Name,
        name: rootDomainType.Name,
        id: String(instance[identifier])
      }
    )
    if (newTab) {
      window.open(pathname, '_blank')
      return
    }
    globalDispatch(
      push(
        pathname,
        {
          instance
        }
      )
    )
  }, [domainTypes, globalDispatch])

  const toFindPage = useCallback((
    domainType: DomainType,
    newTab: boolean | undefined = false
  ) => {
    const rootDomainType = getRootDomainType(domainTypes, domainType)
    if (rootDomainType === null) {
      return
    }
    const pathname = generatePath(
      PAGE_URL.FIND,
      {
        databaseTable: domainType.DatabaseTable ?? rootDomainType.Name,
        name: domainType.Name
      }
    )
    if (newTab) {
      window.open(pathname, '_blank')
      return
    }
    globalDispatch(push(pathname))
  }, [domainTypes, globalDispatch])

  return useMemo(() => ({
    toDetailsPage,
    toFindPage
  }), [toDetailsPage, toFindPage])
}
