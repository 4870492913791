import { Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { TOOLTIP_DELAY } from 'utils/constants'

interface Props {
  readonly value: string
  readonly columnWidth?: number
}

export default function CellTooltip({
  value,
  columnWidth
}: Props): JSX.Element {
  const ref = useRef<HTMLSpanElement>(null)
  const [tooltip, setTooltip] = useState('')
  useEffect(() => {
    if (ref.current) {
      if (ref.current.scrollWidth > ref.current.clientWidth) {
        setTooltip(value)
      } else {
        setTooltip('')
      }
    }
  }, [ref, value, columnWidth])
  return (
    <Tooltip
      title={tooltip}
      enterDelay={TOOLTIP_DELAY}
      enterNextDelay={TOOLTIP_DELAY}>
      <span
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        ref={ref}>
        {value}
      </span>
    </Tooltip>
  )
}
