import { useContext, useMemo } from 'react'
import { DomainType, Query } from 'types'
import { DomainTypeOverriderContext } from 'utils/context'
import { OverriderDetails, QueryOverriderDetails } from 'utils/context/DomainTypeOverriderContext'
import { useDefaultSorts } from './useDefaultSorts'

interface UseQueriesOutput {
  domainTypeQueries: Query[]
  domainTypeQueriesWithAll: Query[]
  overriderQueries: [Query, OverriderDetails][]
  overriderDetails: QueryOverriderDetails | null
  currentQuery: Query
}

export const defaultAllQuery: Query = {
  Id: 'all',
  Title: 'All',
  Filters: [],
  Sorts: [],
  SearchText: '',
  FilterLinkOperator: 'and'
}

export function useQueries(
  domainType: DomainType,
  queryId = 'all'
): UseQueriesOutput {
  const overriderContext = useContext(DomainTypeOverriderContext)
  const domainTypeQueries = useMemo(() => {
    return domainType.Queries ?? []
  }, [domainType.Queries])
  const overriderQueriesWithDetails = useMemo(() => {
    return overriderContext
      .flatMap(details => {
        const queries = details.overrider.DomainTypeOverrides
          ?.find(o => o.Id === domainType.Id)?.Queries ?? []
        return queries.map<[Query, OverriderDetails]>(query => [query, details])
      })
  }, [domainType.Id, overriderContext])
  const defaultSorts = useDefaultSorts(domainType)
  const allQuery = useMemo(() => ({
    ...defaultAllQuery,
    Sorts: defaultSorts
  }), [defaultSorts])
  const domainTypeQueriesWithAll = useMemo(() => {
    return [
      allQuery
    ]
      .concat(domainTypeQueries)
  }, [allQuery, domainTypeQueries])
  const overriderDetails = useMemo<QueryOverriderDetails | null>(() => {
    if (queryId === 'all') {
      return null
    }
    const domainTypeQuery = domainTypeQueries
      .find(query => query.Id === queryId)
    if (domainTypeQuery !== undefined) {
      return {
        overrider: domainTypeQuery,
        type: 'query',
        root: 'domainType',
        id: domainType.Id,
        path: [
          {
            type: 'query',
            id: queryId
          }
        ]
      }
    }
    const overriderQueryWithDetails = overriderQueriesWithDetails
      .slice()
      .reverse()
      .find(([query]) => query.Id === queryId)
    if (overriderQueryWithDetails !== undefined) {
      const [overriderQuery, parentOverriderDetails] = overriderQueryWithDetails
      if (parentOverriderDetails.root === 'domainType') {
        return {
          overrider: overriderQuery,
          type: 'query',
          root: parentOverriderDetails.root,
          id: parentOverriderDetails.id,
          path: [
            ...parentOverriderDetails.path,
            {
              type: 'override',
              id: domainType.Id
            },
            {
              type: 'query',
              id: queryId
            }
          ]
        }
      }
      return {
        overrider: overriderQuery,
        type: 'query',
        root: 'person',
        path: [
          ...parentOverriderDetails.path,
          {
            type: 'override',
            id: domainType.Id
          },
          {
            type: 'query',
            id: queryId
          }
        ]
      }
    }
    return null
  }, [domainType.Id, domainTypeQueries, overriderQueriesWithDetails, queryId])
  return {
    domainTypeQueries,
    domainTypeQueriesWithAll,
    overriderQueries: overriderQueriesWithDetails,
    overriderDetails,
    currentQuery: overriderDetails?.overrider ?? allQuery
  }
}