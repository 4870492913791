import { Theme, ThemeProvider, useTheme } from '@mui/material'
import { PropsWithChildren } from 'react'
import { DomainType } from 'types'
import { useDomainTypeColour } from 'utils/hooks'

interface Props {
  readonly domainType: DomainType | null
}

export default function DomainTypeTheme({
  domainType,
  children
}: PropsWithChildren<Props>): JSX.Element {
  const color = useDomainTypeColour(domainType)
  const theme = useTheme()
  const newTheme: Theme = {
    ...theme,
    palette: {
      ...theme.palette,
      primary: theme.palette.augmentColor({
        color: {
          main: color
        }
      })
    }
  }
  return (
    <ThemeProvider
      theme={newTheme}>
      {children}
    </ThemeProvider>
  )
}
