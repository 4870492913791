import { Action, EDIT_DOMAIN_TYPE_OVERRIDER, EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION, FETCH_PERSON_FULFILLED, Person } from 'types'
import { makeOverrideLens } from 'utils/helpers'

export default function person(state: Person | null = null, action: Action): Person | null {
  switch (action.type) {
    case FETCH_PERSON_FULFILLED:
      return action.payload.person
    case EDIT_PERSON_GLOBAL_DOMAIN_TYPE_SETTING_ACTION:
      if (state === null) {
        return state
      }
      return {
        ...state,
        GlobalDomainTypeSettings: {
          ...state.GlobalDomainTypeSettings,
          [action.payload.setting]: action.payload.value
        }
      }
    case EDIT_DOMAIN_TYPE_OVERRIDER: {
      if (state === null) {
        return state
      }
      if (action.payload.overriderDetails.root !== 'person') {
        return state
      }
      const lens = makeOverrideLens(action.payload.overriderDetails.path, action.payload.domainTypeId)
      return {
        ...state,
        ...lens.modify(previous => ({
          ...previous,
          [action.payload.setting]: action.payload.value
        }))(state) ?? state
      }
    }
    default:
      return state
  }
}

export function getPerson(state: Person | null): Person | null {
  return state
}