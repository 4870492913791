import { Checkbox as MaterialCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import { useEffect } from 'react'
import { CheckboxElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'

export default function Checkbox({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  expectedValue,
  error,
  errorText
}: ElementProps<CheckboxElement>): JSX.Element | null {
  useEffect(() => {
    if (value === null) {
      onChange(false)
    }
  }, [onChange, value])
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={(expectedValue?.test('false') ?? false) || error}>
      <FormControlLabel
        control={(
          <MaterialCheckbox
            size='small'
            checked={value ?? false}
            readOnly={readOnly}
            disabled={locked}
            onChange={readOnly || locked
              ? undefined
              : ((event, checked) => onChange(checked))} />
        )}
        label={element.Text} />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}