import { CircularProgress } from '@mui/material'
import * as E from 'fp-ts/Either'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { DomainTypeInstance, NonListRefAttributeValue } from 'types'
import { getRootDomainType } from 'utils/helpers'
import { useApi, useCancellableApiSession } from 'utils/hooks'
import { NarrowedProps } from './AttributeCell'
import DomainTypeCell from './DomainTypeCell'

export default function RefCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListRefAttributeValue>): JSX.Element | null {
  const [state, setState] = useState<'new' | 'isLoading' | 'error' | DomainTypeInstance>('new')
  const domainTypes = useSelector(getAllDomainTypes)
  const api = useApi()
  const load = useCancellableApiSession(api)
  const domainType = domainTypes[attributeValue.attribute.AttributeDomainType]
  useEffect(() => {
    if (typeof state !== 'string') {
      return
    }
    const apiSession = load.cancelPreviousAndStartNew()
    async function loadInstance() {
      setState('isLoading')
      if (!domainType
        || attributeValue.value === null
        || !apiSession.isSignedIn) {
        setState('error')
      } else {
        const rootDomainType = getRootDomainType(domainTypes, domainType)
        if (rootDomainType === null) {
          setState('error')
          return
        }
        const response = await apiSession.get(
          rootDomainType.Name,
          attributeValue.value
        )
        if (E.isRight(response)) {
          setState(response.right)
        } else {
          setState('error')
        }
      }
    }
    loadInstance()
    return load.cancel
  }, [domainType, domainTypes, attributeValue.value, load, state])
  switch (state) {
    case 'new':
    case 'isLoading':
      return (
        <CircularProgress size='1rem' />
      )
    case 'error':
      return null
    default:
      return (
        <DomainTypeCell
          attributeValue={{
            attribute: {
              ...attributeValue.attribute,
              AttributeType: 'domainType'
            },
            value: state
          }}
          columnWidth={columnWidth} />
      )
  }
}
