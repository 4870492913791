import { Chip, Divider, Paper, Typography } from '@mui/material'
import { StatementElement } from 'types/dataform'
import { ElementProps } from '../ElementInput'

export default function Statement({
  dataform,
  group,
  element,
  readOnly = false
}: ElementProps<StatementElement>): JSX.Element | null {
  if (element.ExtraParams?.appearance === 'subheading') {
    return (
      <Divider>
        <Chip label={element.Text} />
      </Divider>
    )
  }
  return (
    <Paper
      sx={{ p: 1 }}
      variant='outlined'>
      <Typography variant='body1'>
        {element.Text}
      </Typography>
    </Paper>
  )
}