import { AttributeValue, PathError } from 'types'
import { renderAttributeTypeComponent } from 'utils/helpers'
import BoolInput from './BoolInput'
import ContextRefInput from './ContextRefInput'
import DateInput from './DateInput'
import DateTimeInput from './DateTimeInput'
import EnumInput from './EnumInput'
import GuidInput from './GuidInput'
import NumberInput from './NumberInput'
import RefInput from './RefInput'
import DataformResultsInput from './DataformResultsInput'
import StringInput from './StringInput'
import MultiDataformResultsInput from './MultiDataformResultsInput'
import DomainTypeInput from './DomainTypeInput/DomainTypeInput'

interface Props {
  readonly readOnly: boolean
  readonly attributeValue: AttributeValue
  readonly pathError?: PathError
  readonly disabled?: boolean
  onChange?(attributeValue: AttributeValue): void
}

export interface NarrowedProps<A extends AttributeValue> {
  readonly readOnly: boolean
  readonly attributeValue: A
  readonly pathError?: PathError
  readonly disabled?: boolean
  onChange?(attributeValue: A): void
}

const inputTypes = {
  ref: RefInput,
  contextRef: ContextRefInput,
  domainType: DomainTypeInput,
  enum: EnumInput,
  date: DateInput,
  dateTime: DateTimeInput,
  number: NumberInput,
  guid: GuidInput,
  bool: BoolInput,
  string: StringInput,
  dataformResults: DataformResultsInput,
  multiDataformResults: MultiDataformResultsInput
}

export default function AttributeInput(props: Props): JSX.Element | null {
  const [isValid, component] = renderAttributeTypeComponent(inputTypes, props)
  if (isValid) {
    return component
  }
  return null
}
