import { createContext } from 'react'
import { Filter } from 'types'
import { FiltersAction } from '../filtersReducer'

interface Context {
  filters: Filter[]
  dispatchFiltersAction(action: FiltersAction): void
}

export default createContext<Context>({
  filters: [],
  dispatchFiltersAction: action => null
})