import { Filter, SearchResponse, Sort } from 'types'
import { ActionDetails } from 'utils/hooks/useActions'
import { CalendarProps, FindPageView } from './types'

interface SearchInputs {
  readonly page: number
  readonly pageSize: number
  readonly searchText: string
  readonly sorts: Sort[]
  readonly filterLinkOperator: 'and' | 'or'
  readonly filters: Filter[]
  readonly bypassSearchIndex: boolean
  readonly bypassDomainTypeFilters: string[]
}

interface State {
  readonly isLoading: boolean
  readonly searchResponse: SearchResponse | null
  readonly shouldReload: boolean
  readonly editingItem: CalendarProps['editingItem']
  readonly actionDetails: ActionDetails | undefined
  readonly actionDialogOpen: boolean
  readonly changeItemDatesErrorCode: string | undefined
  readonly wasDisplayingCalendarView: boolean
  readonly searchInputs: SearchInputs
}

const PERFORM_SEARCH = 'PERFORM_SEARCH'
interface PerformSearchAction {
  readonly type: typeof PERFORM_SEARCH
  readonly payload: {
    readonly searchInputs: SearchInputs
  }
}
export const performSearch = (searchInputs: SearchInputs): PerformSearchAction => ({
  type: PERFORM_SEARCH,
  payload: {
    searchInputs
  }
})

const PERFORM_SEARCH_FULFILLED = 'PERFORM_SEARCH_FULFILLED'
interface PerformSearchFulfilledAction {
  readonly type: typeof PERFORM_SEARCH_FULFILLED
  readonly payload: {
    readonly searchResponse: SearchResponse
    readonly wasDisplayingCalendarView: boolean
  }
}
export const performSearchFulfilled = (
  searchResponse: SearchResponse,
  wasDisplayingCalendarView: boolean
): PerformSearchFulfilledAction => ({
  type: PERFORM_SEARCH_FULFILLED,
  payload: {
    searchResponse,
    wasDisplayingCalendarView
  }
})

const PERFORM_CHANGE_ITEM_DATES = 'PERFORM_CHANGE_ITEM_DATES'
interface PerformChangeItemDatesAction {
  readonly type: typeof PERFORM_CHANGE_ITEM_DATES
  readonly payload: {
    readonly editingItem: NonNullable<State['editingItem']>
    readonly actionDetails: ActionDetails | undefined
  }
}
export const performChangeItemDates = (
  editingItem: NonNullable<State['editingItem']>,
  actionDetails: ActionDetails | undefined
): PerformChangeItemDatesAction => ({
  type: PERFORM_CHANGE_ITEM_DATES,
  payload: {
    editingItem,
    actionDetails
  }
})

const PERFORM_CHANGE_ITEM_DATES_FULFILLED = 'PERFORM_CHANGE_ITEM_DATES_FULFILLED'
interface PerformChangeItemDatesFulfilledAction {
  readonly type: typeof PERFORM_CHANGE_ITEM_DATES_FULFILLED

}
export const performChangeItemDatesFulfilled = (): PerformChangeItemDatesFulfilledAction => ({
  type: PERFORM_CHANGE_ITEM_DATES_FULFILLED
})

const VIEW_CHANGED = 'VIEW_CHANGED'
interface ViewChangedAction {
  readonly type: typeof VIEW_CHANGED
  readonly payload: {
    readonly from: FindPageView
    readonly to: FindPageView
  }
}

export const viewChanged = (from: FindPageView, to: FindPageView): ViewChangedAction => ({
  type: VIEW_CHANGED,
  payload: {
    from: from,
    to: to
  }
})

const RELOAD = 'RELOAD'
interface ReloadAction {
  readonly type: typeof RELOAD
}

export function reload(): ReloadAction {
  return {
    type: RELOAD
  }
}

const CLOSE_ACTION_DIALOG = 'CLOSE_ACTION_DIALOG'
interface CloseActionDialogAction {
  readonly type: typeof CLOSE_ACTION_DIALOG
}

export function closeActionDialog(): CloseActionDialogAction {
  return {
    type: CLOSE_ACTION_DIALOG
  }
}

const PERFORM_ACTION = 'PERFORM_ACTION'
interface PerformActionAction {
  readonly type: typeof PERFORM_ACTION
}

export function performAction(): PerformActionAction {
  return {
    type: PERFORM_ACTION
  }
}

const CHANGE_ITEM_DATES_ERROR = 'CHANGE_ITEM_DATES_ERROR'
interface ChangeItemDatesErrorAction {
  readonly type: typeof CHANGE_ITEM_DATES_ERROR
  readonly payload: {
    readonly errorCode: string
  }
}

export function changeItemDatesError(errorCode: string): ChangeItemDatesErrorAction {
  return {
    type: CHANGE_ITEM_DATES_ERROR,
    payload: {
      errorCode
    }
  }
}

export type FindPageAction =
  | PerformSearchAction
  | PerformSearchFulfilledAction
  | PerformChangeItemDatesAction
  | PerformChangeItemDatesFulfilledAction
  | ViewChangedAction
  | ReloadAction
  | CloseActionDialogAction
  | PerformActionAction
  | ChangeItemDatesErrorAction

export const defaultState: State = {
  isLoading: true,
  searchResponse: null,
  shouldReload: false,
  editingItem: null,
  actionDetails: undefined,
  actionDialogOpen: false,
  changeItemDatesErrorCode: undefined,
  wasDisplayingCalendarView: false,
  searchInputs: {
    page: 1,
    pageSize: 15,
    searchText: '',
    sorts: [],
    filters: [],
    filterLinkOperator: 'and',
    bypassSearchIndex: false,
    bypassDomainTypeFilters: []
  }
}

const calendarViews = ['calendar', 'timeline']

export default function findPageReducer(state: State, action: FindPageAction): State {
  switch (action.type) {
    case PERFORM_SEARCH:
      return {
        ...state,
        isLoading: true,
        shouldReload: false,
        searchInputs: action.payload.searchInputs
      }
    case PERFORM_SEARCH_FULFILLED:
      return {
        ...state,
        isLoading: false,
        searchResponse: action.payload.searchResponse,
        editingItem: null,
        actionDetails: undefined,
        wasDisplayingCalendarView: action.payload.wasDisplayingCalendarView
      }
    case PERFORM_CHANGE_ITEM_DATES:
      return {
        ...state,
        editingItem: action.payload.editingItem,
        actionDetails: action.payload.actionDetails,
        actionDialogOpen: action.payload.actionDetails !== undefined,
        changeItemDatesErrorCode: undefined
      }
    case VIEW_CHANGED: {
      const shouldReload = calendarViews.includes(action.payload.from) !== calendarViews.includes(action.payload.to)
      return {
        ...state,
        isLoading: shouldReload,
        searchResponse: shouldReload
          ? null
          : state.searchResponse
      }
    }
    case RELOAD:
      return {
        ...state,
        shouldReload: true
      }
    case CLOSE_ACTION_DIALOG:
      return {
        ...state,
        actionDialogOpen: false,
        editingItem: null
      }
    case PERFORM_ACTION:
      return {
        ...state,
        actionDialogOpen: false
      }
    case CHANGE_ITEM_DATES_ERROR:
      return {
        ...state,
        editingItem: null,
        changeItemDatesErrorCode: action.payload.errorCode
      }
    default:
      return state
  }
}
