import { List, SaveOutlined, Tab } from '@mui/icons-material'
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material'
import SaveDomainTypeSettingButton from 'components/domainType/SaveDomainTypeSettingButton'
import { useState } from 'react'
import { DomainType } from 'types'
import { DetailsPageView } from './detailsPageReducer'

interface Props {
  readonly domainType: DomainType
  readonly view: DetailsPageView
  onChangeView?(view: DetailsPageView): void
  onClose(): void
}

export default function DetailsViewPanel({
  domainType,
  view,
  onChangeView,
  onClose
}: Props): JSX.Element {
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  return (
    <>
      <MenuList>
        {view !== 'expandableList' && (
          <MenuItem
            onClick={() => {
              onChangeView?.('expandableList')
              onClose()
            }}>
            <ListItemIcon>
              <List />
            </ListItemIcon>
            <ListItemText>Switch To Expandable List View</ListItemText>
          </MenuItem>
        )}
        {view !== 'tabs' && (
          <MenuItem
            onClick={() => {
              onChangeView?.('tabs')
              onClose()
            }}>
            <ListItemIcon>
              <Tab />
            </ListItemIcon>
            <ListItemText>Switch To Tabs View</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => setSaveDialogOpen(true)}>
          <ListItemIcon>
            <SaveOutlined />
          </ListItemIcon>
          <ListItemText>Save View</ListItemText>
        </MenuItem>
      </MenuList>
      <SaveDomainTypeSettingButton
        domainType={domainType}
        setting='View'
        value={view}
        dialogOpen={saveDialogOpen}
        onChangeDialogOpen={value => {
          setSaveDialogOpen(value)
          if (!value) {
            onClose()
          }
        }} />
    </>
  )
}