import { ContextType, useContext, useMemo } from 'react'
import { DomainTypeContext } from 'utils/context'

export function useDomainTypeContextWithoutOnInvalidate(): Omit<ContextType<typeof DomainTypeContext>, 'onInvalidate'> {
  const {
    instances: contextInstances,
    attributes,
    batchInstances
  } = useContext(DomainTypeContext)
  return useMemo(() => ({
    instances: contextInstances,
    attributes,
    batchInstances
  }), [attributes, batchInstances, contextInstances])
}