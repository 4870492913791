import { Action, Company, FETCH_COMPANY_FULFILLED } from 'types'

export default function company(state: Company | null = null, action: Action): Company | null {
  switch (action.type) {
    case FETCH_COMPANY_FULFILLED:
      return action.payload.company
    default:
      return state
  }
}

export function getCompany(state: Company | null): Company | null {
  return state
}