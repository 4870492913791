import { NonListDateTimeAttributeValue } from 'types'
import { dateTimeToString } from 'utils/helpers'
import CellTooltip from './CellTooltip'
import { NarrowedProps } from './AttributeCell'

export default function DateTimeCell({
  attributeValue,
  columnWidth
}: NarrowedProps<NonListDateTimeAttributeValue>): JSX.Element | null {
  const value = dateTimeToString(attributeValue.value)
  if (value === null) {
    return null
  }
  return (
    <CellTooltip
      value={value}
      columnWidth={columnWidth} />
  )
}
