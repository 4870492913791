import { GuidAttributeValue } from 'types'
import { InputAdornment, TextField } from '@mui/material'
import { NarrowedProps } from './AttributeInput'
import GuidCell from '../AttributeCell/GuidCell'
import { isListAttributeValue, toErrorText } from 'utils/helpers'
import NotSupportedInput from './NotSupportedInput'
import { useEffect } from 'react'
import { v4 } from 'uuid'

export default function GuidInput({
  attributeValue,
  readOnly,
  disabled,
  pathError,
  onChange
}: NarrowedProps<GuidAttributeValue>): JSX.Element | null {
  useEffect(() => {
    if (readOnly
      || isListAttributeValue(attributeValue)
      || attributeValue.value !== null
      || onChange === undefined) {
      return
    }
    onChange({
      attribute: attributeValue.attribute,
      value: v4()
    })
  })
  if (isListAttributeValue(attributeValue)) {
    return (
      <NotSupportedInput attributeValue={attributeValue} />
    )
  }
  if (!readOnly) {
    return null
  }
  return (
    <TextField
      value={attributeValue.value ?? ''}
      label={attributeValue.attribute.Title}
      id={attributeValue.attribute.Name}
      variant='standard'
      fullWidth
      size='small'
      disabled={disabled}
      required={attributeValue.attribute.Required ?? false}
      error={pathError !== undefined}
      helperText={toErrorText(pathError)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <GuidCell
              attributeValue={attributeValue} />
          </InputAdornment>
        ),
        readOnly
      }} />
  )
}
