import { Box, FormControl, FormHelperText, FormLabel } from '@mui/material'
import * as E from 'fp-ts/Either'
import { useEffect, useMemo, useState } from 'react'
import { AttachedFileHandle, MarkupElement } from 'types/dataform'
import { EntityWithAttachedFilesCodec } from 'utils/codecs/dataform'
import { blobToBase64, isNullOrUndefined } from 'utils/helpers'
import { useApi, useDomainTypeContextInstance } from 'utils/hooks'
import { ElementProps } from '../ElementInput'

export default function Markup({
  dataform,
  group,
  element,
  value,
  onChange,
  required,
  readOnly,
  locked,
  error,
  errorText
}: ElementProps<MarkupElement>): JSX.Element | null {
  const work = useDomainTypeContextInstance('Work', 'Work', EntityWithAttachedFilesCodec)
  const task = useDomainTypeContextInstance('Task', 'Task', EntityWithAttachedFilesCodec)
  const decodedValue: E.Either<string, AttachedFileHandle | null> = useMemo(() => {
    if (value === null) {
      return E.right(null)
    }
    if (work === null) {
      return E.left('No work was found from which to load the marked-up image file')
    }
    const contextEntity = {
      work,
      task
    }[element.ExtraParams.fileContext]
    if (contextEntity === null) {
      return E.left(`No ${element.ExtraParams.fileContext} was found from which to load the marked-up image file`)
    }
    const matchingFileHandle = contextEntity.AttachedFiles
      .find(fileHandle => fileHandle.Id === value)
    if (isNullOrUndefined(matchingFileHandle)) {
      return E.left(`No marked-up image file was found on the ${element.ExtraParams.fileContext}`)
    }
    return E.right(matchingFileHandle)
  }, [element.ExtraParams.fileContext, task, value, work])
  const markupValue = E.isRight(decodedValue)
    ? decodedValue.right
    : null
  const markupError = E.isLeft(decodedValue)
    ? decodedValue.left
    : null
  const [markedUpImage, setMarkedUpImage] = useState<string | null>(null)
  const api = useApi()
  useEffect(() => {
    const loadImage = async () => {
      if (!api.isSignedIn
        || work === null
        || markupValue === null) {
        return
      }
      const response = await api.getFileBlob(
        'Work',
        work.Id,
        markupValue.FileId
      )
      if (E.isLeft(response)) {
        return
      }
      const value = await blobToBase64(response.right, 'image/jpeg')
      if (value !== null) {
        setMarkedUpImage(value)
      } else {
        setMarkedUpImage(null)
      }
    }
    loadImage()
  }, [api, markupValue, work])
  return (
    <FormControl
      required={required}
      fullWidth
      disabled={locked}
      error={error || markupError !== null || !readOnly}>
      <FormLabel>{element.Text}</FormLabel>
      {markedUpImage !== null && (
        <Box>
          <img
            style={{ maxHeight: '50vh' }}
            alt=''
            src={markedUpImage} />
        </Box>
      )}
      <FormHelperText>
        {readOnly
          ? markupError ?? errorText
          : 'Markup cannot be performed on the PHALANX Portal'}
      </FormHelperText>
    </FormControl>
  )
}