import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getAllDomainTypes } from 'state/reducers'
import { ActionDomainTypeButton, AttributeValue, ButtonLocation, ContextAttributeNode, ContextTree, DomainType, DomainTypeAction, DomainTypeAttribute, DomainTypeButton, DomainTypeInstance, ListAttribute } from 'types'
import { PATH_SEPARATOR } from 'utils/constants'
import { applyAllFilters, applyContextFilters, FilterContext } from 'utils/filters'
import { getDomainTypeAttributes, getDomainTypeButtons, isDomainTypeListAttribute } from 'utils/helpers'
import { ActionDetails, useActions } from './useActions'
import { useFilterContext } from './useFilterContext'

interface BaseButton {
  readonly domainType: DomainType
  readonly disabled: boolean
  readonly visible: boolean
  readonly showOn?: string[] | null
  readonly role: string | null
  readonly name: string
  readonly priority?: 'high' | 'medium' | 'low' | null
}

export interface ActionButton extends BaseButton {
  readonly type: 'action'
  readonly apiDomainType: DomainType
  readonly pageDomainType: DomainType
  readonly icon: string
  readonly action: DomainTypeAction
  readonly contextTree: ContextTree
  readonly parameterValues?: AttributeValue[]
}

export interface EditButton extends BaseButton {
  readonly type: 'edit'
}

export interface CreateButton extends BaseButton {
  readonly type: 'create'
}

export interface DeleteButton extends BaseButton {
  readonly type: 'delete'
}

export type Button =
  | ActionButton
  | EditButton
  | CreateButton
  | DeleteButton

const OTHER_BUTTON_TYPES: Record<Exclude<DomainTypeButton['Type'], 'ActionButton'>, Exclude<Button['type'], 'action'>> = {
  CreateButton: 'create',
  EditButton: 'edit',
  DeleteButton: 'delete'
}

function getAttributeChain(
  node?: ContextAttributeNode
): ListAttribute<DomainTypeAttribute>[] {
  if (node === undefined) {
    return []
  }
  return (
    node.type === 'context'
      ? []
      : [node.attribute]
  ).concat(getAttributeChain(node.nodes[0]?.nodes[0]))
}

export function getActionButton(
  domainTypes: Partial<Record<string, DomainType>>,
  details: ActionDetails,
  parameterValues?: AttributeValue[]
): ActionButton | null {
  const correspondingActionButton = getDomainTypeButtons(domainTypes, details.actionDomainType)
    .map(([domainType, button]) => button)
    .filter((button): button is ActionDomainTypeButton => button.Type === 'ActionButton')
    .find(button => button.Action === details.action.Name)
  if (correspondingActionButton === undefined) {
    return null
  }
  const attributeChain = getAttributeChain(details.unfilteredContextTree[0]?.nodes[0])
  return {
    type: 'action',
    domainType: details.actionDomainType,
    disabled: details.disabled,
    visible: details.visible,
    showOn: correspondingActionButton.ShowOn,
    role: correspondingActionButton.Role,
    name: attributeChain
      .map(attribute => attribute.Title)
      .concat(correspondingActionButton.Name)
      .join(PATH_SEPARATOR),
    apiDomainType: details.apiDomainType,
    pageDomainType: details.pageDomainType,
    icon: correspondingActionButton.Icon,
    action: details.action,
    contextTree: details.contextTree,
    parameterValues,
    priority: correspondingActionButton.Priority === 'medium'
      ? attributeChain.length === 0
        ? 'medium'
        : 'low'
      : correspondingActionButton.Priority
  }
}

export function isDisabled(
  button: DomainTypeButton,
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  filterContext: FilterContext,
  instances: DomainTypeInstance[]
): boolean {
  return !applyContextFilters(
    domainTypes,
    domainType,
    button.Conditions ?? [],
    filterContext
  ) || !instances.every(instance => {
    return applyAllFilters(
      domainTypes,
      domainType,
      instance,
      button.Conditions ?? [],
      filterContext
    )
  })
}

function getButtons(
  domainTypes: Partial<Record<string, DomainType>>,
  domainType: DomainType,
  instances: DomainTypeInstance[],
  actions: ActionDetails[],
  filterContext: FilterContext,
  parameterValues?: AttributeValue[]
): Button[] {
  const actionButtons = actions.flatMap<ActionButton>(details => {
    const actionButton = getActionButton(
      domainTypes,
      details,
      parameterValues
    )
    if (actionButton === null) {
      return []
    }
    return [actionButton]
  })
  const otherButtons = getDomainTypeButtons(domainTypes, domainType).flatMap<Button>(([domainType, button]) => {
    const disabled = isDisabled(
      button,
      domainTypes,
      domainType,
      filterContext,
      instances
    )
    switch (button.Type) {
      case 'ActionButton':
        return []
      default:
        return [
          {
            type: OTHER_BUTTON_TYPES[button.Type],
            domainType: domainType,
            disabled,
            visible: true,
            showOn: button.ShowOn,
            role: button.Role,
            name: button.Name,
            priority: button.Priority
          }
        ]
    }
  })
  return [
    ...actionButtons,
    ...otherButtons
  ]
}

function isVisible(
  button: Button,
  on: ButtonLocation | null
): boolean {
  return button.visible
    && (
      on === null
        ? (button.showOn?.length ?? 0) === 0
        : button.showOn?.includes(on) ?? false
    )
}

const priorityOrder = {
  high: 0,
  medium: 1,
  low: 2
}

function sortByPriority(b1: Button, b2: Button): 1 | -1 {
  return priorityOrder[(b1.priority ?? 'low')] >= priorityOrder[(b2.priority ?? 'low')] ? 1 : -1
}

export function useButtons(
  domainType: DomainType,
  instances: DomainTypeInstance[] | undefined = [],
  on: ButtonLocation | null,
  parameterValues?: AttributeValue[],
  leafNodeType?: 'active' | 'nested'
): Button[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const actions = useActions(domainType, instances, leafNodeType)
  return useMemo(() => {
    return getButtons(domainTypes, domainType, instances, actions, filterContext, parameterValues)
      .sort(sortByPriority)
      .filter(button => isVisible(button, on))
  }, [domainTypes, domainType, instances, actions, filterContext, parameterValues, on])
}

export interface DomainTypeListAttributeButton {
  readonly attributes: ListAttribute<DomainTypeAttribute>[]
  readonly button: Button
}

export function useDomainTypeListAttributeButtons(
  domainType: DomainType,
  instance: DomainTypeInstance | undefined,
  parameterValues?: AttributeValue[]
): DomainTypeListAttributeButton[] {
  const domainTypes = useSelector(getAllDomainTypes)
  const filterContext = useFilterContext()
  const attributes = getDomainTypeAttributes(domainTypes, domainType)
  const domainTypeListAttributes = attributes.filter(isDomainTypeListAttribute)
  return useMemo(() => {
    if (instance === undefined) {
      return []
    }
    return domainTypeListAttributes
      .flatMap(attribute => {
        const attributeDomainType = domainTypes[attribute.AttributeDomainType]
        if (attributeDomainType === undefined) {
          return []
        }
        return getButtons(domainTypes, attributeDomainType, [], [], filterContext, parameterValues)
          .sort(sortByPriority)
          .filter(button => isVisible(button, 'TableToolbar'))
          .map(button => ({
            attributes: [attribute],
            button
          }))
      })
  }, [domainTypeListAttributes, domainTypes, filterContext, instance, parameterValues])
}