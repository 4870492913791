import { ContentCopy } from '@mui/icons-material'
import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material'
import CreateDialog from 'components/domainType/CreateDialog'
import TooltipIconButton from 'components/utils/TooltipIconButton'
import { useCallback, useContext, useState } from 'react'
import { DomainTypeInstance } from 'types'
import { DomainTypeContext } from 'utils/context'
import { isNullOrUndefined } from 'utils/helpers'
import { useNavigate } from 'utils/hooks'
import { DomainTypeComponentProps } from '..'

export default function ApiKeyCreateButton({
  button,
  Component,
  onComplete
}: DomainTypeComponentProps['createButton']): JSX.Element | null {
  const [apiKey, setApiKey] = useState<DomainTypeInstance | undefined>(undefined)
  const [dialogOpen, setDialogOpen] = useState(false)
  const navigate = useNavigate()
  const context = useContext(DomainTypeContext)

  const onCloseDialog = useCallback(() => {
    setDialogOpen(false)
    onComplete?.()
  }, [setDialogOpen, onComplete])

  const onSuccess = useCallback((apiKey: DomainTypeInstance) => {
    setDialogOpen(false)
    setApiKey(apiKey)
  }, [])

  const onCloseSecretDialog = useCallback(() => {
    if (context.onInvalidate) {
      context.onInvalidate()
    } else if (!isNullOrUndefined(apiKey)) {
      navigate.toDetailsPage(button.domainType, apiKey)
    }

    setApiKey(undefined)
    onComplete?.()
  }, [context, apiKey, onComplete, navigate, button.domainType])

  const secret = String(apiKey?.['Secret'] ?? '')
  const copyToClipboard = useCallback(() => {
    (navigator.clipboard as Clipboard | undefined)?.writeText(secret)
  }, [secret])
  return (
    <>
      <Dialog
        fullWidth
        maxWidth='md'
        disableEscapeKeyDown
        open={!isNullOrUndefined(apiKey)}>
        <DialogTitle>Success</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
          You have successfully added a new API Key. Copy the secret now!
          <TextField
            value={secret}
            label='Secret'
            variant='standard'
            fullWidth
            size='small'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <TooltipIconButton
                    tooltipText='Copy'
                    toastText='Copied'
                    icon={<ContentCopy />}
                    onClick={copyToClipboard} />
                </InputAdornment>
              ),
              readOnly: true
            }} />
          <Alert severity='warning'>
            <AlertTitle>Warning</AlertTitle>
            Make sure you copy the above secret now. You will not be able to see it again.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={onCloseSecretDialog}>
            I Have Copied The Secret
          </Button>
        </DialogActions>
      </Dialog>
      <Component
        text={button.name}
        disabled={button.disabled}
        icon='add'
        onClick={() => setDialogOpen(true)} />
      <CreateDialog
        open={dialogOpen}
        domainType={button.domainType}
        onClose={onCloseDialog}
        onCreateSuccess={onSuccess} />
    </>
  )
}